<template>
    <div class="SageBackup">        
        <div style="position: fixed; z-index: 9999;">
            <transition name="fade" mode="out-in">
                <div v-if="showSuccess" class="desc-error">
                    <div class="desc"
                        style="padding-left: 2rem; padding-right: 2rem; background-color: #c2dab8 !important; opacity: 0.95;">
                        <div class="desc-header">SUCCESS:</div>
                        <div>
                            '{{ lastUploadFileName }}' has been uploaded successfully.
                        </div>
                    </div>
                </div>
            </transition>
            <transition name="fade" mode="out-in">
                <div v-if="showDescription && !showUploadFileError" class="desc-error">
                    <div class="desc" style="padding-left: 2rem; padding-right: 2rem; opacity: 0.95;">
                        <div class="desc-header">ATTENTION:</div>
                        <div>
                            File upload has been terminated:
                            <br>
                            A backup file with the name '{{ uploadFileName }}' already exists.
                        </div>
                    </div>
                </div>
            </transition>
        </div>
        <div v-if="isLoading" class="content-container">
            <div class="loading">
                <span class="loader"></span>
            </div>
        </div>
        <div v-else class="content-container">
            <div class="top-content">
                <UploadFileErrorInfo :showError="showUploadFileError" :closeError="true"
                    errorDescription="(Uploading Backup File)" :error="infoError" :change="showUploadFileErrorChanger"
                    :errorDisplayText="(
                        showGetBackupFilesInfoError?.response?.status.toString().startsWith('5')
                        || showGetBackupFilesInfoError?.toString().startsWith('AxiosError: Network Error')
                    ) ? 'Your request could not be fulfilled at this moment due to a server-side error, please try again later. If this error persists, please raise a ticket with the help desk.'
                      : `Your request could not be fulfilled at this moment due to a client error (an error with your request). Please check to ensure you are uploading the correct backup file (name and type). Please note duplicate file names are not permitted, so the name '${uploadFileName}' must not already exist.`
                    "
                />
                <GetUploadFilesErrorInfo :showError="showGetBackupFilesError" :closeError="true"
                    errorDescription="(Getting Backup Files)" :error="showGetBackupFilesInfoError" :change="showGetBackupFilesErrorChanger" />

                <div class="center-dropdown-table-container">
                    <div class="center-dropdown-table">
                        <tbody>
                            <tr>
                                <td>
                                    <div class="nudge" style="display: flex; justify-content: center; align-items: center;">
                                        Upload Sage Backup File:
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <div>
                                        <span class="search-with-button">
                                            <div>
                                                <button
                                                    @click=triggerFileInput
                                                    :title=fileSelectionTitle
                                                    :class="`left-button ${showDuplicate ? 'errored-button' : ''}`"
                                                    style="border-right: solid var(--primary-color-dark); padding-left: 3rem; padding-right: 3rem;"
                                                >
                                                    {{ uploadFileName ? `Selected '${uploadFileName}'` : 'Select file to upload' }}
                                                </button>
                                                <input ref="fileInput"
                                                    type="file"
                                                    name="fileUpload"
                                                    @change="() => ChooseFile()"
                                                    :disabled="isUploading"
                                                    style="display: none;"
                                                />
                                                <button
                                                    @click="UploadFile()"
                                                    :title="fileUploadTitle"
                                                    :disabled="!selectedFile || isUploading || showDuplicate"
                                                    class="right-button"
                                                    :style="`padding-left: 3rem; padding-right: 3rem; ${showDuplicate ? 'border-color: crimson; color: rgba(255, 0, 0, 0.4);' : ''}`"
                                                >
                                                    Upload
                                                </button>
                                            </div>
                                        </span>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </div>
                </div>
            </div>
            <div class="table-container">
                <!-- p: {{ p }}<br>
                percentage: {{ backupServices.percentage }} -->
                <div class="w3-container">
                    <div class="w3-light-grey">
                        <div
                            :class="uploadStatusClass"
                            :style="`height:24px; width:${p}%; transition: var(--tran-2);`"
                            v-if=!!selectedFile
                        ></div>
                    </div>

                    <Table :Rows=backupFiles
                        :Cols=cols
                        :Labels=labels
                        :DefaultSortCols=defaultSortCols
                        :DefaultSortOrders=defaultSortOrders
                        :HasError=!!showGetBackupFilesInfoError
                    >
                        <template #Modified="props">
                            <div
                                :class="(props.value.FileName === lastUploadFileName) && greenHighlight ? 'highlight-green' : ''"
                            >
                                {{ props.value.Modified }}
                            </div>
                        </template>
                        <template #FileName="props">
                            <div
                                :class="(props.value.FileName === lastUploadFileName) && greenHighlight ? 'highlight-green' : ''"
                            >
                                {{ props.value.FileName }}
                            </div>
                        </template>
                    </Table>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup lang="ts">
import { ref, onMounted, inject, watch, computed } from "vue";
// Services
import { apiLoggerKey } from "@/types/ServiceKeys";
import {
    SageBackupHelper,
    cols,
    labels,
    defaultSortCols,
    defaultSortOrders
} from "@/services/helpers/SageBackupHelper";
// Data
import IBackupFile from "@/Interfaces/SageBackup/IBackupFile";
// Components
import Table from "@/components/TablePersistentChecking.vue";
import ErrorInfo from "@/components/ErrorInfo.vue";
// Misc
import log from "loglevel";
import { AxiosResponse, AxiosError } from "axios";
import useNotifierWithErrFormatter from "@/services/composables/NotifierWithErrFormatter";

const fileName = "SageBackup.vue";
const Logger = inject(apiLoggerKey);

// ERROR
const UploadFileErrorInfo = ErrorInfo;
const GetUploadFilesErrorInfo = ErrorInfo;
const showUploadFileError = ref(false);
const showUploadFileErrorChanger = ref(true);
const showGetBackupFilesErrorChanger = ref(true);
const infoError = ref();

// DESCRIPTION
const showDescription = ref(false);

// SERVICES
const backupServices = new SageBackupHelper();

// MISC
const isLoading = ref(false);
const isUploading = ref(false);

onMounted(() => {
    log.trace("onMounted() Sage Backup");

    document.title = `Sage Backup - Optio`;

    GetBackups();
});

const showGetBackupFilesError = ref(false);
const showGetBackupFilesInfoError = ref();

const backupFiles = ref<IBackupFile[]>();
async function GetBackups(reloadPage: boolean = true) {
    const name = GetBackups.name;
    log.trace(name + "()");
    const Class = fileName + "/" + name;
    var Message = "Getting backup files from storage account.";

    if (reloadPage) isLoading.value = true;
    else backupFiles.value = undefined;

    await backupServices.GetBackups()
        .then(async (backups: AxiosResponse<IBackupFile[]>) => {
            backupFiles.value = backups.data;

            lastUploadFileName.value = `${uploadFileName.value}`;
            uploadFileName.value = "";
            HighlightGreen();
        })
        .catch((error: any) => {
            showGetBackupFilesError.value = true;
            showGetBackupFilesInfoError.value = error;

            showGetBackupFilesErrorChanger.value = !showGetBackupFilesErrorChanger.value;
            backupFiles.value = [];
            isLoading.value = false;
            Message = `(Error) ${Message}: ${error instanceof AxiosError ? error : error.message}`;
            log.error(Message);
            Logger?.LogError(Message, Class);
        })
    isLoading.value = false;
}

const showDuplicate = computed(() => {
    return backupFiles.value?.map((file: IBackupFile) => file.FileName).includes(uploadFileName.value!) || false
});

const selectedFile = ref<File>();
const fileInput = ref<HTMLInputElement>();
const uploadFileName = ref<string>();
const lastUploadFileName = ref<string>();
const fileSize = ref<number>();
const lastModified = ref<Date>();
const p = ref(0);
const showSuccess = ref(false);
const uploadStatusClass = ref("w3-light-grey");
const idCounter = ref(0);
const greenHighlight = ref(false);

function ChooseFile() {
    const name = ChooseFile.name;
    log.debug(`${name}()`);
    if (!fileInput.value) {
        return useNotifierWithErrFormatter().error({
            errorType: 'Choosing a file',
            error: new ReferenceError('fileInput not assigned'),
        })
    }
    // Get the selected file
    selectedFile.value = fileInput.value.files?.[0];

    log.debug(`${name}()\nSelected file: ${selectedFile.value}`)

    // Access file properties
    uploadFileName.value = selectedFile.value?.name as string;
    fileSize.value = selectedFile.value?.size as number; // Get the file size (in bytes)
    const lastModifiedTS = selectedFile.value?.lastModified;
    lastModified.value = lastModifiedTS ? new Date(lastModifiedTS) : undefined;
}
function triggerFileInput() {
    fileInput?.value?.click();
}
async function UploadFile() {
    const name = UploadFile.name;
    const Class = fileName + "/" + name;
    var Message = `Uploading backup file: ${uploadFileName.value}`;

    // Close open error and note
    ResetError();

    // Reset progress bar
    backupServices.percentage.value = 0;

    if (selectedFile.value) {
        selectedFile.value = fileInput.value?.files![0] || undefined;
        log.debug(`${name}()\nSelected file: ${selectedFile.value}`)
        // Display the file information in alert before proceeding
        if(confirm(`You are about to upload the following file:\nName: ${uploadFileName.value}\nSize: ${fileSize.value} bytes (${Math.round(((fileSize.value ? fileSize.value : 0) / 1000000) * 100) / 100} MB)\nLast Modified: ${lastModified.value}`)) {
            if (selectedFile.value && uploadFileName.value != undefined) {
                // Create a new FileReader
                var reader = new FileReader();
        
                // Add an event listener to handle when the file is loaded
                reader.onload = async (event: ProgressEvent<FileReader>) => {
                    if (event && event.target) {
                        // Use a regex to remove data url part
                        const base64String: string = (reader.result! as string)
                            .replace('data:', '')
                            .replace(/^.+,/, '');
        
                        // Upload file
                        isUploading.value = true;
                        Logger?.LogInformation(Message, Class);
                        await backupServices.BackupFile(uploadFileName.value!, fileSize.value!, base64String)
                            .then(async (response: AxiosResponse<any>) => {
                                isUploading.value = false;
                                lastUploadFileName.value = `${uploadFileName.value}`;
                                
                                ShowSuccess();
                                await GetBackups(false);
                            })
                            .catch((error: any) => {
                                if (error instanceof AxiosError) {
                                    if (error.response?.status == 409) {
                                        ShowDescription();
                                    }
                                    else {
                                        showUploadFileError.value = true;
                                        infoError.value = error;
                                    }
                                    backupServices.percentage.value = -1;
                                }
                                showUploadFileErrorChanger.value = !showUploadFileErrorChanger.value;
                                isUploading.value = false;
                                Message = `(Error) ${Message}: ${error instanceof AxiosError ? error : error.message}`;
                                log.error(Message);
                                Logger?.LogError(Message, Class);
                            });
                    }
                };
                reader.onerror = (error: ProgressEvent<FileReader>) => {
                    Message = `(Error) ${Message}: ${error}`;
                    log.error(Message);
                    Logger?.LogError(Message, Class);
                };
        
                reader.readAsDataURL(selectedFile.value);
            } else {
                // Handle the case when no file is selected
                log.debug(`${name}()\nNo file selected.`);
            }
        }
    } else {
        alert("No file selected.");
    }
}
watch(
    () => backupServices.percentage.value,
    (newVal) => {
        log.error('watch()');
        p.value = newVal;
        switch (newVal) {
            case 100:
                p.value = 100;
                uploadStatusClass.value = "w3-green";
                break;
            case -1:
                p.value = 100;
                uploadStatusClass.value = "w3-red";
                break;
            case 0:
                p.value = 0;
                uploadStatusClass.value = "w3-light-grey";
                break;
        }
    }
)
watch(
    () => selectedFile.value,
    (newVal) => {
        if (!newVal) uploadFileName.value = undefined;
    }
)
function ResetError() {
    showDescription.value = false;

    showUploadFileErrorChanger.value = !showUploadFileErrorChanger.value;
    showUploadFileError.value = false;
    infoError.value = undefined;
}
function ShowSuccess() {
    const name = ShowSuccess.name;
    log.trace(`${name}()`);

    setTimeout(() => {
        showSuccess.value = true;
    }, 2000); // milliseconds
    setTimeout(() => {
        showSuccess.value = false;
        p.value = 0;
        ResetFileSelector();
    }, 6000); // milliseconds
}
function ShowDescription() {
    setTimeout(() => {
        showDescription.value = true;
    }, 2000); // milliseconds
    setTimeout(() => {
        showDescription.value = false;
    }, 8000); // milliseconds
}
function ResetFileSelector() {
    const name = ResetFileSelector.name;
    log.trace(`${name}()`);

    selectedFile.value = undefined;
    uploadFileName.value = undefined;
    if (fileInput.value) fileInput.value.value = '';
}
const fileSelectionTitle = computed(() => {
    if (uploadFileName.value) {
        return showDuplicate.value
            ? `A backup file with the name '${uploadFileName.value}' already exists. Duplicate file names are not permitted.`
            : 'Change backup file to upload.'
    } else {
        return 'Select a backup file to upload.';
    }
});
const fileUploadTitle = computed(() => {
    if (uploadFileName.value) {
        return showDuplicate.value
            ? `A backup file with the name '${uploadFileName.value}' already exists. Duplicate file names are not permitted.`
            : `Upload Sage backup file '${uploadFileName.value}'`
    } else {
        return 'You must select a backup file before attempting to upload.';
    }
});
function HighlightGreen() {
    greenHighlight.value = true;

    setTimeout(() => {
        greenHighlight.value = false;
        idCounter.value += 1;
    }, 2000); // milliseconds
    
    idCounter.value += 1;
}
</script>

<style>
.fade-enter-active,
.fade-leave-active {
    transition: opacity 1s;
}

.fade-enter,
.fade-leave-to .fade-leave-active {
    opacity: 0;
    transition: opacity 2s;
}

.file-selector-fake-button {
    border-radius: var(--buttons-border-radius);
    border-top-right-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
    background-color: var(--primary-color-lighter);
    padding: 10px;
    color: blue;
    font-size: larger;
    font-weight: bold;
}

.file-selector input[type="file"] {
    display: none;
}

.file-selector button {
    border-top-left-radius: 0 !important;
    border-bottom-left-radius: 0 !important;
}

.upload-button {
    display: inline;
    padding-left: 4rem;
    padding-right: 4rem;
    border-radius: var(--buttons-border-radius);
    border-top-right-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
    background-color: var(--primary-color-lighter);
    color: blue;
    font-size: larger;
    font-weight: bold;
}
.search-with-button button:disabled:hover {
    background-color: var(--primary-color-lighter);
    cursor: not-allowed;
}
.errored-button {
    background-color: var(--red-background-color) !important;
    cursor: not-allowed;
    color: black !important;
    border-color: crimson !important;
}
.highlight-green {
    color: #4CAF50;
}
.highlight-green-bold {
    color: #4CAF50;
    font-weight: bold;
}


.w3-red,
.w3-hover-red:hover {
    color: #fff !important;
    background-color: #f44336 !important
}

.w3-light-grey,
.w3-hover-light-grey:hover,
.w3-light-gray,
.w3-hover-light-gray:hover {
    color: #000 !important;
    background-color: #f1f1f1 !important
}

.w3-green,
.w3-hover-green:hover {
    color: #fff !important;
    background-color: #4CAF50 !important
}
</style>
