<template>
    <BaseContractPage ref="basePage"
    hasCheckbox
    :contractGetAPI=contractGetAPI
    :cols=cols 
    :seMarketEnable="true"
>
    <template #header-extra>
        <HireAgreementPopUp v-model=secondaryTableData />
    </template>
    <template #hireAgreement-header>
        Backbook Confirmation
    </template>
    <template #backbookConfirmation="{value}">
        <span v-if="value.backbookConfirmation" 
            class="fake-link"
            @click="e=>value.hasFileData?(()=>{basePage?.viewPdf(value.contractID, 'BackbookConfirmation');e.stopPropagation()})():undefined"
        >
        <PdfDownloadSign></PdfDownloadSign>
        </span>
        <div v-else ></div>
    </template>
    <template #orderConfirmation="{value}">
        <span v-if="value.orderConfirmation" 
            class="fake-link"
            @click="e=>value.hasFileData?(()=>{basePage?.viewPdf(value.contractID, 'OrderConf');e.stopPropagation()})():undefined"
        >
            <PdfDownloadSign></PdfDownloadSign>
        </span>
        <div v-else ></div>
    </template>
    <template #orderChange="{value}">
        <span v-if="value.orderChange" 
            class="fake-link"
            @click="e=>value.hasFileData?(()=>{basePage?.viewPdf(value.contractID, 'OrderChange');e.stopPropagation()})():undefined"
        >
            <PdfDownloadSign></PdfDownloadSign>
        </span> 
        <div v-else ></div>
    </template>
    <template #contractChange="{value}">
        <span v-if="value.contractChange" 
            class="fake-link"
            @click="e=>value.hasFileData?(()=>{basePage?.viewPdf(value.contractID, 'ContractChange');e.stopPropagation()})():undefined"
        >
            <PdfDownloadSign></PdfDownloadSign>
        </span>
        <div v-else ></div>
    </template>
    <template #t&CConsented="{ value }" >
        <div class="centre-text">
            <button class="tableDisplayButton"
                v-if="value.customerTermsConsentData.length > 0"
                @click="displayTablePopUp(value.customerTermsConsentData)"
                >
                Display Table
                </button>
            </div>
        </template>
    </BaseContractPage>     
</template>

<script setup lang="ts">
import { ref, computed } from "vue";
import BaseContractPage from "./BaseContractPage.vue";
import { contractHireAgreements } from "@/services/network";

import IContractPageExpose from '@/Interfaces/components/IContractPageExpose';
import IHireAgreement from "@/Interfaces/Contract/IHireAgreement";
import ICustomerTermsConsent from "@/Interfaces/Contract/ICustomerTermsConsent";
import HireAgreementPopUp from "@/components/HireAgreementPopUp.vue";
import { marketSelection } from "@/services/helpers/Helper"; 
import PdfDownloadSign from '@/components/svg/PdfDownloadSign.vue';

const basePage = ref<IContractPageExpose<IHireAgreement>>();

    const colsGB: (keyof IHireAgreement)[] = [
    'contractID',
    'customerID',
    'orderID',
    'market',
    'status',
    'hasFileData',
    'initialDate',
    'activationDate',
    'terminationDate'
];
const colsSE: (keyof IHireAgreement)[]  = [
    'contractID',
    'customerID',
    'orderID',
    'market',
    'status',
    'backbookConfirmation',
    'orderConfirmation',
    'orderChange',
    'contractChange',
    'initialDate',
    'activationDate',
    'terminationDate',
    't&CConsented'
];
const cols = computed<(keyof IHireAgreement)[]>(()=>
    marketSelection.value == 'GB' ? colsGB : colsSE
);

const secondaryTableData = ref<ICustomerTermsConsent[]>();  
function displayTablePopUp(data: ICustomerTermsConsent[]) {
    secondaryTableData.value = data;
}
async function contractGetAPI(data) {
    const res = await contractHireAgreements.get(data);
    return res.data.contracts;
}
</script>
<style scoped lang="scss">
@import "@/assets/styles/selection-form-inputs.scss";
.selection-form  {
    align-items: flex-start;
}
.stretch {
    align-items: stretch;
}
.right-btn {
    background-color: var(--primary-color-light);
    &:hover {
        background-color: var(--primary-color-lighter);
    }
    margin: 5px;
}
.download-btn{
    justify-content: center;
    border: var(--border-width) solid var(--primary-color-dark);
    &:focus-visible {
        outline-offset: 1px;
    }
}
:deep(.toDownload) {  
    text-align: center !important;  
}
.vtl-checkbox {
    text-align: center;
    transform: scale(1.5);
    margin: 2px 10px;
    z-index: -1;
    overflow-x: hidden;
    >input[type=checkbox]:hover {
        cursor: pointer;
    }
}
.tableDisplayButton {
    margin: 0px;
    border-width: 0px;
    border-radius: var(--buttons-border-radius);
    background-color: var(--primary-color-lighter);
    padding: 5px;
    color: blue;
    font-size: larger;
    font-weight: bold;
    text-decoration: none;
    cursor: pointer;
    &:hover {
        text-decoration: none;
        background-color: var(--primary-color);
        color: white;
    }
}
:deep(.backbookConfirmation),
:deep(.orderConfirmation),
:deep(.orderChange),
:deep(.contractChange) {
    text-align: center !important;
    vertical-align: middle;
}
</style>