<template>
    <div class="LoanManagement">
        <div class="content-container">
            This page is currently under development.
        </div>
    </div>
</template>

<script setup lang="ts">
document.title = `Loan Management - Optio`;
</script>

<style lang="scss">
.development {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    height: calc(80vh - 15px);
}
</style>