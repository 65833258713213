import IFinancingFees from "@/Interfaces/FeesConfiguration/IFinancingFees";
import { findLaterDate } from "@/models/ServiceFees";
import DateIgnoreTime from "@/types/DateIgnoreTime";
import DateTimeSv from "@/types/DateTimeSv";

export default class FinancingFees implements IFinancingFees {
    Market!: string;
    DateValidFrom: DateIgnoreTime;
    FinancingFeePercentage!: number;
    FinancingFeeFixed!: number;
    LlmPercentage!: number;
    IntermediationFeePercentage!: number;
    AmcrPercentage!: number;
    VatFinancePeriodMonths!: number;
    User?: string;
    DatetimeEntryInserted: Date;
    DatetimeEntryUpdated?: Date;
    Timestamp: DateTimeSv;
    Updateable: boolean;

    constructor(raw: IFinancingFees) {
        Object.assign(this, raw)
        this.DateValidFrom = new DateIgnoreTime(raw.DateValidFrom);
        this.DatetimeEntryInserted = new Date(raw.DatetimeEntryInserted);
        this.DatetimeEntryUpdated = raw.DatetimeEntryUpdated ? new Date(raw.DatetimeEntryUpdated) : undefined;
        this.Timestamp = new DateTimeSv(findLaterDate(this.DatetimeEntryInserted, this.DatetimeEntryUpdated));
        this.Updateable = new Date().diffDays(this.Timestamp) <= 1;
    }
}