import { App } from 'vue';
import log from "loglevel";
import { INetwork } from '@/Interfaces/INetwork';

export default async function provideNetworkSvcs<T>(app: App<T>) {
    const networkSvcs = await import('.');
    const networkSvcKeys = await import('./NetworkServiceKeys');
    log.debug('provideNetworkSvcs', networkSvcs, networkSvcKeys);
    function provide(name: string, svc: INetwork) {
        app.provide(networkSvcKeys[`${name}Key`] ?? name, svc);
        return svc;
    }
    const toMock = (process.env.VUE_MOCKED_NETWORK_SVC||(await import('./mock/toMock').then(x=>x.default?.() as string??'',_=>'')))?.split(',');
    let provided = {} as Record<string, INetwork>;
    if (toMock) {
        const mockSvcs = await import('./mock');
        log.debug('provideNetworkSvcs.mock', mockSvcs);
        toMock.map(name=>{
            const svc = mockSvcs[name];
            if(svc) {
                log.debug(`Mocked ${name}`)
                provided[name] = provide(name, svc);
            }
        });
    }
    for (const name in networkSvcs) {
        if (!provided[name]) provide(name, networkSvcs[name]);
    }
    return app;
}