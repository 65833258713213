interface IJournalingType {
    Canonical: string;
    Camel: string;
    DisplayShort: string;
    DisplayLong: string;
}

export class JournalingType implements IJournalingType {
    Canonical: string;
    Camel: string;
    DisplayShort: string;
    DisplayLong: string;
    constructor(Canonical: string, Camel: string, DisplayShort: string, DisplayLong: string) {
        this.Canonical = Canonical;
        this.Camel = Camel;
        this.DisplayShort = DisplayShort;
        this.DisplayLong = DisplayLong;
    }
}

export const journalingTypes: JournalingType[] = [
    new JournalingType("CAR_SALE_INVOICE", "CarSaleInvoice", "Car Sale Invoice", "Car Sale Invoice"),
    new JournalingType("INSURANCE", "Insurance", "Insurance", "Insurance"),
    new JournalingType("COLLECTIVE_CAR_SALE_INVOICE", "CollectiveCarSaleInvoice", "Collective Car Sale Invoice", "Collective Car Sale Invoice"),
    new JournalingType("RV", "RV", "RV", "Car Sale Residual Value"),
    new JournalingType("RECHARGE_INVOICE", "RechargeInvoice", "Recharge Invoice", "Recharge Invoice"),
    new JournalingType("SERVICE_INVOICE", "ServiceInvoice", "Service Invoice", "Service Invoice"),
    new JournalingType("CUSTOMER_INVOICE", "CustomerInvoice", "Customer Invoice", "Customer Invoice"),
    new JournalingType("CUSTOMER_PAYMENT", "CustomerPayment", "Customer Payment", "Customer Payment"),
    new JournalingType("CHURN", "Churn", "Churn", "Monthly Settlement"),
    new JournalingType("RVGF", "RVGF", "RVGF", "Residual Value Guarantee Fund"),
    new JournalingType("CUSTOMER_CREDIT_NOTE", "CustomerCreditNote", "Customer Credit Note", "Customer Credit Note"),
    new JournalingType("CUSTOMER_PAYMENT_ADVICE", "CustomerPaymentAdvice", "Customer Payment Advice", "Customer Payment Advice"),
    new JournalingType("CAR_SALE_CREDIT_NOTE", "CarSaleCreditNote", "Car Sale Credit Note", "Car Sale Credit Note"),
    new JournalingType("REMARKETING_INVOICE", "RemarketingInvoice", "Remarketing Invoice", "Remarketing Invoice"),
    new JournalingType("LEDGER_TRANSFER", "LedgerTransfer", "Ledger Transfer", "Ledger Transfer"),
    new JournalingType("FINAL_SETTLEMENT_INVOICE", "FinalSettlementInvoice", "Final Settlement Invoice", "Final Settlement Invoice"),
    new JournalingType("COLLECTIVE_FINAL_SETTLEMENT_INVOICE", "CollectiveFinalSettlementInvoice", "Collective Final Settlement Invoice", "Collective Final Settlement Invoice"),
    new JournalingType("CUSTOMER_PAYMENT_ADVICE_HEADER", "CustomerPaymentAdviceHeader", "Customer Payment Advice Header", "Customer Payment Advice Header"),
];

export function CanonicalToCamel(canonical: string): string {
    return journalingTypes.find(x => x.Canonical == canonical)!.Camel;
}

export function CanonicalToDisplayShort(canonical: string): string {
    return journalingTypes.find(x => x.Canonical == canonical)!.DisplayShort;
}

export function CanonicalToDisplayLong(canonical: string): string {
    return journalingTypes.find(x => x.Canonical == canonical)!.DisplayLong;
}

export enum JournalingTypes {
    CAR_SALE_INVOICE = "CAR_SALE_INVOICE",
    COLLECTIVE_CAR_SALE_INVOICE = "COLLECTIVE_CAR_SALE_INVOICE",
    RV = "RV",
    RECHARGE_INVOICE = "RECHARGE_INVOICE",
    SERVICE_INVOICE = "SERVICE_INVOICE",
    CUSTOMER_INVOICE = "CUSTOMER_INVOICE",
    CUSTOMER_CREDIT_NOTE = "CUSTOMER_CREDIT_NOTE",
    CUSTOMER_PAYMENT = "CUSTOMER_PAYMENT",
    CHURN = "CHURN",
    RVGF = "RVGF",
    CUSTOMER_PAYMENT_ADVICE = "CUSTOMER_PAYMENT_ADVICE",
    CAR_SALE_CREDIT_NOTE = "CAR_SALE_CREDIT_NOTE",
    REMARKETING_INVOICE = "REMARKETING_INVOICE",
    CUSTOMER_PAYMENT_ADVICE_HEADER = "CUSTOMER_PAYMENT_ADVICE_HEADER",
}

export enum JournalingOptions {
    JournalReferencePrefix = "JRN",
    InvoiceReferencePrefix = "JRN_INV",
    JournalBackoutPrefix = "BCK",
}