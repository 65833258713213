export class PromiseCancelledError extends Error {
    constructor(msg: string) {
        super(msg);
        // Set the prototype explicitly.
        Object.setPrototypeOf(this, PromiseCancelledError.prototype);
    }
}
function getCancelText() {
    return `Promise was cancelled at timestamp. ${new Date()}.`;
}
class CancellablePromise<T=void> implements PromiseLike<T> {
    private promise: PromiseLike<T>;
    private abortController: AbortController;

    private copy<T1, T2>(promise: PromiseLike<T1 | T2>): CancellablePromise<T1 | T2> {
        return new CancellablePromise(promise, this.abortController);
    }
    private createCallBack<TResult1>(onResult: ((value: T) => TResult1 | PromiseLike<TResult1>) | null | undefined, 
            resolve: (res: TResult1 | PromiseLike<TResult1>)=>void, reject) {
        if (onResult) return (res: T)=>{
            if (!this.abortController.signal.aborted) {
                resolve(onResult(res));
            } else {
                reject(new PromiseCancelledError(getCancelText()));
            }
        }
        return undefined;
    }
    private createRejectCallBack<TResult2>(onRejected?: ((reason: any) => TResult2 | PromiseLike<TResult2>) | null | undefined) {
        if (onRejected) return (reason: any) => {
            if ( !this.abortController.signal.aborted || (reason instanceof PromiseCancelledError) ) {
                return onRejected(reason);
            } else {
                return onRejected(new PromiseCancelledError(getCancelText()));
            }
        };
        return undefined;
    }
    constructor(delegate: (()=>T) | (()=>PromiseLike<T>) | PromiseLike<T>, abortController?: AbortController) { 
        if (!abortController) {
            this.abortController = new AbortController();
        } else {
            this.abortController = abortController;
        }
        if (delegate instanceof Function) {
            this.promise = new Promise<T>((resolve, reject)=> {
                if (!this.abortController.signal.aborted) {
                    try {
                        resolve(delegate()); //won't work if no scheduler on browser... still fires immediately
                    } catch (e) {
                        reject(e);
                    }
                } else {
                    reject(new PromiseCancelledError(getCancelText()));
                }
            });
        } else {
            this.promise = delegate;
        }
    }
    public then<TResult1 = T, TResult2 = never>(onFulfilled?: ((value: T) => TResult1 | PromiseLike<TResult1>) | null | undefined, onRejected?: ((reason: any) => TResult2 | PromiseLike<TResult2>) | null | undefined): PromiseLike<TResult1 | TResult2> {
        const p = new Promise<TResult1>((resolve,reject)=>this.promise.then(
            this.createCallBack(onFulfilled,resolve,reject),a=>reject(a))).then(a=>a, this.createRejectCallBack(onRejected));
        return this.copy(p);
    }
    public cancel() {
        this.abortController.abort();
    }
}
export default CancellablePromise;