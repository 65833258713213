<script setup lang="ts">
import PropCaseHelper from '@/services/helpers/PropCaseHelper';
import { ref, computed } from 'vue';
import { useRouter } from 'vue-router';
interface LinkTabProps {
    /** When paramKey isn't defined, these are the route names
     * Keys of the tabs, display if tabDisplayMap not defined
    */
    tabs: string[],
    tabDisplayMap?: Record<string, string>,
    /** Keys of the tabs that are not clickable */
    disabled?: string[],
    paramKey?: string,
}
interface ParamTabProps extends LinkTabProps {
    /** Key of the route params to fetch current tab */
    paramKey: string,
}
const router = useRouter();
const emits = defineEmits<{
    (e:'change', tab:string)
}>();
const routeNameNullErrorText = 'name is not defined for current tab';
const props = defineProps<LinkTabProps|ParamTabProps>();
const key = ref(0);
const cur = computed(()=>(
    isParamTabProps(props)?
        (router.currentRoute.value.params[props.paramKey] as string)??props.tabs[0]:
        router.currentRoute.value.name?.toString()??(()=>{throw new ReferenceError(routeNameNullErrorText)})()
).toUpperCase());
function isParamTabProps(x: LinkTabProps|ParamTabProps): x is ParamTabProps {
    return !!(x as ParamTabProps).paramKey;
}
function getParamLocation(x: ParamTabProps, tab: string) {
    const paramKey = x.paramKey
    return {
        name: router.currentRoute.value.name??(()=>{throw new ReferenceError(routeNameNullErrorText)})(),
        params: {
            [paramKey]: tab.toLowerCase(),
        }
    };
}
function changeTab(tab: string) {
    const newLocation = isParamTabProps(props) ?
        getParamLocation(props, tab) :
        {name: tab};
    if (cur.value === tab.toUpperCase()) {
        key.value = key.value + 1;
        if (key.value > 10000) key.value = 0;
    } else router.push(newLocation);
    emits('change', tab);
}
</script>
<template>
<div class="tabs-container">
    <ul class="tabs">
        <li v-for="tab of tabs" 
            :class="['tab', {'selected':cur.startsWith(tab.toUpperCase())}]" 
            @click="changeTab(tab)" 
            :disabled="disabled?.includes(tab)"
        >
            <slot :value=tab>
                {{ tabDisplayMap?.[tab] ?? PropCaseHelper.toSentenceName(tab) }}
            </slot>
        </li>
    </ul>
    <RouterView :key=key />
</div>
</template>
<style lang="scss" scoped>
.tabs-container {   
    display: flex;
    flex-direction: column;
    justify-items: center;
    flex-grow: 0;
    >.tabs {
        margin: 0;
        padding: 0;
        display: flex;
        align-items: stretch;
        list-style:none;
        >.tab {
            flex-grow: 1;
            margin: 0;
            padding: 10px;
            font-size: larger;
            color: #b6b6b6;
            background-color: #f0f0f0;
            font-weight: normal;
            border-color: white;
            border-radius: var(--buttons-border-radius);
            border-width: 0px;
            text-align: center;
            &:hover {
                background-color: var(--primary-color);
                color: white;
                cursor: pointer;
            }
            &:visited {
                color: inherit;
            }
            &:disabled {
                background-color: var(--light-grey);
                color: blue;
                &:hover {
                    background-color: var(--primary-color-lighter);
                    cursor: not-allowed;
                }
            }
            &.selected {
                font-weight: bold;
                background-color: var(--primary-color-lighter);
                border-width: 0 0 .2rem 0;
                border-color: blue;
                color: blue;
                border-bottom-style: outset;
            }
        }
        >:first-child {
            border-radius: var(--topbar-sidebar-content-radius) 0px 0px 0px;
        }
        >:not(:first-child):not(:last-child) {
            border-radius: 0px;
        }
        >:last-child {
            border-radius: 0px var(--topbar-sidebar-content-radius) 0px 0px;
        }
    }
}
</style>