import { ref, Ref, inject, computed } from "vue";
import router from "@/router";
import { DenyFunctionLevelAccess, FunctionAccessLevels } from "@/config/AuthConfig";
import useSpinnerPopup from "./SpinnerPopup";
import { contractHireAgreementPDF } from "../network";
import {  marketSelection } from "../helpers/Helper";
import { apiLoggerKey, loggerKey } from "@/types/ServiceKeys";
import { IContractProps } from "@/views/BaseContractPage.vue";
import { ITableExpose, KeyTypeKeys } from "@/components/TablePersistentChecking.vue";
import IContractRaw from "@/Interfaces/Contract/IContractRaw";
import { isINotFoundError } from "@/Interfaces/Errors/INotFoundError";
import FilterOptions from "@/models/enums/ContractHAFilterOptions";
import DateIgnoreTime from "@/types/DateIgnoreTime";
import useNotifierWithErrFormatter from "./NotifierWithErrFormatter";
import { AxiosError } from "axios";

export function useContractHireAgreement<T extends IContractRaw = IContractRaw>(props: IContractProps<T>) {
    const log = inject(loggerKey);
    const Logger = inject(apiLoggerKey);
    const fileName = 'ContractHireAgreement.vue';
    // ERROR
    const invalidEntryBug = 'Code bug: validation failed to detect improper entries.';
    // FILTER INPUT
    const downloadHireAgreementsHoverText = 'Download selected hire agreements as a zip file (select hire agreements using the table checkboxes)';
    const accessDeniedDownloadHireAgreementsHoverText = 'Download selected hire agreements as a zip file (Access denied)';     
    const dateInputFormatOption = { format: 'yyyy/MM/dd'};
    const searchOption = ref<keyof typeof FilterOptions>('ID');
    const contractIDs = ref('');
    const dateRange = ref<Date[]>();
    // TABLE
    const anyChecked = ref(false);
    type contractID = 'contractID' extends KeyTypeKeys<T> ? 'contractID' : never;
    const contractTable = ref<ITableExpose<T,contractID,true>>();
    const isDownloading = ref(false);
    const downloadedContracts: Ref<T[] | undefined> = ref([]);
    const tableError = ref(false);
    // MISC
    const disallowDownload = DenyFunctionLevelAccess(FunctionAccessLevels.DownloadHireAgreement, true);
    const disallowBatchDownload = DenyFunctionLevelAccess(FunctionAccessLevels.BulkDownloadHireAgreements);
    const searchHoverText = computed(()=>searchOption.value === 'ID' ? 'Please enter contract IDs delimted by comma before clicking the search button' : 'Please use the date picker to choose start and end date before clicking the search button'); 
    function getSelected() {
        if (!contractTable.value) throw new ReferenceError('Table not mounted');
        // typescript bug
        type ContractID = T['contractID'];
        const contractIDs = contractTable.value.GetChecked() as ContractID[];
        if (contractIDs.length <= 0) {
            const msg = 'No contracts have been selected';
            useNotifierWithErrFormatter().warn({msg});
        }
        return contractIDs;
    }
    async function getAllContracts() {
        return await props.contractGetAPI({market: marketSelection.value});
    }
    async function getContractByDates(dateRange: Date[], type: keyof typeof FilterOptions): Promise<T[]> {
		log?.trace(`${getContractByDates.name}`, dateRange);
		const lookupDate = type;
		const [dateFrom, dateTo] = dateRange.map(d=>new DateIgnoreTime(d));
		return await props.contractGetAPI({dateTo, dateFrom, lookupDate, market: marketSelection.value });
    }
	function getContractByActivationDate(dateRange: Date[]): Promise<T[]> {
		return getContractByDates(dateRange, 'ACTIVATION_DATE');
	}
	function getContractByInitialDate(dateRange: Date[]): Promise<T[]> {
		return getContractByDates(dateRange, 'INITIAL_DATE');
	}
	async function getContractByID(contractIDStr: string): Promise<T[]> {
		log?.trace(`${getContractByID.name}\n(${contractIDStr})`);
		const contractIDs = contractIDStr.split(',').map(x=>x.trim());
		return await props.contractGetAPI({ contractIDs, market: marketSelection.value });
	}
    async function searchContractsByActivationDate(){
        const name = fileName + "/" + searchContractsByActivationDate.name;
        if (!dateRange.value || dateRange.value.length < 2) {
            if (props.loadAllByDefault) return await getAllContracts();
            throw ReferenceError(invalidEntryBug + ' ' + JSON.stringify(dateRange.value));
        }
        const result = await getContractByActivationDate(dateRange.value);
        return result;
    }
    async function searchContractsByInitialDate(){
        const name = fileName + "/" + searchContractsByInitialDate.name;
        if (!dateRange.value || dateRange.value.length < 2) {
            if (props.loadAllByDefault) return await getAllContracts();
            throw ReferenceError(invalidEntryBug + ' ' + JSON.stringify(dateRange.value));
        }
        const result = await getContractByInitialDate(dateRange.value);
        return result;
    }
    async function searchContractsByID() {
        if (contractIDs.value) return await getContractByID(contractIDs.value);
        if (props.loadAllByDefault) return await getAllContracts();
        throw new TypeError(invalidEntryBug);
    }
    const searchFuncs: Record<keyof typeof FilterOptions, ()=>Promise<T[] | undefined>> = {
        ID: searchContractsByID,
        INITIAL_DATE: searchContractsByInitialDate,
        ACTIVATION_DATE: searchContractsByActivationDate,
    }
    async function updateContracts(contractIDStr: string) {
        const closeSpinner = useSpinnerPopup().show();
        try {
            const contractIDs = contractIDStr.split(',').map(x=>x.trim());
            if (contractIDs.length == 0) return;
            const update = await props.contractGetAPI({ contractIDs, market: marketSelection.value });
            if (!update?.length) throw new AxiosError(`${contractIDs} not found`, '404');
            if (update.length !== contractIDs.length) {
                const notFound = contractIDs.filter(id=>!update.find(x=>x.contractID===id)).join(',');
                throw new AxiosError(`${notFound} not found`, '404');
            }
            const failure = [] as string[];
            update.forEach(
                x=> {
                    const found = downloadedContracts.value?.find(y=>y.contractID===x.contractID);
                    if (!found) {
                        failure.push(x.contractID);
                        return;
                    }
                    return Object.assign(found, x);
                }
            )
            if (failure.length) throw new ReferenceError(`${failure.join(',')} not found in the original array to update`);
        } catch (error) {
            const name = fileName + "/" + searchFuncs[searchOption.value]?.name;
            tableError.value = true;
            useNotifierWithErrFormatter().error({
                errorType: `Updating Contracts`,
                error,
                name,
                msg: isINotFoundError(error) ? `${error.response?.data.Error}` : undefined,
            });
        } finally {
            closeSpinner();
        }
    }
    async function searchContracts() {
        try {
            clearChecked();
            tableError.value = false;
            downloadedContracts.value = undefined;
            downloadedContracts.value = await searchFuncs[searchOption.value]();
            if (!downloadedContracts.value?.length) {
                useNotifierWithErrFormatter().notify({
                    msg: `${new Date().toLocaleDateString()} - There are zero results with this search.`,
                });
            }
        } catch (error) {
            const name = fileName + "/" + searchFuncs[searchOption.value]?.name;
            tableError.value = true;
            useNotifierWithErrFormatter().error({
                errorType: `Searching for Contracts`,
                error,
                name,
                msg: isINotFoundError(error) ? `${error.response?.data.Error}` : undefined,
            });
        }
    }
    function viewFullPagePDFHireAgreement(fileID: string | undefined, documentType: string | undefined) {
        log?.trace(`${viewFullPagePDFHireAgreement.name}(${fileID})`);
        const rResolved = router.resolve({
            name: "Hire Agreement (PDF)",
            params: {
                id: fileID?.trim(),
                documentType: documentType
            },
            query: {
                endpoint: process.env.VUE_APP_CONTRACT_HIRE_AGREEMENT_DOWNLOAD_URL_PARAMS,
                method: "post"
            },
        });
        const url = rResolved.href;
        // Open in new tab
        log?.debug("viewFullPagePDFHireAgreement() - Opening in new tab")
        window.open(url, "_blank");
    }
    async function viewPdf(contractID: string, documentType: string) {
        log?.trace(`${viewPdf.name}(${contractID})`);
    
        const name = viewPdf.name;
        const Class = fileName + "/" + name;
    
        viewFullPagePDFHireAgreement(contractID, documentType);
        
        const Message = `Downloaded Hire Agreement file (Contract ID: ${contractID}).`;
        Logger?.LogInformation(Message, Class);
    }
    async function clientDownloadHireAgreements() {
        log?.trace(`${clientDownloadHireAgreements.name}()`);
        const name = fileName + "/" + clientDownloadHireAgreements.name;
        const closeSpinner = useSpinnerPopup().show();
        try {
            isDownloading.value = true;
            const contractIDs = getSelected();
            if (contractIDs.length<=0) return;
            await contractHireAgreementPDF.download({ invoiceIds: contractIDs });
            const Message = `Downloaded Hire Agreement files.`;
            Logger?.LogInformation(Message, name);
        } catch (error) {
            useNotifierWithErrFormatter().error({
                errorType: 'Downloading Hire Agreement files',
                error
            });
        } finally {
            isDownloading.value = false;
            closeSpinner();
        }
    }
    function getTableRef() {
        return contractTable.value;
    }
    function clearChecked() {
        contractTable.value?.ClearChecked();
    }
    return {
        tableError,
        downloadHireAgreementsHoverText,
        searchHoverText,
        anyChecked,
        dateInputFormatOption,
        isDownloading,
        searchOption,
        contractIDs,
        dateRange,
        downloadedContracts,
        contractTable,
        disallowBatchDownload,
        disallowDownload,
        getSelected,
        updateContracts,
        searchContracts,
        clientDownloadHireAgreements,
        viewPdf,
        clearChecked,
        getTableRef
    }
}