import { IEoPVerificationReport } from "@/Interfaces/EoPVerifications/IEoPVerificationReport";
import DateTimeSv from "@/types/DateTimeSv";

export default class EoPVerificationReport {
    constructor(raw: IEoPVerificationReport) {
        this.ProcessedDateTime = new DateTimeSv(raw.ProcessedDateTime);
        this.EoPStatus = raw.EoPStatus;
        this.JournalingStatus = raw.JournalingStatus;
        this.User = raw.User;
        this.TotalAmount = raw.TotalAmount;
        this.DeviationAmount = raw.DeviationAmount;
        this.NumberOfErrorItems = raw.NumberOfErrorItems;
        this.ReportName = raw.ReportName;
        this.InvoicesInspected = raw.InvoicesInspected;
    }
    public ProcessedDateTime:  DateTimeSv;
    public EoPStatus:          string;
    public JournalingStatus:   string;
    public User:               string;
    public TotalAmount:        string;
    public DeviationAmount:    string;
    public NumberOfErrorItems: number;
    public ReportName:         string | null;
    public InvoicesInspected:  string[] | null;
}