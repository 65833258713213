<template>
    <div class="clearableContainer">
        <div v-if=selection class="clearBtn">
            <div @click="selection=null">x</div>
        </div>
        <select class="clearable" v-bind=$attrs v-model=selection>
            <slot></slot>
        </select>
    </div>
</template>
<script setup lang="ts" generic="T">
import {computed} from 'vue';
defineOptions({
  inheritAttrs: false
});
const props = defineProps<{
    modelValue: T|null;
}>();
const emits = defineEmits<{
    (e:'update:modelValue', val:T|null)
}>();
const selection = computed({
    get() {
        return props.modelValue;
    },
    set(val) {
        emits('update:modelValue', val);
    }
})
</script>
<style scoped lang="scss">
div.clearableContainer {
    position: relative;
    display: flex;
    >select.clearable {
        padding-left: 1rem;
        width: 100%;
    }
    >div.clearBtn {
        position: absolute;
        display: flex;
        padding-left: 7.5px;
        justify-content: flex-start;
        align-items: center;
        z-index: 5;
        height:100%;
        >div {
            display:inline-block;
            cursor: pointer;
            vertical-align: middle;
        }
    }
}
</style>