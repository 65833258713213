<template>
    <div class="spinner centre"></div>
</template>
<style lang="scss">
.centre {
    position: absolute; /* Add this line */
    left: 50%;
    top: 50%;
    translate: -50% -50%;
}
.spinner {
    width: 48px;
    height: 48px;
    border-radius: 50%;
    border: 3px solid;
    border-color: var(--primary-color-lighter) var(--primary-color-dark) transparent transparent;
    box-sizing: border-box;
    animation: rotation 1s linear infinite;

    &::after,
    &::before {
        content: "";
        box-sizing: border-box;
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        margin: auto;
        border: 3px solid;
        border-color: transparent transparent var(--primary-color) var(--primary-color);
        width: 40px;
        height: 40px;
        border-radius: 50%;
        box-sizing: border-box;
        animation: rotationBack 0.5s linear infinite;
        transform-origin: center center;
    }
    &::before {
        width: 32px;
        height: 32px;
        border-color: var(--primary-color-lighter) var(--primary-color-dark) transparent transparent;
        animation: rotation 1.5s linear infinite;
    }
}
</style>