import DateIgnoreTime from "@/types/DateIgnoreTime";
import IPaymentItem from "./IPaymentItem"
import DatePeriod from "@/types/DatePeriod";
import { MarketSelection, isMarketSupported } from "@/services/helpers/Helper";
import IServiceInvoicePaymentItem from "./IServiceInvoicePaymentItemRaw";

export default class ServiceInvoicePaymentItem implements IPaymentItem {
    constructor(raw: IServiceInvoicePaymentItem) {
        Object.assign(this, raw);
        if (!isMarketSupported(raw.market)) {
            console.error(`market ${raw.market} is unrecognised`, raw);
            this.market = 'GB';
        } else this.market = raw.market;
        this.dueDate = new DateIgnoreTime(raw.dueDate);
        this.issueDate = new DateIgnoreTime(raw.issueDate);
        this.periodDateFrom = new DateIgnoreTime(raw.periodDateFrom);
        this.periodDateTo = new DateIgnoreTime(raw.periodDateTo);
        this.balanceDateUpdated = new DateIgnoreTime(raw.balanceDateUpdated);
        this.periodMonth = new DatePeriod(this.periodDateFrom);
    }
    invoiceId!: string;
    amountTotal!: string;
    amountVAT!: string;
    periodMonth: DatePeriod;
    issueDate: DateIgnoreTime;
    periodDateFrom: DateIgnoreTime;
    periodDateTo: DateIgnoreTime;
    dueDate: DateIgnoreTime;
    balance!: string;
    balanceDateUpdated: DateIgnoreTime;
    market: keyof typeof MarketSelection;
}