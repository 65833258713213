interface Date {
    isValid(): boolean;
    zeroTime(): Date;
    getTimeWithOffset(): number;
    addMinutes(m: number): Date;
    addDays(m: number): Date;
    addMonths(m: number): Date;
    addYears(m: number): Date;
    diffMonths(dateFrom: Date): number;
    diffDays(dateFrom: Date): number;
}
Date.prototype.isValid = function() {
    return !isNaN(this.getTime());
}
Date.prototype.zeroTime = function() {
    this.setHours(0);
    this.setMinutes(0);
    this.setSeconds(0);
    this.setMilliseconds(0);
    return this;
}
Date.prototype.getTimeWithOffset = function() {
    return this.getTime() - this.getTimezoneOffset() * 60 * 1000;
}
Date.prototype.addMinutes = function(m: number) {
    this.setMinutes(this.getMinutes() + m);
    return this;
}
Date.prototype.addDays = function(m: number) {
    this.setDate(this.getDate() + m);
    return this;
}
Date.prototype.addMonths = function(m: number) {
    this.setMonth(this.getMonth() + m);
    return this;
}
Date.prototype.addYears = function(m: number) {
    this.setFullYear(this.getFullYear() + m);
    return this;
}
Date.prototype.diffMonths = function(dateFrom: Date) {
    return this.getMonth() - dateFrom.getMonth()
     + (12 * (this.getFullYear() - dateFrom.getFullYear()));
}
Date.prototype.diffDays = function(dateFrom: Date) {
    return (this.getTime() - dateFrom.getTime())/86400000;
}