<template>
    <div v-if="showError" class="desc-error">
        <div class="error">
            <div class="close-error">
                <button @click="showError = false" v-if="closeError">X</button>
            </div>
            <div class="error-header">{{ errorTitle }} {{ errorDescription }}:</div>
            <div>
                {{
                    errorDisplayText
                        .replace(
                            "ServerOrClient1",
                            serverError ? "server-side" : "client"
                        )
                        .replace(
                            "ServerOrClient2",
                            serverError ? "" : " (an error with your request)"
                        )
                }}
            </div>
            <div class="error-details">
                <button type="button" @click="ToggleErrorDetails" :title="errorTitle">
                    <span>i</span>
                </button>
            </div>
            <br />
            <div v-if="showErrorDetails"
                title="When contacting the help desk, please quote this error details"
                class="error-details-text"
            >
                <div class="error-details-header">
                    Error Details:
                </div>
                <div v-if="isAxiosError(error)">
                    {{
                        `${error}`.endsWith(".")
                            ? `${error}`.slice(0, -1)
                            : `${error}`
                    }}{{ error?.response?.statusText ? ` ${(
                        error?.response?.statusText
                    )}` : ''}} - message: {{ error?.response?.data?.Error }}.
                </div>
                <div v-else>
                    {{ error }}
                </div>
            </div>
        </div>
    </div>
</template>

<script setup lang="ts" generic="T extends any">
import { isAxiosError } from "axios";
import log from "loglevel";
import { ref, computed, watch } from "vue";
export interface IErrorInfoProps<T> {
    showError: boolean;
    closeError?: boolean;
    errorTitle?: string;
    errorDescription?: string;
    errorDisplayText?: string;
    error?: unknown;
    change?: boolean;
}
const props = withDefaults(defineProps<IErrorInfoProps<any>>(), {
    showError: false,
    closeError: false,
    errorTitle: "Error",
    errorDisplayText:
        "Your request could not be fulfilled at this moment due to a ServerOrClient1 errorServerOrClient2, please try again later. If this error persists, please raise a ticket with the help desk.",
    axiosError: false,
});

const showErrorDetails = ref(false);
const showError = ref(props.showError);
const serverError = computed(isServerError);

function ToggleErrorDetails() {
    showErrorDetails.value = !showErrorDetails.value;
}
function isServerError() {
    return isAxiosError(props.error) && (props.error.status ?? 404) > 400;
}
watch(
    () => props.change,
    () => {
        log.debug("Changer changed");
        showError.value = props.showError;
    }
);
</script>

<style>
.error-details {
    padding-bottom: 0.3em;
}

.error-details-text {
    font-size: 0.65rem;
}

.error-details-header {
    font-size: 0.9rem;
    padding-bottom: 0.1rem;
}

.error-details button {
    float: right;
    border-width: 0px;
    background-color: transparent;
    color: inherit;
    font-weight: 700;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 1.2rem;
    height: 1.2rem;
    border-radius: 50%;
    border: 0.1rem solid currentColor;
    font-size: 0.8rem;
}

.error-details button:hover {
    font-weight: 900;
    cursor: pointer;
    border: 0.15rem solid currentColor;
}

.error-details button:visited {
    color: inherit;
}
</style>
