import { ICarPurchasesCollectiveCarSaleInvoice } from "@/Interfaces/CarPurchases/ICarPurchasesCollectiveCarSaleInvoice";
import DateIgnoreTime from "@/types/DateIgnoreTime";

export class CarPurchasesCollectiveCarSaleInvoice {
    CollectiveInvoiceId: string;
    CollectiveInvoiceSequenceNumber: number;
    Market: string;
    IssueDate: DateIgnoreTime;
    Balance: number;

    constructor(item: ICarPurchasesCollectiveCarSaleInvoice) {
        this.CollectiveInvoiceId = item.CollectiveInvoiceId;
        this.CollectiveInvoiceSequenceNumber = item.CollectiveInvoiceSequenceNumber;
        this.Market = item.Market;
        this.IssueDate = new DateIgnoreTime(item.IssueDate);
        this.Balance = item.Balance ?? 0;
    }
}