<script setup lang="ts">
import {ref, computed, watch, onMounted,inject} from 'vue';
import { useRouter } from 'vue-router';
// Components
import TableExpandableTree, { TableExTreeData, TableExTreeDefItem } from '@/components/common/TableExpandableTree.vue';
import GroupedInputButton from '@/components/GroupedInputButton.vue';
// Services
import useNotifierWithErrFormatter from '@/services/composables/NotifierWithErrFormatter';
import { CustomerSearchOption } from '@/Interfaces/ICustomerViewParams';
import { customerView } from '@/services/network';
import { loggerKey } from '@/types/ServiceKeys';
// Table definitions
import CustomerTable from '@/Interfaces/CustomersAndContracts/ICustomerTable';
import ContractTable from '@/Interfaces/CustomersAndContracts/IContractTable';
import CarSaleCreditNoteTable from '@/Interfaces/CustomersAndContracts/ICarSaleCreditNoteTable';
import CarSaleInvoiceTable from '@/Interfaces/CustomersAndContracts/ICarSaleInvoiceTable';
import CustomerInvoiceTable from '@/Interfaces/CustomersAndContracts/ICustomerInvoiceTable';
import ChurnTable from '@/Interfaces/CustomersAndContracts/IChurnTable';
import RvgfTable from '@/Interfaces/CustomersAndContracts/IRvgfTable';
import RvTable from '@/Interfaces/CustomersAndContracts/IRvTable';
import VehicleTable from '@/Interfaces/CustomersAndContracts/IVehicleTable';
import ContractDownPaymentTable from '@/Interfaces/CustomersAndContracts/IContractDownPaymentTable';
import ContractDownPaymentScheduleTable from '@/Interfaces/CustomersAndContracts/IContractDownPaymentScheduleTable';
import FinalSettlementTable from '@/Interfaces/CustomersAndContracts/IFinalSettlementTable';
import FinalSettlementInvoiceTable from '@/Interfaces/CustomersAndContracts/IFinalSettlementInvoiceTable';
import FinalSettlementInvoiceSpecificationTable from '@/Interfaces/CustomersAndContracts/IFinalSettlementInvoiceSpecificationTable';
import RemarketingInvoiceSpecificationTable from '@/Interfaces/CustomersAndContracts/IRemarketingInvoiceSpecificationTable';
import RemarketingInvoiceTable from '@/Interfaces/CustomersAndContracts/IRemarketingInvoiceTable';
import CustomerCreditNoteTable from '@/Interfaces/CustomersAndContracts/ICustomerCreditNoteTable';
import CustomerInvoiceSpecificationTable from '@/Interfaces/CustomersAndContracts/ICustomerInvoiceSpecificationTable';
import CustomerPaymentTable from '@/Interfaces/CustomersAndContracts/ICustomerPaymentTable';
import CustomerPaymentAdviceSpecificationTable from '@/Interfaces/CustomersAndContracts/ICustomerPaymentAdviceSpecificationTable';
import CustomerCreditNoteSpecificationTable from '@/Interfaces/CustomersAndContracts/ICustomerCreditNoteSpecificationTable';
// Factories
import IFactory from '@/services/factories/IFactory';
import { extractNonArrays } from '@/services/helpers/ObjectHelper';
import { makeCarSaleCreditNoteTables, makeCarSaleInvoiceTables, makeChurnTables, makeContractDownPaymentScheduleTables, makeContractTables, makeCustomerCreditNoteSpecificationTables, makeCustomerCreditNoteTables, makeCustomerInvoiceSpecificationTables, makeCustomerInvoiceTables, makeCustomerPaymentAdviceSpecificationTables, makeCustomerPaymentTables, makeCustomerTables, makeFinalSettlementInvoiceTables, makeFinalSettlementTables, makeRemarketingInvoiceTables, makeRvgfTables, makeVehicleTables } from '@/services/factories';

type IHierarchyHelper<T> = {
    [K in keyof T]: T[K] extends undefined ? never : T[K] extends (number|string|boolean|undefined) ? never :
        T[K] extends (Array<infer P>|undefined) ? {
            key: K;
			factory?: IFactory<P,P>;
            children?: IHierarchy<P>[];
        } :
        T[K] extends (Object|undefined) ? {
            key: K;
			factory?: IFactory<NonNullable<T[K]>,NonNullable<T[K]>>;
            children?: IHierarchy<NonNullable<T[K]>>[];
        } : never
}
type IHierarchy<T> = NonNullable<IHierarchyHelper<T>[keyof IHierarchyHelper<T>]>;
// interface IHierarchy<P,CK extends ArrayKeys<P>=ArrayKeys<P>> {
//     //should actually be keys that are arrays only but see 
//     // https://github.com/microsoft/TypeScript/issues/48992
//     key: CK;
//     children?: IHierarchy<ArrayType<P,CK>>[];
// }
interface ICustomerTableWrapper {
    CustomerTable?: CustomerTable[];
}
const carSaleCreditNoteTableTreeDef: TableExTreeDefItem<CarSaleCreditNoteTable> = {
	title: 'Car Sale Credit Note Talbe',
	cols: [
		'CAR_SALE_INVOICE_ID',
		'CREDIT_NOTE_NUMBER',
		'AMOUNT_TOTAL',
		'ISSUE_DATE',
	],
	expandedCols: [
		'CAR_SALE_INVOICE_ID',
		'CREDIT_NOTE_NUMBER',
		'CREDIT_NOTE_SEQUENCE_NUMBER',
		'MARKET',
		'AMOUNT_TOTAL',
		'AMOUNT_EX_VAT',
		'AMOUNT_VAT',
		'ISSUE_DATE',
		'JOURNAL_REFERENCE',
		'JOURNAL_EXTERNAL_REFERENCE',
		'JOURNAL_DATETIME_UPSERTED',
		'JOURNAL_STATUS',
	],
	parentIdKey: 'CAR_SALE_INVOICE_ID'
}
const carSaleInvoiceTableTreeDef: TableExTreeDefItem<CarSaleInvoiceTable> = {
    title: 'Car Sale Invoice Table',
    cols: [
        "CONTRACT_ID",
        "INVOICE_ID",
        "ISSUE_DATE",
        "INVOICE_SEQUENCE_NUMBER",
        "AMOUNT_TOTAL",
    ],
    expandedCols: [
        "CONTRACT_ID",
        "INVOICE_ID",
        "ISSUE_DATE",
        "FILE_REFERENCE",
        "CONTRACT_ID",
        "COLLECTIVE_INVOICE_ID",
        "INVOICE_SEQUENCE_NUMBER",
        "MARKET",
        "AMOUNT_TOTAL",
        "AMOUNT_EX_VAT",
        "AMOUNT_VAT",
        "PAYMENT_TERMS",
        "JOURNAL_REFERENCE",
        "JOURNAL_EXTERNAL_REFERENCE",
        "JOURNAL_DATETIME_UPSERTED",
        "JOURNAL_STATUS",
        "JOURNAL_APPROVED_FOR_PAYMENT",
    ],
    idKey: 'INVOICE_ID',
	children: [carSaleCreditNoteTableTreeDef]
};
const churnTableTreeDef: TableExTreeDefItem<ChurnTable> = {
	title: 'Churn Table',
	cols:["CONTRACT_ID",
	"CHURN_SEQUENCE_NUMBER",
	"INVOICED_AMOUNT",
	"DOWNPAYMENT_AMOUNT",
	"CHURN_AMOUNT",
	"PERIOD_MONTH",],
	expandedCols:[
	"CONTRACT_ID",
	"CHURN_SEQUENCE_NUMBER",
	"MARKET",
	"INVOICED_AMOUNT",
	"DISCOUNTED_AMOUNT",
	"DOWNPAYMENT_AMOUNT",
	"RV_DROP_AMOUNT",
	"CHURN_AMOUNT",
	"PERIOD_MONTH",
	"APPROVED_CHURN_AMOUNT",
	"APPROVED_CHURN_AMOUNT_DATE_UPDATED",
	"JOURNAL_INVOICE_REFERENCE",
	"JOURNAL_INVOICE_EXTERNAL_REFERENCE",
	"JOURNAL_REFERENCE",
	"JOURNAL_EXTERNAL_REFERENCE",
	"JOURNAL_DATETIME_UPSERTED",
	"JOURNAL_STATUS",]
}
const rvgfTableTreeDef: TableExTreeDefItem<RvgfTable> = {
	title: 'Rvgf Table',
	cols:[
	"CONTRACT_ID",
	"RVGF_SEQUENCE_NUMBER",
	"PERIOD_START",
	"PERIOD_END",
	"PERIOD_MONTH",
	"RV_VALUE",
	"AMOUNT",
	"PERCENTAGE",],
	expandedCols:[
	"CONTRACT_ID",
	"RVGF_SEQUENCE_NUMBER",
	"PERIOD_START",
	"PERIOD_END",
	"PERIOD_MONTH",
	"RV_VALUE",
	"AMOUNT",
	"PERCENTAGE",
	"REMARKETING_DATE",
	"ACTUAL_SOLD_VALUE"]
}
const rvTableTreeDef: TableExTreeDefItem<RvTable> = {
	title: 'Rv Table',
	cols:[
	"CONTRACT_ID",
	"RV_SEQUENCE_NUMBER",
	"FINANCING_FEE_PERCENTAGE",
	"FINANCING_FEE_FIXED"],
}
const vehicleTableTreeDef: TableExTreeDefItem<VehicleTable> = {
	title: 'Vehicle Table',
	cols:[
	"CONTRACT_ID",
	"VEHICLE_ID",
	"MANUFACTURE_NAME",
	"ESTIMATED_DELIVERY_DATE",
	"REGISTRATION_DATE",
	"STATUS",],
	expandedCols:[
	"CONTRACT_ID",
	"VEHICLE_SEQUENCE_NUMBER",
	"MARKET",
	"VEHICLE_ID",
	"VEHICLE_PNO_12",
	"MANUFACTURE_NAME",
	"MODEL_NAME",
	"CONDITION",
	"MODEL_YEAR",
	"TYPE_CODE",
	"TRIM_LEVEL",
	"ENGINE",
	"FUEL_TYPE",
	"WHEEL_DRIVE_TYPE",
	"TRANSMISSION",
	"TIRES",
	"TOWBAR",
	"ESTIMATED_DELIVERY_DATE",
	"DELIVERY_DATE",
	"VIN",
	"REGISTRATION_DATE",
	"EV_SUBSIDY_INDICATOR",
	"REGISTRATION_NUMBER",
	"STATUS",
	"RETURN_DATE",
	"MILEAGE"]
}
const contractDownPaymentScheduleTableTreeDef: TableExTreeDefItem<ContractDownPaymentScheduleTable> = {
	title: 'Down Payment Schedule Table',
	cols:["CONTRACT_ID",
	"PERIOD_DATE",
	"DOWNPAYMENT_NET_AMOUNT",
	"DOWNPAYMENT_VAT",
	"JOURNAL_STATUS",],
	expandedCols:["CONTRACT_ID",
	"PERIOD_DATE",
	"DOWNPAYMENT_NET_AMOUNT",
	"DOWNPAYMENT_VAT",
	"JOURNAL_REFERENCE",
	"JOURNAL_EXTERNAL_REFERENCE",
	"JOURNAL_DATETIME_UPSERTED",
	"JOURNAL_STATUS",],
	parentIdKey: 'CONTRACT_ID'
}
const contractDownPaymentTableTreeDef: TableExTreeDefItem<ContractDownPaymentTable> = {
	title: 'Down Payment Table',
	cols:[
	"CONTRACT_ID",
	"CUSTOMER_INVOICE_ID",
	"CUSTOMER_INVOICE_SPECIFICATION_NUMBER",
	"DOWNPAYMENT_NET_AMOUNT",],
	expandedCols:[
	"CONTRACT_ID",
	"CUSTOMER_INVOICE_ID",
	"CUSTOMER_INVOICE_SPECIFICATION_NUMBER",
	"MARKET",
	"DOWNPAYMENT_NET_AMOUNT",
	"DOWNPAYMENT_VAT",
	"DOWNPAYMENT_VAT_CLASS",
	"NUMBER_OF_PERIODS",
	"NUMBER_OF_JOURNAL_PERIODS",
	"JOURNAL_DATETIME_UPSERTED",
	"JOURNAL_STATUS",],
	children: [contractDownPaymentScheduleTableTreeDef],
    idKey: 'CONTRACT_ID',
	filterByDataOnly: true
}
const finalSettlementTableTreeDef: TableExTreeDefItem<FinalSettlementTable> = {
	title: 'Final Settlement Table',
	cols:[
	"CONTRACT_ID",
	"FINAL_SETTLEMENT_SEQUENCE_NUMBER",
	"VEHICLE_SALES_PRICE",
	"VEHICLE_RETURN_DATE",],
	expandedCols:[
	"CONTRACT_ID",
	"MARKET",
	"FINAL_SETTLEMENT_SEQUENCE_NUMBER",
	"VEHICLE_SALES_PRICE",
	"VEHICLE_AGE",
	"VEHICLE_RETURN_DATE",
	"VEHICLE_REMARKETING_DATE",
	"GUARANTEED_RV",
	"ACCUMULATED_CHURN",]
}
const finalSettlementInvoiceSpecificationTableTreeDef: TableExTreeDefItem<FinalSettlementInvoiceSpecificationTable> = {
	title: 'Final Settlement Invoice Specification Table',
	cols:["FINAL_SETTLEMENT_INVOICE_ID",
	"SPECIFICATION_NUMBER",
	"AMOUNT_TOTAL",
	"BASE_AMOUNT",
	"VAT_PERCENTAGE",],
	expandedCols:["FINAL_SETTLEMENT_INVOICE_ID",
	"SPECIFICATION_NUMBER",
	"SPECIFICATION_CODE",
	"DESCRIPTION",
	"AMOUNT_TOTAL",
	"AMOUNT_EX_VAT",
	"VAT_CLASS",
	"BASE_AMOUNT",
	"VAT_PERCENTAGE",
	"AMOUNT_VAT",],
    parentIdKey: 'FINAL_SETTLEMENT_INVOICE_ID',
}
const finalSettlementInvoiceTableTreeDef: TableExTreeDefItem<FinalSettlementInvoiceTable> = {
	title: 'Final Settlement Invoice Table',
	cols:["CONTRACT_ID",
	"INVOICE_ID",
	"AMOUNT_TOTAL",
	"ISSUE_DATE",
	"PAYMENT_AMOUNT",
	"DUE_DATE",],
	expandedCols:["CONTRACT_ID",
	"INVOICE_ID",
	"COLLECTIVE_INVOICE_ID",
	"INVOICE_SEQUENCE_NUMBER",
	"MARKET",
	"AMOUNT_TOTAL",
	"AMOUNT_EX_VAT",
	"AMOUNT_VAT",
	"ISSUE_DATE",
	"PAYMENT_TERMS",
	"PAYMENT_TERMS_DETAILS",
	"PAYMENT_AMOUNT",
	"DUE_DATE",
	"FILE_REFERENCE",
	"JOURNAL_REFERENCE",
	"JOURNAL_EXTERNAL_REFERENCE",
	"JOURNAL_DATETIME_UPSERTED",
	"JOURNAL_STATUS",],
    idKey:'INVOICE_ID',
    children: [ finalSettlementInvoiceSpecificationTableTreeDef ]
}
const remarketingInvoiceTableTreeDef: TableExTreeDefItem<RemarketingInvoiceTable> = {
	title: 'Remarketing Invoice Table',
	cols:[
	"INVOICE_ID",
	"AMOUNT_TOTAL",
	"ISSUE_DATE",
	"DUE_DATE",
	"BUYER_ID",
	"COMPANY_NAME",
	"BALANCE",
	"COLLECTION_BALANCE",],
	expandedCols:[
	"INVOICE_ID",
	"INVOICE_SEQUENCE_NUMBER",
	"MARKET",
	"AMOUNT_TOTAL",
	"AMOUNT_VAT",
	"AMOUNT_EX_VAT",
	"ISSUE_DATE",
	"DUE_DATE",
	"BUYER_ID",
	"BUYER_TYPE",
	"COMPANY_NAME",
	"COMPANY_VAT_ID",
	"COMPANY_ADDRESS",
	"COMPANY_ZIP_CODE",
	"COMPANY_REGION",
	"COMPANY_COUNTRY",
	"FILE_REFERENCE",
	"BALANCE",
	"COLLECTION_BALANCE",
	"BALANCE_DATE_UPDATED",
	"COLLECTION_BALANCE_DATE_UPDATED",
	"JOURNAL_REFERENCE",
	"JOURNAL_EXTERNAL_REFERENCE",
	"JOURNAL_DATETIME_UPSERTED",
	"JOURNAL_STATUS",],
    parentIdKey: 'INVOICE_ID'
}
const remarketingInvoiceSpecificationTableTreeDef: TableExTreeDefItem<RemarketingInvoiceSpecificationTable> = {
	title: 'Remarketing Invoice Specification Table',
	cols:["REMARKETING_INVOICE_ID",
	"SPECIFICATION_NUMBER",
	"CONTRACT_ID",
	"CAR_PRICE_EX_VAT",
	"AMOUNT_TOTAL",
	"VAT_PERCENTAGE",],
	expandedCols:["REMARKETING_INVOICE_ID",
	"SPECIFICATION_NUMBER",
	"SPECIFICATION_CODE",
	"DESCRIPTION",
	"CONTRACT_ID",
	"VIN",
	"CAR_PRICE_EX_VAT",
	"SURCHARGES_EX_VAT",
	"AMOUNT_TOTAL",
	"AMOUNT_VAT",
	"AMOUNT_EX_VAT",
	"VAT_PERCENTAGE",
	"VAT_CLASS",],
    idKey: 'REMARKETING_INVOICE_ID',
    children: [remarketingInvoiceTableTreeDef]
}
const contractTableTreeDef: TableExTreeDefItem<ContractTable> = {
    title: 'Contract Table',
    cols: [
        "CONTRACT_ID",
        "MARKET",
        "STATUS",
        "LEASING_AMOUNT",
        "DURATION_MONTHS",
        "ACTIVATION_DATE",
        "TERMINATION_DATE",
    ],
    expandedCols: [
        "CONTRACT_ID",
        "CUSTOMER_ID",
        "CONTRACT_SEQUENCE_NUMBER",
        "MARKET",
        "STATUS",
        "ORDER_ID",
        "SALES_TYPE",
        "LEASING_AMOUNT",
        "CONTRACT_TYPE",
        "DURATION_MONTHS",
        "INITIAL_DATE",
        "ACTUAL_AMOUNT_PAID",
        "ACTIVATION_DATE",
        "BILLING_PAYMENT_METHOD",
        "TERMINATION_NOTICE_DATE",
        "TERMINATION_DATE",
        "TERMINATION_INDICATOR",
        "TERMINATION_REASON",
        "ESTIMATED_MILEAGE",
        "RAW_LEASING_AMOUNT",
        "TOTAL_SUBSCRIPTION_AMOUNT",
        "BACKBOOK",
        "CANCELLATION_DATE",
        "DOWNPAYMENT_AMOUNT",
        "VALID_FROM_DATE",
        "BACBOOK_ORIGINAL_ACTIVATION_DATE",
    ],
    children: [
        carSaleInvoiceTableTreeDef,
        churnTableTreeDef,
        rvgfTableTreeDef,
        rvTableTreeDef,
        vehicleTableTreeDef,
        contractDownPaymentTableTreeDef,
        finalSettlementTableTreeDef,
        finalSettlementInvoiceTableTreeDef,
        remarketingInvoiceSpecificationTableTreeDef,
    ],
	filterDataUpdateCallback: updateSelectedContractIDs,
    idKey: 'CONTRACT_ID'
}
contractTableTreeDef.children?.forEach(x=>x.parentIdKey='CONTRACT_ID');
const customerCreditNoteSpecificationTableTreeDef: TableExTreeDefItem<CustomerCreditNoteSpecificationTable> = {
    title: 'Customer Credit Note Specification Table',
    cols: ["CUSTOMER_CREDIT_NOTE_NUMBER",
	"SPECIFICATION_NUMBER",
	"AMOUNT_TOTAL",
	"PERIOD_DATE_FROM",
	"PERIOD_DATE_TO",
    ],
    expandedCols: ["CUSTOMER_CREDIT_NOTE_NUMBER",
	"SPECIFICATION_NUMBER",
	"SPECIFICATION_CODE",
	"DESCRIPTION",
	"CONTRACT_ID",
	"ORDER_ID",
	"AMOUNT_TOTAL",
	"AMOUNT_VAT",
	"AMOUNT_EX_VAT",
	"VAT_PERCENTAGE",
	"VAT_CLASS",
	"PERIOD_DATE_FROM",
	"PERIOD_DATE_TO",
	"JOURNAL_REFERENCE",
	"JOURNAL_EXTERNAL_REFERENCE",
	"JOURNAL_DATETIME_UPSERTED",
	"JOURNAL_STATUS",],
    parentIdKey: 'CUSTOMER_CREDIT_NOTE_NUMBER'
}
const customerCreditNoteTableTreeDef: TableExTreeDefItem<CustomerCreditNoteTable> = {
	title: 'Customer Credit Note Table',
	cols:[
	"CUSTOMER_INVOICE_ID",
	"CREDIT_NOTE_NUMBER",
	"AMOUNT_TOTAL",
	"ISSUE_DATE",
	"DUE_DATE",],
	expandedCols:[
	"CUSTOMER_INVOICE_ID",
	"CREDIT_NOTE_NUMBER",
	"CREDIT_NOTE_SEQUENCE_NUMBER",
	"MARKET",
	"AMOUNT_TOTAL",
	"AMOUNT_EX_VAT",
	"AMOUNT_VAT",
	"ISSUE_DATE",
	"DUE_DATE",
	"JOURNAL_DATETIME_UPSERTED",
	"JOURNAL_STATUS",],
    idKey: 'CREDIT_NOTE_NUMBER',
    children: [customerCreditNoteSpecificationTableTreeDef]
}
const customerInvoiceSpecificationTableTreeDef: TableExTreeDefItem<CustomerInvoiceSpecificationTable>=({
	title: 'Customer Invoice Specification Table',
	cols:["CUSTOMER_INVOICE_ID",
	"DESCRIPTION",
	"CONTRACT_ID",
	"AMOUNT_TOTAL",
	"PERIOD_DATE_FROM",
	"PERIOD_DATE_TO",],
	expandedCols:["CUSTOMER_INVOICE_ID",
	"SPECIFICATION_NUMBER",
	"SPECIFICATION_CODE",
	"DESCRIPTION",
	"CONTRACT_ID",
	"ORDER_ID",
	"AMOUNT_TOTAL",
	"AMOUNT_VAT",
	"AMOUNT_EX_VAT",
	"VAT_PERCENTAGE",
	"VAT_CLASS",
	"PERIOD_DATE_FROM",
	"PERIOD_DATE_TO",
	"JOURNAL_REFERENCE",
	"JOURNAL_EXTERNAL_REFERENCE",
	"JOURNAL_DEFERRED_RENTAL_REFERENCE",
	"JOURNAL_DEFERRED_RENTAL_EXTERNAL_REFERENCE",
	"JOURNAL_LAST_STATUS_DEFERRED_RENTAL",
	"JOURNAL_VAT_RENTAL_REFERENCE",
	"JOURNAL_VAT_RENTAL_EXTERNAL_REFERENCE",
	"JOURNAL_DATETIME_UPSERTED",
	"JOURNAL_STATUS",]
});
const customerPaymentTableTreeDef: TableExTreeDefItem<CustomerPaymentTable> = {
	title: 'Customer Payment Table',
	cols:["PAYMENT_REFERENCE",
	"CUSTOMER_INVOICE_ID",
	"AMOUNT_PAID",
	"CURRENCY",
	"PAYMENT_TO_ACCOUNT",
	"TRANSACTION_DATE",],
	expandedCols:["PAYMENT_REFERENCE",
	"CUSTOMER_INVOICE_ID",
	"CUSTOMER_CREDIT_NOTE_ID",
	"PAYMENT_SEQUENCE_NUMBER",
	"MARKET",
	"ORDER_ID",
	"AMOUNT_PAID",
	"CURRENCY",
	"PAYMENT_TO_ACCOUNT",
	"TRANSACTION_DATE",
	"JOURNAL_REFERENCE",
	"JOURNAL_EXTERNAL_REFERENCE",
	"JOURNAL_DATETIME_UPSERTED",
	"JOURNAL_STATUS",
	"REMARKETING_INVOICE_ID",
	"INVOICE_TYPE",
	"IS_CREDIT_NOTE",]
}
const customerPaymentAdviceSpecificationTableTreeDef: TableExTreeDefItem<CustomerPaymentAdviceSpecificationTable> = {
	title: 'Customer Payment Advice Specification Table',
	cols:["PAYMENT_ADVICE_REFERENCE",
	"EXECUTION_DATE",
	"CUSTOMER_INVOICE_ID",
	"CURRENCY",
	"AMOUNT_PAID",
	"TRANSACTION_DATE",],
	expandedCols:["PAYMENT_ADVICE_REFERENCE",
	"MARKET",
	"EXECUTION_DATE",
	"SPECIFICATION_NUMBER",
	"CUSTOMER_INVOICE_ID",
	"CUSTOMER_CREDIT_NOTE_ID",
	"DOCUMENT_NUMBER",
	"CURRENCY",
	"AMOUNT_PAID",
	"PAYMENT_REFERENCE",
	"TRANSACTION_DATE",
	"JOURNAL_REFERENCE",
	"JOURNAL_EXTERNAL_REFERENCE",
	"JOURNAL_DATETIME_UPSERTED",
	"JOURNAL_STATUS",
	"REMARKETING_INVOICE_ID",
	"INVOICE_TYPE",
	"IS_CREDIT_NOTE",]
}
const customerInvoiceTableTreeDef: TableExTreeDefItem<CustomerInvoiceTable>={
    title: 'Customer Invoice Table',
    cols: [
        "INVOICE_ID",
        "MARKET",
        "AMOUNT_TOTAL",
        "ISSUE_DATE",
        "DUE_DATE",
        "BALANCE",
        "COLLECTION_BALANCE",
    ],
    expandedCols: [
        "INVOICE_ID",
        "INVOICE_SEQUENCE_NUMBER",
        "MARKET",
        "AMOUNT_TOTAL",
        "AMOUNT_VAT",
        "AMOUNT_EX_VAT",
        "ISSUE_DATE",
        "DUE_DATE",
        "CUSTOMER_ID",
        "BALANCE",
        "COLLECTION_BALANCE",
        "JOURNAL_DATETIME_UPSERTED",
        "JOURNAL_STATUS",
    ],
    children: [
        customerCreditNoteTableTreeDef,
        customerInvoiceSpecificationTableTreeDef,
        customerPaymentTableTreeDef,
        customerPaymentAdviceSpecificationTableTreeDef
    ].map(x=>({ parentIdKey:'CUSTOMER_INVOICE_ID', ...x })),
    idKey: 'INVOICE_ID'
};
const customerTableTreeDef = ref<TableExTreeDefItem<CustomerTable>>({
    title: 'Customer Table',
    cols: [
        "CUSTOMER_ID",
        "CUSTOMER_SEQUENCE_NUMBER",
        "DATE_CREATED",
        "SOURCE",
        "CUSTOMER_TYPE",
    ],
	rowGap: 2,
    children: [
        contractTableTreeDef,
        customerInvoiceTableTreeDef
    ]
});
const customerAccessors: IHierarchy<ICustomerTableWrapper> = {
    key: 'CustomerTable',
	factory: makeCustomerTables,
    children: [ {
            key: 'ContractTable',
			factory: makeContractTables,
            children: [{
                    key: 'CarSaleInvoiceTable',
					factory: makeCarSaleInvoiceTables,
                    children: [ { key: 'CarSaleCreditNoteTable', factory: makeCarSaleCreditNoteTables } ]
                },
                { key: 'ChurnTable', factory: makeChurnTables },
                { key: 'RvgfTable', factory: makeRvgfTables },
                { key: 'RvTable' },
                { key: 'VehicleTable', factory: makeVehicleTables },
                { key: 'ContractDownPaymentTable',
				  children: [ { key: 'downpayments', factory: makeContractDownPaymentScheduleTables } ]
				},
                { key: 'FinalSettlementTable', factory: makeFinalSettlementTables }, {
                    key: 'FinalSettlementInvoiceTable',
					factory: makeFinalSettlementInvoiceTables,
                    children: [ { key: 'FinalSettlementInvoiceSpecificationTable' } ]
                }, {
                    key: 'RemarketingInvoiceSpecificationTable',
                    children: [ { key: 'RemarketingInvoiceTable', factory: makeRemarketingInvoiceTables } ]
                }
            ]
        }, {
            key: 'CustomerInvoiceTable',
			factory: makeCustomerInvoiceTables,
            children: [ { 
					key: 'CustomerCreditNoteTable', 
					factory: makeCustomerCreditNoteTables,
					children: [ { 
						key: 'CustomerCreditNoteSpecificationTable',
						factory: makeCustomerCreditNoteSpecificationTables
					} ] },
                { key: 'CustomerInvoiceSpecificationTable', factory: makeCustomerInvoiceSpecificationTables },
                { key: 'CustomerPaymentTable', factory: makeCustomerPaymentTables },
                { key: 'CustomerPaymentAdviceSpecificationTable', factory: makeCustomerPaymentAdviceSpecificationTables },
            ]
        },
    ],
};
const router = useRouter();
const log = inject(loggerKey);
const id = ref("");
const idOption = ref<keyof typeof CustomerSearchOption>('customers');
const customerData = ref<TableExTreeData<CustomerTable>>({ rows: [] });

watch(
    () => (idOption.value, id.value),
    () => {
        // update route
        const r = {
            name: router.currentRoute.value.name ?? 'Customer & Contract',
            query: id.value ? {
                idOption: idOption.value,
                id: id.value
            } : {
                idOption: idOption.value,
            },
        };
        router.push(r);
    }
)
onMounted(() => {
    log?.trace("onMounted()");

    document.title = `Customer & Contract - Optio`;
    const qIdOption = router.currentRoute.value.query.idOption?.toString();
    if (qIdOption) {
        log?.debug("onMounted(): table");
        if (CustomerSearchOption[qIdOption]) {
            idOption.value = qIdOption as keyof typeof CustomerSearchOption;
        }
        if (router.currentRoute.value.query.id) {
            log?.debug("onMounted(): table and id");
            id.value = router.currentRoute.value.query.id.toString().trim();
            search();
        }
    }
});
function clearContract() {
    customerData.value = {};
}
function preallocate<P,C=unknown>(root: IHierarchy<P>) {
    // breath first search algorithm
    const datRoot: TableExTreeData<C> =  {};
    const queue = [root] as IHierarchy<any>[];
    const datQ = [datRoot] as TableExTreeData<unknown>[];

    while(queue.length>0) {
        const tempNode = queue.shift();
        const tempDat = datQ.shift();

        if (tempDat) {
            tempDat.rows = [];
            if (tempNode && tempNode.children) {
                tempDat.children = Array.from({ length: tempNode.children.length },()=>({} as TableExTreeData<unknown>));
                datQ.push(...tempDat.children);
                queue.push(...tempNode.children);
            }
        }
    }
    return datRoot;
}
/**
 * @arr the array of objects P to get tables from
 * @datArray data array of the current depth
 * @accessors array of key acccessors to get tables from current P
 */
function mapDataRecursion<P>(arr: P[], datArray: TableExTreeData<unknown>[], accessors: IHierarchy<P>[]) {
    for (const obj of arr) {
        accessors.forEach((accessor, i)=>{
            // may cause error because type checking fails here
            const tmp = obj[accessor.key] as (unknown[]|unknown|undefined);
            if (tmp) {
            	const kArr = tmp instanceof Array ? tmp : [tmp];
                const rowsToPush = datArray[i]?.rows;
                if (!rowsToPush) throw new ReferenceError('Table tree must be preallocated');
				const factory = accessor.factory ?? extractNonArrays;
                rowsToPush.push(...factory(kArr))
                const datArrayiChildren = datArray[i].children;
                if (datArrayiChildren && accessor.children) {
                    mapDataRecursion(kArr, datArrayiChildren, accessor.children as IHierarchy<unknown>[]);
                }
            }
        });
    }
    return datArray;
}
function mapCustomerTableToData(table: CustomerTable|'') {
    if (!table) {
        customerData.value.rows = [];
        return;
    }
    const newDat = preallocate<ICustomerTableWrapper, CustomerTable>(customerAccessors);
	//@ts-expect-error
    newDat.rows = customerAccessors.factory([table]);
    if (customerAccessors.children) {
        if (!newDat.children) throw new ReferenceError('Table tree must be preallocated');
        mapDataRecursion([table], newDat.children, customerAccessors.children);
    }
    customerData.value = newDat;
}
function updateSelectedContractIDs(filterData: string[]){
	const custInvoicePos = 1;
	const custInvoiceSpecPos = 1;

	if (customerTableTreeDef.value.children?.[custInvoicePos]?.children?.[custInvoiceSpecPos]) {
		const invoiceSpecFilter = x=>x.CONTRACT_ID?filterData.some(id=>x.CONTRACT_ID===id):false;
		customerTableTreeDef.value.children[1].children[1].filter = invoiceSpecFilter;

		if (customerData.value.children?.[custInvoicePos]?.children?.[custInvoiceSpecPos]) {
			const invoiceIds = customerData.value.children?.[custInvoicePos]?.children?.[custInvoiceSpecPos].rows?.filter(invoiceSpecFilter)?.map(x=>x.CUSTOMER_INVOICE_ID);
			customerTableTreeDef.value.children[1].filter = invoiceIds ? x=>invoiceIds.some(id=>x.INVOICE_ID===id) : undefined;
		}
	}
}
async function search() {
    const classPath = `${__filename}/${search.name}`;
    const message = `Searching for data (id: ${id.value}, idOption: ${idOption.value})`;
    try {
        clearContract();
        const contractTable = (await customerView.get({
            id: id.value,
            idOption: idOption.value,
        }, null)).data;
        mapCustomerTableToData(contractTable);
		if (!contractTable) useNotifierWithErrFormatter().notify({
			msg: 'No data has been found.'
		});
    } catch(error) {
        useNotifierWithErrFormatter().error({
            name: classPath,
            errorType: message,
            error,
        });
        customerData.value.hasError = true;
    }
}
</script>
<template>
<div class="content-container">
    <div class="top-content">
        <form @submit.prevent=search class="flex-col-centered">
            <label class="label-title" for='select-id'>Search database for Customer or Contract:</label>
            <GroupedInputButton>
                <input v-model=id
                    id="search-id"
                    type="text"
                    :placeholder="`Enter ${CustomerSearchOption[idOption]} ID...`"
                    required
                />
                <select v-model=idOption id="search-option" required>
                    <option v-for="op of Object.entries(CustomerSearchOption)" :key="op[0]" :value="op[0]">
                        {{ op[1] }}
                    </option>
                </select>
                <button class="selection-form-btn" title="View customer data corresponding to the entered id">View</button>
            </GroupedInputButton>
        </form>
    </div>
    <TableExpandableTree :def=customerTableTreeDef :dat=customerData hasExpandAllCols />
</div>
</template>
<style scoped lang="scss">
@import "@/assets/styles/selection-form-inputs.scss";
.label-title {
    font-size: clamp(0.75rem, 0.75vw, 1.75rem);
    margin: 5px;
}
:deep(div.vtl-header) {
	font-size: 12px;
}
</style>