import { Ref, ref } from "vue";
import { AxiosError } from "axios";
import { IJournalVAT, IJournalVATMetadata } from "@/Interfaces/VATReturns/IJournalVAT";
import JournalVAT from "@/models/VATReturns/JournalVat";
import ErrorInfo from "@/components/ErrorInfo.vue";
import { JournalingType, journalingTypes } from "@/models/Journaling/JournalingTypes";
import { DisplayableKeys } from "@/services/SorterService";

// TABLE
export const cols: DisplayableKeys<JournalVAT>[] = [
	"Id",
	"Reference",
	"IssueDateTime",
	"AmountExVat",
	"AmountVat",
	"AmountTotal",
	// "JournalingType"
];
export const labels = [
	"Id",
	"Reference",
	"Issue Date",
	"Amount Excl. VAT",
	"Amount VAT",
	"Amount Total",
	// "JournalingType"
];

export const VATReturns: Ref<IJournalVAT[] | undefined> = ref(undefined);
export const displayedVATReturns: Ref<JournalVAT[] | undefined> = ref(undefined);
export const box1VATReturns: Ref<JournalVAT[] | undefined> = ref(undefined);
export const box3VATReturns: Ref<JournalVAT[] | undefined> = ref(undefined);
export const box4VATReturns: Ref<JournalVAT[] | undefined> = ref(undefined);
export const box5VATReturns: Ref<JournalVAT[] | undefined> = ref(undefined);
export const box6VATReturns: Ref<JournalVAT[] | undefined> = ref(undefined);
export const box7VATReturns: Ref<JournalVAT[] | undefined> = ref(undefined);
export const tabVATReturns: Ref<JournalVAT[] | undefined> = ref(undefined);
export const displayAmountVATTotal = ref(0);
export const tabAmountVATTotal = ref(0);
export const isLoading: Ref<boolean> = ref(false);
export const isDateSearch: Ref<boolean> = ref(false);

export enum SupportedJournalingTypes {
	CarSaleInvoice,
	ServiceInvoice,
	CustomerInvoice,
	CustomerCreditNote,
	CarSaleCreditNote,
	RemarketingInvoice,
};

export const supportedJournalingTypes: JournalingType[] = journalingTypes.filter((jt: JournalingType) =>
	Object.keys(SupportedJournalingTypes).includes(jt.Camel)
);


// ERROR
export const SearchForVATReturnsFromJournaledReferencesErrorInfo = ErrorInfo;
export const showSearchForVATReturnsFromJournaledReferencesError: Ref<boolean> = ref(false);
export const showSearchForVATReturnsFromJournaledReferencesErrorChanger: Ref<boolean> = ref(true);
export const DownloadVATReturnsErrorInfo = ErrorInfo;
export const showDownloadVATReturnsError: Ref<boolean> = ref(false);
export const showDownloadVATReturnsErrorChanger: Ref<boolean> = ref(true);
export const infoError: Ref<AxiosError | undefined> = ref(undefined);

// DESCRIPTION
export const showDescription: Ref<boolean> = ref(false);
export const description: Ref<string | undefined> = ref(undefined);
export const journalingType: Ref<string[] | undefined> = ref([]);
export const metadata: Ref<IJournalVATMetadata | undefined> = ref(undefined);

// MISC
export const journalReference = ref("");
export const validJournalReferences: Ref<string[]> = ref([]);

// Instantiate list with invalid references
export const invalidJournalReferences: Ref<string[]> = ref([]);
// Create a map to store valid journal references by journal type
export const validJournalReferencesByJournalType: Ref<Record<string, string[]>> = ref({});

export const journalTypeSelection: Ref<string> = ref("");
export const journalTypeSelectionDate: Ref<string> = ref("");
export const dateRange: Ref<Date[] | undefined> = ref(undefined);
export const textInputOptions = ref({ format: 'yyyy/MM/dd' });

// Tabs
export enum tabs {
	"Summary" = 0,
	"Box1" = 1,
	"Box2" = 2,
	"Box3" = 3,
	"Box4" = 4,
	"Box5" = 5,
	"Box6" = 6,
	"Box7" = 7,
	"Box8" = 8,
	"Box9" = 9,
}
export const currentTab: Ref<tabs> = ref(tabs.Summary);

export const Box1VATs: Ref<JournalVAT[]> = ref([]);
export const Box3VATs: Ref<JournalVAT[]> = ref([]);
export const Box4VATs: Ref<JournalVAT[]> = ref([]);
export const Box5VATs: Ref<JournalVAT[]> = ref([]);
export const Box6VATs: Ref<JournalVAT[]> = ref([]);
export const Box7VATs: Ref<JournalVAT[]> = ref([]);

export const box1AmountVATTotal: Ref<number> = ref(0);
export const box3AmountVATTotal: Ref<number> = ref(0);
export const box4AmountVATTotal: Ref<number> = ref(0);
export const box5AmountVATTotal: Ref<number> = ref(0);
export const box6AmountExVATTotal: Ref<number> = ref(0);
export const box7AmountExVATTotal: Ref<number> = ref(0);

export const box1JournalingTypes: Ref<string[]> = ref([]);
export const box3JournalingTypes: Ref<string[]> = ref([]);
export const box4JournalingTypes: Ref<string[]> = ref([]);
export const box5JournalingTypes: Ref<string[]> = ref([]);
export const box6JournalingTypes: Ref<string[]> = ref([]);
export const box7JournalingTypes: Ref<string[]> = ref([]);
