import { AxiosError, AxiosResponse } from "axios";
import { financialSystemNetworkService } from "@/services/helpers/Helper";
import { Log2API } from "@/services/Logger";
import { IAPILogger } from "@/Interfaces/IAPILogger";
import { authService } from "@/auth/authSingleton";
import log from "loglevel";
import {IEoPVerificationReport} from "@/Interfaces/EoPVerifications/IEoPVerificationReport";
import INewEoPReportBody from "@/Interfaces/EoPVerifications/INewEoPReportBody";
import { ref } from "vue";
import { DisplayableKeys } from "../SorterService";

const fileName = "EoPVerification.vue";
const Logger: IAPILogger = new Log2API();

export const cols: DisplayableKeys<IEoPVerificationReport>[] = [
    "ProcessedDateTime",
    "TotalAmount",
    "DeviationAmount",
    "ReportName",
    "EoPStatus",
    "JournalingStatus",
    "NumberOfErrorItems",
    "User"
];
export const labels = [
    "Processed",
    "Total Amount",
    "Amount Diff",
    "Detailed Report",
    "EoP Status",
    "Journaling Status",
    "# Errors",
    "User"
];
const sortCol = "ProcessedDateTime" as keyof IEoPVerificationReport;
export const defaultSortCols= [ cols.findIndex((col) => col === sortCol ) ];
export const defaultSortOrders = ref<(1|-1)[]>([-1]);

export class EoPVerificationReportServices {
    public async processEoPVerificaitonData(url: string): Promise<IEoPVerificationReport[]> {
        log.trace(`processEoPVerificaitonData()\nurl: ${url}`);

        const data: IEoPVerificationReport[] = await this.getEoPVerificationData(url)
        .then((data) => {
            log.debug("processEoPVerificaitonData()\ndata", data);
            return data;
        })
        .catch((error: any) => {
            throw error;
        });

        return data;
    }

    private getEoPVerificationData(url: string): Promise<IEoPVerificationReport[]> {
        log.trace(`getEoPVerificationData()\nurl: ${url}`);
    
        return new Promise((resolve, reject) => {
            this.GetEoPVerificationReport(url)
                .then((res: AxiosResponse<IEoPVerificationReport[]>) => {
                    log.debug("getEoPVerificationData()\nres.data", res.data);
                    resolve(res.data);
                })
                .catch((error: AxiosError) => {
                    log.warn(`getEoPVerificationData() CATCH AXIOS ERROR\nstatus: ${error.response?.status}\ndata: ${error.response?.data}\nError: ${error}`);
                    reject(error);
                })
                .catch((error: any) => {
                    reject(error);
                });
        });
    }
    
    public async GetEoPVerificationReport(urlParams: string): Promise<AxiosResponse> {
        log.trace("GetEoPVerificationReport()\nurlParams", urlParams);

        return new Promise((resolve, reject) => {
            financialSystemNetworkService
                .get(urlParams)
                .then((res: AxiosResponse<IEoPVerificationReport[]>) => {
                    resolve(res);
                }).catch((error: any) => {
                    reject(error);
                });
        });
    }
    
	public async CreateNewEoPReport(body: INewEoPReportBody): Promise<number> {
        log.trace("CreateNewEoPReport()\nbody", body);
		
        // const url = this.url + "eop-verifications/reports";
		const urlParams: string = process.env.VUE_APP_CREATE_NEW_REPORT_EOP_VERIFICATIONS_URL_PARAMS!;

        return new Promise(async (resolve, reject) => {
            financialSystemNetworkService
                .post(body, urlParams)
                .then((res: AxiosResponse) => {
                    log.debug("CreateNewEoPReport()\nres.status", res.status);
                    resolve(res.status);
                }).catch((error: any) => {
                    reject(error);
                });
		});
	}

    public async LogViewingInvoice(invoiceName: string, EoPVerificationId: string) {
        log.trace(`LogViewingInvoice()\ninvoiceName: ${invoiceName}\nEoPVerificationId: ${EoPVerificationId}`);

        const name = this.LogViewingInvoice.name;
        const Message = `(${authService.getUserName()}) Viewied EoP Verification Invoice (pdf); Invoice Name: ${invoiceName} (EoPVerificationId: ${EoPVerificationId}).`;
        const Class = fileName + "/" + name;
        log.info(Message);
        Logger.LogInformation(Message, Class);
    }

    public async LogViewingDetailedReport(reportName: string, EoPVerificationId: string) {
        log.trace(`LogViewingDetailedReport()\nreportName: ${reportName}\nEoPVerificationId: ${EoPVerificationId}`);

        const name = this.LogViewingDetailedReport.name;
        const Message = `(${authService.getUserName()}) Viewed EoP Verification Detailed Report (pdf); Detailed Report Name: ${reportName} (EoPVerificationId: ${EoPVerificationId}.`;
        const Class = fileName + "/" + name;
        log.info(Message);
        Logger.LogInformation(Message, Class);
    }

    public async LogCreateNewReport(market?: string, company?: string, period?: string, EoPVerificationId?: string) {
        log.trace(`LogCreateNewReport()\nmarket: ${market}\ncompany: ${company}\nperiod: ${period}\nEoPVerificationId: ${EoPVerificationId}`)

        const name = this.LogCreateNewReport.name;
        const Message = `(${authService.getUserName()}) Created New EoP Verification Report (Market: ${market}, Company: ${company}, Period: ${period} from EoP Verification Report Id: ${EoPVerificationId}).`;
        const Class = fileName + "/" + name;
        log.info(Message);
        Logger.LogInformation(Message, Class);
    }
}


