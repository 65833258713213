import log from "loglevel";
import IMeta from "@/Interfaces/IMeta";import DeferredPromise from "@/types/DeferredPromise";

export interface IPaginatedData<T> extends IMeta {
    data: T[];
}
// don't support sorting for now
type callback<T> = (offset: number, count: number, bookmark?: number) => Promise<IPaginatedData<T>>;

export default class PaginatedCache<T> {
    private meta?: IMeta;
    // number of items per virtual page
    private nPerPage: number;
    // number is page
    private pages: Record<number, DeferredPromise<T[]>> = {};
    constructor(private loadCallBack: callback<T>, nPerPage?: number) {
        this.nPerPage = nPerPage ?? 20;
    }
    private async fetchFromCache(offset: number, count: number) {
        const cachePageStart = Math.floor(offset / this.nPerPage);
        const nCachePage = Math.ceil(count / this.nPerPage);
        let full = [] as T[];
        const proms = [...Array(nCachePage).keys()].map(i=>{
            const prom = this.pages[i + cachePageStart];
            if (!prom) throw new ReferenceError(`${cachePageStart} + ${i} = ${i+cachePageStart} index out of range exception.`);
            return prom;
        });
        for(const prom of proms) {
            const pageI = await prom;
            full = full.concat(pageI);
        }
        const cacheStartOffset = cachePageStart*this.nPerPage;
        const localOffset = offset - cacheStartOffset;
        log.debug('fetchFromCache', offset, count, this.pages, full);
        return full.slice(localOffset, localOffset + count);
    }
    private async cachePages(cachePageStart: number, nCachePage: number) {
        log.debug(this.cachePages.name, `fetching ${nCachePage} pages starting from ${cachePageStart}`);
        const cachePageEnd = cachePageStart + nCachePage;
        const toFetch = nCachePage * this.nPerPage;
        for (let i = cachePageStart; i < cachePageEnd; ++i) {
            this.pages[i] = new DeferredPromise<T[]>();
        }
        try {
            const res = await this.loadCallBack(cachePageStart*this.nPerPage, toFetch);
            for (const i of Array(nCachePage).keys()) {
                const start = i*this.nPerPage;
                const end = start + this.nPerPage;
                const page = res.data.slice(start, end);
                this.pages[i + cachePageStart].Resolve(page);
            }
            return res;
        } catch (e: unknown) {
            for (let i = cachePageStart; i < cachePageEnd; ++i) {
                this.pages[i].Reject(e);
                this.pages[i].catch(()=>{});
                delete this.pages[i];
            }
            throw(e);
        }
    }
    async load(offset: number, count: number) {
        console.trace(`loading ${count} starting from ${offset}`);
        const cachePageStart = Math.floor(offset / this.nPerPage);
        const nCachePage = Math.ceil(count / this.nPerPage);
        const cachePageEnd = cachePageStart + nCachePage;

        for (let i = cachePageStart; i < cachePageEnd; ++i) {
            const fetched: DeferredPromise<T[]> | undefined = this.pages[i];
            if (!fetched) {
                const nPageToFetch = cachePageEnd - i;
                log.debug(`${nPageToFetch} pages (${this.nPerPage} per page) missing from cache starting from ${i}`);
                const res = await this.cachePages(i, nPageToFetch);
                this.meta = {
                    bookmark: res.bookmark,
                    totalCount: res.totalCount,
                };
                break;
            }
        }
        return await this.fetchFromCache(offset, count);
    }
    getTotal() {
        return this.meta?.totalCount;
    }
    reset() {
        log.debug('resetting cache');
        this.meta = undefined;
        this.pages = {};
    }
}