import DateIgnoreTime from "@/types/DateIgnoreTime";
import DatePeriod from "@/types/DatePeriod";
import IPaymentItem from "./IPaymentItem";
import IRechargeInvoicePaymentItems from "./IRechargeInvoicePaymentsItem";
import { MarketSelection, isMarketSupported } from "@/services/helpers/Helper";

export default class RechargeInvoicePaymentItem implements IPaymentItem {
    constructor(raw: IRechargeInvoicePaymentItems) {
        if (!isMarketSupported(raw.MARKET)) {
            console.error(`market ${raw.MARKET} is unrecognised`, raw);
            this.market = 'GB';
        } else this.market = raw.MARKET;
        this.invoiceId = raw.INVOICE_ID;
        this.balance = raw.BALANCE;
        this.amountTotal = raw.AMOUNT_TOTAL;
        this.amountVAT = raw.AMOUNT_VAT;
        this.dueDate = new DateIgnoreTime(raw.DUE_DATE);
        this.issueDate = new DateIgnoreTime(raw.ISSUE_DATE);
        this.balanceDateUpdated = new DateIgnoreTime(raw.BALANCE_DATE_UPDATED);
        this.periodMonth = new DatePeriod(this.issueDate);
    }
    invoiceId: string;
    periodMonth: DatePeriod;
    balance: string;
    market: keyof typeof MarketSelection;
    amountTotal: string;
    amountVAT: string;
    issueDate: DateIgnoreTime;
    dueDate: DateIgnoreTime;
    balanceDateUpdated: DateIgnoreTime;
}