<template>
    <div class="currency-input-container">
        <input v-bind=$attrs
            :class="{'currency-input-long': symbolLen > 1, 'currency-input' : symbolLen == 1 }"
            type="number"
            :step=step
            :value=modelValue
            @input="$emit('update:modelValue', ($event?.target as HTMLInputElement)?.value)"
        />
    </div>
</template>
<script setup lang="ts">
import { ref, computed } from 'vue';
import { CurrencySymbol } from '@/services/helpers/Helper';
defineOptions({
  inheritAttrs: false
});
// maybe use span instead of background image in alternative implementation
const props = withDefaults(defineProps<{
    modelValue: string|number|undefined;
    currency?: string;
    step?: string|number;
}>(),{
    currency: Object.keys(CurrencySymbol)[0],
    step: 0.01
});
defineExpose({
    isValid() { return inputEl.value?.validity?.valid; },
    getValidMsg() { return inputEl.value?.validationMessage; }
});
const inputEl = ref<HTMLInputElement>();
const symbolLen = ref(0);
const symbol = computed(()=>{
    symbolLen.value = (CurrencySymbol[props.currency] ?? CurrencySymbol.GBP).trim().length;
    return `"${CurrencySymbol[props.currency]??CurrencySymbol.GBP}"`
})
</script>
<style lang="scss" scoped>
    .currency-input-container{
        display: inline-block; //makes it use the same style as input
        position: relative;
        &::before {
            position: absolute;
            content: v-bind(symbol);
            transform: translate(75%,-50%);
            top: 50%;
        }
        >input[type=number].currency-input {
            width: 100%;
            height: 100%;
            padding-left: 20px;
            min-width: 0;
        }
        >input[type=number].currency-input-long {
            width: 100%;
            height: 100%;
            padding-left: 30px;
            min-width: 0;
        }
    }
</style>