<template>
    <div class="center-dropdown-table-container">
        <div class="center-dropdown-table" style="margin-top: 0px;">
            <tbody>
                <tr>
                    <td style="width: 14.2857%;">
                        <div class="center-buttons bottom-margin-only">
                            <button :class="`tab-button-left ${currentTab == tabs.Summary ? 'selected' : 'unselected'}`"
                                style="width: 100%;" :disabled="currentTab == tabs.Summary"
                                :title="currentTab == tabs.Summary ? 'Currently viewing Summary' : 'Click to view Summary'"
                                @click="ToggleTab(tabs.Summary)">
                                <div>
                                    Summary
                                </div>
                                <div style="font-size: .7rem;">
                                    <br>
                                </div>
                            </button>
                        </div>
                    </td>
                    <td style="width: 14.2857%;">
                        <div class="center-buttons bottom-margin-only">
                            <button :class="`tab-button-middle ${currentTab == tabs.Box1 ? 'selected' : 'unselected'}`"
                                style="width: 100%;" :disabled="currentTab == tabs.Box1"
                                :title="currentTab == tabs.Box1 ? 'Currently viewing Box 1' : 'Click to view Box 1'"
                                @click="ToggleTab(tabs.Box1)">
                                <div>
                                    Box 1
                                </div>
                                <div style="font-size: .7rem;">
                                    (Sales VAT)
                                </div>
                            </button>
                        </div>
                    </td>
                    <td style="width: 14.2857%;">
                        <div class="center-buttons bottom-margin-only">
                            <button :class="`tab-button-middle ${currentTab == tabs.Box3 ? 'selected' : 'unselected'}`"
                                style="width: 100%;" :disabled="currentTab == tabs.Box3"
                                :title="currentTab == tabs.Box3 ? 'Currently viewing Box 3' : 'Click to view Box 3'"
                                @click="ToggleTab(tabs.Box3)">
                                <div>
                                    Box 3
                                </div>
                                <div style="font-size: 0.7rem;">
                                    (Total VAT Due)
                                </div>
                            </button>
                        </div>
                    </td>
                    <td style="width: 14.2857%;">
                        <div class="center-buttons bottom-margin-only">
                            <button :class="`tab-button-middle ${currentTab == tabs.Box4 ? 'selected' : 'unselected'}`"
                                style="width: 100%;" :disabled="currentTab == tabs.Box4"
                                :title="currentTab == tabs.Box4 ? 'Currently viewing Box 4' : 'Click to view Box 4'"
                                @click="ToggleTab(tabs.Box4)">
                                <div>
                                    Box 4
                                </div>
                                <div style="font-size: 0.7rem;">
                                    (Reclaimed VAT)
                                </div>
                            </button>
                        </div>
                    </td>
                    <td style="width: 14.2857%;">
                        <div class="center-buttons bottom-margin-only">
                            <button :class="`tab-button-middle ${currentTab == tabs.Box5 ? 'selected' : 'unselected'}`"
                                style="width: 100%;" :disabled="currentTab == tabs.Box5"
                                :title="currentTab == tabs.Box5 ? 'Currently viewing Box 5' : 'Click to view Box 5'"
                                @click="ToggleTab(tabs.Box5)">
                                <div>
                                    Box 5
                                </div>
                                <div style="font-size: 0.7rem;">
                                    (Paid or Reclaimed VAT)
                                </div>
                            </button>
                        </div>
                    </td>
                    <td style="width: 14.2857%;">
                        <div class="center-buttons bottom-margin-only">
                            <button :class="`tab-button-middle ${currentTab == tabs.Box6 ? 'selected' : 'unselected'}`"
                                style="width: 100%;" :disabled="currentTab == tabs.Box6"
                                :title="currentTab == tabs.Box6 ? 'Currently viewing Box 6' : 'Click to view Box 6'"
                                @click="ToggleTab(tabs.Box6)">
                                <div>
                                    Box 6
                                </div>
                                <div style="font-size: 0.7rem;">
                                    (Sales Net Value)
                                </div>
                            </button>
                        </div>
                    </td>
                    <td style="width: 14.2857%;">
                        <div class="center-buttons bottom-margin-only">
                            <button :class="`tab-button-right ${currentTab == tabs.Box7 ? 'selected' : 'unselected'}`"
                                style="width: 100%;" :disabled="currentTab == tabs.Box7"
                                :title="currentTab == tabs.Box7 ? 'Currently viewing Box 7' : 'Click to view Box 7'"
                                @click="ToggleTab(tabs.Box7)">
                                <div>
                                    Box 7
                                </div>
                                <div style="font-size: 0.7rem;">
                                    (Purchases Net Value)
                                </div>
                            </button>
                        </div>
                    </td>
                </tr>
            </tbody>
        </div>
    </div>
</template>

<script setup lang="ts">
import { watch } from "vue"
import {
    tabs,
    currentTab,
    journalTypeSelection,
} from "@/services/helpers/VATReturns/VATReturnsVariables";
import {
    ToggleTab,
} from "@/services/helpers/VATReturns/VATReturnsFunctions";
import {
    GetTabData,
} from "@/services/helpers/VATReturns/VATReturnsFunctions";
// Misc
import log from "loglevel";

watch(
    () => currentTab.value,
    () => {
        const name = "watch(currentTab)";
        log.debug(name);

        journalTypeSelection.value = "";
        GetTabData();
    }
)
</script>

<style scoped lang="scss">
.tab-button-left {
    border-radius: var(--topbar-sidebar-content-radius) 0px 0px 0px;
}

.tab-button-middle {
    border-radius: 0px 0px 0px 0px;
}

.tab-button-right {
    border-radius: 0px var(--topbar-sidebar-content-radius) 0px 0px;
}

.bottom-margin-only {
    margin-top: 0px;
    margin-left: 0px;
    margin-right: 0px;
    margin-bottom: 10px;
}

.center-buttons button:disabled {
    color: blue;
    background-color: var(--light-grey);
}

.center-buttons-button-disabled {
    color: var(--primary-color-dark) !important;
    background-color: var(--light-grey);
}

.selected {
    background-color: var(--primary-color-lighter) !important;
    border-width: 0 0 .2rem 0;
    border-color: blue;
}

.unselected {
    color: #b6b6b6;
    background-color: #f0f0f0;
    font-weight: normal;
    border-color: white;
}

.confirm-button {
    margin-top: 10px;
    border-width: 0px;
    border-radius: var(--buttons-border-radius);
    background-color: var(--primary-color-lighter);
    padding: 10px;
    color: blue;
    font-size: larger;
    font-weight: bold;
    width: 100%;
}

.confirm-button:hover {
    background-color: var(--primary-color);
    color: white;
    cursor: pointer;
}

.confirm-button:disabled {
    color: #b6b6b6;
    background-color: var(--light-grey);
}

.confirm-button:disabled:hover {
    background-color: var(--primary-color-lighter);
    cursor: not-allowed;
}

.delete-button {
    margin-top: 10px;
    border-width: 0px;
    border-radius: var(--buttons-border-radius);
    background-color: var(--red-background-color);
    padding: 10px;
    color: red;
    font-size: larger;
    font-weight: bold;
    width: 100%;
}

.delete-button:hover {
    background-color: red;
    color: white;
    cursor: pointer;
}

.overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 9999;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.popup {
    background-color: white;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
    max-width: 75%;
    max-height: 75%;
    overflow-y: auto;
}

.popup-button {
    margin-top: 1rem;
    border-radius: var(--table-button-radius);
    border-width: 1px;
    border-color: blue;
    color: blue;
    padding-left: 1.6rem;
    padding-right: 1.6rem;
    padding-top: 0.4rem;
    padding-bottom: 0.4rem;
}

.popup-button:hover {
    cursor: pointer;
    background-color: var(--primary-color);
    color: white;
}

.description-title {
    font-size: 1.3rem;
    text-align: center;
    padding-bottom: 0.3rem;
}

.description-subtitle {
    padding-bottom: 1rem;
    text-align: center;
}

.description-subtitle input {
    font-size: .8rem;
    border-radius: .4rem;
    padding-left: .6rem;
    padding-top: .2rem;
    padding-bottom: .2rem;
}

.label {
    vertical-align: middle;
}

.label-bold {
    vertical-align: middle;
    font-weight: bold;
}

.wrap {
    white-space: pre-line;
}

.anw-info-textbox {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 1rem;
}

.details-column {
    width: 33.33%;
    float: left;
    padding: .4rem;
}

.details-column-title {
    font-size: large;
    font-weight: bold;
    text-align: center;
    color: var(--primary-color-dark);
}

@media only screen and (max-width: 104rem) {
    .details-column {
        width: 33.33%;
        float: none;
        margin-top: 30px;
    }
}

.text-link {
    color: blue;
    text-decoration: underline;
}

.text-link:hover {
    cursor: pointer;
}

.text-link-denied {
    color: #333;
    border: none;
    background-color: transparent;
}
</style>
