import { ref } from "vue";
import { CarPurchasesServices } from "@/services/helpers/CarPurchases/CarPurchasesHelper";
import { ContractOwner } from "@/models/CarPurchases/ContractOwner";
import { ISelection } from "@/Interfaces/ISelection";

const isLoading = ref(true);
const market = ref("");

// CONTRACT OWNERS
const showContractOwnersError = ref(false);
const contractOwners = ref<ContractOwner[]>([]);
const contractOwnersSelections = ref<ISelection[]>([]);
const selectedContractOwner = ref("");
const fetchingContractOwners = ref(true);

// const assignCarPurchaseToOwnerHoverText: Ref<string> = ref(
// 	`Assign '${checkedRows.value.length}' Car Purchases`
// );
// const accessDeniedAssignCarPurchaseToOwnerHoverText: Ref<string> = ref(
// 	"Assign Car Purchases (Access denied)"
// );

export default function useCarPurchasesVars() {
	const carPurchasesDefaultSortCols = [-1, 0];
	const carPurchasesDefaultSortOrders: (-1|1)[] = [-1, -1];
	const carPurchasesServices = new CarPurchasesServices();
return {
    market,
    carPurchasesDefaultSortCols,
    carPurchasesDefaultSortOrders,
    isLoading,
    carPurchasesServices,
    contractOwners,
    contractOwnersSelections,
    selectedContractOwner,
    fetchingContractOwners,
    showContractOwnersError,
};
}