<template>
    <span title="View invoice in new tab" class="search-with-button" style="padding-left: 1rem; padding-right: 1rem">
        <div class="date-picker" style="width: 60%;">
            <DatePicker v-model="dateRange" range :enable-time-picker="false" :format="FormatDatePicker" text-input
                placeholder="Select Date..." :text-input-options="textInputOptions" :partial-range="false" />
        </div>
        <select v-model="journalTypeSelectionDate" class="embedded-dropdown" style="width: 40%;">
            <option value="" disabled selected hidden>
                Select Journal Type...
            </option>
            <option v-for="jType in supportedJournalingTypes" :value="jType.Canonical" :key="jType.Canonical">
                {{ jType.DisplayShort }}
            </option>
        </select>
        <button ref="invoiceSearchButton" @click="search()" :disabled="dateRange == undefined ||
            journalTypeSelectionDate == ''
            " class="right-button">
            Search
        </button>
    </span>
</template>

<script setup lang="ts">
import {
    currentTab,
    dateRange,
    displayAmountVATTotal,
    displayedVATReturns,
    infoError,
    isDateSearch,
    isLoading,
    journalReference,
    journalTypeSelectionDate,
    showSearchForVATReturnsFromJournaledReferencesError,
    showSearchForVATReturnsFromJournaledReferencesErrorChanger,
    tabs,
    textInputOptions,
    validJournalReferences,
    supportedJournalingTypes,
} from "@/services/helpers/VATReturns/VATReturnsVariables";
import { FormatDatePicker } from "@/services/helpers/Helper";
import VATReturnsServices from "@/services/helpers/VATReturns/VATReturnsHelper";
import DatePicker from '@vuepic/vue-datepicker';
import log from "loglevel";
import { IAPILogger } from "@/interfaces/IAPILogger";
import { inject } from "vue";
import IJournalVATResponse from "@/Interfaces/VATReturns/IJournalVATResponse";
import { DisplayVATReturns, DisplayVATReturnsNotFound } from "@/services/helpers/VATReturns/VATReturnsFunctions";
import JournalVAT from "@/models/VATReturns/JournalVat";
import INotFoundError from "@/Interfaces/Errors/INotFoundError";
import { AxiosError } from "axios";


const JVATServices: VATReturnsServices = new VATReturnsServices();
const Logger: IAPILogger = inject("Logger")!;
const fileName = "VATReturnsDatePicker.vue";
function search() {
    // Logging
    const name = "search";
    const prefix = `${name}()\r\n`;
    log.trace(`${name}{}`);

    SearchForDateRange();

    currentTab.value = tabs.Summary;
}

async function SearchForDateRange() {
    isLoading.value = true;
    isDateSearch.value = true;
    journalReference.value = "";
    validJournalReferences.value = [];

    const name = SearchForDateRange.name;
    const prefix = `${name}()\n`;
    const Class = fileName + "/" + name;
    var Message = `Searching for Invoices files (Journal Reference: ${journalReference.value}).`;

    const dateFrom: string = dateRange.value![0].toLocaleString('sv-SE').split(" ")[0];
    const dateTo: string = dateRange.value![1].toLocaleString('sv-SE').split(" ")[0];

    log.trace(prefix + Message);
    await JVATServices.GetVATReturnsByDate(dateFrom, dateTo)
        .then((response: IJournalVATResponse) => {
            log.trace("SearchForDateRange() Response:", response);
            DisplayVATReturns(response);

            displayAmountVATTotal.value = (displayedVATReturns.value ?? []).reduce((partialSum: number, vatReturn: JournalVAT) => {
                log.trace(prefix + "vatReturn:", vatReturn, "\nJournalVATMetaData:", vatReturn.JournalVATMetaData);
                for (const metadata of vatReturn.JournalVATMetaData.AccountingMetaData) {
                    partialSum += metadata.IsIncrease
                        ? (metadata.UseAmountVAT ? vatReturn.AmountVat : vatReturn.AmountExVat)
                        : - (metadata.UseAmountVAT ? vatReturn.AmountVat : vatReturn.AmountExVat)
                }
                return partialSum;
            }, 0);

            Logger.LogInformation(Message, Class);
        })
        .catch((error: any) => {
            if (error instanceof AxiosError && error.response?.status == 400) {
                DisplayVATReturnsNotFound(error as INotFoundError);
            } else {
                if (error instanceof AxiosError) {
                    isLoading.value = false;
                    showSearchForVATReturnsFromJournaledReferencesError.value = true;
                    showSearchForVATReturnsFromJournaledReferencesErrorChanger.value = !showSearchForVATReturnsFromJournaledReferencesErrorChanger.value;
                    infoError.value = error;
                }
                Message = `(Error) ${Message}: ${error instanceof AxiosError ? error : error.message
                    }`;
                log.error(Message);
                Logger.LogError(Message, Class);
            }
        });
}
</script>
