<template>
    <div class='vue-js-switch' :class="{ disabled: disabled }">
        <input type="checkbox" class="v-switch-input" :id=id :name="name" :checked="toggled" :disabled="disabled"
            @change.stop="toggle">
        <div class="v-switch-core" :style=coreStyle>
            <div class="v-switch-button" :style=buttonStyle></div>
        </div>
        <template v-if=showLabels>
            <span v-if="toggled" class="v-switch-label v-left" :style="labelStyle">
                <slot name="checked">
                    {{ labelChecked }}
                </slot>
            </span>
            <span v-else class="v-switch-label v-right" :style="labelStyle">
                <slot name="unchecked">
                    {{ labelUnchecked }}
                </slot>
            </span>
        </template>
    </div>
</template>

<script setup lang="ts">
import { computed } from 'vue';

export interface IToggleButtonProps {
    modelValue?: boolean;
    id?: string;
    name?: string;
    disabled?: boolean;
    tag?: string;
    speed?: number;
    colorChecked?: string;
    colorUnchecked?: string;
    colorDisabled?: string;
    switchColorChecked?: string;
    switchColorUnchecked?: string;
    cssColors?: boolean;
    showLabels?: boolean;
    labelChecked?: string;
    labelUnchecked?: string;
    height?: number;
    width?: number;
    margin?: number;
    fontSize?: number;
}
function px(value: any) {
    return `${value}px`;
}
function translate3d(x: number | string, y: number | string, z = '0px') {
    return `translate3d(${x}, ${y}, ${z})`;
}
const props = withDefaults(defineProps<IToggleButtonProps>(), {
    modelValue: false,
    speed: 300,
    colorChecked: 'var(--primary-color-lighter)',
    colorUnchecked: '#bfcbd9',
    switchColorChecked: 'var(--primary-color)',
    switchColorUnchecked: 'grey',
    labelChecked: 'on',
    labelUnchecked: 'off',
    height: 22,
    width: 50,
    margin: 3,
});
const emit = defineEmits(['update:modelValue', 'change']);
const toggled = computed({
    get() {
        return props.modelValue;
    },
    set(value) {
        emit('update:modelValue', value);
    }
});
const colorCurrent = computed(() => {
    return toggled.value ? props.colorChecked : props.colorUnchecked;
});
const colorDisabled = computed(() => {
    return props.colorDisabled ? props.colorDisabled : colorCurrent.value;
});
const coreStyle = computed(() => {
    return {
        width: px(props.width),
        height: px(props.height),
        backgroundColor: props.cssColors ? undefined : (props.disabled ? colorDisabled.value : colorCurrent.value),
        borderRadius: px(Math.round(props.height / 2))
    };
});
const switchColorCurrent = computed(() => {
    return toggled.value ? props.switchColorChecked : props.switchColorUnchecked;
});
const buttonRadius = computed(() => px(props.height - props.margin * 2));
const distance = computed(() => px(props.width - props.height + props.margin));
const buttonStyle = computed(() => {
    const transition = `transform ${props.speed}ms`;
    const margin = px(props.margin);
    const transform = toggled.value
        ? translate3d(distance.value, margin)
        : translate3d(margin, margin);
    const background = switchColorCurrent.value;
    return {
        width: buttonRadius.value,
        height: buttonRadius.value,
        transition,
        transform,
        background
    };
});
const labelStyle = computed(() => {
    return {
        lineHeight: px(props.height),
        fontSize: props.fontSize ? px(props.fontSize) : undefined
    };
});
function toggle(event: Event) {
    toggled.value = !toggled.value;
    emit('change', {
        value: toggled,
        tag: props.tag,
        srcEvent: event,
    });
}
</script>

<style lang="scss" scoped>
.vue-js-switch {
    display: inline-block;
    position: relative;
    vertical-align: middle;
    user-select: none;
    font-size: 10px;
    cursor: pointer;

    >.v-switch-input {
        visibility: hidden;
        position: absolute;
        width: 1px;
        height: 1px;
    }
    >.v-switch-label {
        position: absolute;
        top: 0;
        font-weight: 600;
        color: white;
        z-index: 1;

        &.v-left {
            left: 10px;
        }

        &.v-right {
            right: 10px;
        }
    }
    >.v-switch-core {
        display: block;
        position: relative;
        box-sizing: border-box;

        outline: 0;
        margin: 0;

        transition: border-color .3s, background-color .3s;
        user-select: none;

        >.v-switch-button {
            display: block;
            border-radius: 100%;
            position: absolute;
            overflow: hidden;

            top: 0;
            left: 0;

            background-color: #fff;
            z-index: 2;
        }
    }
    &.disabled {
        pointer-events: none;
        opacity: 0.6;
    }
}
</style>