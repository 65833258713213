<template>
    <BaseContractPage ref="basePage"
        loadAllByDefault
        hasCheckbox
        contractName=""
        :rowFilters=contractFilters
        :rowCheckableFilter=checkableFilter
        :contractGetAPI=contractGetAPI
        :cols=cols
        seMarketEnable
    >
        <template #header-extra  v-if=approveAccess>
            <div class="flex-col-centered selection stretch" style="row-gap: 20px;">
                <LabeledInputContainer title="Toggle (Dis)approve" id="toggle-approve">
                    <template #title={value}>
                        {{ value }} <ToggleButton id=toggle-approve v-model=approving @change=basePage?.clearChecked() />
                    </template>
                    <GroupedInputButton>
                        <button class="default-btn"
                            :disabled="working || approving"
                            :title="'Disapprove selected contracts'"
                            @click=disapprove
                            type="button" >
                            Disapprove
                        </button>
                        <button class="default-btn right-btn"
                            :disabled="working || !approving"
                            :title="'Approve selected contracts'"
                            @click=approve
                            type="button" >
                                Approve
                        </button>
                    </GroupedInputButton>
                </LabeledInputContainer>
                <LabeledInputContainer v-if=showDownload class="flex-col-centered selection stretch" title="Select all for download" id=toggle-all-download >
                    <template #title={value}>
                        {{ value }} <ToggleButton id=toggle-all-download v-model=downloadToggle @update:model-value=checkAllContractsForDownload />
                    </template>
                    <button class="download-btn default-btn right-btn" 
                        :disabled="working || !approving"
                        :title="'Approve selected contracts'"
                        @click=onDownloadClicked
                        type="button">
                            Download
                    </button>
                </LabeledInputContainer>
            </div>
            <ContractApprovalDownloadPopUp 
                v-model=contractsToDownload
                @update:model-value="downloadToggle=false"
            />
        </template>
        <template #approved="{value}">
            <TickCrossBooleanDisplay :value="value.approved" />
        </template>
        <template v-for="k of validationCols" v-slot:[`${k}`]={value}>
            <TickCrossBooleanDisplay :value="(value[k] as boolean)" />
        </template>
        <template #filter-group-extra>
            <LabeledInputContainer id="contract-status" title="Status Filter">
                <DropdownTopArray id="contract-status"
                    placeholder="No Filter"
                    v-model=statusFilter
                    :options=statusOptions />
            </LabeledInputContainer>
        </template>
        <template #search-group-extra>
            <RadioButtonToggle name="approve-filter"
                v-model=approveFilterChoice
                :options=approveFilerChoices
                @change="approveFilterChange"
            />
        </template>
        <template #toDownload="{value}"> 
            <input type="checkbox" v-model=value.toDownload class="centre-text vtl-checkbox" @change="updatetDownloandToggle($event)">
        </template>
    </BaseContractPage>
</template>


<script setup lang="ts">

import { ref, computed, watch, inject } from "vue";
import useNotifierWithErrFormatter from '@/services/composables/NotifierWithErrFormatter';
import BaseContractPage from "./BaseContractPage.vue";
import IContractPageExpose from '@/Interfaces/components/IContractPageExpose';
import IContractApproval from "@/Interfaces/Contract/IContractApproval";
import { contractApproval } from "@/services/network";
import useSpinnerPopup from "@/services/composables/SpinnerPopup";
import NotifierWithErrFormatter from "@/services/composables/NotifierWithErrFormatter";
import { authService } from "@/auth/authSingleton";
import DateTimeLocalStr from "@/types/DateTimeLocalStr";
import TickCrossBooleanDisplay from "@/components/TickCrossBooleanDisplay.vue";
import GroupedInputButton from "@/components/GroupedInputButton.vue";
import ToggleButton from "@/components/ToggleButton.vue";
import { AuthorisationLevel, DenyFunctionLevelAccess } from "@/config/AuthConfig";
import RadioButtonToggle from "@/components/common/RadioButtonToggle.vue";
import LabeledInputContainer from "@/components/common/LabeledInputContainer.vue";
import DropdownTopArray from "@/components/common/DropdownTopArray.vue";
import ContractApprovalDownloadPopUp from "./ContractApproval/ContractApprovalDownloadPopUp.vue";   
import { loggerKey } from "@/types/ServiceKeys";

const basePage = ref<IContractPageExpose<IContractApproval>>();
const colBase = [
        'contractID',
        'approved',
        'performedBy',
        'performDate',
        'ownerID',
        //'customerID',
        //'orderID',
        //'market',
        'status',
        //'hasFileData',
        'initialDate',
        'activationDate',
        'terminationDate',
    ] as (keyof IContractApproval)[];
const validationCols = computed(()=>{
    const data = basePage?.value?.downloadedContracts;
    const validationColSet = new Set<keyof IContractApproval>([
        'hireAgreementValidation',
        'customerInvoiceValidation',
        'carSaleInvoiceValidation',
        'underwritingValidation',
    ]);
    data?.forEach(x=>{
        Object.entries(x).forEach(([k,v])=>{
            if (typeof v === 'boolean') {
                if (k.includes('Valid')) {
                    validationColSet.add(k as keyof IContractApproval);
                }
            }
        });
    });
    return [...validationColSet.keys()];
});
const toDownloadCol = computed(()=>((approveFilterChoice.value !=2)?[]:["toDownload"] as (keyof IContractApproval)[]));
const cols = computed<(keyof IContractApproval)[]>(()=>[...toDownloadCol.value ,...colBase, ...validationCols.value]);
const working = ref(false);
const approving = ref(true);
const downloadToggle = ref(false);
const approveAccess = computed(()=>!DenyFunctionLevelAccess(AuthorisationLevel.Analyst));
const checkableFilter = computed(()=>approving.value ? isValidAndNonApproved : ()=>true);
const contractsToDownload = ref();
const visibleRows = computed(() => basePage.value?.getTableRef()?.GetFiltered());
const log = inject(loggerKey);

watch(visibleRows, currentRows => {
    if(approveFilterChoice.value!=2) return;
    log?.debug("watch event triggered");
    downloadToggle.value = !currentRows?.some(row => !row.toDownload);
});

// Filtering
const statusOptions = computed(()=>basePage.value?.getTableRef()?.GetFiltered()?.map(x => x.status) ?? []);
const statusFilter = ref<string|null>(null);

enum ApproveFilter {
    NonApproved,
    Null,
    Approved,
}
const approveFilerChoices = {
    [ApproveFilter.NonApproved]: 'Non-approved',
    [ApproveFilter.Null]: 'No Filter',
    [ApproveFilter.Approved]: 'Approved'
}
const approveFilterChoice = ref(ApproveFilter.Null);
const approveFilters = {
    [ApproveFilter.NonApproved]: (x: IContractApproval)=>!x.approved,
    [ApproveFilter.Null]: undefined,
    [ApproveFilter.Approved]: (x: IContractApproval)=>x.approved,
}
const approveFilter = computed(()=>approveFilters[approveFilterChoice.value]);
const showDownload = computed(()=> approveFilterChoice.value == 2);
const contractFilters = computed(()=>[
    approveFilter.value, 
    statusFilter.value ? 
        (x: IContractApproval)=>x.status === statusFilter.value : 
        undefined
].filter((x):x is ((x: IContractApproval) => boolean) => !!x));

function isValidAndNonApproved(row: IContractApproval) {
    return allValid(row) && !row.approved;
}
function allValid(row: IContractApproval) {
    const anyInvalid = Object.entries(row).some(([k,v])=>{
        if (typeof v === 'boolean') {
            if (k.includes('Valid')) {
                return !v;
            }
        }
    });
    return !anyInvalid;
}
function updatetDownloandToggle(event){
    if (!event.target.checked) {
        downloadToggle.value=false
    }
    if(event.target.checked &&  basePage.value?.getTableRef()?.GetFiltered()?.filter(x=>x.toDownload).length == basePage.value?.getTableRef()?.GetFiltered()?.length){
        downloadToggle.value=true
    }
}
async function contractGetAPI(data) {
    if (data.lookupDate === 'ACTIVATION_DATE') {
        data.activationDateFrom = data.dateFrom;
        data.activationDateTo = data.dateTo;
    } else if (data.lookupDate === 'INITIAL_DATE') {
        data.initialDateFrom = data.dateFrom;
        data.initialDateTo = data.dateTo;
    }
    const result = (await contractApproval.get(data)).data;
    result.forEach(x=>
        {x.performDate=x.performDate?new DateTimeLocalStr(x.performDate):undefined
        x.toDownload = downloadToggle.value
    });
    return result;
}
async function approveReject(approve: boolean) {
    if (working.value) return;
    working.value = true;
    const close = useSpinnerPopup().show();
    try {
        if (!basePage.value) throw new ReferenceError('Base view is not mounted');
        const username = authService.getEmailAddress();
        const contractIDs = basePage.value.getSelected();
        if (contractIDs.length<=0) return;
        await contractApproval.post({
            username,
            contractIDs,
            approve
        });
        basePage.value.searchContracts();
        const actionText = approve ? 'approved' : 'disapproved';
        NotifierWithErrFormatter().success({ msg: `Successfully ${actionText} contracts` });
    } catch (error) {
        const actionText = approve ? 'Approving' : 'Disapproving';
        await NotifierWithErrFormatter().error({ errorType: `${actionText} Contracts`, error });
    } finally {
        working.value = false;
        close();
    }
}
function onDownloadClicked(){
    const setContractsToDownload = basePage.value?.getTableRef()?.GetFiltered()?.filter(x=>x.toDownload);
    if(setContractsToDownload?.length){
        contractsToDownload.value =  setContractsToDownload;    
    }else{
        useNotifierWithErrFormatter().notify({msg: 'No contracts have been selected for download.'});
    }
}
async function approve() {
    approveReject(true);
}
async function disapprove() {
    approveReject(false);
}
function checkAllContractsForDownload(checkAll?: boolean) {
    visibleRows.value?.forEach(x=>x.toDownload = checkAll);
}
function approveFilterChange(){
    basePage.value?.clearChecked()
    if(approveFilterChoice.value != 2){
        downloadToggle.value = false
        basePage.value?.downloadedContracts?.forEach(x=>x.toDownload = undefined);
    }
}
</script>

<style scoped lang="scss">
.stretch {
    align-items: stretch;
}
.default-btn {
    margin: 0;
}
.right-btn {
    background-color: var(--primary-color-light);
    &:hover {
        background-color: var(--primary-color-lighter);
    }
}
.download-btn{
    justify-content: center;
    border: var(--border-width) solid var(--primary-color-dark);
    &:focus-visible {
        outline-offset: 1px;
    }
}
:deep(.toDownload) {  
    text-align: center !important;  
}
.vtl-checkbox {
    text-align: center;
    transform: scale(1.5);
    margin: 2px 10px;
    z-index: -1;
    overflow-x: hidden;
    >input[type=checkbox]:hover {
        cursor: pointer;
    }
}

</style>