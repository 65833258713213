import ICustomerInvoiceSpecificationTable from "@/Interfaces/CustomersAndContracts/ICustomerInvoiceSpecificationTable";
import { extractNonArraysFromObj } from '@/services/helpers/ObjectHelper';
import DateIgnoreTime from "@/types/DateIgnoreTime";

export default class CustomerInvoiceSpecificationTable implements ICustomerInvoiceSpecificationTable {
    CUSTOMER_INVOICE_ID: string;
    PERIOD_DATE_FROM: DateIgnoreTime;
    PERIOD_DATE_TO: DateIgnoreTime;

    constructor(data: ICustomerInvoiceSpecificationTable) {
        Object.assign(this,extractNonArraysFromObj(data));
        this.CUSTOMER_INVOICE_ID = data.CUSTOMER_INVOICE_ID;
        this.PERIOD_DATE_FROM=new DateIgnoreTime(data.PERIOD_DATE_FROM);
        this.PERIOD_DATE_TO=new DateIgnoreTime(data.PERIOD_DATE_TO);
    }
}
