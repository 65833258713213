<script setup lang="ts" generic="T">
import { ref } from 'vue';
import type { ComponentExposed } from 'vue-component-type-helpers';
import TableExpandableRows from './TableExpandableRows.vue';
import MultiStateButton from './MultiStateButton.vue';
import { DisplayableKeys } from '@/services/SorterService';
import useTableExpandableColumns, {ExpandState} from '@/services/composables/TableExpandableColumns';

const props = withDefaults(defineProps<{
    modelValue?: ExpandState;
    rows?: T[];
    maxRows?: number;
    expandedCols?: DisplayableKeys<T>[];
    cols: DisplayableKeys<T>[];
    title: string;
}>(),{
    maxRows: 3,
});
const emits = defineEmits<{
  (e: 'update:modelValue', v?: ExpandState): void,
}>();
defineExpose<{
    checkAll:()=>void;
}>({
    checkAll: ()=>table.value?.checkAll(),
});
const table = ref<ComponentExposed<typeof TableExpandableRows>>();
const {
  labels,
  expandState,
  curCols
} = useTableExpandableColumns(props, emits);
</script>
<template>
    <div class="container" >
      <div class="title-container">
        <h3 class="title">{{ title }}:</h3>
        <MultiStateButton v-if=expandedCols v-model=expandState :states=labels style="font-size: 0.6rem" />
      </div>
      <TableExpandableRows ref="table" v-bind=$attrs :rows=rows :cols=curCols :maxRows=maxRows />
    </div>
  </template>
  <style lang="scss" scoped>
  .container {
    display: flex;
    flex-direction: column;
    flex: 1;
    row-gap: 10px;
    >.title-container {
      display: flex;
      align-items: center;
      column-gap: 1ch;
      >.title {
        margin: 0;
        padding:0;
        font-size: 0.95rem;
        font-weight: bold;
      }
    }
  }
  </style>