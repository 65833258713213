<template>
    <div v-if="currentTab != tabs.Summary" class="table-container tabs-content">
        <Table :Rows="tabVATReturns" :Cols="cols" :Labels="labels"
            v-model:RowsPerPage=rowCount 
            :PageOptions="[100, 200, 500, 1000, 2000, 5000]">
            <template #IssueDateTime="props">
                <div>
                    {{
                        props.value.IssueDateTime.toLocaleString("sv-SE")
                            .split(" ")[0]
                            .replaceAll("-", "/")
                    }}
                </div>
            </template>
            <template #AmountExVat="props">
                <div :class="GetClassOrSignBackoutCompatible(props.value, undefined, true, false)">
                    {{
                        props.value.Reference.startsWith(JournalingOptions.JournalBackoutPrefix)
                        ? GetClassOrSignBackoutCompatible(props.value, -props.value.AmountExVat, false, false)
                        : GetClassOrSignBackoutCompatible(props.value, props.value.AmountExVat, false, false)
                    }}
                </div>
            </template>
            <template #AmountVat="props">
                <div :class="GetClassOrSignBackoutCompatible(props.value, undefined, true)">
                    {{
                        props.value.Reference.startsWith(JournalingOptions.JournalBackoutPrefix)
                        ? GetClassOrSignBackoutCompatible(props.value, -props.value.AmountVat, false)
                        : GetClassOrSignBackoutCompatible(props.value, props.value.AmountVat, false)
                    }}
                </div>
            </template>
            <template #AmountTotal="props">
                {{
                    props.value.Reference.startsWith(JournalingOptions.JournalBackoutPrefix)
                    ? MonetaryValueWithCommas(-props.value.AmountTotal)
                    : MonetaryValueWithCommas(props.value.AmountTotal)
                }}
            </template>
        </Table>
    </div>
    <div v-else class="table-container tabs-content">
        <Table :Rows="VATReturnsSummaryRows" :Cols="VATReturnsSummaryCols" :Labels="VATReturnsSummaryLabels"
            :RowClicked="RowClicked">
            <template #Total="props">
                {{ props.value.Total == "-0.00" ? props.value.Total.slice(1) : props.value.Total }}
            </template>
        </Table>
    </div>
</template>

<script setup lang="ts">
// Services
import { MonetaryValueWithCommas } from "@/services/helpers/Helper";
import {
    cols,
    labels,
    tabVATReturns,
    tabs,
    currentTab,
    displayedVATReturns,
    Box1VATs,
    Box3VATs,
    Box4VATs,
    Box5VATs,
    Box6VATs,
    Box7VATs,
    box1AmountVATTotal,
    box3AmountVATTotal,
    box4AmountVATTotal,
    box5AmountVATTotal,
    box6AmountExVATTotal,
    box7AmountExVATTotal,
    box1JournalingTypes,
    box3JournalingTypes,
    box4JournalingTypes,
    box5JournalingTypes,
    box6JournalingTypes,
    box7JournalingTypes,
} from "@/services/helpers/VATReturns/VATReturnsVariables";
import {
    GetAmountTotal,
    GetTabVATReturns,
    ToggleTab,
    GetClassOrSignBackoutCompatible,
} from "@/services/helpers/VATReturns/VATReturnsFunctions";
// Data
import JournalVAT from "@/models/VATReturns/JournalVat";
// Table
import Table from "@/components/TablePersistentChecking.vue";
// Misc
import log from "loglevel";
import { ref } from "vue";
import { JournalingOptions } from "@/models/Journaling/JournalingTypes";
import { DisplayableKeys } from "@/services/SorterService";

const rowCount = ref(100);

log.trace("displayedVATReturns.value:", displayedVATReturns.value);
Box1VATs.value = GetTabVATReturns(tabs.Box1);
log.trace("Box1VATs:", Box1VATs.value);
box1AmountVATTotal.value = GetAmountTotal(Box1VATs.value, tabs.Box1);
log.trace("box1AmountVATTotal:", box1AmountVATTotal.value);
box1JournalingTypes.value = [...new Set(Box1VATs.value.map((vat: JournalVAT) => vat.JournalingType ?? "") || [])];


Box3VATs.value = GetTabVATReturns(tabs.Box1, tabs.Box2);
log.trace("Box3VATs:", Box3VATs.value);
box3AmountVATTotal.value = GetAmountTotal(Box3VATs.value, tabs.Box1, tabs.Box2);
log.trace("box3AmountVATTotal:", box3AmountVATTotal.value);
box3JournalingTypes.value = [...new Set(Box3VATs.value.map((vat: JournalVAT) => vat.JournalingType ?? "") || [])];


Box4VATs.value = GetTabVATReturns(tabs.Box4);
log.trace("Box4VATs:", Box4VATs.value);
box4AmountVATTotal.value = GetAmountTotal(Box4VATs.value, tabs.Box4);
log.trace("box4AmountVATTotal:", box4AmountVATTotal.value);
box4JournalingTypes.value = [...new Set(Box4VATs.value.map((vat: JournalVAT) => vat.JournalingType ?? "") || [])];


const Tab5Box3VATs: JournalVAT[] = GetTabVATReturns(tabs.Box1, tabs.Box2);
const Tab5Box4VATs: JournalVAT[] = GetTabVATReturns(tabs.Box4);
Box5VATs.value = [...Tab5Box3VATs, ...Tab5Box4VATs]
log.trace("Box5VATs:", Box5VATs.value);
box5AmountVATTotal.value = 0;
box5AmountVATTotal.value += GetAmountTotal(Tab5Box3VATs, tabs.Box1, tabs.Box2);
box5AmountVATTotal.value -= GetAmountTotal(Tab5Box4VATs, tabs.Box4);
log.trace("box5AmountVATTotal:", box5AmountVATTotal.value);
box5JournalingTypes.value = [...new Set(Box5VATs.value.map((vat: JournalVAT) => vat.JournalingType ?? "") || [])];


Box6VATs.value = GetTabVATReturns(tabs.Box6);
log.trace("Box6VATs:", Box6VATs.value);
box6AmountExVATTotal.value = GetAmountTotal(Box6VATs.value, tabs.Box6);
log.trace("box6AmountExVATTotal:", box6AmountExVATTotal.value);
box6JournalingTypes.value = [...new Set(Box6VATs.value.map((vat: JournalVAT) => vat.JournalingType ?? "") || [])];


Box7VATs.value = GetTabVATReturns(tabs.Box7);
log.trace("Box7VATs:", Box7VATs.value);
box7AmountExVATTotal.value = GetAmountTotal(Box7VATs.value, tabs.Box7);
log.trace("box7AmountExVATTotal:", box7AmountExVATTotal.value);
box7JournalingTypes.value = [...new Set(Box7VATs.value.map((vat: JournalVAT) => vat.JournalingType ?? "") || [])];


class VATReturnsSummary {
    Box?: tabs;
    BoxString?: string;
    Description?: string;
    Total?: string;

    constructor(data: Partial<VATReturnsSummary>) {
        Object.assign(this, data);
    }
}

const VATReturnsSummaryRows: VATReturnsSummary[] = (
    displayedVATReturns.value?.length
        ? [
            new VATReturnsSummary({ Box: tabs.Box1, BoxString: `Box ${tabs.Box1}`, Description: "Sales VAT", Total: MonetaryValueWithCommas(box1AmountVATTotal.value) }),
            new VATReturnsSummary({ Box: tabs.Box3, BoxString: `Box ${tabs.Box3}`, Description: "Total VAT Due", Total: MonetaryValueWithCommas(box3AmountVATTotal.value) }),
            new VATReturnsSummary({ Box: tabs.Box4, BoxString: `Box ${tabs.Box4}`, Description: "Reclaimed VAT", Total: MonetaryValueWithCommas(box4AmountVATTotal.value) }),
            new VATReturnsSummary({ Box: tabs.Box5, BoxString: `Box ${tabs.Box5}`, Description: "Paid or Reclaimed VAT", Total: MonetaryValueWithCommas(box5AmountVATTotal.value) }),
            new VATReturnsSummary({ Box: tabs.Box6, BoxString: `Box ${tabs.Box6}`, Description: "Sales Net Value", Total: MonetaryValueWithCommas(box6AmountExVATTotal.value) }),
            new VATReturnsSummary({ Box: tabs.Box7, BoxString: `Box ${tabs.Box7}`, Description: "Purchases Net Value", Total: MonetaryValueWithCommas(box7AmountExVATTotal.value) }),
        ]
        : []
);
const VATReturnsSummaryCols: DisplayableKeys<VATReturnsSummary>[] = ["BoxString", "Description", "Total"];
const VATReturnsSummaryLabels: string[] = ["Box", "Description", "Total"];

function RowClicked(row: any) {
    log.trace(`RowClicked()\nrow: ${JSON.stringify(row)}`);
    log.trace(`RowClicked()\nrowBox: ${row.value.Box}`);

    ToggleTab(row.value.Box);
}
</script>

<style scoped lang="scss">
.tabs-content {
    padding-left: 2em;
    padding-right: 2em;
}
</style>
