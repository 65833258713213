<script setup lang="ts">
import { ref, Component, VNode } from 'vue';
import useMultiStateButton, {MultiStateButtonProps} from '@/services/composables/MultiStateButton';
const props = defineProps<MultiStateButtonProps<Component>>();
const emits = defineEmits<{
    (e: 'update:modelValue', val: number)
}>();
const {
    state,
    toggleState
} = useMultiStateButton(props, emits);
const width = ref(0);
const resizeObserver = new ResizeObserver(entries=>{
    updateWidth(entries[entries.length-1].target)
});
function onSVGMounted(ev: VNode) {
    if(ev.el) {
        const el = ev.el as Element;
        resizeObserver.observe(el);
        updateWidth(el);
    }
}
function onSVGUnmounted(ev: VNode) {
    if(ev.el) resizeObserver.unobserve(ev.el as Element);
    else resizeObserver.disconnect();
}
function updateWidth(el: Element) {
    width.value = el.getBoundingClientRect().width;
}
</script>
<template>
<button type=button class=button-svg @click=toggleState ><component @vue:mounted=onSVGMounted @vue:unmounted=onSVGUnmounted :is=states[state] /></button>
</template>
<style lang="scss" scoped>
.button-svg{
    position:relative;
    right: v-bind("`${width}px`");

    background-color: inherit;
    padding: 0;
    border: 0;
    cursor: pointer;
    
    :deep(svg) {
        height: 100%;
        width: auto;
    }
    &:hover {
        background-color: var(--primary-color-lighter);
        color: blue;
    }
}
</style>