import { IJournalVAT, IJournalVATMetadata } from "@/Interfaces/VATReturns/IJournalVAT";
import { GetClassOrSign, PascalToSnakeCase } from "@/services/helpers/VATReturns/VATReturnsFunctions";
import { tabs } from "@/services/helpers/VATReturns/VATReturnsVariables";
import log from "loglevel";

export default class JournalVAT {
    Reference: string;
    IssueDateTime: Date;
    Id: string;
    AmountExVat: number;
    AmountVat: number;
    AmountTotal: number;
    JournalingType?: string;
    JournalVATMetaData: IJournalVATMetadata;

    constructor(journalVAT: IJournalVAT) {
        this.Reference = journalVAT.Reference;
        this.IssueDateTime = new Date(journalVAT.IssueDateTime);
        this.Id = journalVAT.Id;
        this.AmountExVat = journalVAT.AmountExVat;
        this.AmountVat = journalVAT.AmountVat;
        this.AmountTotal = journalVAT.AmountTotal;
        this.JournalVATMetaData = journalVAT.JournalVATMetaData;

        this.JournalingType = this.GetJournalingTypeFromReference();
    }

    GetJournalingTypeFromReference(toUpperCase = true): string {
        const journalReferenceParts = this.Reference.split("_");
        return PascalToSnakeCase(journalReferenceParts[journalReferenceParts.length - 1], toUpperCase);
    }

    GetCSVDownloadObject(tab?: tabs, useAmountVAT: boolean = true) {
        var amount: number;
        switch (tab) {
            case tabs.Box1:
            case tabs.Box3:
            case tabs.Box4:
            case tabs.Box5:
                amount = this.AmountVat;
                break;
            case tabs.Box6:
            case tabs.Box7:
                amount = this.AmountExVat;
                break;
            default:
                amount = 0;
                break;
        }
        const isDecrease: boolean = GetClassOrSign(this.JournalVATMetaData.AccountingMetaData, false, useAmountVAT, tab).includes("-");
        if (isDecrease) amount = - amount;
        return ({
            // In the case of multiple references, references are comma-separated
            // Thus, joining the row on "," will create a new cell per reference
            Reference: this.Reference.replaceAll(",", ";"),
            IssueDateTime: this.FormatDate(this.IssueDateTime),
            Id: this.Id,
            Amount: amount
        });
    }

    FormatDate(date: Date): string {
        return date.toLocaleString("sv-SE")
            .split(" ")[0]
            .replaceAll("-", "/");
    }
}