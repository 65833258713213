<template>
    
    <div class="EoPVerificationReport">
        <div v-if="isLoading" class="content-container">
            <div class="loading">
                <span class="loader"></span>
            </div>
        </div>
        <div v-else class="content-container">
            <NewEoPVerificationReport v-if="showNewFormatReport"/>
            <div v-else class="top-content">
                <GettingEoPVerificationReportErrorInfo
                    v-if="showGettingEoPVerificationReportError"
                    :showError="showGettingEoPVerificationReportError"
                    errorDescription="(Getting EoP Verification Report)"
                    :error="infoError"
                />
                <ShowPdfErrorInfo
                    v-if="showPdfError"
                    :showError="showPdfError"
                    :closeError="true"
                    errorDescription="(Downloading PDF)"
                    :error="infoError"
                    :change="showPdfErrorChanger"
                />
                <ShowCreateNewReportErrorInfo
                    v-if="showCreateNewReportError"
                    :showError="showCreateNewReportError"
                    :closeError="true"
                    errorDescription="(Create New EoP Verifications Report)"
                    :error="infoError"
                    :change="ShowCreateNewReportErrorChanger"
                />
                <div class="top-container">
                    <div class="eop-v-info">
                        <tbody>
                            <tr>
                                <td style="font-weight: bold;">Summary:</td>
                                <td style="font-weight: bold;">{{ displayType }}</td>
                                <td>
                    
                                </td>
                            </tr>
                            <tr>
                                <td style="font-weight: bold;">EoP Status:</td>
                                <td>
                                    <div
                                        v-if="
                                        currentEoPVerificationReport?.EoPStatus.toUpperCase().startsWith(
                                                'ERROR'
                                            )
                                        "
                                        class="failure"
                                    >
                                        {{ currentEoPVerificationReport?.EoPStatus }} - DEVIATIONS DETECTED
                                    </div>
                                    <div
                                        v-else-if="currentEoPVerificationReport?.EoPStatus.toUpperCase() == 'SUCCESS'"
                                        class="highlight-green-bold"
                                    >
                                        {{ currentEoPVerificationReport?.EoPStatus }}
                                    </div>
                                    <div v-else>{{ currentEoPVerificationReport?.EoPStatus }}</div>
                                </td>
                            </tr>
                            <tr>
                                <td style="font-weight: bold;">Journaling Status:</td>
                                <td>
                                    <div
                                        v-if="currentEoPVerificationReport?.JournalingStatus.toUpperCase() == 'INCOMPLETE'"
                                        class="failure"
                                    >
                                        {{ currentEoPVerificationReport?.JournalingStatus }} 
                                    </div>
                                    <div
                                        v-else-if="currentEoPVerificationReport?.JournalingStatus.toUpperCase() == 'COMPLETE'"
                                        class="highlight-green-bold"
                                    >
                                        {{ currentEoPVerificationReport?.JournalingStatus }}
                                    </div>
                                    <div v-else>{{ currentEoPVerificationReport?.JournalingStatus }}</div>
                                </td>
                            </tr>
                            <tr>
                                <td colspan="2"></td>
                            </tr>
                            <tr>
                                <td style="font-weight: bold;">Processed:</td>
                                <td>{{ currentEoPVerificationReport?.ProcessedDateTime }}</td>
                            </tr>
                            <tr>
                                <td style="font-weight: bold;">Total Amount:</td>
                                <td>{{ formatAmount(currentEoPVerificationReport?.TotalAmount!) }}</td>
                            </tr>
                            <tr>
                                <td style="font-weight: bold;">Amount Diff:</td>
                                <td>{{ formatAmount(currentEoPVerificationReport?.DeviationAmount!) }}</td>
                            </tr>
                            <tr>
                                <td style="font-weight: bold;">Number of Errors:</td>
                                <td>{{ currentEoPVerificationReport?.NumberOfErrorItems }}</td>
                            </tr>
                            <tr>
                                <td style="font-weight: bold;">View Invoice:</td>
                                <td>                           
                                    <div v-if="hasInvoice">
                                        <div v-if="currentEoPVerificationReport!.InvoicesInspected!.length <= 5">
                                            <li class="file-list" v-for="file in currentEoPVerificationReport?.InvoicesInspected">
                                                    <button
                                                    @click="viewFullPagePDFInvoice(file,journalType!)"
                                                    :disabled="invoicePdfButtonStatus"
                                                    :title="
                                                        invoicePdfButtonStatus
                                                            ? ''
                                                            : 'View report pdf (opens file in new tab)'
                                                    "
                                                    class="fake-link"
                                                    >
                                                        {{ file }}
                                                    </button>
                                            </li>
                                        </div>
                                        <div v-if="currentEoPVerificationReport!.InvoicesInspected!.length > 5">
                                            <div class="invoice-scroll-box">
                                                <li class="file-list" v-for="file in currentEoPVerificationReport?.InvoicesInspected">
                                                    <button
                                                    @click="viewFullPagePDFInvoice(file,journalType!)"
                                                    :disabled="invoicePdfButtonStatus"
                                                    :title="
                                                        invoicePdfButtonStatus
                                                            ? ''
                                                            : 'View report pdf (opens file in new tab)'
                                                    "
                                                    class="fake-link"
                                                    >
                                                        {{ file }}
                                                    </button>
                                                </li>
                                            </div>
                                        </div>                                
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td style="font-weight: bold;">Detailed Report:</td>
                                <td>
                                    <button
                                        @click="viewFullPagePDFReport(currentEoPVerificationReport?.ReportName!,'EndOfPeriodVerification')"
                                        :disabled="detailedReportPdfButtonStatus"
                                        :title="
                                            invoicePdfButtonStatus
                                                ? ''
                                                : 'View report pdf (opens file in new tab)'
                                        "
                                        class="fake-link"
                                    >
                                        {{ currentEoPVerificationReport?.ReportName }}
                                    </button>
                                </td>
                            </tr>
                        </tbody>
                    </div>
                    <div class="new-report-button-div">
                        <button
                            @click="CreateNewReport()"
                            :disabled="buttonStatus || showGettingEoPVerificationReportError || DenyFunctionLevelAccess(FunctionAccessLevels.CreateEoPVReport)"
                            :title="DenyFunctionLevelAccess(FunctionAccessLevels.CreateEoPVReport) ? 'Create New Report (Access Denied)' : 'Create New Report'"
                            class="new-report-button"
                        >
                            Create New Report
                        </button>
                    </div>
                </div>
            </div>
            <div v-if="!showNewFormatReport" class="table-container">
                <h2>Previous Reports</h2>
                <Table
                    :Rows=pastEoPVerificationReports
                    :Cols=cols
                    :Labels=labels
                    :DefaultSortCols=defaultSortCols
                    :DefaultSortOrders=defaultSortOrders
                    v-model:RowsPerPage=rowCount
                    v-model:Page=page
                    :HasError=!!infoError
                >
                    <template #ProcessedDateTime="props">
                            {{ props.value.ProcessedDateTime }}
                    </template>
                    <template #TotalAmount="props">
                        <div>
                            {{ formatAmount(props.value.TotalAmount) }}
                        </div>
                    </template>
                    <template #DeviationAmount="props">
                        <div>
                            {{ formatAmount(props.value.DeviationAmount) }}
                        </div>
                    </template>
                    <template #ReportName="props">
                        <button
                            @click="viewFullPagePDFReport(props.value.ReportName ?? undefined,'EndOfPeriodVerification')"
                            :disabled="detailedReportPdfButtonStatuses[props.value.ReportName ?? '']"
                            :title="detailedReportPdfButtonStatuses[props.value.ReportName ?? ''] ? 
                                    '' : 
                                    'View report pdf (opens file in new tab)'
                            "
                            class="fake-link"
                        >
                            {{ props.value.ReportName }}
                        </button>
                    </template>
                </Table>
            </div>
        </div>
    </div>
</template>

<script setup lang="ts">
import { ref, onMounted, inject } from "vue";
import router from "@/router";
import {
    cols,
    labels,
    EoPVerificationReportServices,
    defaultSortCols,
    defaultSortOrders
} from "@/services/helpers/EoPVerificationReportHelper";
import { FunctionAccessLevels, DenyFunctionLevelAccess } from "@/config/AuthConfig";
// Data
import EoPVerificationReport from "@/models/EoPVerifications/EoPVerificationReport";
// Table
import Table from "@/components/TablePersistentChecking.vue";
// Misc
import { useRoute } from "vue-router";
import log from "loglevel";
import ErrorInfo from "@/components/ErrorInfo.vue";
import { authService } from "@/auth/authSingleton";
import { apiLoggerKey, globalReloadKey } from "@/types/ServiceKeys";
import { JournalingTypes } from "@/models/Journaling/JournalingTypes";
import NewEoPVerificationReport from "./NewEoPVerificationReport.vue";

const reload = inject(globalReloadKey);
const JournalTypesWithNewReportUi: string[] = [JournalingTypes.SERVICE_INVOICE, JournalingTypes.CHURN];

const fileName = "EoPVerification.vue";
const Logger = inject(apiLoggerKey);

const page = ref(1);
const rowCount = ref(40);

const hasInvoice = ref(false);
const hasMultipleInvoices = ref(false);
const showNewFormatReport = ref(false);

log.debug("End of Period Verification Report");

////////////////////////////////////////////////////////////////////////////
// LOGIC
////////////////////////////////////////////////////////////////////////////
onMounted(async () => {
    log.trace("onMounted() EoPVerificationReport");
    document.title = `EoP Verification (${journalType}) - Optio`;
    let selectedJournalReportType = route.params.id
    if(JournalTypesWithNewReportUi.includes(selectedJournalReportType as string)){
        isLoading.value = false;
        showNewFormatReport.value = true;
    }else{
        //@ts-expect-error
        await GetEoPVerificationReport(journalType,market,period,company).then(() => {});
    }
});

// Get EoP Verification Report Data from API
async function GetEoPVerificationReport(journalType: string, market: string, period: string, company: string) {
    const name = GetEoPVerificationReport.name;
    const Class = fileName + "/" + name;

    const urlParams: string = url + `/${journalType}?market=${market}&period=${period}&company=${company}`;

    let Message = `Getting EoP Verification report (${urlParams})`;
    log.trace(`${name}()\n${Message}`);
    try {
        isLoading.value = true;
        const data = await EoPVRServices.processEoPVerificaitonData(urlParams);
        eoPVerificationReports.value = data.map(x=>new EoPVerificationReport(x));
        currentEoPVerificationReport.value = eoPVerificationReports.value[0];
        pastEoPVerificationReports.value = eoPVerificationReports.value.slice(1);
        if (currentEoPVerificationReport.value) {
            if (currentEoPVerificationReport.value.InvoicesInspected){
            hasInvoice.value = true;
            hasMultipleInvoices.value = (currentEoPVerificationReport.value.InvoicesInspected!.length > 1);
            }
        }
        Logger?.LogInformation(Message, Class);
    } catch (error) {
        showGettingEoPVerificationReportError.value = true;
        infoError.value = error;
        Message = `(Error) ${Message}: ${error}`;
        log.error(Message);
        Logger?.LogError(Message, Class);
    } finally {
        isLoading.value = false;
    }
}

// ERROR
const GettingEoPVerificationReportErrorInfo = ErrorInfo;
const showGettingEoPVerificationReportError = ref(false);
const ShowPdfErrorInfo = ErrorInfo;
const showPdfError = ref(false);
const showPdfErrorChanger = ref(true);
const ShowCreateNewReportErrorInfo = ErrorInfo;
const showCreateNewReportError = ref(false);
const ShowCreateNewReportErrorChanger = ref(true);
const infoError = ref();

// TABLE
const isLoading = ref(true);
const url: string = process.env.VUE_APP_EOP_VERIFICATIONS_URL_PARAMS!; 
const eoPVerificationReports = ref<EoPVerificationReport[]>();
const currentEoPVerificationReport = ref<EoPVerificationReport>();
const pastEoPVerificationReports = ref<EoPVerificationReport[]>();

// MISC
const EoPVRServices = new EoPVerificationReportServices();
const buttonStatus = ref(false);

// Send POST request to create new EoP Verification Report
async function CreateNewReport() {
    const name = CreateNewReport.name;
    const Class = fileName + "/" + name;

    log.trace(`${name}()\njournalType: ${journalType}`);
    log.debug("Removing EoPVerifications from sessionStorage");
    sessionStorage.removeItem("EoPVerifications");

    // Disable button until 201 status response
    buttonStatus.value = true;
    isLoading.value = true;

    // Get MARKET and PERIOD from EoP Verifications
    EoPVRServices.LogCreateNewReport(
        market,
        company,
        period,
        journalType!
    );
    // Send POST request
    try {
        const status = await EoPVRServices.CreateNewEoPReport({
            UserName: authService.getEmailAddress(),
            //@ts-expect-error
            Market: market,
            Company: company,
            //@ts-expect-error
            PeriodMonth: period,
            JournalType: journalType!,
        });
        if (status === 201) {
            log.debug(`CreateNewReport()\nstatus: ${status}`);

            // refresh page
            reload?.();

            // enable button
            buttonStatus.value = false;
            isLoading.value = false;

            // Remove error message (if displayed)
            showCreateNewReportError.value = false;
            ShowCreateNewReportErrorChanger.value = !ShowCreateNewReportErrorChanger.value;
        }
    } catch (error ){
        infoError.value = error;

        showCreateNewReportError.value = true;
        ShowCreateNewReportErrorChanger.value = !ShowCreateNewReportErrorChanger.value;

        // enable button
        buttonStatus.value = false;

        const Message = `(Error) Creating new EoP Verifications report: ${error}`;
        log.error(Message);
        Logger?.LogError(Message, Class);
    } finally {
        isLoading.value = false;
    }
}

const invoicePdfButtonStatus = ref(false);
const detailedReportPdfButtonStatus = ref(false);
const detailedReportPdfButtonStatuses = ref<Record<string,boolean>>({});

// View full page pdf in new tab 
function viewFullPagePDFInvoice(fileID: string | undefined, fileType: string | undefined,) {
    log.trace("viewFullPagePDFInvoice()");
    log.debug(`"viewFullPagePDFInvoice()": ${fileID}`);

    const rResolved = router.resolve({
        name: "EoP Verification (PDF)",
        params: {
            id: fileID,
        },
        query: {
            type: fileType,
            endpoint: process.env.VUE_APP_JOURNALED_INVOICES_DOWNLOAD_URL_PARAMS,
            method: "post"
        },
    });
    const url = rResolved.href;
    // Open in new tab
    window.open(url, "_blank");
}
function viewFullPagePDFReport(fileID: string | undefined, fileType: string | undefined,) {
    log.trace("viewFullPagePDFReport()");
    log.debug(`"viewFullPagePDFReport()": ${fileID}`);

    const rResolved = router.resolve({
        name: "EoP Verification (PDF)",
        params: {
            id: fileID,
        },
        query: {
            type: fileType,
            endpoint: process.env.VUE_APP_DETAILED_REPORT_PDF_EOP_VERIFICATIONS_URL_PARAMS,
            method: "get"
        },
    });
    const url = rResolved.href;
    // Open in new tab
    window.open(url, "_blank");
}

// ROUTE
const route = useRoute();
const journalType = route.path.split("/").pop();
const market = route.query.market?.toString();
const company = "Volvo";
const period = route.query.period?.toString();
const displayType = route.query.type?.toString();

function formatAmount(amount: string) {

const number = parseFloat(amount);

if (isNaN(number)) {
    return 0.00.toFixed(2);
}

const formattedNumber = number.toFixed(2);

return formattedNumber;
}

</script>

<style scoped lang="scss">
.eop-v {
    padding: 2%;
}
.eop-v-info {
    padding: 2%;
}
.eop-v-new-report {
    padding-left: 2%;
}
.eop-v-reports {
    padding-bottom: 2%;
}
.eop-v-reports h2 {
    display: flex;
    justify-content: center;
    align-items: center;
}
.new-report-button {
    border-radius: var(--buttons-border-radius);
    background-color: var(--primary-color-lighter);
    padding: 10px;
    color: blue;
    font-size: larger;
    font-weight: bold;
    border: none;
}
.new-report-button:hover {
    cursor: pointer;
    background-color: var(--primary-color);
    color: white;
}
.new-report-button:disabled {
    border-width: 1px;
    color: var(--primary-color-dark);
    background-color: var(--light-grey);
    cursor: not-allowed;
}
.new-report-button:visited {
    color: inherit;
}
.file-list {
    list-style-type: none;
}
.new-report-button-div {
    padding-top:5%;
    padding-right:4%;
}
.invoice-scroll-box {
    height: 95px;
    width: 130px;
    overflow-y: scroll;
}
.top-container {
    display: flex;
    align-items: start;
    justify-content: space-between;
}
</style>