import ILedgerTransaction from "@/Interfaces/Ledgering/ILedgerTransaction";
import DateIgnoreTime from "@/types/DateIgnoreTime";
import DatePeriod from "@/types/DatePeriod";

export default class LedgerTransaction implements ILedgerTransaction {
    constructor(raw: ILedgerTransaction) {
        this.paymentId = raw.paymentId;
        this.transactionId = raw.transactionId;
        this.accountId = raw.accountId;
        this.valueDate = new DateIgnoreTime(raw.valueDate);
        this.targetDate = new DateIgnoreTime(raw.targetDate);
        this.amount = raw.amount;
        this.market = raw.market;
        this.currency = raw.currency;
        this.paymentAdviceReference = raw.paymentAdviceReference;
        this.paymentReference = raw.paymentReference;
        this.transferTypeId = raw.transferTypeId;
        this.itemType = raw.itemType;
        this.itemCategory = raw.itemCategory;
        this.categoryPeriod = new DatePeriod(raw.categoryPeriod);
        this.invoiceId = raw.invoiceId;
        this.creditNoteId = raw.creditNoteId;
        this.contractId = raw.contractId;
        this.assignedTo = raw.assignedTo;
        this.transferInfo = raw.transferInfo;
    }
    paymentId: number;
    transactionId: number | null;
    accountId: string;
    valueDate: DateIgnoreTime | null;
    targetDate: DateIgnoreTime;
    amount: number;
    market: string;
    currency: string;
    paymentAdviceReference: string | null;
    paymentReference: string | null;
    transferTypeId: string;
    itemType: string;
    itemCategory: string;
    categoryPeriod: DatePeriod | null;
    invoiceId: string | null;
    creditNoteId: string | null;
    contractId: string | null;
    assignedTo: string | null;
    transferInfo: string | null;
}