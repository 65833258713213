<template>
    <div class="VATReturns">
        <!-- <VATReturnsTabs /> -->
        <!-- <div v-if="isLoading" class="loading-content-container"> -->
        <div v-if="isLoading" class="content-container">
            <div class="loading">
                <span class="loader"></span>
            </div>
        </div>
        <!-- <div v-else class="tabs-content-container"> -->
        <div v-else class="tabs-content-container">
            <div class="top-content">
                <VATReturnsTabs />
                <div class="tabs-content">
                    <SearchForVATReturnsFromJournaledReferencesErrorInfo
                        :showError="showSearchForVATReturnsFromJournaledReferencesError" :closeError="true"
                        errorDescription="(Getting VAT Returns)" :error="infoError"
                        :change="showSearchForVATReturnsFromJournaledReferencesErrorChanger" />
                    <DownloadVATReturnsErrorInfo :showError="showDownloadVATReturnsError" :closeError="true"
                        errorDescription="(Downloading VAT Returns PDFs)"
                        errorDisplayText="An error was encountered generating and downloading the CSV file, please check the data and try again later. If this error persists, please raise a ticket with the help desk."
                        :error="infoError" :change="showDownloadVATReturnsErrorChanger" />

                    <div class="center-dropdown-table-container">
                        <table class="center-dropdown-table">
                            <tbody>
                                <tr>
                                    <td>
                                        <div>Search for Journal Reference:</div>
                                    </td>
                                    <td>
                                        <div>Search for Invoice by Date:</div>
                                    </td>
                                    <td v-if="currentTab != tabs.Summary && !isDateSearch">
                                        <div>Filter VAT Returns by Journal Type:</div>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <span title="Search for invoices to view and download by journal reference"
                                            class="search-with-button" style="padding-left: 1rem; padding-right: 1rem">
                                            <input ref="journalSearchInput" type="text" v-model="journalReference"
                                                placeholder="Enter comma-separated Journal References..."
                                                @keyup.enter="search" style="width: 100%;" />
                                            <button ref="journalSearchButton" @click="search"
                                                :disabled="journalReference.trim().length < 1" class="right-button">
                                                Search
                                            </button>
                                        </span>
                                    </td>
                                    <td>
                                        <VATReturnsDatePicker />
                                    </td>
                                    <td v-if="currentTab != tabs.Summary && !isDateSearch">
                                        <span class="search-with-button"
                                            style="padding-left: 1rem; padding-right: 1rem; width: 100%;">
                                            <div class="embedded-dropdown" style="justify-content: center; width: 100%;">
                                                <select v-model="journalTypeSelection"
                                                    style="width: 100%; margin-top: 0px;">
                                                    <option value="" disabled selected hidden>
                                                        Select Journal Type...
                                                    </option>
                                                    <option value="">
                                                        {{
                                                            `${journalTypeSelection
                                                                ? "All Journal Types"
                                                                : "Select Journal Type..."
                                                                }`
                                                        }}
                                                    </option>
                                                    <option :id="currentTab.toString()" v-for="jType in GetTabJournalingTypes().sort((a, b) =>
                                                        a.Canonical.localeCompare(
                                                            b.Canonical
                                                        )
                                                    )
                                                    " :value="jType.Canonical" :key="jType.Canonical">
                                                        {{ jType.DisplayShort }}
                                                    </option>
                                                </select>
                                            </div>
                                        </span>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <div class="center-buttons" style="margin-top: 0">
                                            <button v-if="currentTab != tabs.Summary" ref="downloadButton"
                                                class="incident-nav-button" :disabled="!displayedVATReturns?.length"
                                                @click="JVATServices.DownloadVATReturnsData()">
                                                <div>
                                                    Export Box {{ currentTab }} data to CSV
                                                </div>
                                            </button>
                                            <button ref="downloadButton" class="incident-nav-button"
                                                style="margin-left: 1rem;" :disabled="!displayedVATReturns?.length"
                                                @click="JVATServices.DownloadVATReturnsData(true)">
                                                Export all data to CSV
                                            </button>
                                        </div>
                                    </td>
                                    <td v-if="currentTab != tabs.Summary && !isDateSearch"></td>
                                    <td style="vertical-align: middle;">
                                        <div v-if="currentTab == tabs.Summary">
                                            <details v-if="validJournalReferences.length">
                                                <summary>
                                                    View {{ validJournalReferences.length }} Journal References:
                                                </summary>

                                                <body style="font-weight: normal !important;">
                                                    {{ validJournalReferences.join(' ') }}
                                                </body>
                                            </details>
                                            <details v-if="invalidJournalReferences.length">
                                                <summary>
                                                    View {{ invalidJournalReferences.length }} Invalid Journal References:
                                                </summary>

                                                <body style="font-weight: normal !important;">
                                                    {{ invalidJournalReferences.join(' ') }}
                                                </body>
                                            </details>
                                        </div>
                                        <div v-else class="center-buttons" style="margin-top: 0;">
                                            {{
                                                (currentTab == tabs.Box6 || currentTab == tabs.Box7)
                                                ? 'Amount Ex. VAT Total:'
                                                : 'Amount VAT Total:'
                                            }}
                                            <button class="incident-nav-button-fake" style="margin-left: 0.7rem;">
                                                {{ MonetaryValueWithCommas((Math.round(displayAmountVATTotal * 100) /
                                                    100))
                                                }}
                                            </button>
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>

                    <div v-if="showDescription &&
                        !showSearchForVATReturnsFromJournaledReferencesError
                        " class="desc-error">
                        <div class="desc">
                            <div class="desc-header">NOTE:</div>
                            <div>
                                {{
                                    `${description}`.endsWith(".")
                                    ? `${description}`.slice(0, -1)
                                    : `${description}`
                                }}.
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <VATReturnsTables />
        </div>
    </div>
</template>

<script setup lang="ts">
import { ref, Ref, onMounted, provide, inject, watch } from "vue";
// Components
import VATReturnsTabs from "@/components/VATReturns/VATReturnsTabs.vue";
import VATReturnsTables from "@/components/VATReturns/VATReturnsTables.vue";
import VATReturnsDatePicker from "@/components/VATReturns/VATReturnsDatePicker.vue";
// Services
import { IAPILogger } from "@/interfaces/IAPILogger";
import { MonetaryValueWithCommas } from "@/services/helpers/Helper";
import VATReturnsServices from "@/services/helpers/VATReturns/VATReturnsHelper";
import {
    VATReturns,
    displayedVATReturns,
    displayAmountVATTotal,
    isLoading,
    journalReference,
    invalidJournalReferences,
    validJournalReferences,
    infoError,
    tabs,
    currentTab,
    SearchForVATReturnsFromJournaledReferencesErrorInfo,
    showSearchForVATReturnsFromJournaledReferencesError,
    showSearchForVATReturnsFromJournaledReferencesErrorChanger,
    DownloadVATReturnsErrorInfo,
    showDownloadVATReturnsError,
    showDownloadVATReturnsErrorChanger,
    journalTypeSelection,
    description,
    showDescription,
    isDateSearch,
    dateRange,
    journalTypeSelectionDate
} from "@/services/helpers/VATReturns/VATReturnsVariables";
import {
    ValidateJournalReferences,
    FilterVATReturns,
    DisplayVATReturns,
    DisplayVATReturnsNotFound,
    GetTabJournalingTypes,
} from "@/services/helpers/VATReturns/VATReturnsFunctions";
// Data
import IJournalVATResponse from "@/interfaces/VATReturns/IJournalVATResponse";
import INotFoundError from "@/Interfaces/Errors/INotFoundError";
import JournalVAT from "@/models/VATReturns/JournalVat";
// Misc
import log from "loglevel";
import { AxiosError } from "axios";
import "@vuepic/vue-datepicker/dist/main.css";

const page: Ref<number> = ref(1);
const rowCount: Ref<number> = ref(50);
provide("page", page);
provide("rowCount", rowCount);

const fileName = "VATReturns.vue";
const Logger: IAPILogger = inject("Logger")!;

const journalSearchInput: Ref<any> = ref();
const journalSearchButton: Ref<any> = ref();

const downloadButton: Ref<any> = ref();

watch(
    () => journalTypeSelection.value,
    () => {
        FilterVATReturns();
    }
);

// MISC
const JVATServices: VATReturnsServices = new VATReturnsServices();

onMounted(() => {
    log.trace("onMounted() Invoices");

    document.title = `Invoices - Optio`;

    VATReturns.value = [];
    displayedVATReturns.value = [];
});

/*
    Search for comma-separated journal references at once.
*/
function search() {
    /*
        August Long:    JRN_10_CustomerCreditNote, JRN_51_CustomerInvoice, JRN_53_CustomerInvoice, JRN_15_CarSaleInvoice, JRN_44_CustomerInvoice, JRN_25_CustomerInvoice, JRN_26_CustomerInvoice, JRN_38_CustomerInvoice, JRN_9_CustomerCreditNote, JRN_17_CarSaleInvoice, JRN_41_CustomerInvoice, JRN_54_CustomerInvoice, JRN_3_CarSaleCreditNote, JRN_12_CarSaleInvoice, JRN_50_CustomerInvoice, JRN_31_CustomerInvoice, JRN_16_CarSaleInvoice, JRN_49_CustomerInvoice, JRN_INV_3_ServiceInvoice, JRN_36_CustomerInvoice, JRN_8_CustomerCreditNote, JRN_9_CarSaleInvoice, JRN_14_CarSaleInvoice, JRN_33_CustomerInvoice, JRN_47_CustomerInvoice, JRN_1_CarSaleCreditNote, JRN_6_CustomerCreditNote, JRN_34_CustomerInvoice, JRN_48_CustomerInvoice, JRN_45_CustomerInvoice, JRN_52_CustomerInvoice, JRN_30_CustomerInvoice, JRN_28_CustomerInvoice, JRN_46_CustomerInvoice, JRN_39_CustomerInvoice, JRN_7_CustomerCreditNote, JRN_4_CarSaleInvoice, JRN_11_CarSaleInvoice, JRN_37_CustomerInvoice, JRN_40_CustomerInvoice, JRN_55_CustomerInvoice, JRN_42_CustomerInvoice, JRN_29_CustomerInvoice, JRN_6_CarSaleInvoice, JRN_22_CustomerInvoice, JRN_7_CarSaleInvoice, JRN_8_CarSaleInvoice, JRN_35_CustomerInvoice, JRN_5_CarSaleInvoice, JRN_5_CustomerCreditNote, JRN_25_CarSaleInvoice, JRN_13_CarSaleInvoice, JRN_24_CustomerInvoice, JRN_32_CustomerInvoice, JRN_43_CustomerInvoice, JRN_10_CarSaleInvoice, JRN_23_CustomerInvoice, JRN_27_CustomerInvoice, JRN_2_CarSaleCreditNote
        August Short:   JRN_13_CustomerInvoice, JRN_10_CustomerInvoice, JRN_14_CustomerInvoice, JRN_12_CustomerInvoice, JRN_17_CustomerInvoice, JRN_INV_1_ServiceInvoice, JRN_16_CustomerInvoice, JRN_15_CustomerInvoice, JRN_2_CustomerCreditNote, JRN_11_CustomerInvoice, JRN_18_CustomerInvoice, JRN_21_CustomerInvoice, JRN_3_CustomerCreditNote, JRN_9_CustomerInvoice, JRN_4_CustomerCreditNote, JRN_19_CustomerInvoice, JRN_1_CustomerCreditNote
    
        JRN_6_CustomerCreditNote, JRN_26_CustomerInvoice, JRN_38_CustomerInvoice, JRN_45_CustomerInvoice, JRN_24_CustomerInvoice, JRN_7_CarSaleInvoice, JRN_46_CustomerInvoice, JRN_14_CarSaleInvoice, JRN_6_CarSaleInvoice, JRN_16_CarSaleInvoice, JRN_8_CarSaleInvoice, JRN_5_CarSaleInvoice, JRN_9_CustomerCreditNote, JRN_31_CustomerInvoice, JRN_30_CustomerInvoice, JRN_37_CustomerInvoice, JRN_39_CustomerInvoice, JRN_55_CustomerInvoice, JRN_22_CustomerInvoice, JRN_2_CarSaleCreditNote, JRN_15_CarSaleInvoice, JRN_5_CustomerCreditNote, JRN_49_CustomerInvoice, JRN_INV_3_ServiceInvoice, JRN_23_CustomerInvoice, JRN_28_CustomerInvoice, JRN_17_CarSaleInvoice, JRN_11_CarSaleInvoice, JRN_44_CustomerInvoice, JRN_48_CustomerInvoice, JRN_51_CustomerInvoice, JRN_35_CustomerInvoice, JRN_4_CarSaleInvoice, JRN_7_CustomerCreditNote, JRN_33_CustomerInvoice, JRN_8_CustomerCreditNote, JRN_1_CarSaleCreditNote, JRN_9_CarSaleInvoice, JRN_10_CustomerCreditNote, JRN_3_CarSaleCreditNote, JRN_10_CarSaleInvoice, JRN_41_CustomerInvoice, JRN_52_CustomerInvoice, JRN_29_CustomerInvoice, JRN_54_CustomerInvoice, JRN_42_CustomerInvoice, JRN_27_CustomerInvoice, JRN_12_CarSaleInvoice, JRN_53_CustomerInvoice, JRN_40_CustomerInvoice, JRN_36_CustomerInvoice, JRN_25_CarSaleInvoice, JRN_13_CarSaleInvoice, JRN_34_CustomerInvoice, JRN_50_CustomerInvoice, JRN_43_CustomerInvoice, JRN_32_CustomerInvoice, JRN_47_CustomerInvoice, JRN_25_CustomerInvoice
        JRN_6_CarSaleInvoice, JRN_1_RechargeInvoice, JRN_1_ServiceInvoice, JRN_INV_1_ServiceInvoice, JRN_INV_9_ServiceInvoice, JRN_4_CustomerInvoice, JRN_5_CustomerInvoice, JRN_6_CustomerCreditNote, JRN_29_CustomerCreditNote, JRN_1_CarSaleCreditNote, JRN_5_CarSaleCreditNote, JRN_80_CustomerInvoice

        ERROR: ERROR_JRN_6_CarSaleInvoice, ERROR_JRN_1_RechargeInvoice, ERRORJRN_1_ServiceInvoice, JRN_INV_1ERROR_ServiceInvoice, JRN_INV_9_ServiceInvoice, JRN_4_ERRORCustomerInvoice, JRN_5_CustomerInvoice, JRN_6_CustomerCreditNote, JRN_29_CustomerCreditNote, JRN_1_CarSaleCreditNote, JRN_5_CarSaleCreditNote, JRN_80_CustomerInvoice
     */
    // Logging
    const name = "search";
    const prefix = `${name}()\r\n`;
    log.trace(`${name}{}`);

    const delimeter: string = "\r\n        ";
    journalReference.value = journalReference.value.replaceAll(" ", "");
    var journalReferences: string[] = journalReference.value
        .split(",")
        .map((ref: string) => ref + ",");

    // Remove trailing comma
    if (journalReferences[journalReferences.length - 1].endsWith(",")) {
        journalReferences[journalReferences.length - 1] = journalReferences[
            journalReferences.length - 1
        ].slice(0, -1);
    }

    // Updates <invalidJournalReferences> and <validJournalReferencesByJournalType>
    ValidateJournalReferences(journalReferences);

    if (invalidJournalReferences.value.length) {
        var invalids: string = delimeter + invalidJournalReferences.value.join(delimeter);
        // Remove trailing comma
        if (invalids.endsWith(",")) invalids = invalids.slice(0, -1);
        invalids = invalids + "\r\n\r\nPlease try again.";

        alert(
            `(ERROR) The following ${invalidJournalReferences.value.length} references were invalid: ${invalids}`
        );
    } else {
        var query: string = delimeter + journalReferences.join(delimeter);
        // Remove trailing comma
        if (query.endsWith(",")) query = query.slice(0, -1);

        if (query) {
            log.trace(`search()\nSearching for ${journalReference.value}`);

            SearchForJournalReference();

            currentTab.value = tabs.Summary;
        } else {
            alert(`No journal references to search for: '${query}'`);
        }
    }
}

async function SearchForJournalReference() {
    isLoading.value = true;
    isDateSearch.value = false;
    dateRange.value = undefined;
    journalTypeSelectionDate.value = "";

    const name = SearchForJournalReference.name;
    const prefix = `${name}()\n`;
    const Class = fileName + "/" + name;
    var Message = `Searching for Invoices files (Journal Reference: ${journalReference.value}).`;

    log.trace(prefix + Message);
    await JVATServices.GetVATReturnsFromJournalReferences(journalReference.value)
        .then((response: IJournalVATResponse) => {
            log.trace("SearchForJournalReference() Response:", response);
            DisplayVATReturns(response);

            displayAmountVATTotal.value = (displayedVATReturns.value ?? []).reduce((partialSum: number, vatReturn: JournalVAT) => {
                log.trace(prefix + "vatReturn:", vatReturn, "\nJournalVATMetaData:", vatReturn.JournalVATMetaData);
                for (const metadata of vatReturn.JournalVATMetaData.AccountingMetaData) {
                    partialSum += metadata.IsIncrease
                        ? (metadata.UseAmountVAT ? vatReturn.AmountVat : vatReturn.AmountExVat)
                        : - (metadata.UseAmountVAT ? vatReturn.AmountVat : vatReturn.AmountExVat)
                }
                return partialSum;
            }, 0);

            Logger.LogInformation(Message, Class);
        })
        .catch((error: any) => {
            if (error instanceof AxiosError && error.response?.status == 400) {
                DisplayVATReturnsNotFound(error as INotFoundError);
            } else {
                if (error instanceof AxiosError) {
                    isLoading.value = false;
                    showSearchForVATReturnsFromJournaledReferencesError.value = true;
                    showSearchForVATReturnsFromJournaledReferencesErrorChanger.value = !showSearchForVATReturnsFromJournaledReferencesErrorChanger.value;
                    infoError.value = error;
                }
                Message = `(Error) ${Message}: ${error instanceof AxiosError ? error : error.message
                    }`;
                log.error(Message);
                Logger.LogError(Message, Class);
            }
        });
}
</script>

<style scoped lang="scss">
.incident-nav-button-fake:hover {
    cursor: default;
    border-radius: var(--buttons-border-radius);
    background-color: var(--primary-color-lighter);
    padding: 10px;
    color: blue;
    font-size: larger;
    font-weight: bold;
}

.loading-content-container {
    height: 100%;
}

.tabs-content-container {
    position: relative;
    display: flex;
    flex-direction: column;
    width: var(--content-width);
    align-items: center;
    justify-content: center;
    flex: 1;
    /* Fill the remaining vertical space */
    overflow-y: auto;
    /* Add scroll if content overflows */
}

.tabs-content {
    padding-left: 2em;
    padding-right: 2em;
}
</style>
