import * as Msal from "@azure/msal-browser";
import { msalConfig, apiScope, msalConfigPowerBi, apiScopePowerBi } from "@/config/msalConfig";
import log from "loglevel";
import { InteractionRequiredAuthError } from "@azure/msal-browser";

export default class AuthService {
    constructor(powerBi) {
        this.powerBi = powerBi;

        this.loginRequest = {
            scopes: [!powerBi ? apiScope : apiScopePowerBi],
            prompt:  "select_account",
        };
        this.accessTokenRequest = {
            scopes: [!powerBi ? apiScope : apiScopePowerBi], 
            forceRefresh: true,
        };

        this.app = !powerBi ? new Msal.PublicClientApplication(msalConfig) : new Msal.PublicClientApplication(msalConfigPowerBi);

        this.initializeApp();
    }

    async initializeApp() {
        try {
            await this.app.initialize();
            log.debug("AuthService\ninitializeApp()\nMSAL instance initialized successfully.");
        } catch (error) {
            log.error("AuthService\ninitializeApp()\nInitialization error:", error);
        }
    }

    async login() {
        log.debug("AuthService\nlogin()");
        try {
            const loginResponse = await this.app.loginPopup(this.loginRequest);
            log.trace("AuthService\nlogin()\nloginResponse:", loginResponse);

            this.app.setActiveAccount(loginResponse.account);
            const accessTokenResponse = await this.app.acquireTokenSilent(this.accessTokenRequest);

            if (accessTokenResponse && accessTokenResponse.accessToken) {
                log.trace("AuthService\nlogin()\naccessTokenResponse:", accessTokenResponse);
                return true;
            }
            
            return false;
        } catch (error) {
            log.error("AuthService\nlogin()\nerror:", error);
            return false;
        }
    }

    async logout() {
        log.debug("AuthService\nlogout()");
        try {
            await this.app.logoutPopup();
        } catch (error) {
            log.error("AuthService\nlogout()\nerror:", error);
        }
    }


    async clearAuthCacheAndReauthenticate() {
        // Clear browser storage
        sessionStorage.removeItem("msal.accessToken");
    
    }

    async getApiToken(loginHint) {
        log.trace("AuthService\ngetApiToken()");
        await this.clearAuthCacheAndReauthenticate()
        console.log("getApiToken() ___  get api token triggered")
        const activeAccount = this.app.getActiveAccount() || this.app.getAllAccounts()[0];
        console.log("getApiToken() ___ Active Account: ")
        console.log(activeAccount)
        if (!activeAccount) {
            console.log("getApiToken() ___ Active Account: ")
            console.log(activeAccount)
            log.error("No active account found.");
            return undefined;
        }
    
        try {
            //throw new InteractionRequiredAuthError("simulated_error_code", "Simulated interaction required");
            const tokenResponse = await this.app.acquireTokenSilent(this.accessTokenRequest);
            console.log("getApiToken() ___  tokenResponse")
            console.log(tokenResponse)

            return tokenResponse.accessToken;
        } catch (error) {
            log.error("Silent token acquisition failed:", error);
    
            if (error instanceof InteractionRequiredAuthError) {
                try {
                    console.log("getApiToken() ___  interactionRequired Auth Error ")

                    const tokenResponse = await this.app.acquireTokenPopup(this.accessTokenRequest);
                    console.log("getApiToken() ___  interactionRequired tokenResponse")
                    console.log(tokenResponse)


                    return tokenResponse.accessToken;
                } catch (popupError) {
                    log.error("Token acquisition via popup failed:", popupError);
                    console.log(" getApiToken() ___ error in token pop up response ")
                    console.log(error)

                }
            }
        }
    
        return undefined;
    }

    async isLoggedIn() {
        log.trace("AuthService\nisLoggedIn()");
        const accounts = this.app.getAllAccounts();
        if (accounts.length > 0) {
            try {
                const response = await this.app.acquireTokenSilent(this.accessTokenRequest);
                if (response && response.accessToken) {
                    return true;
                }
            } catch (error) {
                log.error("AuthService\nisLoggedIn()\nerror:", error);
            }
        }
        return false
    }

    getUserName() {
        log.trace("AuthService\ngetUserName()");
        const accounts = this.app.getAllAccounts();
        if (accounts.length > 0) {
            return accounts[0].name || "Unknown User";
        }
        return "Unknown User";
    }

    getEmailAddress() {
        log.trace("AuthService\ngetEmailAddress()");
        const accounts = this.app.getAllAccounts();
        if (accounts.length > 0) {
            return accounts[0].username || "Unknown User";
        }
        log.warn("AuthService\ngetEmailAddress()\nUser email could not be retrieved.");
        return "Unknown User";
    }

    getUsersRole() {
        log.trace("AuthService\ngetUsersRole()");
        const accounts = this.app.getAllAccounts();
        if (accounts.length > 0 && accounts[0].idTokenClaims) {
            const roles = accounts[0].idTokenClaims.roles;
            if (roles && roles.length > 0) {
                return roles[0];
            }
        }
        return "UnknownRole";
    }
}