import FileNetworkService from "./FileNetworkService";

export default class PostFileNetworkService<Param=undefined, Body=Param> extends FileNetworkService<Param,Body> {
    protected override getUrl(paramURI: string) {
        return this.getBaseURL() + paramURI;
    }
    protected override getMethod() {
        return 'POST';
    }
    protected override async getReqConfig(data?: Body|null) {
        const req = await super.getReqConfig();
        req.data = data ?? undefined;
        return req;
    }
}