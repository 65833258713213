<template>
    <div class='notification' :class="[NotificationType[type], {
        paused: paused, progress:(duration > 0)
    }]" >
        <div v-html="getSVGIcon(type)" class="notification-icon"></div>
        <div ref="body" class="body">
            <slot :text=msg :title=title >
                <div v-if=title class="notification-title">{{ title }}</div>
                <ExpandableText v-model=expand 
                    class="notification-content"
                >
                    {{ msg }}
                </ExpandableText>
            </slot>
        </div>
        <CloseCircleOutline class="close" @click=onCloseClick />
    </div>
</template>
<script setup lang="ts">
import { ref, computed, onMounted } from 'vue';
import CloseCircleOutline from '@/components/CloseCircleOutline.vue';
import NotificationType from '@/types/NotificationType';
import ExpandableText from './ExpandableText.vue';
import log from 'loglevel';

export interface INotificationPopupProps {
    id: number;
    type: NotificationType;
    title?: string;
    msg: string;
    duration: number;
    startPaused?: boolean;
}
export interface INotificationPopupExpose {
    pause: () => void;
    resume: () => void;
    destroy: () => void;
    getId: () => number;
}
export interface INotificationPopup extends INotificationPopupExpose, INotificationPopupProps {}
const props = defineProps<INotificationPopupProps>();
const emits = defineEmits<{
    (e: 'destroy', id: number): void;
}>();
defineExpose<INotificationPopupExpose>({
    pause,
    resume,
    destroy,
    getId: ()=>props.id,
});
let start = 0;
const timer = ref(0);
const timePassed = ref(0);
const paused = computed(()=>timer.value === 0);
const alive = computed(()=>timePassed.value <= props.duration);
// expand button
const body = ref<HTMLElement>();
const expand = ref(false);
function onCloseClick(e: Event) {
    e.stopPropagation();
    destroy();
}
function pause() {
    if (props.duration > 0 && !paused.value) {
        window.clearTimeout(timer.value);
        const timePassedSinceLastResume = performance.now() - start;
        timePassed.value += timePassedSinceLastResume;
        timer.value = 0;
    }
}
function destroy() {
    log?.debug('destroying popup', props.id);
    window.clearTimeout(timer.value);
    timePassed.value = props.duration;
    emits('destroy', props.id);
}
function createDestroyTimer() {
    if (props.duration - timePassed.value > 0) {
        log?.debug('createDestroyTimer', props.duration, timePassed.value);
        return window.setTimeout(() => destroy(), props.duration - timePassed.value);
    }
    return 0;
}
function resume() {
    log.debug('resume', alive.value, expand.value);
    if (alive.value) {
        if (!expand.value) {
            start = performance.now();
            timer.value = createDestroyTimer();
        }
    }
    else emits('destroy', props.id);
}
function getSVGIcon(type: NotificationType) {
    let svg: string;
    switch (type) {
        case NotificationType.SUCCESS:
            svg =
                '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">' +
                '<path d="M504 256c0 136.967-111.033 248-248 248S8 392.967 8 256 119.033 8 ' +
                '256 8s248 111.033 248 248zM227.314 387.314l184-184c6.248-6.248 6.248-16.379 ' +
                '0-22.627l-22.627-22.627c-6.248-6.249-16.379-6.249-22.628 0L216 ' +
                '308.118l-70.059-70.059c-6.248-6.248-16.379-6.248-22.628 0l-22.627 ' +
                '22.627c-6.248 6.248-6.248 16.379 0 22.627l104 104c6.249 6.249 ' +
                '16.379 6.249 22.628.001z"></path></svg>';
            break;
        case NotificationType.WARN:
            svg =
                '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">' +
                '<path d="M504 256c0 136.997-111.043 248-248 248S8 392.997 8 256C8 119.083 ' +
                '119.043 8 256 8s248 111.083 248 248zm-248 50c-25.405 0-46 20.595-46 46s20.595 ' +
                '46 46 46 46-20.595 46-46-20.595-46-46-46zm-43.673-165.346l7.418 136c.347 6.364 ' +
                '5.609 11.346 11.982 11.346h48.546c6.373 0 11.635-4.982 ' +
                '11.982-11.346l7.418-136c.375-6.874-5.098-12.654-11.982-12.654h-63.383c-6.884 ' +
                '0-12.356 5.78-11.981 12.654z"></path></svg>';
            break;
        case NotificationType.ERR:
            svg =
                '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">' +
                '<path d="M256 8C119 8 8 119 8 256s111 248 248 248 248-111 248-248S393 8 256 ' +
                '8zm121.6 313.1c4.7 4.7 4.7 12.3 0 17L338 377.6c-4.7 4.7-12.3 4.7-17 0L256 ' +
                '312l-65.1 65.6c-4.7 4.7-12.3 4.7-17 0L134.4 338c-4.7-4.7-4.7-12.3 ' +
                '0-17l65.6-65-65.6-65.1c-4.7-4.7-4.7-12.3 0-17l39.6-39.6c4.7-4.7 12.3-4.7 17 ' +
                '0l65 65.7 65.1-65.6c4.7-4.7 12.3-4.7 17 0l39.6 39.6c4.7 4.7 4.7 12.3 0 ' +
                '17L312 256l65.6 65.1z"></path></svg>';
            break;
        case NotificationType.INFO:
        default:
            svg =
                '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">' +
                '<path d="M256 8C119.043 8 8 119.083 8 256c0 136.997 111.043 248 248 248s248-111.003 ' +
                '248-248C504 119.083 392.957 8 256 8zm0 110c23.196 0 42 18.804 42 42s-18.804 ' +
                '42-42 42-42-18.804-42-42 18.804-42 42-42zm56 254c0 6.627-5.373 12-12 12h-88c-6.627 ' +
                '0-12-5.373-12-12v-24c0-6.627 5.373-12 12-12h12v-64h-12c-6.627 ' +
                '0-12-5.373-12-12v-24c0-6.627 5.373-12 12-12h64c6.627 0 12 5.373 12 12v100h12c6.627 ' +
                '0 12 5.373 12 12v24z"></path></svg>';
            break;
    }
    return svg;
}
onMounted(()=>{
    //console.log('mounting new notification',props.id,performance.now());
    props.startPaused?undefined:resume();
});
</script>
<style lang="scss" scoped>
@import '@/assets/styles/colours.module';
@import '@/assets/styles/variables.module.scss';
div.notification {
    position: relative;

    display: flex;
    flex-wrap: nowrap;
    column-gap: $normal-font-size;

    padding-left: $normal-font-size !important;
    
    color: white;
    text-align: left;
    white-space: pre-wrap;
    background: #44A4FC;

    box-shadow: 0 0 0.5rem rgb(0, 0, 0, 0.7);

    overflow: hidden;

    &:before {
        content: '';
        display: inline-block;
        position: absolute;
        top: 0; bottom: 0; left: 0;
        width: $notification-bar-width;
        height: 100%;
        animation-name: notification-progress;
        overflow:hidden;
    }
    &.progress {
        &:before {
            animation-fill-mode: forwards;
            animation-timing-function: linear;
            animation-duration: calc(v-bind(duration)*1ms);
        }
        &.paused:before {
            animation-play-state: paused;
        }
    }
    >.body {
        // this bit makes it expand to only maximum allowable size when content too long
        flex: 1 1 auto;
        overflow: hidden;

        display: flex;
        flex-direction: column;
        row-gap: 0.5lh;
        >.notification-title {
            font-weight: 600;
            font-size: $large-font-size;
        }
        >.notification-content{
            font-size: $normal-font-size;
        }
    }
    >.close,
    >.notification-icon {
        flex: 0 0 auto;

        display:flex;
        align-items:center;
        justify-content:center;
    }
    >.close {
        height: $large-font-size;
        cursor:pointer;
    }
    >.notification-icon {
        &>* {
            width: $normal-font-size;
            height: $normal-font-size;
        }
        width:$large-font-size;
        height:$large-font-size;
    }
    &.WARN {
        //background: #ffb648;
        //border-left-color: #f48a06;
        background: $warn-bg-color;
        fill: $dark-grey;
        color: $dark-grey;
        &:before {
            background: $warn-progress-color;
        }
    }
    &.ERR {
        //background: #E54D42;
        //border-left-color: #B82E24;
        background: $err-bg-color;
        fill: white;
        &:before {
            background: $err-progress-color;
        }
    }
    &.SUCCESS {
        //background: #68CD86;
        //border-left-color: #42A85F;
        background: $success-bg-color;
        fill: white;
        &:before {
            background: $success-progress-color;
        }
    }
    &.INFO {
        background: $info-bg-color;
        fill: white;
        &:before {
            background: $info-progress-color;
        }
    }
}
@keyframes notification-progress {
    0% { top: 0%; }
    100% { top: 100% }
}
</style>