import ContractValidation from './ContractValidation';
import { IContractWithValidation } from "@/Interfaces/Contract/IContractValidationResponse";

export default class ContractWithValidations extends ContractValidation implements IContractWithValidation {
    constructor(raw: IContractWithValidation) {
        const lastValidation = ((raw.validations ?? []).length > 0) ? raw.validations?.sort(
            (v1, v2) => (v1.validationDateTime < v2.validationDateTime) ? 1 : 
            (v1.validationDateTime > v2.validationDateTime) ? -1 : 0
        ).at(0) : undefined;
        super(lastValidation);
        Object.assign(this, raw);
        this.validations = raw.validations?.map(x => new ContractValidation(x));
    }
    validations?: ContractValidation[] | undefined;
    ownerID!: string | null;
    contractID!: string;
    customerID!: string;
    orderID!: string | null;
    market!: string;
    status!: string;
    initialDate!: string | null;
    activationDate!: string | null;
    terminationDate!: string | null;
    hasFileData!: boolean;
}