<template>
    <TransitionGroup tag="div" 
        name="notification-fade" 
        class="notification-group"
        @mouseenter=onMouseEnter 
        @mouseleave=onMouseLeave
        @click="disableResume=!disableResume" >
        <div v-if="popUpProps.size > 1" class="closeAll" @click=destroyAll>
            <CloseThick />
        </div>
        <NotificationPopup v-for="item of popUpProps.reversed()"
            :ref="(el)=>setPopupRef(el as unknown as INotificationPopupExpose)"
            :key="item.id"
            :id="item.id"
            :title="item.title"
            :msg="item.msg"
            :type="item.type"
            :duration="item.duration"
            :start-paused="item.startPaused"
            @destroy=onDestroy
        />
    </TransitionGroup>
</template>

<script setup lang="ts">
import { reactive, ref } from 'vue';
import CloseThick from '@/components/CloseThick.vue';
import Map from '@/types/LinkedMap';
import NotificationPopup, {INotificationPopupProps, INotificationPopupExpose} from './NotificationPopup.vue';
import NotificationType from '@/types/NotificationType';
import DeferredPromise from '@/types/DeferredPromise';

export interface INotify {
    type?: NotificationType;
    title?: string;
    msg: string;
    /**
     * duration
     * 0 for not hiding on its own
     */
    dur?: number;
}
defineExpose({ add });
const popUpProps = reactive(new Map<number, INotificationPopupProps>());
const popUpRefs = new Map<number, DeferredPromise<INotificationPopupExpose>>();
const hovering = ref(false);
const disableResume = ref(false);
// let tail = 0; // for bug tracking only...
let head = -1;

function setPopupRef(el: INotificationPopupExpose) {
    if (el) {
        const id = el.getId();
        let promise = popUpRefs.get(id);
        if (!promise) {
            promise = new DeferredPromise<INotificationPopupExpose>();
            popUpRefs.set(id, promise);
        }
        promise.Resolve(el);        
    }
}
function pauseTimeout() {
    popUpRefs.forEach(p => p.then(x=>x.pause()));
}
function resumeTimeout() {
    popUpRefs.forEach(p => p.then(x=>x.resume()));
}
function onMouseEnter() {
    hovering.value = true;
    pauseTimeout();
}
function onMouseLeave() {
    hovering.value = false;
    if (!disableResume.value) resumeTimeout();
}
function getNextId(): number {
    if (++head >= 9999999) head = 0;
    return head;
}
async function add(props: INotify) {
    const processed: INotificationPopupProps = {
        id: getNextId(),
        title: props.title,
        msg: props.msg,
        type: props.type ?? NotificationType.INFO,
        duration: props.dur ?? (props.type === NotificationType.ERR ? 0 : 3000),
        startPaused: hovering.value || disableResume.value,
    };
    const promise = new DeferredPromise<INotificationPopupExpose>();
    popUpRefs.set(processed.id, promise);
    popUpProps.set(processed.id, processed);
    return await promise;
}
function destroyAll(e: Event): void {
    e.stopPropagation();
    popUpRefs.forEach(a => a.then(x=>x.destroy()));
    // following probably not need
    //popUpRefs.clear();
    //popUpProps.clear();
}
function onDestroy(id: number) {
    popUpRefs.delete(id);
    popUpProps.delete(id);
    if (popUpProps.size <= 0) {
        disableResume.value = false;
    }
}
</script>

<style lang="scss" scoped>
@import '@/assets/styles/colours.module';
@import '@/assets/styles/variables.module.scss';
.notification-group {
    display: flex;
    flex-direction: column;
    row-gap: calc($normal-font-size/2);
    
    position: fixed;
    top: 0;
    right: 50%;
    transform: translateX(50%);
    z-index: 9999;

    padding-top: calc($normal-font-size / 2);
    max-width: 80%;
    
    >.closeAll,
    >:deep(.notification) {        
        padding: calc($normal-font-size/2);
        border-radius: 0.25rem;
        margin: 0;
    }
    >.closeAll {
        display: flex;
        align-items: center;
        justify-content: center;
        
        color: white;
        background-color: $grey;
        
        cursor:pointer;
        >span {
            flex-grow: 0;
            height: fit-content;
        }
        >svg {
            display: block;
            margin: auto;
            height: $large-font-size;
            width: fit-content;
        }
    }
}

.notification-fade-enter-active,
.notification-fade-leave-active,
.notification-fade-move {
    transition: all .5s;
}
.notification-fade-enter-from,
.notification-fade-leave-to {
    opacity: 0;
}
</style>