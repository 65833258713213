import ICustomerTable from "@/Interfaces/CustomersAndContracts/ICustomerTable";
import { extractNonArraysFromObj } from '@/services/helpers/ObjectHelper';
import DateIgnoreTime from "@/types/DateIgnoreTime";

export default class CustomerTable implements ICustomerTable {
    CUSTOMER_ID: string;
    DATE_CREATED: DateIgnoreTime;

    constructor(data: ICustomerTable) {
        Object.assign(this,extractNonArraysFromObj(data));
        this.CUSTOMER_ID = data.CUSTOMER_ID;
        this.DATE_CREATED = new DateIgnoreTime(data.DATE_CREATED);
    }
}
