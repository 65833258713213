import IContractValidationRaw from "@/Interfaces/Contract/IContractValidationRaw";
import DateTimeLocalStr from "@/types/DateTimeLocalStr";

export default class ContractValidation implements IContractValidationRaw {
    constructor(raw?: IContractValidationRaw) {
        Object.assign(this, raw);
        this.validationDateTime = new DateTimeLocalStr(raw?.validationDateTime ?? '');
        this.parsedTestData = raw?.testData ? JSON.parse(raw.testData) : undefined;
    }
    validationDateTime!: DateTimeLocalStr;
    validation!: boolean;
    manualOverride!: boolean;
    performedBy!: string;
    testData!: string;
    parsedTestData?: Record<string|'ValidationMessage',boolean|string|number|undefined>;
}