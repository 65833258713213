import log from "loglevel";
import useNotifier from "./Notifier";
import { AxiosError, isAxiosError } from "axios";
import { useAPILogger } from "@/main";
import DateTimeSv from "@/types/DateTimeSv";

export interface INotifyError {
    /** What caused this error */
    errorType?: string,
    /** Additional Msg */
    msg?: string,
    /** From which class and function */
    name?: string,
    /** Error obj */
    error: unknown,
}
export interface IError {
    Error: string;
}
export default function useNotifierWithErrFormatter() {
    const notifier = useNotifier();
    const _error = notifier.error;
    function isIError(data): data is IError {
        return !!data?.Error;
    }
    async function error(props: INotifyError) {
        log.error(props.error);
        
        const bServerSide = isAxiosError(props.error) ? 
            props.error.response?.status?.toString().startsWith('5') : false;
        const is400 = isAxiosError(props.error) ? props.error.response?.status === 400 : false;
        const errTitle = is400 ? 'Warning' : 'Error';
        const title = props.errorType ? `${new DateTimeSv()} - ${errTitle} (${props.errorType})` : undefined;
        const serverOrClient1 =  bServerSide ? 'server-side' : 'client';
        const serverOrClient2 = bServerSide ? '' : ' (an error with your request)';
        const errorBaseText = `\n\n${props.error}`;
        const data = (props.error as AxiosError).response?.data;
        const errorExtraText = is400 && isIError(data) ? data.Error : undefined;
        const errorText = errorExtraText ? `${errorBaseText}\n${errorExtraText}` : errorBaseText;
        const baseMsg = props.msg ? `\n${props.msg}` : '';
        const msgError = `Your request could not be fulfilled at this moment due to a ${serverOrClient1} error${serverOrClient2}, please try again later. If this error persists, please raise a ticket with the help desk.${errorText}${baseMsg}`;
        const msgWarn = `Your request could not be fulfilled at this moment due to a ${serverOrClient1} error${serverOrClient2}. Please try again with different parameters. If this error persists, please raise a ticket with help desk.${errorText}${baseMsg}`;
        useAPILogger().LogError(`${title} - ${is400? msgWarn : msgError}`, props.name);
        if (is400) return notifier.warn({ title, msg:msgWarn, dur: 0 });
        return _error({ title,msg: msgError });
    }
    return {success: notifier.success, warn: notifier.warn, notify: notifier.notify, error};
}