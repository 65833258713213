<template>
    <Popup
        :modelValue="!!selectedRow"
        @update:model-value="closeChild"
        title="Loan Re-Payment Confirmation"
        hasClose
    >
        <template #contents v-if="selectedRow">
            <LoanRepaymentErrorInfo
                :showError="showError"
                :closeError="closeError"
                :errorDescription="errorDescription"
                :error="infoError"
                :change="!!showError"
                :axiosError="true"
                :errorDisplayText="errorText"
            />
            <LoanRepaymentWarningInfo
                :showWarning="showWarning"
                :closeWarning="closeWarning"
                :warningDescription="warningDescription"
                :warning="infoWarning"
                :change="!!showWarning"
                :axiosError="true"
            />
            <div class="grid-item">
                The remaining balance on this VAT Loan is:
                <strong
                    >{{ CurrencySymbol[MarketCurrency[selectedRow.Market!]]
                    }}{{ MonetaryValueWithCommas(selectedRow.RemainingBalance) }}</strong
                >
            </div>
            <form class="top-content" @submit.prevent="ConfirmLoanPayment">
                <span style="padding-bottom: 0.4rem"
                    >Select a payment date and an amount:</span
                >
                <span
                    title="Select a date for confirmed ledger tranfers"
                    class="search-with-button"
                >
                    <div class="date-picker" style="width: 50%">
                        <DatePicker
                            v-model="selectedDate"
                            type="date"
                            :enable-time-picker="false"
                            :format="FormatDatePicker"
                            placeholder="Select Date..."
                            :text-input-options="textInputOptions"
                            text-input
                            :max-date="new Date()"
                            :teleport="true"
                            required
                        />
                    </div>

                    <CurrencyInput
                        v-model="paymentAmount"
                        :min="0.01"
                        :max="selectedRow.RemainingBalance"
                        :currency="MarketCurrency[selectedRow.Market!]"
                        style="border-radius: 0"
                        required
                    />

                    <button :disabled="working" class="right-button">Confirm</button>
                </span>
            </form>
        </template>
    </Popup>
</template>

<script setup lang="ts">
import { defineProps, defineEmits, ref, computed } from "vue";
import DatePicker from "@vuepic/vue-datepicker";
import "@vuepic/vue-datepicker/dist/main.css";
import Popup from "@/components/Popup.vue";
import CurrencyInput from "@/components/CurrencyInput.vue";
import useNotifierWithErrFormatter from "@/services/composables/NotifierWithErrFormatter";
import useSpinnerPopup from "@/services/composables/SpinnerPopup";
import { authService } from "@/auth/authSingleton";
import {
    CurrencySymbol,
    MarketCurrency,
    MonetaryValueWithCommas,
} from "@/services/helpers/Helper";
import { VatLoan } from "@/models/VatLoan";
import { DrawdownHelper } from "@/services/helpers/DrawdownHelper";
import IConfirmLoanPaymentRequest from "@/Interfaces/Ledgering/IConfirmLoanPaymentRequest";
import ErrorInfo from "@/components/ErrorInfo.vue";
import WarningInfo from "../WarningInfo.vue";
import { AxiosError } from "axios";

// ERRORS
const LoanRepaymentErrorInfo = ErrorInfo;
const showError = ref<boolean>(false);
const closeError = ref<boolean>(true);
const infoError = ref<AxiosError | undefined>(undefined);
const errorDescription = ref<string>("");
const errorText = ref<string>("");

// Warning
const LoanRepaymentWarningInfo = WarningInfo;
const showWarning = ref<boolean>(false);
const closeWarning = ref<boolean>(true);
const infoWarning = ref();
const warningDescription = ref<string>("");

//Services
const DrawdownServices: DrawdownHelper = new DrawdownHelper();
const urlParams = process.env.VUE_APP_VAT_LOAN_PAYMENT_URL_PARAMS!;

const props = defineProps<{
    modelValue?: VatLoan;
}>();
const selectedRow = computed({
    get() {
        return props.modelValue;
    },
    set(val) {
        emits("update:modelValue", val);
    },
});
const working = ref(false);
const selectedDate = ref<Date>();
const textInputOptions = { format: "yyyy/MM/dd" };
const paymentAmount = ref("");

const emits = defineEmits<{
    (e: "update:modelValue", val?: VatLoan);
    (e: "confirm");
}>();
function closeChild() {
    selectedDate.value = undefined;
    paymentAmount.value = "";
    selectedRow.value = undefined;
    showError.value = false;
    showWarning.value = false;
}
const FormatDatePicker = (date: Date) => {
    return date.toLocaleString("sv-SE").split(" ")[0].replaceAll("-", "/");
};
async function ConfirmLoanPayment() {
    if (working.value) return;
    working.value = true;
    const closeSpinner = useSpinnerPopup().show();
    try {
        if (!selectedRow.value) throw new ReferenceError("selectedRow cannot be null when submitting the form");
        const payload: IConfirmLoanPaymentRequest = {
            Market: selectedRow.value.Market!,
            DrawdownDate: selectedRow.value.DrawdownDate!.toLocaleString('sv-SE').split(" ")[0],
            VatLoanAmount: selectedRow.value.RemainingBalance!,
            VatLoanPeriod: selectedRow.value.VatLoanPeriod.toLocaleString('sv-SE').split(" ")[0],
            PaymentDate: selectedDate.value!.toLocaleString('sv-SE').split(" ")[0],
            PaymentAmount: `${paymentAmount.value}`,
            UserEmail: authService.getEmailAddress(),
        };
        await DrawdownServices.confirmPayment(urlParams, payload);
        useNotifierWithErrFormatter().success({
            msg: "Payment successfully registered.",
        });
        closeChild();
        emits("confirm");
    } catch (error) {
        useNotifierWithErrFormatter().error({
            name: `${__filename}/${ConfirmLoanPayment.name}`,
            errorType: "Confirming Loan payment",
            error,
        });
    } finally {
        closeSpinner();
        working.value = false;
    }
}
</script>

<style scoped>
.grid-item {
    padding: 10px;
}
</style>
