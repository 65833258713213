export enum ContractValidationType {
    HIRE_AGREEMENT = "Hire Agreement",
    CUSTOMER_INVOICE = "Customer Invoice",
    CAR_SALE_INVOICE = "Car Sale Invoice",
    UNDERWRITING = "Underwriting"
}
export const ContractValidationTypeKeys = [
    'HIRE_AGREEMENT',
    'CUSTOMER_INVOICE',
    'CAR_SALE_INVOICE',
    'UNDERWRITING'
] as (keyof typeof ContractValidationType)[];