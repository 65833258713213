import { RouteLocationRaw, RouteRecordRaw } from "vue-router";

export enum RoutePaths {
    Root = "/",
    Admin = '/admin',
    Analyst = '/analyst',
    Blank = "/Blank",
    Operator = '/operator',
    'Deposit Manager' = '/deposit-manager',
    "Page Not Found" = '/:pathMatch(.*)*',
    Login = "/login",
    Unauthorised = "/unauthorised",
    Home = "/home",
    Journaling = "/journaling",
    "Journaling Report" = "/journaling/reports/:id",
    "Journaling Report (PDF)" = "/journaling/reports/files/:id",
    "Alerts and Warnings" = "/alerts-and-warnings",
    "EoP Verifications" = "/eop-verifications",
    "EoP Verification Report" = "/eop-verifications/report/:id",
    "EoP Verification (PDF)" = "/eop-verifications/files/:id",
    "Invoices" = "/invoices",
    "Invoice (PDF)" = "/invoices/files/:id",
    "Data Processing" = "/data-processing",
    "Loan Management" = "/loan-management",
    "Fund Management" = "/fund-management",
    "Consumer Extract" = "/consumer-extract",
    "Reports" = "/reports",
    "Customer & Contract" = "/customer-and-contract",
    "Hire Agreements" = "/hire-agreements",
    "Hire Agreement (PDF)" = "/hire-agreement/files/:id/:documentType?",
    "Contract Validation Wrapper" = "/contract-validations/:type?",
    "Contract Validation" = "/contract-validations/:type?",
    'Contract Approval' = '/contract-approval',
    "Sage Backup" = "/sage-backup",
    "Fees Configuration" = "/fees-configuration",
    "VAT Returns" = "/vat-returns",
    "Transfer Payments" = "/transfer-payments/:transferStatus?",
    "Service Invoice Payments" = "/service-invoice-payments",
    "Recharge Invoice Payments" = "/recharge-invoice-payments",
    "Churn Payments" = "/churn-payments",
    "VAT Payments" = "/vat-payments",
    "Ledger Transactions" = "/ledger-transactions",
    "Car Purchases" = "/car-purchases/:market?",
    "Car Purchase" = "/car-purchases/:market/:id",
    "Funding Reports" = "/funding-reports",
    "Drawdown" = "/drawdown",
    "VAT Loans" = "/vat-loans",
    "Insurance Claims" = "/insurance-claims",
    "Portfolio Forecasts" = '/portfolio-forecasts',
    "Current Portfolio" = 'current',
    "Current Portfolio Forecast Details" = 'current/:id',
    "Estimated Portfolio" = 'estimated',
    "Estimated Portfolio Forecast Details" = 'estimated-details',
    "Configurations" = 'configs',
    "Confirm Interest Payments" = "/confirm-interest-payments"
}
export const nonSidebarViews = [
    "/login",
    RoutePaths["Journaling Report (PDF)"].replace("/:id", ""),
    RoutePaths["EoP Verification (PDF)"].replace("/:id", ""),
    RoutePaths["Invoice (PDF)"].replace("/:id", ""),
    RoutePaths["Hire Agreement (PDF)"].replace("/:id", "").replace("/:documentType?", "")
];
export const nonTopbarViews = [
    "/login",
    RoutePaths["Journaling Report (PDF)"].replace("/:id", ""),
    RoutePaths["EoP Verification (PDF)"].replace("/:id", ""),
    RoutePaths["Invoice (PDF)"].replace("/:id", ""),
    RoutePaths["Hire Agreement (PDF)"].replace("/:id", "").replace("/:documentType?", "")
];
export type RouteRecordCarsly = RouteRecordRaw & {
    path: RoutePaths,
    name?: (keyof typeof RoutePaths)
};
export type RouteLocationCarsly = RouteLocationRaw & {
    name: keyof typeof RoutePaths,
};