<template class="ServiceInvoicePayments">
    <div v-if="isLoading" class="content-container">
        <div class="loading">
            <span class="loader"></span>
        </div>
    </div>

    <div v-else class="content-container">
        <div class="top-content">
            <GettingServiceInvoiceErrorInfo
                :showError="!!infoError"
                errorDescription="(Getting Service Invoices)"
                :error="infoError"
                :change="!!infoError"
            />
            <div class="flex-row-centered">
                <MarketSelector />
                <PeriodSelector
                    v-model="period"
                    hasNullSelector
                    id="periodSelector"
                    showTitle
                    @update:model-value="updateSelection"
                    :filter="(p) => allPayments?.some((x) => p.within(x.periodMonth))"
                />
                <div class="center-buttons">
                    <button
                        :disabled="!hasCheckboxes"
                        @click="proceedToPayment"
                        class="incident-nav-button"
                    >
                        Confirm Payment
                    </button>
                </div>
            </div>
        </div>
        <div class="table-container">
            <TablePersistentChecking
                ref="table"
                :Rows="displayedPayments"
                :Cols="cols"
                :Labels="labels"
                :HasCheckBox="hasCheckboxes"
                v-model:RowsPerPage="rowCount"
            />
        </div>

        <ServiceInvoicePaymentPopUp
            v-model="selectedRows"
            @confirm="refreshAfterConfirm"
        />
    </div>
</template>

<script setup lang="ts">
import { ref, Ref, onMounted, computed, provide, inject } from "vue";
import { marketSelection } from "@/services/helpers/Helper";
import TablePersistentChecking, {
    ITableExpose,
} from "@/components/TablePersistentChecking.vue";
import PaymentItem from "@/Interfaces/Payments/ServiceInvoicePaymentItem";
import ErrorInfo from "@/components/ErrorInfo.vue";
import ServiceInvoicePaymentPopUp from "@/components/ServiceInvoicePaymentPopUp.vue";
import DatePeriod from "@/types/DatePeriod";
import { serviceInvoicePayments } from "@/services/network";
import PeriodSelector from "@/components/PeriodSelector.vue";
import MarketSelector from "@/components/MarketSelector.vue";
import useNotifier from "@/services/composables/Notifier";
import { globalReloadKey } from "@/types/ServiceKeys";

const cols: (keyof PaymentItem)[] = [
    "invoiceId",
    "balance",
    "amountTotal",
    "amountVAT",
    "issueDate",
    "periodMonth",
    "dueDate",
    "balanceDateUpdated",
];
const labels = [
    "Invoice Id",
    "Balance",
    "Amount Total",
    "Amount VAT",
    "Issue Date",
    "Period Month",
    "Due Date",
    "Balance Date Updated",
];

const page: Ref<number> = ref(1);
const rowCount: Ref<number> = ref(50);
provide("page", page);

// ERRORS
const GettingServiceInvoiceErrorInfo = ErrorInfo;
const infoError = ref();

// Table
const isLoading = ref(false);
const allPayments = ref<PaymentItem[]>([]);
const period = ref(new DatePeriod());

const displayedPayments = computed(() =>
    period.value.isValid()
        ? allPayments.value?.filter(
              (x) => x.periodMonth.valueOf() === period.value.valueOf()
          )
        : allPayments.value
);
const checkedRows = ref<PaymentItem[]>();
const hasCheckboxes = computed(() => period.value.isValid());
const selectedRows = ref<PaymentItem[]>();
const table = ref<ITableExpose<PaymentItem>>();

onMounted(async () => {
    marketSelection.value = "GB";
    getPayments();
});
async function getPayments() {
    //const errorType = 'Getting Recharge Invoices from API';
    try {
        isLoading.value = true;
        allPayments.value = (await serviceInvoicePayments.get()).data.map(
            (x) => new PaymentItem(x)
        );

        if (period.value.isValid()) {
            updateSelection();
        }
    } catch (error) {
        infoError.value = error;
    } finally {
        isLoading.value = false;
    }
}
const updateSelection = () => {
    checkedRows.value = undefined;
    selectedRows.value = undefined;
};
const proceedToPayment = () => {
    if (checked.value.length < 1) useNotifier().notify({ msg: "No item selected" });
    else {
        selectedRows.value = checked.value;
    }
};
const checked: Ref<PaymentItem[]> = computed(() => {
    return table.value?.GetChecked() ? table.value?.GetChecked() : [];
});
function refreshAfterConfirm() {
    period.value = new DatePeriod();
    getPayments();
    checkedRows.value = undefined;
}
</script>

<style scoped>
/* .table-container td ch */
.vtl-thead {
    padding: 0.5rem 0.5rem 0.5rem 0.5rem;
    text-align: left;
    background: var(--primary-color-dark);
    /* color: #fff; */
    color: red;
}
</style>
