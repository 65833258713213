import { authService } from "@/auth/authSingleton";
import IPaymentVATForm from "./IPaymentVATForm";
import DateIgnoreTime from "@/types/DateIgnoreTime";

export default interface IPaymentVATSubmitReq {
    username: string;
    market: string;
    period: DateIgnoreTime;
    VATReturnDate: DateIgnoreTime;
    totalAmount: string;
    corporateAmount: string;
}

export function fromFormToReq(input: IPaymentVATForm): IPaymentVATSubmitReq {
    return {
        username: authService.getEmailAddress(),
        market: input.market,
        period: input.period,
        VATReturnDate: new DateIgnoreTime(input.VATReturnDate),
        totalAmount: input.totalAmount,
        corporateAmount: input.corporateAmount
    };
}