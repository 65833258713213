import { computed } from 'vue';
import useVModelDefaultVar from './VModelDefaultVar';

export interface MultiStateButtonProps<T> {
    modelValue?: number;
    states: T[];
}
export default function useMultiStateButton<T>(props: MultiStateButtonProps<T>, emits: (e: 'update:modelValue', val: number)=>void) {
    const state = useVModelDefaultVar(props, emits, 'modelValue', 0);
    const display = computed(()=>props.states[state.value]);
    function toggleState() {
        state.value = ((state.value+1)>=props.states.length?0:(state.value+1));
    }
    return {
        state,
        display,
        toggleState
    }
}