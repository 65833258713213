<template>
    <Popup :model-value=!!selectedRow title="Edit Insurance Claim" hasClose @update:modelValue=closeChild>
        <template #contents>
            <div class="flex-col-centered">
               <br>
            </div>
            <div class="top-content" >
                <form @submit.prevent="EditInsuranceClaim">
                    <div class="grid-container">
                        <!--Market  input field -->
                        <label class="grid-item-left" for="market">Market</label>
                        <select id="market" class="dropdown invalid-input"  v-model="selectedRowCopy.market" disabled >
                            <option v-for="[market,display] in markets" :value="market" :key="market">
                                {{ display }}
                            </option>
                        </select>

                        <!--Contract ID  input field -->
                        <label class="grid-item-left" for="contractId">Contract ID</label>
                        <input class="form-input invalid-input" type=text maxlength="24" placeholder=""  id="contractId" readonly v-model="selectedRowCopy.contractId">

                        <!--Payment Date input field -->
                        <span class="grid-item-left">
                            <ToolTipLabel warning tip="After inserting this value claim becomes non editable" text="Payment Date" forId="datePicker"></ToolTipLabel>
                        </span>
                        <div class="date-picker" style="width: 100%" >
                            <DatePicker 
                            id="datePicker"
                            v-model="selectedRowCopy.paymentDate"
                            type="date"
                            :enable-time-picker="false"
                            :format="FormatDatePicker"
                            placeholder="Select Payment Date..."
                            :text-input-options="textInputOptions"
                            text-input
                            :max-date="new Date()"
                            :teleport="true"
                            class="invalid-input"
                            :disabled="selectedRowCopy.isCompleted == true"
                            @input="HandleFullAmountRequirementChange"
                            />
                        </div>
                        
                        <!--Case Reference input field -->
                        <label class="grid-item-left" for="caseReference">Case Reference ID</label>
                        <input class="form-input invalid-input" type=text maxlength=24 placeholder="" id="caseReference" v-model="selectedRowCopy.caseReferenceId" :disabled="selectedRowCopy.isCompleted == true">

                        <!--Full Amount input field -->
                        <span class="grid-item-left">
                            <ToolTipLabel information tip="Full insurance payment amount excluding excess" text="Full Amount" forId="fullAmount"></ToolTipLabel>
                        </span>
                        <CurrencyInput
                        v-model="selectedRowCopy.fullAmount"
                        :min="0"
                        :currency="selectedRowCopy.market ? MarketCurrency[selectedRowCopy.market] : undefined"
                        id="fullAmount"
                        class="invalid-input"
                        :required="fullAmmountRequiredFields"
                        :disabled="selectedRowCopy.isCompleted == true"
                        />

                        <!--excess Amount input field -->
                        <span class="grid-item-left">
                            <ToolTipLabel information tip="Insurance company excess amount" text="Excess Amount" forId="excess"></ToolTipLabel>
                        </span>
                        <CurrencyInput
                        v-model="selectedRowCopy.excessAmount"
                        :min="0.01"
                        :currency="selectedRowCopy.market ? MarketCurrency[selectedRowCopy.market] : undefined"
                        id="excess"
                        class="invalid-input"
                        :disabled="selectedRowCopy.isCompleted == true"
                        />

                        <!--Company Name input field -->
                        <span class="grid-item-left">
                            <ToolTipLabel information tip="Name of the insurance company handling the case" text="Company Name" forId="companyName"></ToolTipLabel>
                        </span>
                        <input class="form-input invalid-input" type=text maxlength=30 id="companyName" v-model="selectedRowCopy.companyName" :disabled="selectedRowCopy.isCompleted == true">

                        <!--Company VAT input field -->
                        <span class="grid-item-left">
                            <ToolTipLabel information tip="VAT ID of the insurance company handling the case" text="Company VAT ID" forId="companyVat"></ToolTipLabel>
                        </span>
                        <input class="form-input invalid-input"  type=text maxlength=30 id="companyVat" v-model="selectedRowCopy.companyVatId" :disabled="selectedRowCopy.isCompleted == true">

                        <!--Company Location input field -->
                        <span class="grid-item-left">
                            <ToolTipLabel information tip="Location the insurance company handling the case" text="Company Location" forId="companyLocation"></ToolTipLabel>
                        </span>
                        <input class="form-input invalid-input" type=text maxlength=30 id="companyLocation" v-model="selectedRowCopy.companyLocation" :disabled="selectedRowCopy.isCompleted == true">

                        <!--comments input field -->
                        <label class="grid-item-left" for="comments">Comments</label>
                        <textarea  class="form-input textarea-input invalid-input"  maxlength=4096 spellcheck="true" rows="4" id="comments" v-model="selectedRowCopy.comments"/>  
                    </div>
                    <div class="flex-col-centered">
                        <button class="submit-btn" type="submit">
                            CONFIRM CHANGES
                        </button>
                    </div>
                </form>
            </div>
        </template>
    </Popup>
</template>

<script setup lang="ts">
import { computed, ref, defineProps, watch } from "vue";
import DatePicker from "@vuepic/vue-datepicker";
import "@vuepic/vue-datepicker/dist/main.css";
import Popup from "./Popup.vue";
import useNotifierWithErrFormatter from "@/services/composables/NotifierWithErrFormatter";
import useSpinnerPopup from "@/services/composables/SpinnerPopup";
import ToolTipLabel from "@/components/ToolTipLabel.vue";
import CurrencyInput from "@/components/CurrencyInput.vue";
import { MarketCurrency } from "@/services/helpers/Helper";
import { insuranceClaims } from "@/services/network";
import IInsuranceForm from "@/Interfaces/InsuranceClaims/IInsuranceClaimsForm";
import {  MarketSelection } from '@/services/helpers/Helper';
import DateIgnoreTime from "@/types/DateIgnoreTime";
import { authService } from "@/auth/authSingleton";

const markets = Object.entries(MarketSelection);
const FormatDatePicker = (date: Date) => {
    return date.toLocaleString("sv-SE").split(" ")[0].replaceAll("-", "/");
};
const textInputOptions = { format: "yyyy/MM/dd" };
const props = defineProps<{
    modelValue?: IInsuranceForm;
}>();
const selectedRow = computed({
    get() {
        return props.modelValue
    },
    set(val) {
        emits('update:modelValue', val);
    }
})
const emits = defineEmits<{
    (e: 'update:modelValue', val?: IInsuranceForm);
    (e: 'confirm');
}>();
const working = ref(false);
const selectedRowCopy = ref<Partial<IInsuranceForm>>({});

watch(selectedRow, (val)=>{
    if (val) {
        selectedRowCopy.value = Object.assign({}, val);
    }
});
function closeChild(){
    selectedRow.value = undefined;
}
const fullAmmountRequiredFields = ref(false)
function HandleFullAmountRequirementChange(event: Event): void{
    const target = event.target as HTMLInputElement;
    if(target.value != ""){
        fullAmmountRequiredFields.value = true
    }else{
        fullAmmountRequiredFields.value = false
    }
}
async function EditInsuranceClaim() {
    if(working.value) return;    
    const closeSpinner = useSpinnerPopup().show();
    try{
        if(JSON.stringify(selectedRowCopy.value)===JSON.stringify(selectedRow.value)){
            useNotifierWithErrFormatter().warn({msg: "No Change Applied"});
        }
        if(selectedRowCopy.value.paymentDate?.isValid()  && (selectedRowCopy.value.fullAmount == null || selectedRowCopy.value.fullAmount.toString() === "")){
            useNotifierWithErrFormatter().warn({msg: "When specifying 'Payment Date' remeber to specify 'Full Amount'"});
        }
        else{
            working.value=true
            let postRequest: IInsuranceForm = {
                market: selectedRowCopy.value?.market ?? 'GB',
                contractId: selectedRowCopy.value?.contractId || '',
                paymentDate: new DateIgnoreTime(selectedRowCopy.value?.paymentDate),
                caseReferenceId: selectedRowCopy.value?.caseReferenceId, 
                fullAmount: selectedRowCopy.value?.fullAmount, 
                excessAmount: selectedRowCopy.value?.excessAmount,
                companyName: selectedRowCopy.value?.companyName, 
                companyVatId: selectedRowCopy.value?.companyVatId, 
                companyLocation: selectedRowCopy.value?.companyLocation,
                comments: selectedRowCopy.value?.comments,
                isCompleted: selectedRowCopy.value?.isCompleted,
                userEmail: authService.getEmailAddress(), 
                ticketId: selectedRowCopy.value?.ticketId
            };        
            await insuranceClaims.put(postRequest)
            useNotifierWithErrFormatter().success({ msg: "Insurance Claim successfully edited." });
            emits("confirm");
            closeChild()   
        }
        
    }catch(error){
        useNotifierWithErrFormatter().error({error});
    }
    closeSpinner()
    working.value=false
}
</script>

<style scoped lang="scss">
.grid-item-left {
    margin: auto 3px auto auto;
}
.grid-container {
    display: grid;
    grid-template-columns: auto auto;
    gap: 0.5rem;
}
.textarea-input{
    resize: none;
    border-radius: 0.5rem;
    border: var(--border-width) solid var(--primary-color-dark);
    width: 30em;
    height: 10em
}
.submit-btn {
    margin-top: 1rem;
    cursor: pointer;
    border-width: 0px;
    border-radius: var(--buttons-border-radius);
    background-color: var(--primary-color-lighter);
    padding: 10px;
    color: blue;
    font-size: larger;
    font-weight: bold;
    &:hover {
        color: white;
        background-color: var(--primary-color);
    }
}

</style>