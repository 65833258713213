import { IFileDetails, ILastReceivedFilesDetails } from "@/Interfaces/DataProcessing/ILastReceivedFilesDetails";
import DateTimeSv from "@/types/DateTimeSv";

export class LastReceivedFilesDetails implements ILastReceivedFilesDetails {
    Processed: FileDetails[];
    Failed: FileDetails[];
    Waiting: FileDetails[];

    constructor(lastReceivedFilesDetails: ILastReceivedFilesDetails) {
        this.Processed = lastReceivedFilesDetails.Processed?.map(x => new FileDetails(x)) ?? [];
        this.Failed = lastReceivedFilesDetails.Failed?.map(x => new FileDetails(x)) ?? [];
        this.Waiting = lastReceivedFilesDetails.Waiting?.map(x => new FileDetails(x)) ?? [];
    }
}

export class FileDetails implements IFileDetails {
    Name: string;
    CreatedOn: DateTimeSv;

    constructor(fileDetails: IFileDetails) {
        this.Name = fileDetails.Name;
        this.CreatedOn = new DateTimeSv(fileDetails.CreatedOn ?? '');
    }
}
