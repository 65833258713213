import { IJournalVATMetadata } from './../../../Interfaces/VATReturns/IJournalVAT';
import { AxiosResponse, AxiosError } from "axios";
import { financialSystemNetworkService } from "@/services/helpers/Helper";
import {
	infoError,
	isLoading,
	showSearchForVATReturnsFromJournaledReferencesError,
	showDownloadVATReturnsError,
	showDownloadVATReturnsErrorChanger,
	tabVATReturns,
	validJournalReferences,
	journalReference,
	currentTab,
	tabs,
	Box1VATs,
	Box3VATs,
	Box4VATs,
	Box5VATs,
	Box6VATs,
	Box7VATs,
	box1AmountVATTotal,
	box3AmountVATTotal,
	box4AmountVATTotal,
	box5AmountVATTotal,
	box6AmountExVATTotal,
	box7AmountExVATTotal,
	displayAmountVATTotal,
	journalTypeSelection,
	displayedVATReturns,
	dateRange,
	journalTypeSelectionDate,
} from "@/services/helpers/VATReturns/VATReturnsVariables";
import log from "loglevel";
import { Log2API } from "@/services/Logger";
import IJournalVATResponse from "@/Interfaces/VATReturns/IJournalVATResponse";
import { IGetVATReturnsFromJournalReferencesBody } from "@/Interfaces/VATReturns/IGetVATReturnsFromJournalReferencesBody";
import JournalVAT from "@/models/VATReturns/JournalVat";
import { DisplayVATReturns, ResetError } from "@/services/helpers/VATReturns/VATReturnsFunctions";
import { IGetVATReturnsByDateBody } from "@/Interfaces/VATReturns/IGetVATReturnsByDateBody";
import { IAPILogger } from "@/Interfaces/IAPILogger";
import { inject } from 'vue';


class VATReturnsServices {
	Logger = new Log2API();
	fileName = "VATReturnsHelper.ts";

	public async GetVATReturnsFromJournalReferences(
		journalReferencesString: string
	): Promise<IJournalVATResponse> {
		const name = `GetVATReturnsFromJournalReferences`;
		log.trace(`${name}()\njournalReference: ${journalReferencesString}`);

		ResetError();

		const journalReferences: string[] = journalReferencesString.split(",")
			.map((ref: string) => ref.trim());
		log.trace(`${name}()\njournalReferences: ${journalReferences}`);

		const body: IGetVATReturnsFromJournalReferencesBody = {
			JournalReferences: journalReferences
		};

		const urlParams: string = process.env.VUE_APP_JOURNALED_VAT_RETURNS_URL_PARAMS!;
		var Message = `Getting VAT Returns from journal references (${urlParams})`;
		const Class = this.fileName + "/" + name;
		const data: IJournalVATResponse = await new Promise((resolve, reject) => {
			financialSystemNetworkService
				.post(body, urlParams, undefined, true)
				.then((res: AxiosResponse<IJournalVATResponse>) => {
					log.trace(`${name}()\nres.data: ${JSON.stringify(res.data)}`);

					journalReference.value = "";

					this.Logger.LogInformation(Message, Class);
					resolve(res.data)
				})
				.catch((error: any) => {
					isLoading.value = false;

					if (error instanceof AxiosError) {
						showSearchForVATReturnsFromJournaledReferencesError.value = true;
						infoError.value = error;
					}

					Message = `(Error) ${Message}: ${error instanceof AxiosError ? error : error.message}`;
					log.error(Message);
					this.Logger.LogError(Message, Class);
				});
		});

		return data;
	}

	public async GetVATReturnsByDate(dateFrom: string, dateTo: string): Promise<IJournalVATResponse> {
		// Logging
		const name = "GetVATReturnsByDate";
		const prefix = `${name}()\r\n`;
		log.debug(prefix + "dateRange", dateRange.value, "\njournalingType", journalTypeSelectionDate.value);
		const Class = this.fileName + "/" + name;
		var Message = `Searching for Invoices by Date for Journal Type ${journalTypeSelectionDate.value} and Date Range ${dateRange.value}.`;

		ResetError();

		const body: IGetVATReturnsByDateBody = {
			JournalType: journalTypeSelectionDate.value,
			DateFrom: dateFrom,
			DateTo: dateTo
		};
		log.debug(prefix + "body:", body);

		const urlParams: string = process.env.VUE_APP_JOURNALED_VAT_RETURNS_BY_DATE_URL_PARAMS!;

		const data: IJournalVATResponse = await new Promise((resolve, reject) => {
			financialSystemNetworkService
				.post(body, urlParams)
				.then((res: AxiosResponse<IJournalVATResponse>) => {
					resolve(res.data)

					this.Logger.LogInformation(Message, Class);
				})
				.catch((error: any) => {
					isLoading.value = false;

					if (error instanceof AxiosError) {
						showSearchForVATReturnsFromJournaledReferencesError.value = true;
						infoError.value = error;
					}

					Message = `(Error) ${Message}: ${error instanceof AxiosError ? error : error.message}`;
					log.error(Message);
					this.Logger.LogError(Message, Class);
				});
		});

		return data;
	}

	public async PerformGetVATReturns(urlParams: string): Promise<AxiosResponse> {
		log.trace("GetVATReturns()\nurlParams", urlParams);

		return new Promise((resolve, reject) => {
			financialSystemNetworkService
				.get(urlParams)
				.then((res: AxiosResponse<IJournalVATResponse>) => {
					resolve(res)
				})
				.catch((error: any) => {
					reject(error);
				});
		});
	}

	public DownloadVATReturnsData(downloadAllData = false) {
		// Logging
		const name = "DownloadVATReturnsData";
		const prefix = `${name}()\r\n`
		log.trace(prefix + "downloadAllData:", downloadAllData);

		var Message = (downloadAllData || currentTab.value == tabs.Summary)
			? `Downloading all VAT Returns data (Journal References: ${validJournalReferences.value.join(' ')})`
			: `Downloading VAT Returns data for Box ${currentTab.value} (Journal References: ${validJournalReferences.value.join(' ')})`;
		const Class = this.fileName + "/" + name;

		if ((
			downloadAllData && (
				!Box1VATs.value.length
				&& !Box3VATs.value.length
				&& !Box4VATs.value.length
				&& !Box5VATs.value.length
				&& !Box6VATs.value.length
				&& !Box7VATs.value.length
			)
		) || !downloadAllData && !tabVATReturns.value?.length
		) {
			alert("Attempted to download VAT Returns data with no data to download.");
		} else {
			if (!downloadAllData) {
				const eg: JournalVAT = (tabVATReturns.value?.at(0) as JournalVAT);

				const titleKeys: string[] = Object.keys(eg.GetCSVDownloadObject());

				const refinedData: any[] = [];
				refinedData.push(titleKeys);

				let csvContent = "";

				var useAmountVAT: boolean;
				switch (currentTab.value) {
					case tabs.Box6:
					case tabs.Box7:
						useAmountVAT = false;
						break;
					default:
						useAmountVAT = true;
						break;
				}
				tabVATReturns.value?.forEach((item: JournalVAT) => {
					refinedData.push(Object.values(item.GetCSVDownloadObject(currentTab.value, useAmountVAT)));
				});

				refinedData.forEach(row => {
					csvContent += row.join(",") + "\n";
				})

				log.trace(prefix + "refinedData:", refinedData.length, refinedData.slice(0, 10));
				// Blank line
				titleKeys.forEach(_ => {
					csvContent += ",";
				});
				switch (currentTab.value) {
					case tabs.Box6:
					case tabs.Box7:
						csvContent += `\nBox ${currentTab.value} Amount Ex VAT Total: ${(Math.round(displayAmountVATTotal.value * 100) / 100).toFixed(2)}\n`;
						break;
					default:
						csvContent += `\nBox ${currentTab.value} Amount VAT Total: ${(Math.round(displayAmountVATTotal.value * 100) / 100).toFixed(2)}\n`;
						break;
				}

				// Blank line
				titleKeys.forEach(_ => {
					csvContent += ",";
				});
				var displayedReferences: string[] = journalTypeSelection.value == ""
					? tabVATReturns.value?.map((VATReturn: JournalVAT) =>
						VATReturn.Reference
					) || []
					: tabVATReturns.value?.filter((VATReturn: JournalVAT) =>
						VATReturn.JournalingType == journalTypeSelection.value
					).map((VATReturn: JournalVAT) =>
						VATReturn.Reference
					) || [];
				displayedReferences = [...new Set(displayedReferences)];
				log.trace(prefix + "displayedReferences:", displayedReferences);
				csvContent += "\n" + "Valid Journal References used in search:," + displayedReferences.join('; ').replaceAll(',', ';');

				try {
					// Create download blob
					const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8,' })
					const objUrl: string = URL.createObjectURL(blob)

					// Download file
					const link = document.createElement('a')
					link.href = objUrl;
					link.download = downloadAllData ? 'vat_returns.csv' : `vat_returns_box_${currentTab.value}.csv`;
					link.download = journalTypeSelection.value == "" ? link.download : link.download.replace(".csv", `_${journalTypeSelection.value}.csv`);
					link.textContent = "Click to Download";

					// Trigger a click on the anchor element
					link.click();

					// Remove the anchor element
					link.remove();

					this.Logger.LogInformation(Message, Class);
				} catch (error: any) {
					Message = `(Error) ${Message}: '${error}'`;
					log.error(Message);

					infoError.value = new AxiosError(error);

					showDownloadVATReturnsError.value = true;
					showDownloadVATReturnsErrorChanger.value = !showDownloadVATReturnsErrorChanger.value;
					this.Logger.LogError(Message, Class);
				}
			} else {
				const eg: JournalVAT = (Box1VATs.value?.at(0) as JournalVAT);

				const titleKeys: string[] = Object.keys(eg.GetCSVDownloadObject());

				let csvContent = "";

				// Add column headers
				var refinedData: any[] = [];
				refinedData.push(titleKeys);
				refinedData.forEach(row => {
					csvContent += row.join(",") + "\n";
				})


				// Box 1
				var refinedData: any[] = [];
				titleKeys.forEach(_ => {
					csvContent += ",";
				}); // Blank line
				csvContent += "\n" + "Box 1:" + "\n";
				Box1VATs.value?.forEach((item: JournalVAT) => {
					refinedData.push(Object.values(item.GetCSVDownloadObject(tabs.Box1, true)));
				});
				refinedData.forEach(row => {
					csvContent += row.join(",") + "\n";
				})
				// Blank line
				titleKeys.forEach(_ => {
					csvContent += ",";
				});
				csvContent += "\n" + "Box 1 Amount VAT Total:," + box1AmountVATTotal.value + "\n";


				// Box 3
				var refinedData: any[] = [];
				titleKeys.forEach(_ => {
					csvContent += ",";
				}); // Blank line
				csvContent += "\n" + "Box 3:" + "\n";
				Box3VATs.value?.forEach((item: JournalVAT) => {
					refinedData.push(Object.values(item.GetCSVDownloadObject(tabs.Box3, true)));
				});
				refinedData.forEach(row => {
					csvContent += row.join(",") + "\n";
				})
				// Blank line
				titleKeys.forEach(_ => {
					csvContent += ",";
				});
				csvContent += "\n" + "Box 3 Amount VAT Total:," + box3AmountVATTotal.value + "\n";


				// Box 4
				var refinedData: any[] = [];
				titleKeys.forEach(_ => {
					csvContent += ",";
				}); // Blank line
				csvContent += "\n" + "Box 4:" + "\n";
				Box4VATs.value?.forEach((item: JournalVAT) => {
					refinedData.push(Object.values(item.GetCSVDownloadObject(tabs.Box4, true)));
				});
				refinedData.forEach(row => {
					csvContent += row.join(",") + "\n";
				})
				// Blank line
				titleKeys.forEach(_ => {
					csvContent += ",";
				});
				csvContent += "\n" + "Box 4 Amount VAT Total:," + box4AmountVATTotal.value + "\n";


				// Box 5
				var refinedData: any[] = [];
				titleKeys.forEach(_ => {
					csvContent += ",";
				}); // Blank line
				csvContent += "\n" + "Box 5:" + "\n";
				Box5VATs.value?.forEach((item: JournalVAT) => {
					refinedData.push(Object.values(item.GetCSVDownloadObject(tabs.Box5, true)));
				});
				refinedData.forEach(row => {
					csvContent += row.join(",") + "\n";
				})
				// Blank line
				titleKeys.forEach(_ => {
					csvContent += ",";
				});
				csvContent += "\n" + "Box 5 Amount VAT Total:," + box5AmountVATTotal.value + "\n";


				// Box 6
				var refinedData: any[] = [];
				titleKeys.forEach(_ => {
					csvContent += ",";
				}); // Blank line
				csvContent += "\n" + "Box 6:" + "\n";
				Box6VATs.value?.forEach((item: JournalVAT) => {
					refinedData.push(Object.values(item.GetCSVDownloadObject(tabs.Box6, false)));
				});
				refinedData.forEach(row => {
					csvContent += row.join(",") + "\n";
				})
				// Blank line
				titleKeys.forEach(_ => {
					csvContent += ",";
				});
				csvContent += "\n" + "Box 6 Amount Ex. VAT Total:," + box6AmountExVATTotal.value + "\n";


				// Box 7
				var refinedData: any[] = [];
				titleKeys.forEach(_ => {
					csvContent += ",";
				}); // Blank line
				csvContent += "\n" + "Box 7:" + "\n";
				Box7VATs.value?.forEach((item: JournalVAT) => {
					refinedData.push(Object.values(item.GetCSVDownloadObject(tabs.Box7, false)));
				});
				refinedData.forEach(row => {
					csvContent += row.join(",") + "\n";
				})
				// Blank line
				titleKeys.forEach(_ => {
					csvContent += ",";
				});
				csvContent += "\n" + "Box 7 Amount Ex. VAT Total:," + box7AmountExVATTotal.value + "\n";


				log.debug(prefix + refinedData.length, refinedData.slice(0, 10));
				// Blank line
				titleKeys.forEach(_ => {
					csvContent += ",";
				});
				csvContent += "\nValid Journal References used in search:," + validJournalReferences.value.join(' ').replaceAll(',', ';');

				try {
					// Create download blob
					const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8,' })
					const objUrl: string = URL.createObjectURL(blob)

					// Download file
					const link = document.createElement('a')
					link.href = objUrl;
					link.download = downloadAllData ? 'vat_returns.csv' : `vat_returns_box_${currentTab.value}.csv`;
					link.textContent = "Click to Download";

					// Trigger a click on the anchor element
					link.click();

					// Remove the anchor element
					link.remove();

					this.Logger.LogInformation(Message, Class);
				} catch (error: any) {
					Message = `(Error) ${Message}: '${error}'`;
					log.error(Message);

					infoError.value = new AxiosError(error);

					showDownloadVATReturnsError.value = true;
					showDownloadVATReturnsErrorChanger.value = !showDownloadVATReturnsErrorChanger.value;
					this.Logger.LogError(Message, Class);
				}
			}
		}
	}

	// public async SearchInvoiceByDate() {
	// 	// Logging
	// 	const name = "SearchInvoiceByDate";
	// 	const Class = this.fileName + "/" + name;
	// 	var Message = `Searching for Invoices by Date for Journal Type ${journalTypeSelectionDate.value} and Date Range ${dateRange.value}.`;
	// 	log.debug(`${name}()\n${Message}`);

	// 	isLoading.value = true;
	// 	await JVATServices.GetVATReturnsByDate(dateRange.value!, journalTypeSelectionDate.value)
	// 		.then((response: IJournalVATResponse) => {
	// 			log.debug("GetInvoiceByDate() Response:", response);
	// 			DisplayVATReturns(response);
	// 			Logger.LogInformation(Message, Class);
	// 		})
	// 		.catch((error: any) => {
	// 			if (error instanceof AxiosError && error.response?.status == 400) {
	// 				DisplayVATReturnsNotFound(error as INotFoundError);
	// 			} else {
	// 				if (error instanceof AxiosError) {
	// 					isLoading.value = false;
	// 					showSearchForVATReturnsFromJournaledReferencesError.value = true;
	// 					showSearchForVATReturnsFromJournaledReferencesErrorChanger.value = !showSearchForVATReturnsFromJournaledReferencesErrorChanger.value;
	// 					infoError.value = error;
	// 				}
	// 				Message = `(Error) ${Message}: ${error instanceof AxiosError ? error : error.message}`;
	// 				log.error(Message);
	// 				Logger.LogError(Message, Class);
	// 			}
	// 		})
	// }
}

export default VATReturnsServices
