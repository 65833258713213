import { createApp } from 'vue';
import SuccessCard from '@/components/SuccessCard.vue';

export interface INotify {
    msg?: string;
    title?: string;
    dur?: number;
}
export default class SuccessCardPopup {
    private static popup: InstanceType<typeof SuccessCard> | undefined = undefined;
    static show(props: INotify) {
        const id = 'successCard';
        let mountingDiv = document.getElementById(id);
        if (!mountingDiv) {
            mountingDiv = document.createElement('div');
            mountingDiv.id = id;
            document.body.appendChild(mountingDiv);
        }
        if (!SuccessCardPopup.popup) {
            const app = createApp(SuccessCard);
            SuccessCardPopup.popup = app.mount(mountingDiv) as InstanceType<typeof SuccessCard>;
        }
        SuccessCardPopup.popup?.show({
            ...props,
        });
    }
}