export class MantisIssue implements IMantisIssue {
    id!: number;
    summary!: string;
    description!: string;
    project!: MantisGeneric;
    category!: MantisGeneric;
    reporter?: MantisUser;
    handler?: MantisUser;
    status!: MantisGenericWithLabelAndColor;
    resolution!: MantisGenericWithLabel;
    view_state!: MantisGenericWithLabel;
    priority!: MantisGenericWithLabel;
    severity!: MantisGenericWithLabel;
    reproducibility!: MantisGenericWithLabel;
    sticky!: boolean;
    created_at!: string | Date;
    updated_at!: string | Date;
    notes?: MantisNote[];
    custom_fields!: CustomField[];
    tags?: MantisGeneric[];
    history?: HistoryItem[];
    module?: string;
    correlation_id?: string;
    additional_information?: string;
    files?: IFiles[];

    constructor(mantisIssue: IMantisIssue) {
        Object.assign(this, mantisIssue);
        this.project = new MantisGeneric(mantisIssue.project);
        this.category = new MantisGeneric(mantisIssue.category);
        if (mantisIssue.reporter) { this.reporter = new MantisUser(mantisIssue.reporter) };
        if (mantisIssue.handler) { this.handler = new MantisUser(mantisIssue.handler) };
        this.status = new MantisGenericWithLabelAndColor(mantisIssue.status);
        this.resolution = new MantisGenericWithLabel(mantisIssue.resolution);
        this.view_state = new MantisGenericWithLabel(mantisIssue.view_state);
        this.priority = new MantisGenericWithLabel(mantisIssue.priority);
        this.severity = new MantisGenericWithLabel(mantisIssue.severity);
        this.reproducibility = new MantisGenericWithLabel(mantisIssue.reproducibility);
        if (mantisIssue.notes) { this.notes = mantisIssue.notes.map((note: IMantisNote) => new MantisNote(note)) };
        this.custom_fields = mantisIssue.custom_fields.map((customField: ICustomField) => new CustomField(customField));
        if (mantisIssue.tags) { this.tags = mantisIssue.tags.map((tag: IMantisGeneric) => new MantisGeneric(tag)) };
        if (mantisIssue.history) { this.history = mantisIssue.history.map((history: IHistoryItem) => new HistoryItem(history)) };
        if (mantisIssue.files) { this.files = mantisIssue.files.map((file: IFiles) => new Files(file)) };
    }

    GetCategory(): string {
        return this.category.GetName()
    }
    GetPriority(): string {
        return this.priority.GetName()
    }
    GetSeverity(): string {
        return this.severity.GetName()
    }
    GetStatus(): string {
        return this.status.GetName()
    }
    GetModule(): string {
        return this.custom_fields!.find((customField: CustomField) => customField.field.name === 'Module')?.value?? ""
    }
    GetCorrelationId(): string {
        return this.custom_fields!.find((customField: CustomField) => customField.field.name === 'Correlation Id')?.value?? ""
    }
    GetAssignedTo(): string {
        return this.handler?.real_name?? ""
    }
    GetCreatedAt(): string {
        return this.created_at?.toLocaleString().slice(0, -6).replaceAll("-", "/").replace("T", " ")?? ""
    }
    GetVolvoTicketID(): string {
        return this.custom_fields
            .find((customField: CustomField) => {
                return customField["field"]["name"] == "Volvo Ticket ID"
            })?.value ?? ""
    }
}
export interface IMantisIssue {
    id: number,
    summary: string,
    description: string,
    project: IMantisGeneric,
    category: IMantisGeneric,
    reporter?: IMantisUser,
    handler?: IMantisUser,
    status: IMantisGenericWithLabelAndColor,
    resolution: IMantisGenericWithLabel,
    view_state: IMantisGenericWithLabel,
    priority: IMantisGenericWithLabel,
    severity: IMantisGenericWithLabel,
    reproducibility: IMantisGenericWithLabel,
    sticky: boolean,
    created_at: string | Date,
    updated_at: string | Date,
    notes?: IMantisNote[],
    custom_fields: ICustomField[],
    tags?: IMantisGeneric[],
    history?: IHistoryItem[],
    module?: string,
    correlation_id?: string,
    additional_information?: any,
    files?: any

    GetCreatedAt(): string
}

export class MantisGeneric implements IMantisGeneric {
    id?: number;
    name!: string;
    
    constructor(mantisGeneric: IMantisGeneric) {
        Object.assign(this, mantisGeneric)
    }

    public GetName(): string {
        return this.name?? "";
    }
}
interface IMantisGeneric {
    id?: number,
    name: string,
}

export class MantisGenericWithEmail extends MantisGeneric implements IMantisGeneric {
    email?: string;

    constructor(mantisGenericWithEmail: IMantisGenericWithEmail) {
        super(mantisGenericWithEmail)
        this.email = mantisGenericWithEmail.email;
    }
}
interface IMantisGenericWithEmail extends IMantisGeneric {
    email?: string,
}

export class MantisGenericWithLabel extends MantisGeneric implements IMantisGenericWithLabel {
    label?: string;

    constructor(mantisGenericWithLabel: IMantisGenericWithLabel) {
        super(mantisGenericWithLabel);
        this.label = mantisGenericWithLabel.label;
    }
}
interface IMantisGenericWithLabel extends IMantisGeneric {
    label?: string,
}

export class MantisGenericWithLabelAndColor extends MantisGenericWithLabel implements IMantisGenericWithLabelAndColor {
    color?: string;

    constructor(mantisGenericWithLabelAndColor: IMantisGenericWithLabelAndColor) {
        super(mantisGenericWithLabelAndColor);
        this.color = mantisGenericWithLabelAndColor.color;
    }
}
interface IMantisGenericWithLabelAndColor extends IMantisGenericWithLabel {
    color?: string,
}

export class MantisUser extends MantisGenericWithEmail implements IMantisUser {
    real_name?: string;

    constructor(mantisUser: IMantisUser) {
        super(mantisUser);
        this.real_name = mantisUser.real_name;
    }
}
interface IMantisUser extends IMantisGenericWithEmail {
    real_name?: string
}

export class MantisNote implements IMantisNote {
    id?: number;
    reporter?: MantisUser;
    text!: string;
    view_state?: MantisGenericWithLabel;
    attachments?: any[];
    type!: string;
    created_at!: string;
    updated_at!: string;

    constructor(mantisNote: IMantisNote) {
        this.id = mantisNote.id;
        this.reporter = new MantisUser(mantisNote.reporter!);
        this.text = mantisNote.text;
        this.view_state = new MantisGenericWithLabel(mantisNote.view_state!);
        this.attachments = mantisNote.attachments;
        this.type = mantisNote.type;
        this.created_at = mantisNote.created_at;
        this.updated_at = mantisNote.updated_at;
    }
}
interface IMantisNote {
    id?: number,
    reporter?: IMantisUser,
    text: string,
    view_state?: IMantisGenericWithLabel,
    attachments?: any[],
    type: string,
    created_at: string,
    updated_at: string,
}

export class CustomField implements ICustomField {
    field!: MantisGeneric;
    value!: string;

    constructor(customField: ICustomField) {
        this.field = new MantisGeneric(customField.field);
        this.value = customField.value;
    }
}
interface ICustomField {
    field: IMantisGeneric,
    value: string,
}

export class HistoryItem implements IHistoryItem {
    created_at?: string;
    user?: MantisUser;
    field?: { name: string, label: string };
    type?: MantisGeneric;
    old_value?: any;
    new_value?: any;
    message?: string;
    change?: string;

    constructor(historyItem: IHistoryItem) {
        this.created_at = historyItem.created_at;
        this.user = new MantisUser(historyItem.user!);
        this.field = historyItem.field;
        this.type = new MantisGeneric(historyItem.type!);
        this.old_value = historyItem.old_value;
        this.new_value = historyItem.new_value;
        this.message = historyItem.message;
        this.change = historyItem.change;
    }
}
interface IHistoryItem {
    created_at?: string,
    user?: IMantisUser,
    field?: { name: string, label: string },
    type?: IMantisGeneric,
    old_value?: any,
    new_value?: any,
    message?: string,
    change?: string,
}

export class Files implements IFiles {
    name!: string;
    content!: string;
    
    constructor(files: IFiles) {
        Object.assign(this, files)
    }
}
interface IFiles {
    name: string
    content: string
}
