import ICarSaleInvoiceTable from '@/Interfaces/CustomersAndContracts/ICarSaleInvoiceTable';
import { extractNonArraysFromObj } from '@/services/helpers/ObjectHelper';
import DateIgnoreTime from "@/types/DateIgnoreTime";

export default class CarSaleInvoiceTable implements ICarSaleInvoiceTable {
    INVOICE_ID: string;
    ISSUE_DATE: DateIgnoreTime;
    BALANCE_DATE_UPDATED: DateIgnoreTime;

    constructor(data: ICarSaleInvoiceTable) {
        Object.assign(this,extractNonArraysFromObj(data));
        this.INVOICE_ID = data.INVOICE_ID;
        this.ISSUE_DATE = new DateIgnoreTime(data.ISSUE_DATE);
        this.BALANCE_DATE_UPDATED = new DateIgnoreTime(data.BALANCE_DATE_UPDATED);
    }
}
