<template>
    <div class="FullPagePDF">
        <div v-if="isLoading" class="content-container">
            <div class="loading">
                <span class="loader"></span>
            </div>
        </div>
        <div v-else class="content-container">
            <div class="top-content">
                <ShowPdfErrorInfo
                    :showError="showPdfError"
                    :closeError="true"
                    errorDescription="(Downloading PDF)"
                    :error="infoError"
                    :change="showPdfErrorChanger"
                />
                <div v-if="showBadRequest" class="desc-error" style="height: calc(100vh - (2 * var(--default-body-margin)));">
                    <div class="desc">
                        <div class="desc-header">NOTE:</div>
                        <div>
                            File not found in our database. Please try again later or search for a different file. 
                        </div>
                    </div>
                </div>

                <div v-if="showDowloadButton" class="center-dropdown-table-container">
                    <div class="center-dropdown-table">
                        <tbody>
                            <tr>
                                <td>
                                    {{  fileID }} <span v-if="displayDocumentType"> - {{ documentType }}</span>
                                </td>
                                <td>
                                    <div>
                                        <button @click="DownloadPdf()" :title="`Download ${fileID}`" class="table-button">
                                            Download File
                                        </button>
                                    </div>
                                </td>
                                <td></td>
                            </tr>
                        </tbody>
                    </div>
                </div>
            </div>
            <div v-if="pdfUrl" class="table-container">
                <div class="pdf-viewer">
                    <div class="embedded-pdf">
                        <embed
                            :src="pdfUrl"
                            type="application/pdf"
                        />
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup lang="ts">
import { ref, Ref, onMounted, provide, inject, computed } from "vue";
import { useRoute } from "vue-router";
// Services
import { IAPILogger } from "@/interfaces/IAPILogger";
import { GetPdfUrl } from "@/services/helpers/Helper";
// Misc
import log from "loglevel";
import ErrorInfo from "@/components/ErrorInfo.vue";
import { AxiosError } from "axios";
import IDownloadJournaledInvoiceBody from "@/interfaces/IDownloadJournaledInvoiceBody";
import { JournaledInvoicesServices } from "@/services/helpers/JournaledInvoicesHelper";

const page: Ref<number> = ref(1);
provide("page", page);

log.debug("Journaling Report PDF");
const fileName = "Journaling.vue";
const Logger: IAPILogger = inject("Logger")!;

const JIServices: JournaledInvoicesServices = new JournaledInvoicesServices();

////////////////////////////////////////////////////////////////////////////
// LOGIC
////////////////////////////////////////////////////////////////////////////
onMounted(async () => {
    log.trace("onMounted() FullPagePDF");
    document.title = `${fileID} - Optio`

    const name = onMounted.name;
    const Class = fileName + "/" + name;

    if (method.toUpperCase() === "GET") {
        const urlParams = endpointURL + "/" + fileID + "?type=" + fileType;
        var Message = `Getting PDF file using 'GET' method: ${urlParams}`;
        log.debug(`name()\n${Message}`);

        isLoading.value = true;
        await GetPdfUrl(urlParams, fileID)
            .then((url: string) => {
                pdfUrl.value = url;

                console.log("url: ", url);

                let a: HTMLAnchorElement = document.createElement("a");
                a.href = url;
                a.download = fileID;
                a.textContent = `View pdf ${fileType}_${fileID}`;
                document.querySelector("pdf-viewer")?.append(a);

                Logger.LogInformation(Message, Class);
            })
            .catch((error: any) => {
                isLoading.value = false;

                if (error instanceof AxiosError) {
                    showPdfError.value = true;
                    infoError.value = error;
                    showDowloadButton.value = false;
                }

                Message = `(Error) ${Message}: ${error instanceof AxiosError ? error : error.message}`;
                log.error(Message);
                Logger.LogError(Message, Class);
            });
    }
    else if (method.toUpperCase() === "POST") {
        const urlParams = endpointURL;
        const body: IDownloadJournaledInvoiceBody = {
            InvoiceIds: [fileID],
            ZipFile: false,
            JournalingType: fileType,
            DocumentType: documentType
        }
        var Message = `Getting PDF file using 'POST' method, body: ${JSON.stringify(body)}`;
        log.debug(`name()\n${Message}`);

        isLoading.value = true;
        await JIServices.GetInvoiceBillJournalPdfFileUrl(urlParams,body)
            .then((url: string) => {
                pdfUrl.value = url;

                let a = document.createElement("a");
                a.href = url;
                a.download = fileID;
                a.textContent = `View pdf ${fileType}_${fileID}`;
                document.querySelector("pdf-viewer")?.append(a);

                Logger.LogInformation(Message, Class);
            })
            .catch((error: any) => {
                isLoading.value = false;

                if (error instanceof AxiosError) {
                    if (error.response?.status === 400) {
                        showBadRequest.value = true;
                        showDowloadButton.value = false;
                    }
                    else {
                        showPdfError.value = true;
                        infoError.value = error;
                        showDowloadButton.value = false;
                    }
                }

                Message = `(Error) ${Message}: ${error instanceof AxiosError ? error : error.message}`;
                log.error(Message);
                Logger.LogError(Message, Class);
            });
    }
    else { throw new Error("REST API method not supported or recognised"); }

    isLoading.value = false;
});

// ERRORS
const ShowPdfErrorInfo = ErrorInfo;
const showPdfError: Ref<boolean> = ref(false);
const showPdfErrorChanger: Ref<boolean> = ref(true);
const infoError: Ref<AxiosError | undefined> = ref(undefined);
const showDowloadButton: Ref<boolean> = ref(true);
const showBadRequest: Ref<boolean> = ref(false);

// TABLE
const isLoading = ref(false);

// ROUTE
const route = useRoute();
const fileID = route.params.id as string; 
const documentType = route.params.documentType as string;
const fileType = route.query.type as string;
const endpointURL = route.query.endpoint as string;
const method = route.query.method as string;

const displayDocumentType = computed<boolean>(()=>{
    return (documentType=="" || documentType == undefined)? false : true
})

// PDF
const pdfUrl: Ref<string | undefined> = ref(undefined);

function DownloadPdf() {
    log.trace(`DownloadPdf()\nFile Type: ${fileType}, File ID: ${fileID}`);

    const downloadLink = document.createElement("a");
    downloadLink.href = pdfUrl.value!;
    downloadLink.download = fileID ;
    // downloadLink.target = "_blank";
    downloadLink.setAttribute("target", "_blank");

    // Trigger download link
    downloadLink.click();

    // Clean up
    URL.revokeObjectURL(downloadLink.href);
}


</script>

<style scoped lang="scss">
.FullPagePDF {
    height: calc(100vh - ( 2 * var(--default-body-margin) )); 
}
.content-container {
    height: 100%;
}
.pdf-viewer {
    height: 100%;
}
.embedded-pdf {
    height: 100%;
}
.embedded-pdf embed {
    width: 100%;
    height: 100%;
}
</style>

<style scoped lang="scss">
.loading {
    height: calc(100vh - 3 * var(--default-body-margin));
}
</style>
@/Interfaces/Logger@/Interfaces/IDownloadJournaledInvoiceBody@/Interfaces/IAPILogger