<template>
    <Popup :modelValue=!!modelValue @update:modelValue=close style="min-height: 40%;">
        <div class="layout">
            <div class="header">
                <h3>Validation Results of {{ modelValue }}</h3>
                <form v-if=hasScoreUpdate @submit.prevent=onSubmitCredit >
                    <GroupedInputButton class="form-button-group">
                        <input class="form-input" title="The new credit score to submit" type=number :min=0 :max=2000 step=any placeholder="New Score" required>
                        <button class=form-button title="Manually override credit score" :disabled=working >Submit Credit Score</button>
                    </GroupedInputButton>
                </form>
                <GroupedInputButton v-if=hasOverride class="button-group">
                    <button class="left-btn"
                        title="Override the validation result to be valid"
                        :disabled=working
                        @click=onForceValid
                        type="button" >
                        Force Valid
                    </button>
                    <button title="Override the validation result to be invalid"
                        @click=onForceInvalid
                        :disabled=working
                        type="button" >
                        Force Invalid
                    </button>
                </GroupedInputButton>
            </div>
            <div class="body">
                <TablePersistentChecking
                    :Rows="flattened"
                    :Cols="cols"
                    DisableSort
                    SplitColKeys
                >
                    <template v-for="k in boolCols" v-slot:[`${k}`]="{ value }">
                        <TickCrossBooleanDisplay :value="(value[k] as boolean | undefined)" />
                    </template>
                    <template #validation="{value}">
                        <OverallValidationBooleanDisplay :value="value.validation" />
                    </template>
                    <template #manualOverride="{value}">
                        <TickCrossBooleanDisplay :value=value.manualOverride>
                            <template #true>Y</template>
                            <template #false>N</template>
                        </TickCrossBooleanDisplay>
                    </template>
                    <template #validation-header>
                        Overall Validation
                    </template>
                    <template #ValidationMessage={value} >
                        <ExpandableText :maxLines=3>{{ value.ValidationMessage }}</ExpandableText>
                    </template>
                </TablePersistentChecking>
            </div>
        </div>
    </Popup>
</template>
<script setup lang="ts">
import { Ref, ref, computed, watch } from 'vue';
import Popup from '../Popup.vue';
import GroupedInputButton from '../GroupedInputButton.vue';
import ContractValidation from '@/models/ContractValidation';
import TablePersistentChecking from '../TablePersistentChecking.vue';
import TickCrossBooleanDisplay from '../TickCrossBooleanDisplay.vue';
import { contractValidations, manualCreditScore as manualCreditScoreSvc } from '@/services/network';
import useContractValidation from '@/services/composables/ContractValidation';
import useNotifierWithErrFormatter from '@/services/composables/NotifierWithErrFormatter';
import { INotificationPopupExpose } from '../NotificationPopup.vue';
import { marketSelection } from '@/services/helpers/Helper';
import ExpandableText from "@/components/ExpandableText.vue";
import OverallValidationBooleanDisplay from "@/components/Contracts/OverallValidationBooleanDisplay.vue";
import {ContractValidationType} from '@/models/enums/ContractValidationType';
import { authService } from '@/auth/authSingleton';
import { AuthorisationLevel, GetAuthLevel } from '@/config/AuthConfig';

const downloadError = ref<INotificationPopupExpose>();
const working = ref(false);
const {
    getFlattened,
    getColKeys,
    getBoolKeys
} = useContractValidation();

const props = defineProps<{
    /**
     * contractID
     */
    modelValue?: string;
    hasOverride?: boolean;
    validationType: keyof typeof ContractValidationType;
}>();
const hasScoreUpdate = computed(()=>(props.validationType === 'UNDERWRITING') && (props.hasOverride || GetAuthLevel(authService.getUsersRole()) === AuthorisationLevel.External));
const validations: Ref<ContractValidation[]|undefined> = ref();
const emits = defineEmits<{
    (e:'update:modelValue', val?: undefined),
    (e:'override', contractIDs: string[], setToValid: boolean),
    (e:'request-update', contractID: string)
}>();
const flattened = computed(()=>validations.value?.map(getFlattened));
const cols = computed(()=>getColKeys(validations.value));
const boolCols = computed(()=>getBoolKeys(validations.value));
function close() {
    emits('update:modelValue');
}
async function downloadValidations(contractID: string) {
    try {
        downloadError.value?.destroy();
        validations.value = undefined;
        validations.value = (await contractValidations.post({
            market: marketSelection.value,
            contractIDs: [contractID],
            includeHistory: true,
            validationType: props.validationType
        }))
            .data
            .contracts[0]
            ?.validations
            ?.map(x => new ContractValidation(x));
    } catch(error) {
        await useNotifierWithErrFormatter().error({ errorType: 'Downloading Validations', error});
    }
}
async function handleNewContractID(contractID?: string) {
    if (contractID) {
        working.value = true;
        await downloadValidations(contractID);
        working.value = false;
    }
}
function onForceValid() {
    if (props.modelValue) emits('override', [props.modelValue], true);
}
function onForceInvalid() {
    if (props.modelValue) emits('override', [props.modelValue], false);
}
async function onSubmitCredit(e: Event) {
    if (working.value) return;
    working.value = true;
    const old = validations.value;
    try {
        const manualCreditScore = ((e.target as HTMLFormElement).children[0].children[0] as HTMLInputElement).valueAsNumber;
        const contractID = props.modelValue;
        if (!contractID) throw new ReferenceError('contractID is unexpectedly undefined');
        const json = {
            username: authService.getEmailAddress(),
            manualCreditScore,
            contractID
        }
        const confirm = window.confirm(
            `Please confirm the credit score update details:\n\n` +
            `Contract ID: ${json.contractID}\n`+
            `Credit Score: ${json.manualCreditScore}\n`
        );
        if (confirm) {
            validations.value = undefined;
            await manualCreditScoreSvc.post(json);
            await useNotifierWithErrFormatter().success({ msg: `Credit score updated for contract ${contractID} to ${manualCreditScore}.` });
            emits('request-update', contractID);
            close();
        }
    } catch(error) {
        await useNotifierWithErrFormatter().error({ errorType: 'Submitting Credit Score', error });
        validations.value = old;
    } finally {
        working.value = false;
    }
}
watch(()=>props.modelValue, handleNewContractID);
</script>
<style lang="scss" scoped>
.layout {
    display: flex;
    flex-direction: column;
    overflow: auto;
    flex: 1 1 auto;
    row-gap: 10px;
    >.header{
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        height: fit-content;
        flex-grow: 0;
        flex-shrink: 0;
        column-gap: 1rem;
        >h3 {
            margin: 0;
            padding: 0;
        }
        >.credit-form {
            column-gap: 1ch;
            >.form-input {
                flex: 1;
            }
            >.form-button {
                flex: 1;
                &:hover {
                    cursor: pointer;
                }
            }
        }
        .form-button-group {
            >.form-input {
                padding-left: 1ch;
                min-width: 11ch;
            }
            >.form-button {
                cursor: pointer;
                color: --primary-color;
                background-color: var(--primary-color-lighter);
                &:hover {
                    color: white;
                    background-color: var(--primary-color);
                }
            }
        }
        >.button-group {
            >button {
                cursor: pointer;
                color: --primary-color;
                background-color: var(--primary-color-lighter);
                &:hover {
                    color: white;
                    background-color: var(--primary-color);
                }
                &.left-btn {
                    background-color: var(--primary-color-light);
                    &:hover {
                        background-color: var(--primary-color-lighter);
                    }
                }
            }
        }
    }
    >.body {
        flex: 1 1 auto;
        display: flex;
        overflow: auto;
    }
}
</style>