<template>
    <div class="Journaling">
        <div v-if="isLoading" class="content-container">
            <div class="loading">
                <span class="loader"></span>
            </div>
        </div>
        <div v-else class="content-container">
            <div class="top-content">
                <GettingJournalReportsErrorInfo :showError="!!tableError"
                    errorDescription="(Getting Journal Reports)" :error="tableError" />
                <ProformaJournalErrorInfo :showError="!!infoError" :closeError="true"
                    errorDescription="(Proforma Journal)" :error="infoError" :change="!!infoError" />
                <ExportJournalErrorInfo :showError="!!exportError" :closeError="true"
                    errorDescription="(Export Journal)" :error="exportError" :change="!!exportError" />
                <div class="flex-row-centered">
                    <MarketSelector @change=updateMarketSelection enableSE />
                    <TitledSelection title="Journal Type Selection:" for="journalTypeSelect">
                        <select id="journalTypeSelect" v-model="journalingTypeSelection" class="dropdown" style="width: 20rem;" @change="journalingSelectionChanged()">
                            <option v-for="journalingType in journalingTypeOptions" :value="journalingType.val"
                                :key="journalingType.val">
                                {{ journalingType.text }}
                            </option>
                        </select>
                    </TitledSelection>
                    <div v-if="journalingTypeSelection !=''">
                        <TitledSelection :title="`Enter IDs to force journal:`">
                                <div style="display: grid; grid-template-columns: max-content;">
                                    <input v-model=forcedIDs
                                        id="contact-id"
                                        type="text"
                                        placeholder="Enter IDs..."
                                        @keyup.enter="test(forcedIDs)"
                                        style="grid-column: 1; grid-row: 1; width: 100%;"/>
                                </div>
                        </TitledSelection>
                    </div>
                    <button @click="ProformaJNL()" class="centre-button" :disabled="!!tableError ||
                        proformaButtonDisabled ||
                        DenyFunctionLevelAccess(FunctionAccessLevels.ProformaJNL)
                        "
                        :title="DenyFunctionLevelAccess(FunctionAccessLevels.ProformaJNL) ? 'Proforma Journal (Access denied)' : 'Proforma Journal'">
                        Proforma JNL
                    </button>
                    <button @click="ExportJNL()" class="centre-button" :disabled="!!tableError ||
                        exportButtonDisabled ||
                        DenyFunctionLevelAccess(FunctionAccessLevels.ExportJNL)
                        "
                        :title="DenyFunctionLevelAccess(FunctionAccessLevels.ExportJNL) ? 'Export Journal (Access denied)' : 'Export Journal'">
                        Export JNL
                    </button>
                </div>

                <div v-if="showDescription" class="desc-error">
                    <div class="desc">
                        <div class="close-error">
                            <button @click="CloseDescription">X</button>
                        </div>
                        <div class="desc-header">NOTE:</div>
                        <div>
                            {{
                                `${description}`.endsWith(".")
                                ? `${description}`.slice(0, -1)
                                : `${description}`
                            }}.
                        </div>
                    </div>
                </div>
            </div>
            <Table
                :Rows=displayedJournalingReports
                :Cols=cols
                :Labels=labels
                :RowClickedCallBack=RowClicked
                :DefaultSortCols=defaultSortCols
                :DefaultSortOrders=defaultSortOrders
                :HasError=!!tableError
                v-model:Page=page
                v-model:RowsPerPage=rowCount
            >
                <template #Status="props">
                    <div v-if="props.value.Status === 'SUCCESS'" class="highlight-green-bold">
                        {{ props.value.Status }}
                    </div>
                    <div v-if="props.value.Status === 'FAILURE'" class="failure">
                        {{ props.value.Status }}
                    </div>
                </template>
                <template #JournalType="props">
                    {{ CanonicalToCamel(props.value.JournalType) }}
                </template>
            </Table>
        </div>
    </div>
</template>

<script setup lang="ts">
import {
    ref,
    Ref,
    onMounted,
    onBeforeUnmount,
    onBeforeMount,
    inject
} from "vue";
import { onBeforeRouteLeave } from "vue-router";
import router from "@/router";
// Services
import { apiLoggerKey } from "@/types/ServiceKeys";
import { authService } from "@/auth/authSingleton";
import { FunctionAccessLevels, DenyFunctionLevelAccess } from "@/config/AuthConfig";
import { MarketSelection, financialSystemNetworkService } from "@/services/helpers/Helper";
import {
    cols,
    labels,
    ParseItems,
    JournalingServices,
    ICache,
} from "@/services/helpers/JournalingHelper";
// Data
import IJournalReportsItem from "@/interfaces/Journaling/IJournalingReportsItem";
import { journalingTypes, JournalingType, CanonicalToCamel } from "@/models/Journaling/JournalingTypes";
import { ISelection } from "@/Interfaces/ISelection";
import { IMetadata } from "@/interfaces/IMetadata";
import IJournalingBody from "@/Interfaces/Journaling/IJournalingBody";
import { marketSelection } from "@/services/helpers/Helper";
// Table
import Table from "@/components/TablePersistentChecking.vue";
// Misc
import log from "loglevel";
import { AxiosError } from "axios";
import ErrorInfo from "@/components/ErrorInfo.vue";
import MarketSelector from "@/components/MarketSelector.vue";
import TitledSelection from "@/components/TitledSelection.vue";

const page = ref(1);
const rowCount = ref(50);
const defaultSortCols = ref([0]);
const defaultSortOrders = ref<(1|-1)[]>([-1]);
const scrollPosition = ref(0);
const forcedIDs = ref('');

onBeforeUnmount(() => {
    log.trace("onBefoureUnmount() Journaling");
});

log.trace("Journaling");
const fileName = "Journaling.vue";
const Logger = inject(apiLoggerKey);

////////////////////////////////////////////////////////////////////////////
// LOGIC
////////////////////////////////////////////////////////////////////////////
onBeforeRouteLeave((to, from, next) => {
    log.trace("onBeforeRouteLeave() Journaling");

    // If leaving to go to "Journaling Report", save journalingReports in session storage
    if (to.name === 'Journaling Report') {
        const cache: ICache = {
            Data: journalingReports.value!,
            Metadata: {
                Market: marketSelection.value,
                PageNumber: page.value,
                RowCount: rowCount.value,
                SortCols: defaultSortCols.value,
                SortOrders: defaultSortOrders.value,
            },
        };
        log.trace("onBeforeRouteLeave()\ncache", cache);
        sessionStorage.setItem("Journaling", JSON.stringify(cache));
    }
    next();
});

onBeforeMount(() => {
    log.trace("onBeforeMount() Journaling");

    if (
        lastPage.value?.startsWith(journalingRoutePath!) ||
        previousPage.value?.startsWith(journalingRoutePath!)
    ) {
        if ("Journaling" in sessionStorage) {
            log.debug("onBeforeMount() 'Journaling' in sessionStorage");
        }
    } else {
        log.debug("onBeforeMount() removing 'Journaling' from sessionStorage");
        sessionStorage.removeItem("Journaling");
    }
});

const journalingRoutePath: string | undefined = router.options.routes
    .find((route) => route.name === 'Journaling Report')
    ?.path.split("/:")[0];
const previousPage: Ref<string | undefined> = ref(
    router.options.history.state.back?.toString()
);
const lastPage: Ref<string | undefined> = ref(
    router.options.history.state.forward?.toString()
);

onMounted(async () => {
    log.trace("onMounted() Journaling");

    document.title = `Journaling - Optio`;

    isLoading.value = true;
    if (!("Journaling" in sessionStorage)) {
        marketSelection.value = "GB";
    }
    await GetJournalingReports().then(() => {
        // We don't need to filter as only filter is Market and API handles filtering
        sortJournalingReports();
    });
    isLoading.value = false;
});

async function GetJournalingReports() {
    log.trace("GetJournalingReports()");

    const name = GetJournalingReports.name;
    const Class = fileName + "/" + name;

    showDescription.value = false;

    if ("Journaling" in sessionStorage) {
        log.debug(
            "GetJournalingReports()\nLoading Journaling Reports from session storage"
        );

        const cache: ICache = JSON.parse(sessionStorage.getItem("Journaling")!);
        const data: IJournalReportsItem[] = cache.Data;
        const metadata: IMetadata = cache.Metadata;
        // log.debug("GetJournalingReports()\nCACHE cache", cache);
        // log.debug("GetJournalingReports()\nCACHE data", data);

        journalingReports.value = data;
        displayedJournalingReports.value = journalingReports.value.map(ParseItems);

        marketSelection.value = (Object.keys(MarketSelection) as (keyof typeof MarketSelection)[]).find(
            m => m === metadata.Market
        ) ?? 'GB';
        scrollPosition.value = metadata.ScrollPosition!;
        page.value = metadata.PageNumber!;
        rowCount.value = metadata.RowCount!;
        defaultSortCols.value = metadata.SortCols!;
        defaultSortOrders.value = metadata.SortOrders!;
    } else {
        const urlParams: string =
            process.env.VUE_APP_JOURNALING_REPORTS_URL_PARAMS! +
            "?market=" +
            marketSelection.value +
            "&";

        var Message = `Getting Journaling Reports: ${urlParams}`;

        log.debug(`${name}()\n${Message}`);
        try {
            resetErrors();
            const data = await journalingServices.getJournalingReports(urlParams);
            journalingReports.value = data;
            displayedJournalingReports.value = journalingReports.value.map(
                ParseItems
            );
            log.trace(
                "GetJournalingReports()\nAPI journalingReports",
                journalingReports.value
            );
            Logger?.LogInformation(Message, Class);
        } catch (error) {
            tableError.value = error;
            Message = `(Error) ${Message}: ${error}`;
            log.error(`${Message}`);
            Logger?.LogError(Message, Class);
        }
    }
}

// ERRORS
const GettingJournalReportsErrorInfo = ErrorInfo;
const ProformaJournalErrorInfo = ErrorInfo;
const ExportJournalErrorInfo = ErrorInfo;
const infoError = ref();
const tableError = ref();
const exportError = ref();

// BUTTONS
const proformaButtonDisabled = ref(false);
const exportButtonDisabled = ref(false);

// TABLE
const isLoading = ref(true);
const journalingReports = ref<IJournalReportsItem[]>();
const displayedJournalingReports = ref<IJournalReportsItem[]>();

// MISC
const journalingServices: JournalingServices = new JournalingServices();
const lastProformaJobId= ref<number>();
const lastExportJobId = ref<number>();
const showDescription = ref(false);
const description = ref<string>();

const journalingTypeOptions: ISelection[] = journalingTypes.map((journalingType: JournalingType) => {
    return { text: journalingType.DisplayShort, val: journalingType.Canonical } as ISelection;
})
journalingTypeOptions.sort((a: ISelection, b: ISelection) =>
    a.text.localeCompare(b.text)
);
journalingTypeOptions.unshift({ text: "All", val: "" } as ISelection);
const journalingTypeSelection = ref('');
function journalingSelectionChanged(){
    if(journalingTypeSelection.value == ""){
        forcedIDs.value = ""
    }
}
async function updateMarketSelection() {
    const name = updateMarketSelection.name;
    const Message = `Changed selection to: market: ${marketSelection.value}.`;
    const Class = fileName + "/" + name;

    sessionStorage.removeItem("Journaling")
    // Fetch Data
    isLoading.value = true;
    await GetJournalingReports();

    // We don't need to filter as only filter is Market and API handles filtering
    sortJournalingReports();

    isLoading.value = false;
    Logger?.LogInformation(Message, Class);
}
function sortJournalingReports() {
    log.trace("sortJournalingReports()");

    displayedJournalingReports.value = displayedJournalingReports.value?.sort(
        (a, b) => new Date(b.Time).getTime() - new Date(a.Time).getTime()
    );
}
function resetErrors() {
    tableError.value = undefined;
    infoError.value = undefined;
    exportError.value = undefined;
}
async function ProformaJNL() {
    log.trace(ProformaJNL.name);

    const name = ProformaJNL.name;
    const Class = fileName + "/" + name;

    proformaButtonDisabled.value = true;
    resetErrors();

    lastProformaJobId.value = displayedJournalingReports.value
        ?.map((item: IJournalReportsItem) => Number(item.JobID))
        .sort((a, b) => b - a)
        .shift();
    log.debug("ProformaJNL()\nlastProformaJobId:", lastProformaJobId.value);

    log.debug("ProformaJNL() removing 'Journaling' from sessionStorage");
    sessionStorage.removeItem("Journaling");

    // log.debug("ProformaJNL()\nEmail Address:", authService.getEmailAddress());
    const body: IJournalingBody = {
        ExportJournal: false,
        UserName: authService.getEmailAddress(),
        Market: marketSelection.value,
        JournalType: journalingTypeSelection.value,
        ForcedIDs: forcedIDs.value
    };
    const urlParams: string = process.env.VUE_APP_PROFORMA_EXPORT_JOURNALING_URL_PARAMS!;

    isLoading.value = true;

    var Message = `Performing Proforma Journal, body: ${JSON.stringify(body)}`;

    log.debug(`name()\n${Message}`);
    await financialSystemNetworkService
        .post(body, urlParams)
        .then(async (response) => {
            log.debug("ProformaJNL() Response:", response);
            // Pull new data and load table
            await GetJournalingReports();
            sortJournalingReports();

            NoNewDataNotification(true);
            Logger?.LogInformation(Message, Class);
        })
        .catch((error) => {
            infoError.value = error;
            Message = `(Error) ${Message}: ${error}`;
            log.error(Message);
            Logger?.LogError(Message, Class);
        });
    isLoading.value = false;
    proformaButtonDisabled.value = false;
}
async function ExportJNL() {
    sessionStorage.removeItem("Journaling");

    const name = ExportJNL.name;
    const Class = fileName + "/" + name;

    exportButtonDisabled.value = true;
    resetErrors();

    lastExportJobId.value = displayedJournalingReports.value
        ?.map((item: IJournalReportsItem) => Number(item.JobID))
        .sort((a, b) => b - a)
        .shift();
    log.debug("ExportJNL()\nlastExportJobId:", lastExportJobId.value);

    log.debug("ExportJNL() removing 'Journaling' from sessionStorage");
    sessionStorage.removeItem("Journaling");

    const body: IJournalingBody = {
        ExportJournal: true,
        UserName: authService.getEmailAddress(),
        Market: marketSelection.value,
        JournalType: journalingTypeSelection.value,
        ForcedIDs:forcedIDs.value
    };
    const urlParams: string = process.env.VUE_APP_PROFORMA_EXPORT_JOURNALING_URL_PARAMS!;

    isLoading.value = true;

    var Message = `Performing Export Journal, body: ${JSON.stringify(body)}`;

    log.debug(`name()\n${Message}`);
    await financialSystemNetworkService
        .post(body, urlParams)
        .then(async (response) => {
            log.debug("ExportJNL() Response:", response);
            // Pull new data and load table
            await GetJournalingReports();
            sortJournalingReports();

            NoNewDataNotification(false);
            Logger?.LogInformation(Message, Class);
        })
        .catch((error) => {
            exportError.value = error;
            Message = `(Error) ${Message}: ${error}`;
            log.error(Message);
            Logger?.LogError(Message, Class);
        });
    isLoading.value = false;
    exportButtonDisabled.value = false;
}
function NoNewDataNotification(proforma: boolean) {
    description.value = "No new data to perform Journaling.";

    if (proforma) {
        const latestProformaJobId = displayedJournalingReports.value
            ?.map((item: IJournalReportsItem) => Number(item.JobID))
            .sort((a, b) => b - a)
            .shift();

        log.debug(
            "NoNewDataNotification()\nlatestProformaJobId:",
            latestProformaJobId,
            "\nlastProformaJobId:",
            lastProformaJobId.value
        );
        if (latestProformaJobId === lastProformaJobId.value) {
            showDescription.value = true;
        }
    } else {
        const latestExportJobId = displayedJournalingReports.value
            ?.map((item: IJournalReportsItem) => Number(item.JobID))
            .sort((a, b) => b - a)
            .shift();

        log.debug(
            "NoNewDataNotification()\nlatestExportJobId:",
            latestExportJobId,
            "\nlastExportJobId:",
            lastExportJobId.value
        );
        if (latestExportJobId === lastExportJobId.value) {
            showDescription.value = true;
        }
    }
}
function CloseDescription() {
    showDescription.value = false;
}
function RowClicked(row: IJournalReportsItem) {
    log.trace(RowClicked.name);
    const r = {
        name: 'Journaling Report',
        params: {
            id: row.JobID,
        },
        query: {
            JournalType: row.JournalType,
            Status: row.Status,
        },
    };
    // Open in current tab
    router.push(r);
}
</script>
<style lang="scss" scoped>
@import "@/assets/styles/centre-button.scss";

:deep(.flex-row-centered ) {
    display:flex;
    align-items: flex-end;
}

.selection-form-btn {
    border-color: var(--primary-color-dark);
    font-weight: bold;
    background-color: var(--primary-color-lighter);
    color: blue;
    cursor: pointer;

    &:hover {
        background-color: var(--primary-color);
        color: white;
    }
}
</style>