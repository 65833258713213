import log from "loglevel";
import { AxiosResponse } from "axios";
import {
    IGetReportsInGroupResposne,
    IReportInGroupDetails,
} from "@/Interfaces/CustomReports/IGetReportsInGroupResponse";
import { powerBiApiNetworkService } from "./Helper";
import ErrorInfo from "@/components/ErrorInfo.vue";

const fileName = "CustomReports.vue";

// Errors
export const GetReportsInGroupErrorInfo = ErrorInfo;

const customWorkspaceUrl: string =
    process.env.VUE_APP_POWER_BI_CUSTOM_REPORT_WORKSPACE_URL_PREFIX! +
    process.env.VUE_APP_POWER_BI_CUSTOM_REPORT_WORKSPACE_ID +
    process.env.VUE_APP_POWER_BI_CUSTOM_REPORT_WORKSPACE_URL_SUFFFIX;

export function NavigateToPowerBIWorkspace() {
    log.trace("NavigateToPowerBIWorkspace()");

    // Open url in new tab
    window.open(customWorkspaceUrl, "_blank");
}

export async function GetReportsInGroup(): Promise<IReportInGroupDetails[]> {
    const name = GetReportsInGroup.name;
    const Class = fileName + "/" + name;
    var customReportsMessage = GetMessage("Custom Reports");
    var restrictedCustomReportsMessage = GetMessage("Restricted Custom Reports");
    
    const customReportsUrlParams: string = process.env.VUE_APP_POWER_BI_CUSTOM_REPORT_WORKSPACE_ID! + process.env.VUE_APP_POWER_BI_GET_REPORTS_IN_GROUP_URL_SUFFIX!;
    const restrictedCustomReportsUrlParams: string = process.env.VUE_APP_POWER_BI_RESTRICTED_CUSTOM_REPORT_WORKSPACE_ID! + process.env.VUE_APP_POWER_BI_GET_REPORTS_IN_GROUP_URL_SUFFIX!;
    
    // try {
    //     if (!DenyFunctionLevelAccess(FunctionAccessLevels.ViewRestrictedReports)) {
    //         log.debug(`${name}()\n${customReportsMessage}\nAND\n${restrictedCustomReportsMessage}`);
    //         // Initiate both API calls simultaneously
    //         const [customReportsData, restrictedCustomReportsData] = await Promise.all([
    //             powerBiApiNetworkService.get(customReportsUrlParams),
    //             powerBiApiNetworkService.get(restrictedCustomReportsUrlParams)
    //         ]);
            
    //         return [...customReportsData.data.value, ...restrictedCustomReportsData.data.value]
    //     } else {
    //         log.debug(`${name}()\n${customReportsMessage}`);
    //         return await powerBiApiNetworkService
    //             .get(customReportsUrlParams)
    //             .then((res: AxiosResponse<IGetReportsInGroupResposne>) => {
    //                 return res.data.value
    //             })
    //     }
    // } catch (error: any) {
    //     log.error(`GetReportsInGroup()\nerror: ${error}`);
    //     throw error;
    // }
    const customReportsData: IGetReportsInGroupResposne = await new Promise((resolve, reject) => {
        powerBiApiNetworkService
            .get(customReportsUrlParams)
            .then((res: AxiosResponse<IGetReportsInGroupResposne>) => {                    
                resolve(res.data);
            })
            .catch((error: any) => {
                log.error(`GetReportsInGroup()\n${customReportsMessage}\nerror: ${error}`);
                reject(error);
            });
    });


    const restrictedCustomReportsData: IGetReportsInGroupResposne = await new Promise((resolve, reject) => {
        powerBiApiNetworkService
            .get(restrictedCustomReportsUrlParams)
            .then((res: AxiosResponse<IGetReportsInGroupResposne>) => {                    
                resolve(res.data);
            })
            .catch((error: any) => {
                log.error(`GetReportsInGroup()\n${restrictedCustomReportsMessage}\nerror: ${error}`);
                // reject(error);
                const failure: IGetReportsInGroupResposne = {
                    "@odata.context": "ERROR",
                    value: []
                }
                resolve(failure);
            });
    });

    return [...customReportsData.value, ...restrictedCustomReportsData.value];
}

function GetMessage(groupName: string) {
    return `Getting reports in '${groupName}' group`;
}