<template ref="TransferPayments">
    <div class="tabs-container">
        <div class="top-tabs tabs-buttons">
          <div class="tabs">

            <!-- Outstanding Payments Tab -->
            <button
              :class="`button-left ${currentTab === tabs.WAITING ? 'selected' : 'unselected'}`"
              :disabled="currentTab === tabs.WAITING"
              :title="currentTab === tabs.WAITING
                   ? `Currently viewing ${currentTab.toLowerCase()} transfer payments`
                  : 'Click to view outstanding Transfer Payments'"
              @click="ToggleTabs(tabs.WAITING)"
            >
              Outstanding
            </button>
          </div>
    
           <!-- Confirmed Payments Tab -->
           <div class="tabs">
            <button
              :class="`button-middle ${currentTab === tabs.CONFIRMED ? 'selected' : 'unselected'}`"
              :disabled="currentTab === tabs.CONFIRMED"
              :title="currentTab === tabs.CONFIRMED
                ? `Currently viewing ${currentTab.toLowerCase()} transfer payments`
                : 'Click to view confirmed payments'"
              @click="ToggleTabs(tabs.CONFIRMED)"
            >
              Confirmed
            </button>
          </div>

          <!-- Completed Payments Tab -->
          <div class="tabs">
            <button
              :class="`button-right ${currentTab === tabs.COMPLETED ? 'selected' : 'unselected'}`"
              :disabled="currentTab === tabs.COMPLETED"
              :title="currentTab === tabs.COMPLETED
                ? `Currently viewing ${currentTab.toLowerCase()} payments`
                : 'Click to view completed payments'"
              @click="ToggleTabs(tabs.COMPLETED)"
            >
              Completed
            </button>
          </div>

        </div>
      </div>
    <div class="content-container">
        <div class="top-content">
            <div class="flex-row-centered">
                <MarketSelector  @change="MarketSelectionUpdate" enableSE />
                <div class="flex-col-centered" v-if="currentTab === tabs.COMPLETED">
                    <div>Transfer Type Selection:</div>
                    <select
                        @change="updateDisplayedBankTransfers"
                        v-model="selectedType"
                        class="dropdown wider"
                    >
                        <option value="">All</option>
                        <option
                            v-for="transferType in transferTypes"
                            :value="transferType.key"
                            :key="transferType.key"
                        >
                            {{ transferType.val }}
                        </option>
                    </select>
                </div>
                <div class="flex-col-centered">
                    <div class="center-buttons">
                        <button
                            v-if="currentTab != tabs.COMPLETED"
                            @click="ButtonPressedAction"
                            :disabled="disableButton"
                            class="incident-nav-button"
                        >
                            {{ currentTab == tabs.WAITING ? "Confirm Transfer" : "Approve Transfer" }}
                        </button>
                    </div>
                </div>
            </div>
        </div>
        <div class="container">
            <div class=utility-bar>
                <div v-if="currentTab == tabs.COMPLETED" class=link-btn>
                    [<a @click=exportFilteredDisplayable>Export csv</a>]
                </div>
            </div>
            <TablePersistentChecking
                ref="table"
                id="bankTransferTable"
                :Rows="displayedBankTransferPayments"
                :Cols="displayedBankTransferColumns"
                :HasCheckBox="currentTab != tabs.COMPLETED"
                :SingleSelection=true
                :Labels=displayedBankTransferLabels
                HasPreCol
            >
            </TablePersistentChecking>
        </div>
        <TransferPopUp
            v-model="selectedRow"
            :currentTab=currentTab
            @confirm="refreshAfterButtonAction">
        </TransferPopUp>
    </div>
</template>

<script setup lang="ts">
import { ref, onMounted, computed, onUnmounted, nextTick } from "vue";
import { marketSelection } from "@/services/helpers/Helper";
import TablePersistentChecking, { ITableExpose } from "@/components/TablePersistentChecking.vue";
import MarketSelector from "@/components/MarketSelector.vue";
import { getBankTransferPayments } from "@/services/network";
import IBankTransferPayments from "@/Interfaces/Ledgering/IBankTransferPayments";
import DbTableCsvHelper from "@/services/helpers/DbTableCsvHelper";
import useNotifier from "@/services/composables/Notifier";
import TransferPopUp from "@/components/Ledger/TransferPopUp.vue";
import { useRoute } from "vue-router";
import router from "@/router";
import { authService } from "@/auth/authSingleton";

onMounted(() => {
    marketSelection.value = 'GB'
    routeTab.value = route.params.transferStatus as string;    
    if (!routeTab.value || routeTab.value === "") {
        ToggleTabs(tabs.WAITING);
    }
    else if (Object.values(routeTabs).includes(routeTab.value)) {
        switch (routeTab.value) {
            case routeTabs.OUTSTANDING:
                ToggleTabs(tabs.WAITING);
                break;
            case routeTabs.CONFIRMED:
                ToggleTabs(tabs.CONFIRMED);
                break;
            case routeTabs.COMPLETED:
                ToggleTabs(tabs.COMPLETED);
                break;
        }
    } 
    else {
        router.push({ name: "Page Not Found" });
    }
});
onUnmounted(() => {
    marketSelection.value = 'GB'
})

enum tabs {
    WAITING = "WAITING",
    CONFIRMED = "CONFIRMED",
    COMPLETED = "COMPLETED",
}
enum routeTabs {
    OUTSTANDING = "outstanding",
    CONFIRMED = "confirmed",
    COMPLETED = "completed",
}

const route = useRoute(); 
const routeTab = ref()
const currentTab = ref<tabs>(tabs.WAITING);
const bankTransferPayments = ref<IBankTransferPayments[]>();
const displayedBankTransferPayments = ref<IBankTransferPayments[]>();
const bankTransferCols: (keyof IBankTransferPayments)[] = ["Market", "TransferId", "TransferTypeId", "AccountFrom", "AccountTo", "Amount", "TicketId", "TargetDate", "ConfirmedValueDate", "ApprovedValueDate", "FinalApprovedValueDate", "ConfirmedUser", "ApprovedUser", "FinalApprovedUser"];
const displayedBankTransferColumns = ref<(keyof IBankTransferPayments)[]>(bankTransferCols); 
const bankTransferLabels = ["Market", "Transfer ID", "Transfer Type ID", "Account From", "Account To", "Amount", "Ticket ID", "Target Date", "Confirmed Value Date", "Approved Value Date", "Final Approved Value Date","Confirmed User", "Approved User", "Final Approved User"];
const displayedBankTransferLabels = ref<string[]>(bankTransferLabels); 
const selectedType = ref("");
const transferTypes = ref<{ key: string; val: string }[]>([]);
const selectedRow = ref<IBankTransferPayments>();
const table = ref<ITableExpose<IBankTransferPayments>>();
const disableButton = computed(() => {
    return table?.value?.GetChecked()[0] == null 
});
const userEmail = authService.getEmailAddress();

function ButtonPressedAction(){
    selectedRow.value = table?.value?.GetChecked()[0]; 
}
function ToggleTabs(tab: tabs) {
    currentTab.value = tab
    const r = {name: "Transfer Payments", params: { transferStatus: routeTabs.OUTSTANDING.toString()}};
    router.push(r);
    if(currentTab.value == tabs.WAITING){
        router.push(r);
        displayedBankTransferColumns.value = ["Market", "TransferId", "TransferTypeId", "AccountFrom", "AccountTo", "Amount", "TicketId", "TargetDate"];
        displayedBankTransferLabels.value = ["Market", "Transfer ID", "Transfer Type ID", "Account From", "Account To", "Amount", "Ticket ID", "Target Date"]
    }
    if(currentTab.value == tabs.CONFIRMED){
        router.push(r.params.transferStatus = routeTabs.CONFIRMED.toString());
        displayedBankTransferColumns.value = ["Market", "TransferId", "TransferTypeId", "AccountFrom", "AccountTo", "Amount", "TicketId", "TargetDate", "ConfirmedValueDate", "ApprovedValueDate","ConfirmedUser", "ApprovedUser"];
        displayedBankTransferLabels.value = ["Market", "Transfer ID", "Transfer Type ID", "Account From", "Account To", "Amount", "Ticket ID", "Target Date", "Confirmed Value Date", "Approved Value Date","Confirmed User", "Approved User"]
    }
    if(currentTab.value == tabs.COMPLETED){
        router.push(r.params.transferStatus = routeTabs.COMPLETED.toString());
        displayedBankTransferColumns.value = bankTransferCols
        displayedBankTransferLabels.value = bankTransferLabels
    }
    BankTransferPaymentsGetter()
}
function refreshAfterButtonAction(){
    BankTransferPaymentsGetter();
}
async function BankTransferPaymentsGetter(){
    displayedBankTransferPayments.value = undefined
    bankTransferPayments.value = undefined
    const res = await getBankTransferPayments.get( {market: marketSelection.value.toString(), status: currentTab.value})
    bankTransferPayments.value = res.data
    if(currentTab.value == tabs.COMPLETED){
        displayedBankTransferPayments.value = res.data.sort((a, b) => {
        return b.TransferId - a.TransferId;
    });
    }
    else{
        displayedBankTransferPayments.value = res.data
        if(currentTab.value == tabs.CONFIRMED){
            PreventDoubledUserApproval()
        }
    }
    GenerateTransferTypeSelectionPairs()
}
async function PreventDoubledUserApproval() {
    await nextTick();
    var table = document.getElementById("bankTransferTable");
    if (!table) return;
    var rows = table.getElementsByTagName("tr");
    for (var i = 0; i < rows.length; i++) {
        var approvedUserCell = rows[i].querySelector("td.ApprovedUser");
        if(approvedUserCell?.textContent != null){
            if (approvedUserCell && approvedUserCell.textContent == userEmail) {
                var checkboxCell = rows[i].querySelector("td.vtl-checkbox");
                var checkbox = checkboxCell?.querySelector("input[type='checkbox']") as HTMLInputElement;;
                if (checkbox) {
                    checkbox.disabled = true;
                }
            }
        }
    }
}
function MarketSelectionUpdate() {
    selectedType.value = "";
    BankTransferPaymentsGetter();
}
function GenerateTransferTypeSelectionPairs(){
    if(currentTab.value != tabs.COMPLETED) return
    selectedType.value = ""
    const uniqueTransferPairs = [...new Set(bankTransferPayments.value?.map(x => [x.AccountFrom, x.AccountTo].join(" - ")))];
    transferTypes.value = uniqueTransferPairs.map(pair => ({
        key: pair,
        val: pair
    }));
}
function updateDisplayedBankTransfers() {
    table.value?.ClearChecked();
    selectedType.value == "" 
    ? displayedBankTransferPayments.value = bankTransferPayments.value 
    : displayedBankTransferPayments.value = bankTransferPayments.value?.filter(x =>([x.AccountFrom, x.AccountTo].join(" - ") == selectedType.value ))
}
function exportFilteredDisplayable() {
    const filtered = table.value?.GetFiltered() ?? [];
    if (filtered.length) {
        DbTableCsvHelper.generateCsvDownload(filtered, `CompletedBankTransfers.csv`);
    } else useNotifier().warn({ msg: 'There is nothing in the table to export.' });
}
</script>
<style scoped lang="scss">
@import "@/assets/styles/layout.scss";
@import "@/assets/styles/buttons.scss";
@import "@/assets/styles/table.scss";
.container {
    display: flex;
    flex-direction: column;
    flex: 1;
    overflow: auto;
}
.utility-bar {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0.1rem;
}
.utility-bar > .link-btn {
    margin-left: auto;
}
.link-btn > a {
    cursor: pointer;
    color: var(--theme-link-color);
    &:hover {
        text-decoration: underline;
        color: var(--theme-link-color-hover);
    }
}
</style>
