import { AxiosResponse } from "axios";
import { financialSystemNetworkService } from "@/services/helpers/Helper";
import log from "loglevel";
import { Log2API } from "@/services/Logger";
import { CarPurchasesPaymentRequest } from "@/models/CarPurchases/CarPurchasesPaymentRequest";
import { FormatDatePicker } from "@/services/helpers/CarPurchases/CarPurchasesFunctions";
import { authService } from "@/auth/authSingleton";
import { CarPurchasesCarSaleInvoice } from "@/models/CarPurchases/CarPurchasesCarSaleInvoice";
import { CarPurchasePayment } from "@/models/CarPurchases/CarPurchasePayment";


export class CarPurchasesServices {
	Logger = new Log2API();

	public async GetCollectiveCarSaleInvoices<T>(market: string): Promise<AxiosResponse<T>> {
		log.trace(`GetCollectiveCarSaleInvoices()\nmarket: ${market}`);

		const urlParams: string = `${process.env.VUE_APP_CAR_PURCHASES_URL_PARAMS!}?market=${market}`;
		log.debug(urlParams);
		const response: AxiosResponse<T> = await GET<T>(urlParams);

		return response;
	}
	public async GetCarSaleInvoice<T>(collectiveInvoiceId: string): Promise<AxiosResponse<T>> {
		log.trace(`GetCarSaleInvoice()\ncollectiveInvoiceId: ${collectiveInvoiceId}`);

		const urlParams: string = `${process.env.VUE_APP_CAR_PURCHASES_URL_PARAMS!}/${collectiveInvoiceId}`;
		const response: AxiosResponse<T> = await GET<T>(urlParams);

		return response;
	}
	public async GetContractOwners<T>(): Promise<AxiosResponse<T>> {
		log.trace(`GetContractOwners()`);

		const urlParams: string = `${process.env.VUE_APP_CONTRACT_OWNERS_URL_PARAMS!}`;
		const response: AxiosResponse<T> = await GET<T>(urlParams);

		return response;
	}
	public async ConfirmCarPurchasesPayment<T>(
		$: {
			collectiveInvoiceId?: string,
			confirmCarPurchaseRows?: CarPurchasesCarSaleInvoice[],
			confirmCarPurchaseRowsTotalBalance?: number,
			confirmCarPurchaseRowsTotalExVATBalance?: number,
			confirmCarPurchaseRowsTotalVATBalance?: number,
			market?: string,
			ownerAssignmentDate?: Date,
			selectedContractOwner?: string,
		}
	): Promise<AxiosResponse<T>> {
		log.trace(`ConfirmCarPurchasesPayment()`);
		log.error($.market)

		const body: CarPurchasesPaymentRequest = new CarPurchasesPaymentRequest({
			CollectiveInvoiceId: $.collectiveInvoiceId,
			AmountTotal: $.confirmCarPurchaseRowsTotalBalance,
			AmountExVATTotal: $.confirmCarPurchaseRowsTotalExVATBalance,
			AmountVATTotal: $.confirmCarPurchaseRowsTotalVATBalance,
			Payments: $.confirmCarPurchaseRows?.map(row =>
				new CarPurchasePayment(row)
			),
			ContractOwner: $.selectedContractOwner,
			OwnerAssignmentDate: $.ownerAssignmentDate ? FormatDatePicker($.ownerAssignmentDate, false) : undefined,
			Market: $.market,
			UserEmail: authService.getEmailAddress(),
		});
		log.debug(`ConfirmCarPurchasesPayment()\nbody: ${JSON.stringify(body)}`);

		const urlParams: string = `${process.env.VUE_APP_CONFIRM_CAR_PURCHASES_PAYMENTS_URL_PARAMS!}`;
		const response: AxiosResponse<T> = await PUT<T>(body, urlParams);

		return response;
	}
}
async function GET<T>(urlParams: string): Promise<AxiosResponse<T>> {
	log.debug("GET()\nurlParams:", urlParams);

	const response: AxiosResponse<T> = await new Promise((resolve, reject) => {
		financialSystemNetworkService
			.get(urlParams)
			.then((res: AxiosResponse<T>) => {
				log.debug("GET()\nurlParams:", urlParams, "\nres.data:", res.data);
				resolve(res)
			})
			.catch((error: any) => {
				reject(error);
			});
	});

	return response;
}
async function PUT<T>(body: any, urlParams: string): Promise<AxiosResponse<T>> {
	log.debug("PUT()\nurlParams:", urlParams);

	const response: AxiosResponse<T> = await new Promise((resolve, reject) => {
		financialSystemNetworkService
			.put(body, urlParams)
			.then((res: AxiosResponse<T>) => {
				log.debug("PUT()\nurlParams:", urlParams, "\nres.data:", res.data);
				resolve(res)
			})
			.catch((error: any) => {
				reject(error);
			});
	});

	return response;
}
export default CarPurchasesServices
