<template id="confirmDrawdownPopup">
    <Popup
        style="min-width: 60%; min-height: 30%; overflow-y: scroll"
        :model-value="!!drawdown"
        title="Drawdown confirmation"
        hasClose
        :onClose="closeChild"
    >
        <template #contents>
            <ConfirmDrawdownErrorInfo
                :showError="showError"
                :closeError="closeError"
                :errorDescription="errorDescription"
                :error="infoError"
                :change="!!showError"
                :axiosError="true"
                :errorDisplayText="errorText"
            />
            <ConfirmDrawdownWarningInfo
                :showWarning="showWarning"
                :closeWarning="closeWarning"
                :warningDescription="warningDescription"
                :warning="infoWarning"
                :change="!!showWarning"
                :axiosError="true"
            />
            <div>
                Please confirm you want to proceed for a drawdown of
                <strong>{{ symbol }}{{ drawdown!.DrawdownAmount }}</strong> on
                <strong
                    >{{ drawdown!.DrawdownDate.toLocaleString("sv-SE").split(" ")[0] }}:</strong
                >
            </div>
            <div style="display: flex">
                <label for="checkbox">Select to add any VAT Loan</label>
                <input type="checkbox" id="checkbox" v-model="isLoan" />
            </div>

            <div v-if="isLoan">
                <form class="form-container flex-row-centered" @submit.prevent="submit">
                    <label for="vat-loan-period">
                        <h5 class="header">Period Selection:</h5>
                    </label>
                    <PeriodSelector
                        required
                        id="periodSelector"
                        hasNullSelector
                        nullSelectorName=""
                        v-model="loanPeriod"
                    />

                    <label for="vat-loan-amount">
                        <h5 class="header">VAT Loan amount:</h5>
                    </label>
                    <CurrencyInput
                        id="vat-loan-amount"
                        v-model="loanAmount"
                        :min="0.01"
                        title="The Vat Loan amount"
                        required
                    />

                    <button type="submit" class="default-btn" :title="submitHint">
                        Add
                    </button>
                </form>

                <TablePersistentChecking :Rows="rows" :Cols="cols" :Labels="labels" />
            </div>

            <div>
                <button
                    type="submit"
                    class="default-btn"
                    :disabled="working"
                    @click="ConfirmDrawdown"
                    :title="workingReason"
                >
                    Confirm
                </button>
            </div>
        </template>
    </Popup>
</template>

<script setup lang="ts">
import { computed, ref } from "vue";
import Popup from "../Popup.vue";
import DatePeriod from "@/types/DatePeriod";
import PeriodSelector from "../PeriodSelector.vue";
import TablePersistentChecking from "@/components/TablePersistentChecking.vue";
import { DrawdownHelper } from "@/services/helpers/DrawdownHelper";
import { DrawdownTableItem } from "@/models/Drawdown";
import { CurrencySymbol } from "@/services/helpers/Helper";
import { AxiosError } from "axios";
import ErrorInfo from "@/components/ErrorInfo.vue";
import WarningInfo from "../WarningInfo.vue";
import IVatLoanItem from "@/Interfaces/VATLoans/IVatLoan";
import CurrencyInput from "../CurrencyInput.vue";
import { authService } from "@/auth/authSingleton";
import useSpinnerPopup from "@/services/composables/SpinnerPopup";
import useNotifierWithErrFormatter from "@/services/composables/NotifierWithErrFormatter";

const props = defineProps<{
    modelValue: DrawdownTableItem;
}>();
const emits = defineEmits<{
    (e: "update:modelValue");
    (e: "confirm");
}>();

const drawdown = computed({
    get() {
        return props.modelValue;
    },
    set(val) {
        emits("update:modelValue");
    },
});

const symbol = computed(
    () => `${CurrencySymbol[drawdown.value.Market!] ?? CurrencySymbol.GBP}`
);

const isLoan = ref<boolean>(false);
const rows = ref<IVatLoanItem[]>([]);
const cols: Array<keyof IVatLoanItem> = [
    "Market",
    "DrawdownDate",
    "VatLoanAmount",
    "VatLoanPeriod",
];
const labels: string[] = [
    "Market",
    "Drawdown Date",
    "VAT Loan Amount",
    "VAT Loan Period",
];
const loanAmount = ref<string>();
const loanPeriod = ref<DatePeriod>();
const urlParams = process.env.VUE_APP_CONFIRM_DRAWDOWNS_URL_PARAMS;
const submitHint = "Submit the Vat Loan";

// ERRORS
const ConfirmDrawdownErrorInfo = ErrorInfo;
const showError = ref<boolean>(false);
const closeError = ref<boolean>(true);
const infoError = ref<AxiosError | undefined>(undefined);
const errorDescription = ref<string>("");
const errorText = ref<string>("");

// Warning
const ConfirmDrawdownWarningInfo = WarningInfo;
const showWarning = ref<boolean>(false);
const closeWarning = ref<boolean>(true);
const infoWarning = ref();
const warningDescription = ref<string>("");

const working = computed(() => {
    return isLoan.value && rows.value.length < 1;
});
const workingReason = computed(() => {
    return isLoan.value && rows.value.length < 1
        ? "When loan is selected, you must enter at least one entry before confirming"
        : "";
});

// Services
const DrawdownServices: DrawdownHelper = new DrawdownHelper();

function clearEntries() {
    loanPeriod.value = undefined;
    loanAmount.value = undefined;
    isLoan.value = false;
    showError.value = false;
    showWarning.value = false;
    rows.value = [];
}

function submit() {
    if (!loanPeriod.value || loanPeriod.value.toString() === "NULL") {
        useNotifierWithErrFormatter().warn({
            msg: "Please select loan amount and period",
        });
    } else {
        const newRow: IVatLoanItem = {
            Market: `${drawdown.value!.Market}`,
            DrawdownDate: drawdown.value
                .DrawdownDate!.toLocaleString("sv-SE")
                .split(" ")[0],
            VatLoanAmount: loanAmount.value!,
            VatLoanPeriod: loanPeriod.value!.toString(),
            UserEmail: authService.getEmailAddress(),
        };
        rows.value = [...rows.value, newRow];
        loanAmount.value = undefined;
        loanPeriod.value = new DatePeriod(null);
    }
}

function hasDuplicates() {
    const values = rows.value.map((obj) => obj["VatLoanPeriod"]);
    return new Set(values).size !== values.length;
}

async function ConfirmDrawdown() {
    drawdown.value!.VatLoans = rows.value ? rows.value : [];

    if (hasDuplicates()) {
        useNotifierWithErrFormatter().warn({
            msg: "Duplicate Vat Loan Period entries",
        });
    } else {
        if (working.value) return;
        const closeSpinner = useSpinnerPopup().show();
        try {
            await DrawdownServices.ConfirmDrawdown(urlParams!, drawdown.value);
            useNotifierWithErrFormatter().success({
                msg: "Payment successfully registered.",
            });
            closeChild();
            emits("confirm");
        } catch (error) {
            useNotifierWithErrFormatter().error({
                name: `${__filename}/${ConfirmDrawdown.name}`,
                errorType: "Confirming Drawdown",
                error,
            });
        } finally {
            closeSpinner();
        }
    }
}

function closeChild() {
    clearEntries();
    emits("update:modelValue");
}
</script>

<style scoped lang="scss"></style>
