import IVehicleTable from "@/Interfaces/CustomersAndContracts/IVehicleTable";
import { extractNonArraysFromObj } from '@/services/helpers/ObjectHelper';
import DateIgnoreTime from "@/types/DateIgnoreTime";

export default class VehicleTable implements IVehicleTable {
    CONTRACT_ID: string;
    ESTIMATED_DELIVERY_DATE: DateIgnoreTime;
    DELIVERY_DATE: DateIgnoreTime;
    REGISTRATION_DATE: DateIgnoreTime;
    RETURN_DATE: DateIgnoreTime;

    constructor(data: IVehicleTable) {
        Object.assign(this,extractNonArraysFromObj(data));
        this.CONTRACT_ID = data.CONTRACT_ID;
        this.ESTIMATED_DELIVERY_DATE=new DateIgnoreTime(data.ESTIMATED_DELIVERY_DATE);
        this.DELIVERY_DATE=new DateIgnoreTime(data.DELIVERY_DATE);
        this.REGISTRATION_DATE=new DateIgnoreTime(data.REGISTRATION_DATE);
        this.RETURN_DATE=new DateIgnoreTime(data.RETURN_DATE);

    }
  }
  