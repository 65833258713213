import axios, { AxiosResponse } from "axios";
import { NetworkService } from "../NetworkService";
import log from "loglevel";
import { Buffer } from "buffer";
import IPdfFileResponse from "@/Interfaces/IpdfFileResponse";
import IDownloadJournaledInvoiceBody from "@/Interfaces/IDownloadJournaledInvoiceBody";
import { ref } from "vue";
export enum MarketSelection {
    GB = 'UK',
    SE = 'SE'
}
export enum MarketCurrency {
    GB = 'GBP',
    SE = 'SEK'
}
export enum CurrencySymbol {
    GBP = '£',
    SEK = 'kr'
}
export function isMarketSupported(x: string): x is (keyof typeof MarketSelection){
    return !!MarketSelection[x];
}
export const defaultMarket: keyof typeof MarketSelection = 'GB';
export const marketSelection = ref<keyof typeof MarketSelection>(defaultMarket);
export const dateFormat = "yyyy/MM/dd";

export function GetStringDateFromDateRange(dateRange: Date[], index: 0 | 1): string {
    if (!!dateRange) {
        try {
            return dateRange![index].toLocaleString('sv-SE').split(" ")[0];
        } catch { return "" };
    }
    return ""
}

export function getIPAddress(): Promise<string> {
    log.trace(`getIPAddress()`);

    const url: string = process.env.VUE_APP_IP_ADDRESS_PROVIDER_URL!;

    return new Promise((resolve, reject) => {
        const response = axios
            .get(url)
            .then((res: AxiosResponse<any>) => {
                log.trace("getIPAddress()\nres.data:", res.data);
                resolve(res.data);
            }).catch((error: any) => {
                reject(error);
            });
    });
}

export var mocking = false;

export const financialSystemNetworkService = new NetworkService(
    mocking
        ? process.env.VUE_APP_MOCK_API_URL_NO_PARAMS!
        : process.env.VUE_APP_FINANCIAL_SYSTEM_API_URL_NO_PARAMS!
);
export const ticketingAdapterNetworkService = new NetworkService(
    mocking
        ? process.env.VUE_APP_MOCK_API_URL_NO_PARAMS!
        : process.env.VUE_APP_TICKETING_ADAPTER_API_URL_NO_PARAMS!
);

export const powerBiApiNetworkService = new NetworkService(
    process.env.VUE_APP_POWER_BI_GET_REPORTS_IN_GROUP_URL_PREFIX!,
    undefined,
    undefined,
    true
);

export const loggingApiNetworkService = new NetworkService(process.env.VUE_APP_LOGGING_API_URL_NO_PARAMS!);

export function sleep(ms: number) {
    return new Promise((resolve) => setTimeout(resolve, ms));
}

export const formatter = new Intl.NumberFormat("en-UK", {
    style: "currency",
    currency: "EUR",
    minimumFractionDigits: 2,
});

export function getKeyByValue<T extends object>(
    object: T,
    value: unknown
): keyof T | undefined {
    return (
        Object
            .keys(object)
            .find((key) => object[key] === value)
    ) as keyof T | undefined;
}

export async function GetPdfUrl(urlParams: string, pdfName?: string): Promise<string> {
    log.trace("GetPdfUrl()\nurlParams:", urlParams);

    const url: string = await GetByteArrayFromBase64String(urlParams)
        .then((byteArray: ArrayBuffer) => {
            // const file: Blob = new Blob([byteArray], { type: "application/pdf" });
            const file: File = new File([byteArray], pdfName ?? urlParams, { type: "application/pdf" });
            const url = URL.createObjectURL(file);
            log.debug("GetPdfUrl()\nPdf url", url);
            return url;
        })
        .catch((error: any) => {
            throw error;
        });

    return url;
}

export async function GetByteArrayFromBase64String(urlParams: string): Promise<ArrayBuffer> {
    log.trace("GetByteArrayFromBase64String()\nurlParams:", urlParams);

    const byteArray: ArrayBuffer = await GetPdfDataAsBase64String(urlParams)
        .then((base64String: string) => {
            log.debug("GetByteArrayFromBase64String()\nbase64String", base64String);
            const byteArray: ArrayBuffer = Buffer.from(base64String, "base64");
            log.debug("GetByteArrayFromBase64String()\nbyteArray:", byteArray);
            return byteArray;
        })
        .catch((error: any) => {
            log.warn("Error convering base64 string to byte array.")
            throw error;
        });

    return byteArray;
}

async function GetPdfDataAsBase64String(urlParams: string): Promise<string> {
    log.trace("GetPdfDataAsBase64String()\nurlParams:", urlParams);

    return new Promise((resolve, reject) => {
        financialSystemNetworkService
            .get(urlParams)
            .then((res: AxiosResponse<IPdfFileResponse>) => {
                log.debug("GetPdfDataAsBase64String()\nres.data", res.data);
                resolve(res.data.Data);
            })
            .catch((error: any) => {
                log.warn("Error getting base64 string")
                reject(error);
            });
    });
}

export async function GetPostPdfUrl(urlParams: string, body: IDownloadJournaledInvoiceBody, pdfName?: string): Promise<string> {
    log.trace("GetPdfUrl()\nurlParams:", urlParams);

    const url: string = await GetPostByteArrayFromBase64String(urlParams, body)
        .then((byteArray: ArrayBuffer) => {
            // const file: Blob = new Blob([byteArray], { type: "application/pdf" });
            const file: File = new File([byteArray], pdfName ?? urlParams, { type: "application/pdf" });
            const url = URL.createObjectURL(file);
            log.debug("GetPdfUrl()\nPdf url", url);
            return url;
        })
        .catch((error: any) => {
            throw error;
        });

    return url;
}

export async function GetPostByteArrayFromBase64String(urlParams: string, body: IDownloadJournaledInvoiceBody): Promise<ArrayBuffer> {
    log.trace("GetByteArrayFromBase64String()\nurlParams:", urlParams);

    const byteArray: ArrayBuffer = await GetPostPdfDataAsBase64String(urlParams, body)
        .then((base64String: string) => {
            log.debug("GetByteArrayFromBase64String()\nbase64String", base64String);
            const byteArray: ArrayBuffer = Buffer.from(base64String, "base64");
            log.debug("GetByteArrayFromBase64String()\nbyteArray:", byteArray);
            return byteArray;
        })
        .catch((error: any) => {
            log.warn("Error convering base64 string to byte array.")
            throw error;
        });

    return byteArray;
}

async function GetPostPdfDataAsBase64String(urlParams: string, body: IDownloadJournaledInvoiceBody): Promise<string> {
    log.trace("GetPdfDataAsBase64String()\nurlParams:", urlParams);

    return new Promise((resolve, reject) => {
        financialSystemNetworkService
            .post(body, urlParams)
            .then((res: AxiosResponse<IPdfFileResponse>) => {
                log.debug("GetInvoiceBillJournalsPdfData() res.data", res.data);
                resolve(res.data.Data);
            })
            .catch((error: any) => {
                reject(error);
            });
    });
}

export function GetClassAttributeNames<T>(classType: { new(data: T): any }): string[] {
    const instance = new classType({} as T);
    return Object.keys(instance) as string[];
}

export function toTitleCase(input: string, delimiter: string = " ", replace?: string, replaceWith: string = "", joinWith: string = " "): string {
    return input
        .replace(replace ?? "", replaceWith)
        .split(delimiter)
        .map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
        .join(joinWith);
}

export function pascalToSnake(pascalCaseString: string, toUpper: boolean = false): string {
    let snakeCaseString = '';
    for (let i = 0; i < pascalCaseString.length; i++) {
        const char = pascalCaseString[i];

        if (i > 0 && char === char.toUpperCase()) {
            snakeCaseString += '_' + char.toLowerCase();
        } else {
            snakeCaseString += char.toLowerCase();
        }
    }

    return toUpper ? snakeCaseString.toUpperCase() : snakeCaseString;
}

export function addUnderscoresToPascalCase(input: string): string {
    return input.replace(/(?<!^)([A-Z])/g, '_$1');
}

export const FormatDatePicker = (dates: Date[]) => {
    const name = FormatDatePicker.name;
    log.trace(`${name}()`);

    if (dates.length === 1) {
        return dates[0]
            .toLocaleString("sv-SE")
            .split(" ")[0]
            .replaceAll("-", "/");
    }
    if (dates.length === 2) {
        const startDateString: string = dates[0]
            .toLocaleString("sv-SE")
            .split(" ")[0]
            .replaceAll("-", "/");
        const endDateString: string = dates[1]
            .toLocaleString("sv-SE")
            .split(" ")[0]
            .replaceAll("-", "/");
        return startDateString + " - " + endDateString;
    } else {
        return "";
    }
};
export function MonetaryValueWithCommas(x?: number|string|null): string {
    return (x||x===0?+x:undefined)?.toFixed(2)?.replace(/\B(?=(\d{3})+(?!\d))/g, ',') ?? '';
}
export function generateBlobDownload(blob: Blob, name: string) {
    if(navigator["msSaveBlob"]) {
      navigator["msSaveBlob"](blob, name);
    } else {
        const link = document.createElement('a');
        if (link.download !== undefined) {
            // Browsers that support HTML5 download attribute
            const url = URL.createObjectURL(blob);
            link.setAttribute('href', url);
            link.setAttribute('download', name);
            link.style.visibility = 'hidden';
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            window.URL.revokeObjectURL(url);
        }
    }
}