<template>
    <div class="JournalingReport">
        <div v-if="isLoading" class="content-container">
            <div class="loading">
                <span class="loader"></span>
            </div>
        </div>
        <div v-else class="content-container">
            <div class="top-content">
                <GettingJournalReportErrorInfo
                    :showError="showJournalingReportError"
                    errorDescription="(Getting Journal Report)"
                    :error="infoError"
                />
                <ShowPdfErrorInfo
                    :showError="showPdfError"
                    :closeError="true"
                    errorDescription="(Downloading PDF)"
                    :error="infoError"
                    :change="showPdfErrorChanger"
                />
                <ShowBackoutErrorInfo
                    :showError="showBackoutError"
                    :closeError="true"
                    errorDescription="(Backout Journal)"
                    :error="infoError"
                    :change="showBackoutErrorChanger"
                />
            </div>
            <Table
                :Rows=displayedJournalingReport
                :Cols=cols
                :Labels=labels
                :HasError=!!infoError
            >
                <template #Status="props">
                    <div v-if="props.value.Status === 'SUCCESS'" class="highlight-green-bold">
                        {{ props.value.Status }}
                    </div>
                    <div v-if="props.value.Status === 'FAILURE'" class="failure">
                        {{ props.value.Status }}
                    </div>
                </template>
                <template #BackoutAvailable="props">
                    <div v-if="props.value.BackoutAvailable">
                        <button
                            @click="Backout(props.value)"
                            :ref="props.value.JournalReference"
                            :disabled="
                                backoutButtonStatuses[props.value.JournalReference] ||
                                DenyFunctionLevelAccess(FunctionAccessLevels.BackoutJNLReport)
                            "
                            :title="DenyFunctionLevelAccess(FunctionAccessLevels.BackoutJNLReport) ? `Backout ''${props.value.JournalReference}'', ${jobId} (Access denied)` : `Backout ''${props.value.JournalReference}'' (${jobId})`"
                            class="table-button"
                        >
                            Backout
                        </button>
                    </div>
                    <div v-else></div>
                </template>
                <template #ReportName="props">
                    <button
                        @click="viewFullPagePDF(props.value.ReportName,`Journaling`)"
                        :disabled="pdfButtonStatuses[props.value.ReportName]"
                        :title="
                            pdfButtonStatuses[props.value.ReportName]
                                ? ''
                                : 'View report pdf (opens file in new tab)'
                        "
                        class="fake-link"
                    >
                        {{ props.value.ReportName }}
                    </button>
                </template>
            </Table>
        </div>
    </div>
</template>

<script setup lang="ts">
import { ref, onMounted, inject } from "vue";
import { useRoute } from "vue-router";
import router from "@/router";
// Services
import { apiLoggerKey } from "@/types/ServiceKeys";
import { JournalingReportService } from "@/services/helpers/JournalingReportHelper";
import { FunctionAccessLevels, DenyFunctionLevelAccess } from "@/config/AuthConfig";
// Data
import IJournalReportItem from "@/interfaces/Journaling/IJournalingReportItem";
// Table
import Table from "@/components/TablePersistentChecking.vue";
// Misc
import log from "loglevel";
import { AxiosError, AxiosResponse } from "axios";
import ErrorInfo from "@/components/ErrorInfo.vue";
import { DisplayableKeys } from "@/services/SorterService";

log.debug("Journaling Report");
const fileName = "Journaling.vue";
const Logger = inject(apiLoggerKey);

////////////////////////////////////////////////////////////////////////////
// LOGIC
////////////////////////////////////////////////////////////////////////////
onMounted(async () => {
    log.trace("onMounted() JournalingReport");

    document.title = `Journaling (${jobId}) - Optio`;

    const name = onMounted.name;
    const Class = fileName + "/" + name;
    var Message = `Getting Journaling report (Job Id: ${jobId}, Journal type: ${journalType})`;

    isLoading.value = true;

    log.debug(`name()\n${Message}`);
    await jnlReportServices
        .GetJournalingReport(jobId, journalType)
        .then((data: IJournalReportItem[]) => {
            journalingReport.value = data;
            displayedJournalingReport.value = journalingReport.value.map(
                jnlReportServices.ParseItems
            );

            // Button Statuses
            ManagePdfDownloadUrlsAndButtonStatuses(data);

            isLoading.value = false;
            Logger?.LogInformation(Message, Class);
        })
        .catch((error: any) => {
            isLoading.value = false;

            if (error instanceof AxiosError) {
                showJournalingReportError.value = true;
                infoError.value = error
            }

            Message = `(Error) ${Message}: ${error instanceof AxiosError ? error : error.message}`;
            log.error(Message);
            Logger?.LogError(Message, Class);
        });
});

function ManagePdfDownloadUrlsAndButtonStatuses(data: IJournalReportItem[]) {
    log.trace("ManagePdfDownloadUrlsAndButtonStatuses()");

    /* Looping throuhgh table,
       instantiate backout button and pdf button statuses */
    data.forEach((item: IJournalReportItem) => {
        if (item.BackoutAvailable === true) {
            backoutButtonStatuses.value[item.JournalReference] = false;
            pdfButtonStatuses.value[item.ReportName] = false;
        }
    });
}

// ERRORS
const GettingJournalReportErrorInfo = ErrorInfo;
const showJournalingReportError = ref(false);
const ShowPdfErrorInfo = ErrorInfo;
const showPdfError = ref(false);
const showPdfErrorChanger = ref(true);
const ShowBackoutErrorInfo = ErrorInfo;
const showBackoutError = ref(false);
const showBackoutErrorChanger = ref(true);
const infoError = ref();

// TABLE
const isLoading = ref(true);
const backoutButtonStatuses = ref<Record<string, boolean>>({});
const pdfButtonStatuses = ref<Record<string, boolean>>({});

// ROUTE
const route = useRoute();
const routeParams = route.path.split("/").pop()!.split("-");

const jobId = routeParams[0];
const journalType = route.query.JournalType!.toString();

// REPORTS
const jnlReportServices: JournalingReportService = new JournalingReportService();

const journalingReport = ref<IJournalReportItem[] | undefined>();
const displayedJournalingReport = ref<IJournalReportItem[] | undefined>();

const cols: DisplayableKeys<IJournalReportItem>[] = [
    "IssueDate",
    "Status",
    "ReportName",
    "JournalReference",
    "BackoutAvailable",
];
const labels = ["Issue Date", "Status", "Report Name", "Journal Reference", "Action"];

async function Backout(journalReportItem: IJournalReportItem) {
    const name = Backout.name;
    var Message = `Backed out Journal (Journal Reference: ${journalReportItem.JournalReference}, Job Id: ${jobId}, Journal type: ${journalType})`;
    const Class = fileName + "/" + name;

    // Disable button
    backoutButtonStatuses.value[journalReportItem.JournalReference] = true;
    showBackoutError.value = false;
    showBackoutErrorChanger.value = !showBackoutErrorChanger.value;

    // Reload table
    isLoading.value = true;

    log.debug(`name()\n${Message}`);
    // Send backout (POST) request
    const code: number = await jnlReportServices
        .PerformBackoutJournal(journalReportItem, jobId, journalType)
        .then((res: AxiosResponse<any>) => {
            return res.status;
        })
        .catch((error: any) => {
            if (error instanceof AxiosError) {
                showBackoutError.value = true;
                showBackoutErrorChanger.value = !showBackoutErrorChanger.value;
                infoError.value = error;
            }
            
            Message = `(Error) ${Message}: ${error instanceof AxiosError ? error : error.message}`;
            log.error(Message);
            Logger?.LogError(Message, Class);

            // Return a default value in case of error
            return -1;
        });

    log.debug("Backout()\nstatusCode", code);
    if (code === 201) {
        Logger?.LogInformation(Message, Class);

        await jnlReportServices
            .GetJournalingReport(jobId, journalType)
            .then((data: IJournalReportItem[]) => {
                log.debug("Backout()\nJournaling report data after backout:\n", data);
                journalingReport.value = data;
                displayedJournalingReport.value = journalingReport.value.map(
                    jnlReportServices.ParseItems
                );

                // Button Statuses
                ManagePdfDownloadUrlsAndButtonStatuses(data);
            })
            .catch((error: any) => {
                if (error instanceof AxiosError) {
                    showJournalingReportError.value = true;
                    infoError.value = error;
                }

                Message = `(Error) ${Message}: ${error instanceof AxiosError ? error : error.message}`;
                log.error(Message);
                Logger?.LogError(Message, Class);
            });
    }
    isLoading.value = false;

    // Enable button
    backoutButtonStatuses.value[journalReportItem.JournalReference] = false;
}

// View full page pdf in new tab 
function viewFullPagePDF(fileID: string | undefined, fileType: string | undefined,) {
    log.trace("viewFullPagePDF()");
    log.debug(`"viewFullPagePDF()": fileID: ${fileID}, fileType: ${fileType}`);

    const rResolved = router.resolve({
        name: "Journaling Report (PDF)",
        params: {
            id: fileID,
        },
        query: {
            type: fileType,
            endpoint: process.env.VUE_APP_JOURNALING_REPORT_FILES_URL_PARAMS,
            method: "get",
        },
    });
    const url = rResolved.href;
    // Open in new tab
    window.open(url, "_blank");
}

// async function ViewPdf(reportName: string) {
//     log.trace("ViewPdf()\nreportName:", reportName);

//     const name = ViewPdf.name;
//     const Class = fileName + "/" + name;

//     // Disable button
//     pdfButtonStatuses.value[reportName] = true;
//     showPdfError.value = false;
//     showPdfErrorChanger.value = !showPdfErrorChanger.value;

//     // {{url}}/journaling-reports/files/{{reportName}}
//     const urlParams =
//         process.env.VUE_APP_JOURNALING_REPORT_FILES_URL_PARAMS! + "/" + reportName + "?";

//     // Get url (API call)
//     await GetPdfUrl(urlParams)
//         .then((url: string) => {
//             // Open url in new tab
//             window.open(url, "_blank");

//             const Message = `Viewed Journaling Report Pdf: (Report name: ${reportName}, Journal type: ${journalType}, Job Id: ${jobId}).`;
//             Logger?.LogInformation(Message, Class);
//         })
//         .catch((error: AxiosError) => {
//             showPdfError.value = true;
//             showPdfErrorChanger.value = !showPdfErrorChanger.value;
//             infoError.value = error;

//             const Message = `Error viewing Journaling Report Pdf (Report name: ${reportName}, Journal type: ${journalType}, Job Id: ${jobId}): ${error}`;
//             log.error(Message);
//             Logger?.LogError(Message, Class);
//         })
//         .catch((error: any) => {
//             const Message = `Error viewing Journaling Report Pdf (Report name: ${reportName}, Journal type: ${journalType}, Job Id: ${jobId}): ${error}`;
//             log.error(Message);
//             Logger?.LogError(Message, Class);
//         });

//     // Enable button
//     pdfButtonStatuses.value[reportName] = false;
// }
</script>