<template ref="ConfirmInterestPayments">
    <div class="content-container">
        <div class="top-content">
            <div class="flex-row-centered selection-form" style="align-items:flex-end;">
                <MarketSelector @change="getInterestPayments" enableSE />
                <div class="flex-col-centered selection">
                    <div class="center-buttons">
                        <button class="incident-nav-button"
                        @click="displayingForm = !displayingForm">
                        Record Interest
                    </button>
                    </div>
                </div>
            </div>
        </div>
        <div class="container">
            <div class=utility-bar>
                <div class=link-btn>
                    [<a @click=exportFilteredDisplayable>Export csv</a>]
                </div>
            </div>
            <TablePersistentChecking
            ref="table"
            id="interestPaymentTable"
            :Rows="displayedInterestPayments"
            :Cols="cols"
            :Labels="labels"
            :DefaultSortCols="defaultSortCols"
            :DefaultSortOrders="defaultSortOrders"
            >
            </TablePersistentChecking>
        </div>
    </div>
    <ConfirmInterestPaymentPopUp 
        v-model=displayingForm 
        @confirm="getInterestPayments"
    />
</template>

<script setup lang="ts">
import { ref, onMounted } from 'vue';
import { marketSelection } from "@/services/helpers/Helper";
import TablePersistentChecking, { ITableExpose } from '@/components/TablePersistentChecking.vue';
import MarketSelector from '@/components/MarketSelector.vue';
import ConfirmInterestPaymentPopUp from '@/components/Ledger/ConfirmInterestPaymentPopUp.vue';
import { interestPayments } from '@/services/network';
import useNotifierWithErrFormatter from '@/services/composables/NotifierWithErrFormatter';
import IInterestPayment from '@/Interfaces/Ledgering/IInterestPayment';
import DbTableCsvHelper from '@/services/helpers/DbTableCsvHelper';
import useNotifier from '@/services/composables/Notifier';

const displayingForm = ref(false);
const displayedInterestPayments = ref<IInterestPayment[]>();
const cols: (keyof IInterestPayment)[] = [
    'transactionId',
    'receivedAccountType',
    'valueDate',
    'amount',
    'market',
    'currency',
    'interestPeriod',
    'accumulatedAccountType'
]
const labels: string[] = [
    'Transaction ID',
    'Account Interest Received',
    'Value Date',
    'Amount',
    'Market',
    'Currency',
    'Interest Period',
    'Account Interest Accumulated'
]
const sortCol: keyof (IInterestPayment) = "interestPeriod";
const defaultSortCols = [cols.findIndex(col =>col === sortCol)];
const defaultSortOrders: (1|-1)[] = [-1];
const table = ref<ITableExpose<IInterestPayment>>();

onMounted(async () => {
    document.title='Confirm Interest Payments - Optio';
    marketSelection.value = 'GB';
    await getInterestPayments();
});

async function getInterestPayments() {
    try {
        displayedInterestPayments.value = undefined;
        const market = marketSelection.value;
        const res = await interestPayments.get({ market });
                displayedInterestPayments.value = res.data;
    } catch(error) {
        const name = __filename + "/" + getInterestPayments.name;
        const errorType = `Getting Interest Payment tables.`;
        useNotifierWithErrFormatter().error({
            name,
            errorType,
            error
        })
    }
}
function exportFilteredDisplayable() {
    const filtered = table.value?.GetFiltered() ?? [];
    if (filtered.length) {
        DbTableCsvHelper.generateCsvDownload(filtered, `InterestPayments.csv`);
    } else useNotifier().warn({ msg: 'There is nothing in the table to export.' });
}
</script>
<style scoped lang="scss">
@import "@/assets/styles/layout.scss";
@import "@/assets/styles/buttons.scss";
@import "@/assets/styles/table.scss";
.incident-nav-button {
    margin-bottom: 0px
}
.container {
    display: flex;
    flex-direction: column;
    flex: 1;
    overflow: auto;
}
.utility-bar {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0.1rem;
}
.utility-bar > .link-btn {
    margin-left: auto;
}
.link-btn > a {
    cursor: pointer;
    color: var(--theme-link-color);
    &:hover {
        text-decoration: underline;
        color: var(--theme-link-color-hover);
    }
}
</style>