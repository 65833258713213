import ICustomerPaymentTable from "@/Interfaces/CustomersAndContracts/ICustomerPaymentTable";
import { extractNonArraysFromObj } from '@/services/helpers/ObjectHelper';
import DateIgnoreTime from "@/types/DateIgnoreTime";

export default class CustomerPaymentTable implements ICustomerPaymentTable {
    CUSTOMER_INVOICE_ID: string;
    TRANSACTION_DATE: Date;

    constructor(data: ICustomerPaymentTable) {
        Object.assign(this,extractNonArraysFromObj(data));
        this.CUSTOMER_INVOICE_ID = data.CUSTOMER_INVOICE_ID;
        this.TRANSACTION_DATE = new DateIgnoreTime(data.TRANSACTION_DATE);
    }
}
