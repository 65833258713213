<template>
    <BaseContractPage ref="$root"
        :contractGetAPI=contractGetAPI
        :cols=cols
        contractName="" 
        hasCheckbox
        :noSingleDownload = "validationTypeVar !== 'HIRE_AGREEMENT'"
        :rowCheckableFilter="()=>true"
        :rowClickedCallback=onRowClicked 
        :showTableCount="true"
        >
        <template #header-extra v-if=validateAccess>
            <div class="flex-col-centered input-container" v-if=bulkOverrideAccess>
                <button class="default-btn"
                    type="button"
                    @click=onValidateClick
                    :title="`${$root?.anyChecked?'':'(Selected contracts with checkboxes first) '}Request the server to run checks on the selected contracts, even if they have been validated before.`"
                >
                    Validate Selected
                </button>
                <GroupedInputButton>
                    <button class="default-btn left-btn"
                        title="Override the validation results of selected to be valid"
                        @click=onForceValidClick
                        type="button"
                        :disabled=working >
                        Force Valid
                    </button>
                    <button title="Override the validation results of selected to be invalid"
                        class="default-btn"
                        @click=onForceInvalidClick
                        type="button"
                        :disabled=working >
                        Force Invalid
                    </button>
                </GroupedInputButton>
            </div>
        </template>
        <template v-for="k of boolCols" v-slot:[`${k}`]={value} >
            <TickCrossBooleanDisplay :value="(value[k] as boolean | undefined)" />
        </template>
        <template #validation="{value}">
            <OverallValidationBooleanDisplay :value=value.validation />
        </template>
        <template #manualOverride="{value}">
            <TickCrossBooleanDisplay :value=value.manualOverride>
                <template #true>Y</template>
                <template #false>N</template>
            </TickCrossBooleanDisplay>
        </template>
        <template #ValidationMessage={value} >
            <ExpandableText :maxLines=3>{{ value.ValidationMessage }}</ExpandableText>
        </template>
        <template #search-group-extra>
            <div class="flex-row-centered">
                <button class=default-btn
                    :title="`${$root?.anyChecked?'':'(Selected contracts with checkboxes first) '}Export data to a csv file.`"
                    @click=exportCSV
                    type="button">
                    Export csv
                </button>
                <button v-if="!bulkOverrideAccess && validateAccess"
                        class="default-btn"
                        type="button"
                        @click=onValidateClick
                        :title="`${$root?.anyChecked?'':'(Selected contracts with checkboxes first) '}Request the server to run checks on the selected contracts, even if they have been validated before.`"
                    >
                        Validate Selected
                </button>
            </div>
            <ValidationDataPopup v-model=detailPopupContractID 
                :hasOverride=singlularOverrideAccess 
                :validationType = validationTypeVar
                @override=onOverrideRequest
                @request-update="id=>$root?.updateContracts(id)" />
            <ContractValidationOverridePopup
                v-model=overrideContractIDs
                :validationType = validationTypeVar
                :setToValid=setToValid @override=onOverride />
        </template>
    </BaseContractPage>
</template>

<script setup lang='ts'>
import { ref, computed, watch } from 'vue';
import { useRoute } from 'vue-router';
// Components
import BaseContractPage from './BaseContractPage.vue';
import ValidationDataPopup from '@/components/Contracts/ValidationDataPopup.vue';
import ContractValidationOverridePopup from '@/components/Contracts/ContractValidationOverridePopup.vue';
import OverallValidationBooleanDisplay from '@/components/Contracts/OverallValidationBooleanDisplay.vue';
import TickCrossBooleanDisplay from '@/components/TickCrossBooleanDisplay.vue';
import ExpandableText from '@/components/ExpandableText.vue';
import GroupedInputButton from '@/components/GroupedInputButton.vue';
import useSpinnerPopup from '@/services/composables/SpinnerPopup';
// Misc
import IContractPageExpose  from '@/Interfaces/components/IContractPageExpose';
import {ContractValidationType,ContractValidationTypeKeys} from '@/models/enums/ContractValidationType';
import ContractWithValidations from '@/models/ContractWithValidations';
import { contractApprovalValidate } from '@/services/network';
import { contractValidations } from '@/services/network';
import { authService } from '@/auth/authSingleton';
import DateTimeFileName from '@/types/DateTimeFileName';
import DbTableCsvHelper from '@/services/helpers/DbTableCsvHelper';
import useContractValidation from '@/services/composables/ContractValidation';
import useNotifierWithErrFormatter from '@/services/composables/NotifierWithErrFormatter';
import { DenyFunctionLevelAccess, AuthorisationLevel } from '@/config/AuthConfig';

const route = useRoute();

const $root = ref<IContractPageExpose<ContractWithValidations>>();
const {
    getFlattened,
    getColKeys,
    getBoolKeys
} = useContractValidation();
const validateAccess = computed(()=>!DenyFunctionLevelAccess(AuthorisationLevel.Operator));
const bulkOverrideAccess = computed(()=>!DenyFunctionLevelAccess(AuthorisationLevel.Admin));
const singlularOverrideAccess = computed(()=>!DenyFunctionLevelAccess(AuthorisationLevel.Analyst));

// TABLE
const working = ref(false);
const validationTypeVar = computed(()=>
    route.params.type ?
        (route.params.type as string)?.toUpperCase() as (keyof typeof ContractValidationType) :
        ContractValidationTypeKeys[0]
);
// POPUP
const detailPopupContractID = ref<string>();
const overrideContractIDs = ref<string[]>();
const setToValid = ref(false);

const cols = computed(()=>['contractID', 'ownerID', ...getColKeys($root.value?.downloadedContracts)]);
const boolCols = computed(()=>getBoolKeys($root.value?.downloadedContracts));

async function contractGetAPI(req): Promise<(ContractWithValidations & Record<string, string | number | boolean | undefined>)[]> {
    req.validationType = validationTypeVar.value;
    const res = await contractValidations.post(req);
    const contracts = res.data.contracts.map(x=>new ContractWithValidations(x));
    const flattened = contracts.map(getFlattened);
    return flattened; 
}
async function onValidateClick() {
    if (working.value) return;
    working.value = true;
    const hideSpinner = useSpinnerPopup().show();
    try {
        if (!$root.value) throw new ReferenceError('$root not mounted.');
        const contractIDs = $root.value.getSelected();
        if (contractIDs.length<=0) return;
        const username = authService.getEmailAddress();
        const validationType = validationTypeVar.value;
        const res = await contractApprovalValidate.post({validationType}, {
            username,
            contractIDs,
        });
        useNotifierWithErrFormatter().success({
            msg: 'Successfully validated selected contracts',
        });
        $root.value.searchContracts();
    } catch(error) {
        await useNotifierWithErrFormatter().error({
            errorType: 'Validating Contracts',
            error
        });
    } finally {
        hideSpinner();
        working.value = false;
    }
}
function onRowClicked(contract: ContractWithValidations) {
    detailPopupContractID.value = contract.contractID;
}
function onForceValidClick() {
    onOverrideRequest($root.value?.getSelected(), true);
}
function onForceInvalidClick() {
    onOverrideRequest($root.value?.getSelected());
}
function onOverrideRequest(contractIDs?: string[], _setToValid?: boolean) {
    if (!contractIDs?.length) return;
    setToValid.value = _setToValid ?? false;
    overrideContractIDs.value = contractIDs;
}
function onOverride() {
    detailPopupContractID.value = undefined;
    $root.value?.searchContracts();
}
function exportCSV() {
    try {
        if (!$root.value) throw new ReferenceError('$root not mounted.');
        const contractIDs = $root.value.getSelected();
        if (contractIDs.length<=0) return;
        const selected = new Set(contractIDs);
        const data = $root.value?.downloadedContracts?.filter(x=>selected.has(x.contractID)) ?? [];
        const duplicate = data.map(x=>{
            const tmp = Object.assign({},x);
            tmp.validations = undefined;
            tmp.parsedTestData = undefined;
            tmp.testData = undefined as unknown as string;
            return tmp;
        });
        const dlTime = new DateTimeFileName();
        DbTableCsvHelper.generateCsvDownload(duplicate, `ContractValidations${dlTime}.csv`);
    } catch(error) {
        useNotifierWithErrFormatter().error({
            errorType: 'Exporting csv',
            error
        });
    }
}

watch(validationTypeVar, ()=> {
    if (!!$root.value) {
        $root.value.downloadedContracts = []
    }
} )


</script>
<style scoped lang="scss">
.default-btn.left-btn {
    background-color: var(--primary-color-light);
    &:hover {
        background-color: var(--primary-color-lighter);
    }
}
</style>