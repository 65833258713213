import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import { Log2API } from "@/services/Logger";
import useQueryParamPlugin from "vue-use-query-param";
import { apiLoggerKey, loggerKey } from "./types/ServiceKeys";
import log from "loglevel";
import provideNetworkSvcs from "./services/network/NetworkServiceProvider";

const Logger = new Log2API();

export function useAPILogger() {
        return Logger;
}

log.debug('env', process.env);
const app = createApp(App);
(await provideNetworkSvcs(app))
    .provide("log", log)
    .provide("Logger", Logger)
    .provide(loggerKey, log)
    .use(router)
    .use(useQueryParamPlugin, {})
    .mount("#app");