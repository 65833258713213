import IVatLoanRepayment from "@/Interfaces/Ledgering/IVatLoanRepayment";
import IVatLoanItem from "@/Interfaces/VATLoans/IVatLoan";
import DatePeriod from "@/types/DatePeriod";

export class VatLoan {
    Market?: string;
    DrawdownDate?: Date;
    VatLoanPeriod: DatePeriod;
    VatLoanAmount: number;
    UserEmail: string;
    Repayments?: IVatLoanRepayment[];
    RemainingBalance?: number;

    constructor(data: IVatLoanItem) {
        this.Market = data.Market;
        this.DrawdownDate = new Date(data.DrawdownDate);
        this.VatLoanPeriod = new DatePeriod(data.VatLoanPeriod);
        this.VatLoanAmount = parseFloat(data.VatLoanAmount);
        this.UserEmail = data.UserEmail;
        this.Repayments = data.Repayments;
        this.RemainingBalance = parseFloat(
            (this.VatLoanAmount - data.Repayments!.reduce(
              (sum, item) => sum + parseFloat(item.RepaymentAmount),
              0
            )).toFixed(2)
          );
    }
}