<template class="ConfirmedDrawdowns">
    <div v-if="isLoading" class="loading">
        <span class="loader"></span>
    </div>
    <div v-else class="content-container">
        <Notifier
            v-model="showingSuccNotifier"
            :type="NotifierType.SUCCESS"
            msg="VAT payment successfully registered."
        />
        <GettingErrorInfo
            :showError="!!infoError"
            errorDescription="(Getting Confirmed Drawdowns)"
            :error="infoError"
            :change="!!infoError"
        />
        <div class="top-content">
            <form class="form-container">
                <MarketSelector @change="updateMarketSelection" />
            </form>
        </div>

        <div class="table-container">
            <TablePersistentChecking
                ref="table"
                :Rows="rows"
                :Cols="cols"
                :HideTable="false"
                :HasError="!!infoError"
                :HasCheckBox="true"
                :SingleSelection="true"
                :CheckCallBack="updateCheckedRows"
                v-model:RowsPerPage="rowCount"
                SplitColKeys
            >
            </TablePersistentChecking>
            <div v-if="checkedRow" style="padding-top: 1rem">
                <label>
                    <h3 class="header">VAT Loans:</h3>
                </label>
                <TablePersistentChecking
                    :Rows="RowsLoans"
                    :Cols="colsLoans"
                    SplitColKeys
                >
                </TablePersistentChecking>
            </div>
        </div>
    </div>
</template>

<script setup lang="ts">
import { computed, onMounted, ref } from "vue";
import ErrorInfo from "@/components/ErrorInfo.vue";
import Notifier from "@/components/Notifier.vue";
import NotifierType from "@/types/NotifierType";
import { DrawdownTableItem } from "@/models/Drawdown";
import { DrawdownHelper } from "@/services/helpers/DrawdownHelper";
import TablePersistentChecking, {
    ITableExpose,
} from "@/components/TablePersistentChecking.vue";
import { VatLoan } from "@/models/VatLoan";
import MarketSelector from "@/components/MarketSelector.vue";
import DatePeriod from "@/types/DatePeriod";
import { marketSelection } from "@/services/helpers/Helper";

// ERRORS
const GettingErrorInfo = ErrorInfo;
const infoError = ref();

const isLoading = ref(false);
const showingSuccNotifier = ref(false);
const isLoan = ref<boolean>(false);
const isConfirmed = ref<boolean>(true);

// TABLE
const table = ref<ITableExpose<DrawdownTableItem>>();
const rows = ref<DrawdownTableItem[]>([]);
const rowCount = ref(50);
function getCols(confirmed?: boolean): Array<keyof DrawdownTableItem> {
    const baseColumns: Array<keyof DrawdownTableItem> = [
        "Market",
        "DrawdownDate",
        "DrawdownAmount",
    ];
    if (confirmed) {
        return [...baseColumns, "SumVatLoans", "UserEmail"];
    } else {
        return [...baseColumns, "UserEmail"];
    }
}
function getLoanCols(includeBalance?: boolean): Array<keyof VatLoan> {
    const baseLoanColumns: Array<keyof VatLoan> = [
        "VatLoanAmount",
        "VatLoanPeriod",
        "UserEmail"
    ];
    if (includeBalance) {
        return [...baseLoanColumns, "RemainingBalance"];
    }
    return baseLoanColumns;
}
const cols = computed(() => getCols(!isLoan.value));
const colsLoans = computed(() => getLoanCols());
const RowsLoans = ref<VatLoan[]>([]);
const urlParams = process.env.VUE_APP_GET_CONFIRMED_DRAWDOWNS_URL_PARAMS;

const checkedRow = ref<DrawdownTableItem>();

// Services
const DrawdownServices: DrawdownHelper = new DrawdownHelper();

onMounted(async () => {
    checkedRow.value = undefined;
    getConfirmedDrawdowns(urlParams!, marketSelection.value);
});

async function getConfirmedDrawdowns(urlParams: string, market: string) {
    try {
        const data = await DrawdownServices.getConfirmedDrawdowns(urlParams, market);
        rows.value = data
            .map((x) => new DrawdownTableItem(x))
            .filter((x) => x.Market === marketSelection.value);

        if (isConfirmed.value === true) {
            rows.value.forEach((obj) => {
                const sum = obj.VatLoans.reduce(
                    (total, loan) => total + parseFloat(loan.VatLoanAmount),
                    0
                );
                obj.SumVatLoans = parseFloat(sum.toFixed(2));
            });
        }
    } catch (error) {
        infoError.value = error;
    } finally {
        isLoading.value = false;
    }
}

function updateCheckedRows() {
    checkedRow.value = table.value?.GetChecked()[0];
    if (checkedRow.value) {
        getLoans(checkedRow.value);
    }
}

function updateMarketSelection() {
    checkedRow.value = undefined;
    getConfirmedDrawdowns(urlParams!, marketSelection.value);
}

async function getLoans(checkedRow: DrawdownTableItem) {
    RowsLoans.value = [];
    for (const i of checkedRow.VatLoans) {
        let loan: VatLoan = {
            VatLoanAmount: parseFloat(i.VatLoanAmount),
            VatLoanPeriod: new DatePeriod(i.VatLoanPeriod),
            UserEmail: i.UserEmail,
        };
        RowsLoans.value.push(loan);
    }
}
</script>

<style scoped lang="scss">
@import "@/assets/styles/layout.scss";
@import "@/assets/styles/buttons.scss";
</style>
