import { AxiosResponse } from "axios";
import { financialSystemNetworkService } from "@/services/helpers/Helper";
import log from "loglevel";
import IJournalReportsItem from '@/Interfaces/Journaling/IJournalingReportsItem';
import { IMetadata } from "@/Interfaces/IMetadata";
import { DisplayableKeys } from "../SorterService";
import DateTimeSv from "@/types/DateTimeSv";

export const cols: DisplayableKeys<IJournalReportsItem>[] = [
    "DateTime",
    "JournalType",
    "ExportType",
    "JobID",
    "Status",
    "User",
];
export const labels = [
    "Date",
    "Journal Type",
    "Export Type",
    "Job ID",
    "Status",
    "User",
];

export interface ICache {
    Data: IJournalReportsItem[];
    Metadata: IMetadata;
}

export function ParseItems(item: IJournalReportsItem) {
    log.trace(`ParseItems()`);

    const [year, month, day] = `${item.Date}`.split("-");
    const [hour, minute, second] = `${item.Time}`.split(":");
    
    // Convert Date and Time strings to Date objects
    item.DateTime = new DateTimeSv();
    item.DateTime.setFullYear(parseInt(year, 10));
    item.DateTime.setMonth(parseInt(month, 10)-1);
    item.DateTime.setDate(parseInt(day, 10));
    item.DateTime.setHours(parseInt(hour, 10));
    item.DateTime.setMinutes(parseInt(minute, 10));
    item.DateTime.setSeconds(parseInt(second, 10));

    // Convert JobID from string to integer
    item.JobID = parseInt(`${item.JobID}`);
    return item;
}

export class JournalingServices {
    public async getJournalingReports(urlParams: string): Promise<IJournalReportsItem[]> {
        log.trace(`getJournalingReports()\nurlParams: ${urlParams}`);
    
        return new Promise((resolve, reject) => {
            financialSystemNetworkService.get(urlParams)
                .then((res: AxiosResponse<IJournalReportsItem[]>) => {
                    const data = res.data;
                    log.trace("getJournalingReports()\nAPI journalingReports data", data);
                    resolve(data);
                })
                .catch((error: any) => {
                    reject(error);
                });
        });
    }
}

