<script setup lang="ts">
    import useMultiStateButton, {MultiStateButtonProps} from '@/services/composables/MultiStateButton';
    defineOptions({
        inheritAttrs: false
    });
    const props = defineProps<MultiStateButtonProps<string>>();
    const emits = defineEmits<{
        (e: 'update:modelValue', val: number)
    }>();
    const {
        display,
        toggleState
    } = useMultiStateButton(props, emits);
    </script>
    <template>
        <div class=stack >
            <button v-bind=$attrs type=button class=stack-item @click=toggleState >{{ display }}</button>
            <button v-for="x in states" v-bind=$attrs class="stack-item hidden" disabled>{{ x }}</button>
        </div>
    </template>
    <style lang="scss" scoped>
    .stack {
        display: grid;
        >.stack-item {
            grid-column: 1;
            grid-row: 1;
    
            border-radius: var(--table-button-radius);
            border-width: 1px;
            border-color: blue;
            color: blue;
            padding-left: 10px;
            padding-right: 10px;
            padding-top: 3px;
            padding-bottom: 3px;
            font-size: inherit;
    
            &:hover {
                background-color: var(--primary-color-lighter);
                cursor: pointer;
            }
        }
    }
    </style>