<template>
    <div :class="['expandable', { expand }]" >
        <div ref=content class="collapsible"><slot></slot></div>
        
        <ChevronDown v-if=overflow class="chevron" @click=onExpandClicked />
    </div>
</template>
<script setup lang="ts">
import {ref,computed,watch, onMounted, onUnmounted, nextTick} from 'vue';
import ChevronDown from './svg/ChevronDown.vue';

const props = withDefaults(defineProps<{
    modelValue?: boolean; //expanded
    /**
     * max number of lines to show before hiding content
     * and showing drop down button
     */
    maxLines?: number;
}>(), { modelValue: undefined, maxLines: 1 });
const emits = defineEmits<{
    (e: 'update:modelValue', val?: boolean): void,
}>();
const _expand = ref(props.modelValue);
const expand = computed({
    get() {
        return props.modelValue ?? _expand.value;
    },
    set(val) {
        emits('update:modelValue', val);
        _expand.value = val;
    }
});
const overflow = ref(false);
const content = ref<HTMLElement>();
const ro = new ResizeObserver(checkOverflow);
function checkOverflow() {
    setTimeout(()=>{
        if (content.value && !expand.value)
            {overflow.value = content.value.scrollWidth > content.value.clientWidth || content.value.scrollHeight > content.value.clientHeight;}
        },0);
}
function onExpandClicked(e: MouseEvent) {
    expand.value=!expand.value;
    e.stopPropagation();
}
watch(()=>props.modelValue, (val)=>{
    _expand.value=val
});
onMounted(()=>{
    checkOverflow();
    if (content.value) {
        ro.observe(content.value);
    } else {
        console.error('ExpandableText failed to assign content ref');
    }
});
onUnmounted(()=>{
    ro.disconnect();
});
</script>
<style lang="scss" scoped>
@import '@/assets/styles/variables.module.scss';
div.expandable {
    display: flex;
    white-space: pre-wrap;
    word-wrap: break-word;
    //max-height: v-bind("`${maxLines}lh`");
    column-gap: calc($normal-font-size/2);

    >.collapsible{
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: v-bind(maxLines);

        flex: 1 1 auto;
        overflow: hidden;
        width: max-content;
    }
    >.chevron {
        border: 1px solid black;
        flex: 0 0 auto;
        height: 1lh;
        cursor: pointer;
    }
    &.expand {
        max-height: unset;
        >.collapsible {
            -webkit-line-clamp: unset;
            overflow: auto;
            text-overflow: inherit;
        }
        >.chevron {
            transform: rotate(180deg);
        }
    }
}
</style>