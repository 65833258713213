export default class PropCaseHelper {
    public static toSnakeCase(str: string) {
        const pascal2Snake = /(?<!^|_)((?<=[a-z])[A-Z0-9]|[A-Z](?=[a-z]))/g;
        const startOfWords = /(?<=^|-)([a-z])/g;
        str = str.replace(pascal2Snake, '_$1').replace(startOfWords,v=>v.toUpperCase());
        return str;
    }
    public static toSentenceName(str: string) {
        return this.toSnakeCase(str).replace(/_/g,' ').replace(/-/g,' - ');
    }
    public static toCamelCase(input: string) {
        // Convert PascalCase to camelCase
        if (/^[a-zA-Z0-9]*$/.test(input)) {
            return input.charAt(0).toLowerCase() + input.slice(1);
        }
        // Convert snake_case and kebab-case to camelCase
        return input
            .toLowerCase() // Convert the entire string to lowercase
            .replace(/([-_][a-z0-9])/g, (match) => match.charAt(1).toUpperCase()) // Convert - or _ followed by a character to uppercase
            .replace(/^[A-Z]/, (match) => match.toLowerCase()); // Ensure the first character is lowercase
    }
    public static toCamelObj(x: unknown) {
        if (!x) return x;
        if (typeof x !== 'object') return x;
        if (x instanceof Array) return x.map(y=>this.toCamelObj(y));
        return Object.fromEntries(Object.entries(x).map(([k,v])=>[this.toCamelCase(k),this.toCamelObj(v)]));
    }
}