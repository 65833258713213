<script setup lang="ts" generic="T, P extends KeyTypeKeys<T>|undefined=undefined, C extends KeyTypeKeys<T>|undefined=undefined">
import { ref, computed, watch, Ref } from 'vue';
import { DisplayableKeys } from '@/services/SorterService';
import {ExpandState, labels} from '@/services/composables/TableExpandableColumns';
import TableExpandableTree from './TableExpandableTree.vue';
import TableExpandable from './TableExpandable.vue';
import MultiStateButton from './MultiStateButton.vue';
import Spinner from '../Spinner.vue';
import { KeyTypeKeys } from '../TablePersistentChecking.vue';
import { ComponentExposed } from 'vue-component-type-helpers';
/** static part of data */
export interface TableExTreeDefItem<T, P extends KeyTypeKeys<T>|undefined=KeyTypeKeys<T>, C extends KeyTypeKeys<T>|undefined=KeyTypeKeys<T>> {
    id?: string;
    title: string;
    cols: DisplayableKeys<T>[];
    expandedCols?: DisplayableKeys<T>[];
    idKey?: P;
    parentIdKey?: C;
    filterByDataOnly?: boolean;
    /** number of lines of gap between tables root */
    rowGap?: number;
    filter?: (x: T)=>boolean;
    filterDataUpdateCallback?: (x: T[NonNullable<P>][])=>void;
    children?: TableExTreeDefItem<any, any, any>[];
}
/** dynamic part */
export interface TableExTreeData<T> {
    rows?: T[];
    hasError?: boolean;
    children?: TableExTreeData<any>[];
}
interface TableExTreeItemWithFilterData<T, P extends KeyTypeKeys<T>|undefined, C extends KeyTypeKeys<T>|undefined> extends TableExTreeDefItem<T, P, C> {
    idKey: NonNullable<P>;
    children: TableExTreeDefItem<any>[];
}
const props = defineProps<{
    def: TableExTreeDefItem<T, P, C>;
    dat: TableExTreeData<T>;
    filterData?: T[NonNullable<C>][];
    hasExpandAllCols?: boolean;
}>();
const emits = defineEmits<{
    (e: 'update:modelValue', v?: ExpandState): void,
}>();
defineExpose<{
    getTotalExpandState(): ExpandState;
    expandAllCols(): void;
    collapseAllCols(): void;
}>({
    getTotalExpandState, expandAllCols, collapseAllCols
});
const children = ref<ComponentExposed<typeof TableExpandableTree>[]>();
const tableExpandState = ref<ExpandState>();
// must all be expanded to be considered expanded
const expandState = computed(()=>(
    (children.value?.some(x=>x.getTotalExpandState()===ExpandState.Collapsed)) ||
            tableExpandState.value===ExpandState.Collapsed) ?
                ExpandState.Collapsed :
                ExpandState.Expanded);
const filteredRows = ref(getFilteredRows()) as Ref<T[]|undefined>;
const tableHasFilterData = computed(()=>hasFilterData(props.def));
const tableHasCheckBox = computed(()=>tableHasFilterData.value&&!props.def.filterByDataOnly);
const treeGap = computed(()=>props.def.rowGap?`calc(10px + ${props.def.rowGap}lh)`:'10px');
function hasFilterData(def: TableExTreeDefItem<T,P,C>): def is TableExTreeItemWithFilterData<T,P,C> {
    return !(!def.children||!def.idKey||!def.children.some(x=>x.parentIdKey));
}
watch([()=>props.def.parentIdKey, ()=>props.filterData, ()=>props.dat.rows, ()=>props.def.filter], ([a,b,c,e],[aOld,bOld,cOld,eOld])=>{
    if (!(b?.length===0||bOld?.length===0)||!(c?.length===0||cOld?.length===0)||e!=eOld) updateFilteredRows();
});
const checkedFilterData = ref<T[NonNullable<P>][]>();
const filterDataForChildren = computed(()=>
    tableHasFilterData.value ? 
        tableHasCheckBox.value ?
            checkedFilterData.value :
            //@ts-expect-error def is TableExTreeItemWithFilterDat...
            filteredRows.value?.map(x=>x[props.def.idKey]) :
        undefined
);
function getFilteredRows() {
    let filtered = props.dat.rows;
    const parentIdKey = props.def.parentIdKey;
    if (parentIdKey) {
        const filterData = props.filterData;
        if (filterData) {
            filtered = filtered?.filter(x=>filterData.includes(x[parentIdKey]));
        }
    }
    if (props.def.filter) {
        filtered = filtered?.filter(props.def.filter);
    }
    return filtered;
}
function updateFilteredRows() {
    const backup = filteredRows.value;
    const rows = getFilteredRows();
    filteredRows.value = rows;
    const k = props.def.idKey;
    if (props.def.filterDataUpdateCallback && k) {
        const oldFilter = backup?.map(x=>x[k]).sort() ?? [];
        const newFilter = rows?.map(x=>x[k]).sort() ?? [];
        if (oldFilter.length !== newFilter.length || oldFilter.some((x,i)=>x!==newFilter[i])) {
            props.def.filterDataUpdateCallback(newFilter);
        }
    } 
}
function assignCheckedValues(checked: T[NonNullable<P>][]) {
    checkedFilterData.value = checked;
    props.def.filterDataUpdateCallback?.(checked);
}
function getChildDat(i: number) {
    const child = props.dat.children?.[i];
    if (props.dat.hasError) return {
        hasError: true,
        children: child?.children
    }
    const rows = child?.rows ? child.rows : props.dat.rows ? [] : undefined;
    return {
        rows,
        children: child?.children
    }
}
function getTotalExpandState() {
    return expandState.value;
}
function expandAllCols() {
    tableExpandState.value = ExpandState.Expanded;
    children.value?.forEach(x=>x.expandAllCols());
}
function collapseAllCols() {
    tableExpandState.value = ExpandState.Collapsed;
    children.value?.forEach(x=>x.collapseAllCols());
}
function expandButtonAction(val: unknown) {
    if (val === ExpandState.Expanded) expandAllCols();
    else collapseAllCols();
}
</script>
<template>
    <div class="tree-container">
        <template v-if=dat.rows||dat.hasError>
            <MultiStateButton v-if=hasExpandAllCols :model-value=expandState @update:model-value=expandButtonAction :states=labels style="font-size: 0.6rem" />
            <TableExpandable v-model=tableExpandState
                :id=def.id
                style="flex: 0"
                :rows=filteredRows
                :cols=def.cols
                :title=def.title
                :expanded-cols=def.expandedCols 
                :HasCheckBox=tableHasCheckBox
                :ColKey=def.idKey
                :CheckCallBack=tableHasCheckBox?assignCheckedValues:undefined
                :HasError=dat.hasError
                :ReturnKey=true
                DisableCheckPersistentAcrossRefresh
                CheckByDefault
                CheckIncludesNonDisplayed />
            <ul v-if=def.children class="tree">
                <li v-for="(child, i) in def.children">
                    <TableExpandableTree ref="children" :def=child :dat="getChildDat(i)" :filterData=filterDataForChildren />
                </li>
            </ul>
        </template>
        <Spinner v-else/>
    </div>
</template>
<style scoped lang="scss">
.tree-container {
    display: flex;
    flex-direction: column;
    flex: 1 1 100%;
    row-gap: 10px;
    margin-right: auto;
    align-items: flex-start;
    >.tree {
        display: flex;
        flex-direction: column;
        list-style-type: none;
        margin: 0;
        margin-top: v-bind("`${props.def.rowGap}lh`");
        row-gap: v-bind(treeGap);
    }
    :deep(.vtl-container>.vtl-table-wrapper>.vtl-table>tbody>tr.highlightable.vtl-checked) {
        background: inherit;
    }
    :deep(.vtl-container>.vtl-table-wrapper>.vtl-table>tbody>tr.striped:nth-child(odd)) {
        background-color: var(--light-grey);
    }
    :deep(.vtl-container>.vtl-table-wrapper>.vtl-table>tbody>tr.highlightable:hover),
    :deep(.vtl-container>.vtl-table-wrapper>.vtl-table>tbody>tr.striped:focus) {
        background: var(--primary-color-lighter);
    }
}
</style>