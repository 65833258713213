import ContractValidation from '@/models/ContractValidation';

export default function useContractValidation() {
    function getFlattened<T extends ContractValidation>(validation: T): T & Record<string, string | number | boolean | undefined> {
        return Object.assign({ ...validation },validation.parsedTestData);
    }
    function getColKeys(validations?: ContractValidation[]) {
        const base: (keyof ContractValidation)[] = ['validation','validationDateTime','manualOverride','performedBy'];
        if (validations && validations.length > 0) {
            const keys = new Set<string>();
            for (const v of validations) {
                if (v.parsedTestData) {
                    Object.keys(v.parsedTestData).forEach(k=>keys.add(k));
                }
            }
            // making sure it's last...
            keys.delete('ValidationMessage');
            keys.add('ValidationMessage');
            return [...base,...keys];
        }
        return base;
    }
    function getBoolKeys(validations?: ContractValidation[]) {
        const base: (keyof ContractValidation)[] = [];
        if (validations && validations.length > 0) {
            const keys = new Set<string>();
            for (const v of validations) {
                if (v.parsedTestData) {
                    Object.entries(v.parsedTestData).forEach(([k,v])=>
                        (typeof v === 'boolean') ? keys.add(k) : undefined
                    );
                }
            }
            return [...base,...keys];
        }
        return base;
    }
    return {
        getFlattened,
        getColKeys,
        getBoolKeys
    }
}