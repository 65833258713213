import IDisplayable from '@/types/IDisplayable';
export type DisplayableKeys<T, K extends keyof T=keyof T> = K extends any ? T[K] extends (undefined|null) ? never : T[K] extends (()=>string)|IDisplayable|string|number|boolean|null|undefined ? K : never : never;
export default class SorterService {
  static access<T>(item:T, key:keyof T) {
      const v = item[key];
      return (v instanceof Function) ? v.call(item) : v;
  }
  static isUndefinedOrNull(a: unknown) {
    return a === undefined || a === null;
  }
  static Compare(a: any, b: any, order: number): number {
      if (this.isUndefinedOrNull(a)) {
        if (!this.isUndefinedOrNull(b)) return 0;
        else return 1;
      }
      if (this.isUndefinedOrNull(b)) return -1;
      if (a < b) return -1 * order;
      else if (a > b) return 1 * order;
      else return 0;
  }
  static CompareObjectWithMultipleKeys<T>(a: T, b: T, keys: (keyof T)[], order: (-1|1)[]): number {
      for (const [i, key_s] of keys.entries()) {
        const tmp = this.Compare(this.access(a,key_s), this.access(b,key_s), order[i]);
        if (tmp != 0) return tmp;
      }
      return 0;
  }
}