import IRemarketingInvoiceTable from "@/Interfaces/CustomersAndContracts/IRemarketingInvoiceTable";
import { extractNonArraysFromObj } from '@/services/helpers/ObjectHelper';
import DateIgnoreTime from "@/types/DateIgnoreTime";

export default class RemarketingInvoiceTable implements IRemarketingInvoiceTable {
    INVOICE_ID: string;
    ISSUE_DATE: DateIgnoreTime;
    DUE_DATE: DateIgnoreTime;
    BALANCE_DATE_UPDATED: DateIgnoreTime;
    COLLECTION_BALANCE_DATE_UPDATED: DateIgnoreTime;
    constructor(data: IRemarketingInvoiceTable) {
        Object.assign(this,extractNonArraysFromObj(data));
        this.INVOICE_ID = data.INVOICE_ID;
        this.ISSUE_DATE=new DateIgnoreTime(data.ISSUE_DATE);
        this.DUE_DATE=new DateIgnoreTime(data.DUE_DATE);
        this.BALANCE_DATE_UPDATED=new DateIgnoreTime(data.BALANCE_DATE_UPDATED);
        this.COLLECTION_BALANCE_DATE_UPDATED=new DateIgnoreTime(data.COLLECTION_BALANCE_DATE_UPDATED);
    }
}
