<template>
    <div id="VATPayments">
        <LedgerTransactionForm ref=createForm v-model=showingPostForm @submit=onPostSuccess />
        <div class="content-container">
            <div class="top-content">
                <div class="flex-row-centered selection-form" style="align-items:flex-end;">
                    <MarketSelector @change=onSelectorUpdate enableSE />
                    <PeriodSelector v-model=period 
                        showTitle
                        hasNullSelector
                        @update:model-value=onSelectorUpdate />
                    <div class="flex-col-centered selection">
                        <button class="centre-button"
                            style="font-size: 1.8rem; padding: 0;"
                            type="button" 
                            @click="showingPostForm = true">
                            +
                        </button>
                    </div>
                    <div class="flex-col-centered selection">
                        <button class="centre-button" 
                            type="button" 
                            @click=exportCsv >
                            Export Full Table
                        </button>
                    </div>
                </div>
            </div>
            <Table ref="table"
                :Rows=rows
                :Cols=cols
                :NumRecords="cache.getTotal()"
                :LoadDataCallBack=loadDataAsync
                :RowClickedCallBack=showCreateFormAndAssignTemplate
                :HasError=!!errGet
                ShowHeaderWhileLoading
                SplitColKeys >
                <template #valueDate=props>
                    <NoWrapText :val="props.value.valueDate"/>
                </template>
                <template #targetDate=props>
                    <NoWrapText :val="props.value.targetDate"/>
                </template>
                <template #categoryPeriod=props>
                    <NoWrapText :val="props.value.categoryPeriod"/>
                </template>
            </Table>
        </div>
    </div>
</template>
<script setup lang="ts">
import { ref, onMounted } from 'vue';
import { marketSelection as market } from "@/services/helpers/Helper";
import PeriodSelector from '@/components/PeriodSelector.vue';
import NoWrapText from '@/components/NoWrapText.vue';
import LedgerTransaction from '@/models/Ledgering/LedgerTransaction';
import CancellablePromise, {PromiseCancelledError} from '@/types/CancellablePromise';
import Table, { ITableExpose } from '@/components/TablePersistentChecking.vue';
import ILedgerTransactions from '@/Interfaces/Ledgering/ILedgerTransactions';
import DatePeriod from '@/types/DatePeriod';
import PaginatedCache from '@/types/PaginatedCache';
import MarketSelector from '@/components/MarketSelector.vue';
import LedgerTransactionForm from '@/components/Ledger/LedgerTransactionForm.vue';
import { ledgerTransactions } from '@/services/network';
import useSpinnerPopup from '@/services/composables/SpinnerPopup';
import DbTableCsvHelper from '@/services/helpers/DbTableCsvHelper';
import DateTimeFileName from '@/types/DateTimeFileName';
import useNotifierWithErrFormatter from '@/services/composables/NotifierWithErrFormatter';
import IFilter from '@/Interfaces/IFilter';

type Filter = IFilter<LedgerTransaction>;

const showingPostForm = ref(false);
const errGet = ref();
const errDownload = ref();
const period = ref<DatePeriod>(new DatePeriod(null));
const table = ref<ITableExpose<LedgerTransaction>>();
const createForm = ref<InstanceType<typeof LedgerTransactionForm>>();
const rows = ref<LedgerTransaction[]>([]);
const cache = new PaginatedCache(loadPaginatedData);

onMounted(() => {document.title='Ledger Transactions - Optio'});
const cols: (keyof LedgerTransaction)[] = [
    'paymentId',
    'transactionId',
    'accountId',
    'valueDate',
    'targetDate',
    'amount',
    'market',
    'currency',
    'paymentAdviceReference',
    'paymentReference',
    'transferTypeId',
    'itemType',
    'itemCategory',
    'categoryPeriod',
    'invoiceId',
    'creditNoteId',
    'contractId',
    'assignedTo',
    'transferInfo',
];
let lastSort = [] as number[];
let lastOrder = [] as (-1|1)[];
let lastFilter: Filter = {};
// need to move following code to a composable
function onChangeFilter() {
    cache.reset();
    table.value?.LoadData();
}
async function loadPaginatedData(offset: number, count: number, bookmark?: number) {
    const sort = lastSort.map(x=>cols[x]);
    const res = await ledgerTransactions.get({
        market: market.value
    }, {
        sort: sort.length > 0 ? sort : undefined,
        order: sort.length > 0 ? lastOrder : undefined,
        offset,
        count,
        period: period.value.isValid()? period.value : undefined,
        bookmark,
        ...lastFilter
    });
    const data = res.data as ILedgerTransactions;
    return {
        data: data.transactions.map(t=>new LedgerTransaction(t)),
        bookmark: data.bookmark,
        totalCount: data.totalCount,
    }
}
function getSortedEntries(obj: NonNullable<unknown>) {
    return Object.entries(obj).sort(([k1,v1],[k2,v2])=>k1.localeCompare(k2));
}
async function loadOrResetCache(offset: number, count: number, sort: number[] = [], order: (-1|1)[] = [], filter?: Filter) {
    filter = filter ?? {}
    if (JSON.stringify(lastSort) !== JSON.stringify(sort) ||
        JSON.stringify(lastOrder) !== JSON.stringify(order) ||
        JSON.stringify(getSortedEntries(filter)) !== JSON.stringify(getSortedEntries(lastFilter))
    ) {
        cache.reset();
        lastSort = Array.from(sort);
        lastOrder = Array.from(order);
        lastFilter = Object.assign({}, filter);
    }
    return await cache.load(offset, count);
}
function loadDataAsync(offset: number, count: number, sort: number[] = [], order: (-1|1)[] = [], filter?: Filter) {
    errGet.value = undefined;
    const promise = loadOrResetCache(offset,count,sort,order,filter);
    return new CancellablePromise(promise).then(
        (res)=>rows.value = res,
        (error)=> {
            if (!(error instanceof PromiseCancelledError)) {
                errGet.value = error;
                useNotifierWithErrFormatter().error({
                    errorType: 'Getting Ledger Transactions',
                    error
                })
            }
        }
    ) as CancellablePromise;
}
function onPostSuccess() {
    onChangeFilter();
}
async function exportCsv() {
    const closeSpinner = useSpinnerPopup().show();
    try {
        errDownload.value = undefined;
        const task = ledgerTransactions.get();
        const dlTime = new DateTimeFileName();
        const res = await task;
        DbTableCsvHelper.generateCsvDownload(res.data.transactions, `LedgerTransactions${dlTime}.csv`);
    } catch(error) {
        useNotifierWithErrFormatter().error({
            errorType: 'Downloading Ledger Transaction Table',
            error
        });
    }
    closeSpinner();
}
function showCreateFormAndAssignTemplate(x: LedgerTransaction) {
    createForm.value?.assignTemplate(x);
    showingPostForm.value = true;
}
function onSelectorUpdate() {
    onChangeFilter();
}
</script>
<style scoped lang="scss">
@import "@/assets/styles/centre-button.scss";
</style>