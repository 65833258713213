export default class DeferredPromise<T> extends Promise<T>{
    private _resolve: ((v: T | PromiseLike<T>) => void);
    private _reject: (reason?: any) => void;

    constructor() {
        var _resolve;
        var _reject;
        super((resolve,reject)=>{
            _resolve = resolve;
            _reject = reject;
        });
        this._resolve = _resolve;
        this._reject = _reject;
    }
    Resolve(v: T | PromiseLike<T>) {
        this._resolve(v);
    }
    Reject(reason: unknown){
        this._reject(reason);
    }
    // make sure the calls for constructor in other member functions is calling the parent class's
    static get [Symbol.species]() {
        return Promise;
    }
    get [Symbol.toStringTag]() {
        return 'DeferredPromise';
    }
}