<template>
    <div class="FeesConfiguration">
        <div class="center-dropdown-table-container">
            <div class="center-dropdown-table" style="margin-top: 0px;">
                <tbody>
                    <tr>
                        <td>
                            <div class="center-buttons bottom-margin-only">
                                <button
                                    :class="`tab-button-left ${showServiceFees ? 'selected' : 'unselected'}`"
                                    style="width: 100%;"
                                    :disabled="showServiceFees"
                                    :title="showServiceFees ? 'Currently viewing Service Fees' : 'Click to view Service Fees'"
                                    @click="ToggleServiceFees()"
                                >
                                    Service Fees
                                </button>
                            </div>
                        </td>
                        <td>
                            <div class="center-buttons bottom-margin-only">
                                <button
                                    :class="`tab-button-right ${showServiceFees ? 'unselected' : 'selected'}`"
                                    style="width: 100%;"
                                    :disabled="!showServiceFees"
                                    :title="!showServiceFees ? 'Currently viewing Financing Fees' : 'Click to view Financing Fees'"
                                    @click="ToggleServiceFees()"
                                >
                                    Financing Fees
                                </button>
                            </div>
                        </td>
                    </tr>
                </tbody>
            </div>
        </div>
        <div class="content-container">
            <Teleport to="#app">
                <div v-if=showFeesPopup
                    @click=ClosePopup
                    title="Click outside of popup to close source file."
                    class="overlay"
                >
                    <form @click.stop class="popup" @submit.prevent="feesItemBeingEdited?PostEditFees():CreateFees()" >
                        <div class="description-title">
                            <div>{{ popupAction }} {{ feesTitle }}:</div>
                        </div>
                        <div class="description-subtitle">
                            <label for=inputMarket>Market:</label>
                            <label for=inputDateValid>Date Valid From:</label>
                                <input id=inputMarket
                                    ref=inputMarket
                                    class="col2"
                                    type="text"
                                    :title=marketTitle
                                    :value="feesEditedTo.Market"
                                    disabled
                                    required
                                >
                                <input v-if=feesItemBeingEdited
                                    id=inputDateValid
                                    ref=inputDateValid
                                    class="col2"
                                    type="text"
                                    :title=dateTitle
                                    :value="`${feesEditedTo.DateValidFrom}`"
                                    style="color: var(--text-color);"
                                    disabled
                                >
                                <input v-else 
                                    type="date"
                                    v-model="feesEditedTo.DateValidFrom"
                                    id=inputDateValid
                                    ref=inputDateValid
                                    class="col2 invalid-input"
                                    :title=dateTitle
                                    required
                                >
                                <template v-if=isEditingServiceFee(feesEditedTo)>
                                    <label for=inputOutsourcing>Outsourcing Fee:</label>
                                    <label for=inputRemarketing>Remarketing Fee:</label>
                                    <CurrencyInput id="inputOutsourcing"
                                        ref=inputOutsourcing
                                        v-model=feesEditedTo.OutsourcingFee
                                        class="col2 invalid-input"
                                        :currency=MarketCurrency[marketSelection]
                                        :title=fee1Title
                                        :placeholder="feesItemBeingEdited&&isServiceFee(feesItemBeingEdited) ?
                                            `${feesItemBeingEdited.OutsourcingFee}`:
                                            'Outsourcing Fee'"
                                        required
                                    />
                                    <CurrencyInput id="inputRemarketing"
                                        ref=inputRemarketing
                                        v-model=feesEditedTo.RemarketingFee
                                        class="col2 invalid-input"
                                        :currency=MarketCurrency[marketSelection]
                                        :title=fee2Title
                                        :placeholder="feesItemBeingEdited&&isServiceFee(feesItemBeingEdited) ?
                                            `${feesItemBeingEdited.RemarketingFee}`:
                                            'Remarketing Fee'"
                                        required
                                    />
                                </template>
                                <template v-else>
                                    <label for=inputFinancingFeePercentage>Financing Fee Percentage:</label>
                                    <label for=inputFinancingFeeFixed>Financing Fee Fixed:</label>
                                    <label for=inputLLMPerc>LLM Percentage:</label>
                                    <label for=inputIntermediationFeePercentage>Intermediation Fee Percentage:</label>
                                    <label for=inputAmcrPercentage>AMCR Percentage:</label>
                                    <label for=inputVatFinancePeriodMonths>VAT Finance Period Months:</label>

                                    <input id="inputFinancingFeePercentage"
                                        ref=inputFinancingFeePercentage
                                        v-model=feesEditedTo.FinancingFeePercentage
                                        class=invalid-input
                                        type=number
                                        :title=fee1Title
                                        :placeholder="feesItemBeingEdited&&!isServiceFee(feesItemBeingEdited) ?
                                            `${feesItemBeingEdited.FinancingFeePercentage}`:
                                            'Financing Fee Percentage'"
                                        required
                                        min=0
                                        step=any
                                    />
                                    <CurrencyInput id="inputFinancingFeeFixed"
                                        ref=inputFinancingFeeFixed
                                        v-model=feesEditedTo.FinancingFeeFixed
                                        class="col2 invalid-input"
                                        :currency=MarketCurrency[marketSelection]
                                        :title="fee2Title"
                                        :placeholder="feesItemBeingEdited&&!isServiceFee(feesItemBeingEdited) ?
                                            `${feesItemBeingEdited.FinancingFeeFixed}`:
                                            'Financing Fee Fixed'"
                                        required
                                    />
                                    <input id="inputLLMPerc"
                                        ref=inputLLMPerc
                                        v-model=feesEditedTo.LlmPercentage
                                        type="number"
                                        class="col2 invalid-input"
                                        :title="fee3Title"
                                        :placeholder="feesItemBeingEdited&&!isServiceFee(feesItemBeingEdited) ?
                                            `${feesItemBeingEdited.LlmPercentage}`:
                                            'Llm Percentage'"
                                        required
                                        min=0
                                        step=any
                                    >
                                    <input id=inputIntermediationFeePercentage
                                        ref=inputIntermediationFeePercentage
                                        v-model=feesEditedTo.IntermediationFeePercentage
                                        type=number
                                        class="col2 invalid-input"
                                        :title=fee4Title
                                        :placeholder="feesItemBeingEdited&&!isServiceFee(feesItemBeingEdited) ?
                                            `${feesItemBeingEdited.IntermediationFeePercentage}`:
                                            'Intermediation Fee Percentage'"
                                        required
                                        min=0
                                        step=any
                                    >
                                    <input id="inputAmcrPercentage"
                                        ref=inputAmcrPercentage
                                        v-model=feesEditedTo.AmcrPercentage
                                        type="number"
                                        class="col2 invalid-input"
                                        :title=fee6Title
                                        :placeholder="feesItemBeingEdited&&!isServiceFee(feesItemBeingEdited) ?
                                            `${feesItemBeingEdited.AmcrPercentage}`:
                                            'Amcr Percentage'"
                                        required
                                        min=0
                                        step=any
                                    >
                                    <input id=inputVatFinancePeriodMonths
                                        ref=inputVatFinancePeriodMonths
                                        type=number
                                        v-model=feesEditedTo.VatFinancePeriodMonths
                                        class="col2 invalid-input"
                                        :title=fee5Title
                                        :placeholder="feesItemBeingEdited&&!isServiceFee(feesItemBeingEdited) ?
                                            `${feesItemBeingEdited.VatFinancePeriodMonths}`:
                                            'Vat Finance Period Months'"
                                        required
                                        min=0
                                        max=3000
                                    >
                                </template>
                        </div>
                        <div class="button-group" >
                            <button class="confirm-button"
                                :title="confirmEditTitle"
                            >
                                Confirm {{ popupAction }}
                            </button>
                            <button v-if=feesItemBeingEdited
                                type="button"
                                class="delete-button"
                                @click=DeleteFees
                                :title="`Delete ${feesTitle}`"
                            >
                                Delete
                            </button>
                        </div>
                    </form>
                    <div style="text-align: center">
                        <button
                            class="popup-button"
                            @click="ClosePopup()"
                            title="Close source file. (You can also click outside the popup to close source file.)"
                        >
                            Close
                        </button>
                    </div>
                </div>
            </Teleport>
            <div class="top-content">
                <div class="flex-row-centered">
                    <MarketSelector @change="updateSelection" enableSE />
                    <button
                        class="centre-button center-buttons-button-disabled"
                        style="width: 10rem; font-size: 1.8rem; padding: 0;"
                        :title="DenyFunctionLevelAccess(FunctionAccessLevels.WriteFeesConfig) ? `Add ${showServiceFees ? 'Service' : 'Financing'} Fee item (Access Denied)` : `Add ${showServiceFees ? 'Service' : 'Financing'} Fee item`"
                        @click="ShowCreateFeesPopup()"
                        :disabled="DenyFunctionLevelAccess(FunctionAccessLevels.WriteFeesConfig)"
                    >
                        +
                    </button>
                </div>
            </div>
            <Table v-if=showServiceFees 
                :Rows=displayedServiceFees
                :Cols=serviceFeesCols
                :Labels=serviceFeesLabels
                :DefaultSortCols=defaultSortCols
                :DefaultSortOrders=defaultSortOrders
                :HasError=!!getServiceFeesError >
                <template #Updateable={value}>
                    <button v-if=value.Updateable
                        @click="EditFees(value)"
                        class="table-button"
                        :title=serviceTitle
                    >
                        Edit
                    </button>
                    <div v-else></div>
                </template>
            </Table>
            <Table v-else
                :Rows=displayedFinancingFees
                :Cols=financingFeesCols
                :Labels=financingFeesLabels
                :DefaultSortCols=defaultSortCols
                :DefaultSortOrders=defaultSortOrders
                :HasError=!!getFinancingFeesError >
                <template #Updateable="props">
                    <button v-if="props.value.Updateable"
                        @click="EditFees(props.value)"
                        class="table-button"
                        :title="financingTitle"
                    >
                        Edit
                    </button>
                    <div v-else></div>
                </template>
            </Table>
        </div>
    </div>
</template>

<script setup lang="ts">
import { ref, onMounted, watch, computed, onUnmounted } from "vue";
import router from "@/router";
import { useRoute } from "vue-router";
// Services
import { authService } from "@/auth/authSingleton";
import { FunctionAccessLevels, DenyFunctionLevelAccess } from "@/config/AuthConfig";
import { serviceFees, financingFees } from "@/services/network";
// Data
import ServiceFees from "@/models/ServiceFees";
import FinancingFees from "@/models/FinancingFees";
import { marketSelection, MarketCurrency } from "@/services/helpers/Helper";
// Components
import useNotifierWithErrFormatter from "@/services/composables/NotifierWithErrFormatter";
import Table from '@/components/TablePersistentChecking.vue';
import { DisplayableKeys } from "@/services/SorterService";
import CurrencyInput from "@/components/CurrencyInput.vue";
// Misc
import log from "loglevel";
import { RouteLocationCarsly } from "@/router/RouteDetails";
import DateIgnoreTime from "@/types/DateIgnoreTime";
import MarketSelector from "@/components/MarketSelector.vue";

class NoChangeError extends Error {}

// ERROR
const getServiceFeesError = ref();
const getFinancingFeesError = ref();

const showServiceFees = ref(true);

// Table
const serviceFeesColsBase: DisplayableKeys<ServiceFees>[] = [
    "Market",
    "DateValidFrom",
	"OutsourcingFee",
	"RemarketingFee",
	"User",
	"Timestamp",
];
const serviceFeesCols = serviceFeesColsBase.concat(DenyFunctionLevelAccess(FunctionAccessLevels.WriteFeesConfig)?[]:['Updateable']);
const financingFeesColsBase: DisplayableKeys<FinancingFees>[] = [
    "Market",
    "DateValidFrom",
	"FinancingFeePercentage",
	"FinancingFeeFixed",
	"LlmPercentage",
	"IntermediationFeePercentage",
	"AmcrPercentage",
	"VatFinancePeriodMonths",
	"User",
	"Timestamp",
];
const sortCol: keyof (ServiceFees|FinancingFees) = "DateValidFrom";
const defaultSortCols = [serviceFeesCols.findIndex(col =>col === sortCol)];
const defaultSortOrders: (1|-1)[] = [-1];

const serviceFeesLabels = [
    "Market",
    "Date Valid From",
	"Outsorcing Fee",
	"Remarketing Fee",
	"User",
	"Timestamp",
	"Edit"
];
const financingFeesLabels = [
    "Market",
    "Date Valid From",
	"Financing Fee Percentage",
	"Financing Fee Fixed",
	"LLM Percentage",
	"Intermediation Fee Percentage",
	"AMCR Percentage",
	"VAT Finance Period Months",
	"User",
	"Timestamp",
	"Edit"
];

const financingFeesCols = financingFeesColsBase.concat(DenyFunctionLevelAccess(FunctionAccessLevels.WriteFeesConfig)?[]:['Updateable']);
const serviceAllowTitle = "Edit Service Fees item";
const financingAllowTitle = "Edit Financing Fees item";
const denyText = ' (Access denied)';
const serviceTitle = `${serviceAllowTitle}${DenyFunctionLevelAccess(FunctionAccessLevels.WriteFeesConfig)?denyText:''}`;
const financingTitle = `${financingAllowTitle}${DenyFunctionLevelAccess(FunctionAccessLevels.WriteFeesConfig)?denyText:''}`;
//const tableTitle = computed(()=>showServiceFees.value ? serviceTitle : financingTitle);
const displayedServiceFees = ref<ServiceFees[]>();
const displayedFinancingFees = ref<FinancingFees[]>();

const greenHighlight = ref(false);

// Edit Popup states
const showFeesPopup = ref(false);
const feesItemBeingEdited = ref<ServiceFees | FinancingFees>();
const feesEditedTo = ref<Partial<ServiceFees>|Partial<FinancingFees>>(getDefaultEditFees());

//Edit Buttons
const inputMarket = ref<HTMLInputElement>();
const inputDateValid = ref<HTMLInputElement>();
const inputOutsourcing = ref<InstanceType<typeof CurrencyInput>>();
const inputRemarketing = ref<InstanceType<typeof CurrencyInput>>();
const inputFinancingFeePercentage = ref<HTMLInputElement>();
const inputFinancingFeeFixed = ref<InstanceType<typeof CurrencyInput>>();
const inputLLMPerc = ref<HTMLInputElement>();
const inputIntermediationFeePercentage = ref<HTMLInputElement>();
const inputAmcrPercentage = ref<HTMLInputElement>();
const inputVatFinancePeriodMonths = ref<HTMLInputElement>();

//Edit Titles
const inputOutsourcingValid = computed(()=>inputOutsourcing.value?.isValid() ?? false);
const inputRemarketingValid = computed(()=>inputRemarketing.value?.isValid() ?? false);
const inputFinancingFeePercentageValid = computed(()=>inputFinancingFeePercentage.value?.validity?.valid ?? false);
const inputFinancingFeeFixedValid = computed(()=>inputFinancingFeeFixed.value?.isValid() ?? false);
const inputLLMPercValid = computed(()=>inputLLMPerc.value?.validity?.valid ?? false);
const inputIntermediationFeePercentageValid = computed(()=>inputIntermediationFeePercentage.value?.validity?.valid ?? false);
const inputAmcrPercentageValid = computed(()=>inputAmcrPercentage.value?.validity?.valid ?? false);
const inputVatFinancePeriodMonthsValid = computed(()=>inputVatFinancePeriodMonths.value?.validity?.valid ?? false);

const feesTitle = computed(()=>showServiceFees.value ? 'Service Fees' : 'Financing Fees');
const popupAction= computed(()=>feesItemBeingEdited.value ? `Edit` : `Create`);
const marketTitle = computed(()=>feesItemBeingEdited.value ? 'Cannot edit "Market"' : '"Market" is selected from the drop down on the main table"');
const dateTitle = computed(()=>feesItemBeingEdited.value ? 'Cannot edit "Date Valid From"' : 'Edit "Date Valid From"');
const fee1Title = computed(()=>showServiceFees.value ? 
    inputOutsourcingValid.value ? "Edit Outsourcing Fee" : "'Outsourcing Fee' must be a valid number" :
    inputFinancingFeePercentageValid.value ? "Edit Financing Fee Percentage" : "'Financing Fee Percentage' must be a valid number");
const fee2Title = computed(()=>showServiceFees.value ? 
    inputRemarketingValid.value ? "Edit Remarketing Fee" : "'Remarketing Fee' must be a valid number" :
    inputFinancingFeeFixedValid.value ? "Edit Financing Fee Fixed" : "'Financing Fee Fixed' must be a valid number");
const fee3Title = computed(()=>inputLLMPercValid.value ? 'Edit LLM Percentage (1 is 100%)' : '"LLM Percentage" must be a valid number');
const fee4Title = computed(()=>inputIntermediationFeePercentageValid.value ? 'Edit Intermediation Fee Percentage (1 is 100%)' : '"Intermediation" Fee Percentage" must be a valid number');
const fee5Title = computed(()=>inputAmcrPercentageValid.value ? 'Edit VAT Finance Period Months' : '"VAT Finance Period Months" must be a valid intiger');
const fee6Title = computed(()=>inputVatFinancePeriodMonthsValid.value ? 'Edit AMCR Percentage (1 is 100%)' : '"AMCR Percentage" must be a valid number');
const confirmEditTitle = computed(()=>`Edit ${feesTitle.value}.`);

const route = useRoute();
onMounted(async () => {
    const name = route.name?.toString() ?? 'Fees Configuration';
    log.trace(onMounted.name, name);
    document.title = `${name} - Optio`;
    if (route.query.config) {
        showServiceFees.value = route.query.config.toString().includes("service-fees");
    }
    await GetFees();
    marketSelection.value = 'GB'
});
onUnmounted(() => {
    marketSelection.value = 'GB'
})
watch(
    () => (showServiceFees.value),
    () => {
        // update route
        const r: RouteLocationCarsly = {
            name: 'Fees Configuration',
            query: {
                    config: showServiceFees.value
                        ? 'service-fees'
                        : 'financing-fees'
                },
        };
        router.push(r);
    }
)
function updateSelection() {
    feesEditedTo.value.Market = marketSelection.value;
    GetFees();
}
async function GetFees() {
    try {
        const market = marketSelection.value;
        if (showServiceFees.value) {
            try {
                getServiceFeesError.value = undefined;
                displayedServiceFees.value = undefined;
                const res = await serviceFees.get({ market });
                displayedServiceFees.value = res.data.map(x=>new ServiceFees(x));
            } catch(error) {
                getServiceFeesError.value = error;
                throw error;
            }
        } else {
            try {
                getFinancingFeesError.value = undefined;
                displayedFinancingFees.value = undefined;
                const res = await financingFees.get({ market });
                displayedFinancingFees.value = res.data.map(x=>new FinancingFees(x));
            } catch(error) {
                getFinancingFeesError.value = error;
                throw error;
            }
        }
        HighlightGreen();
    } catch(error) {
        const name = __filename + "/" + GetFees.name;
        const errorType = `Getting ${feesTitle.value} tables.`;
        useNotifierWithErrFormatter().error({
            name,
            errorType,
            error
        })
    }
}
function ToggleServiceFees() {
    showServiceFees.value = !showServiceFees.value;
    GetFees();
}
function HighlightGreen() {
    greenHighlight.value = true;
    setTimeout(()=>greenHighlight.value = false, 4000); // milliseconds
}
async function EditFees(fees: ServiceFees | FinancingFees) {
    log.trace(`${EditFees.name}(${JSON.stringify(fees)})`);
    Object.assign(feesEditedTo.value, fees);
    feesEditedTo.value.User = authService.getEmailAddress();
    feesItemBeingEdited.value = fees;
    showFeesPopup.value = true;
}
async function PostEditFees() {
    const name = __filename + "/" + PostEditFees.name;
    try {
        if (!feesItemBeingEdited.value) throw new ReferenceError('feesItemBeingEdited.value cannot be undefined');
        if (!validateFees(feesEditedTo.value, feesItemBeingEdited.value)) return;
        log.trace(`${name}()\nfeesItemBeingEdited: ${JSON.stringify(feesItemBeingEdited.value)}\nfees: ${JSON.stringify(feesEditedTo.value)}`);
        if (isServiceFee(feesEditedTo.value)) {
            await serviceFees.put(feesEditedTo.value);
        } else {
            await financingFees.put(feesEditedTo.value);
        }
        GetFees();
        useNotifierWithErrFormatter().success({
            msg: `${feesTitle.value} Edited.`
        });
        ClosePopup();
    } catch(error) {
        if (error instanceof NoChangeError) {
            useNotifierWithErrFormatter().notify({msg:'No changes have been proposed.'});
            return;
        }
        const errorType = `(Editing ${feesTitle.value})`;
        useNotifierWithErrFormatter().error({
            name,
            errorType,
            error
        });
    }
}
function getDefaultEditFees(): Partial<ServiceFees|FinancingFees> {
    return { Market: marketSelection.value, DateValidFrom: new DateIgnoreTime(''), User: authService.getEmailAddress() };
}
function ClosePopup() {
    const name = ClosePopup.name;
    log.trace(`${name}()`);

    feesItemBeingEdited.value = undefined;
    showFeesPopup.value = false

    feesEditedTo.value = getDefaultEditFees();
}
async function CreateFees() {
    const name = __filename + "/" + CreateFees.name;
    // POST
    try {
        log.trace(`${name}()\nfees: ${JSON.stringify(feesEditedTo.value)}`);
        if (!validateFees(feesEditedTo.value)) return;
        if(isServiceFee(feesEditedTo.value)) await serviceFees.post(feesEditedTo.value);
        else await financingFees.post(feesEditedTo.value);
        GetFees();
        useNotifierWithErrFormatter().success({
            msg: `Created ${feesTitle.value}`
        });
        ClosePopup();
    } catch(error) {
        const errorType = `Creating new ${feesTitle.value}.`;
        useNotifierWithErrFormatter().error({
            errorType,
            error
        });
    }
}
async function DeleteFees() {
    const name = __filename + "/" + DeleteFees.name;
    try {
        // DELETE
        if (!feesItemBeingEdited.value) throw new ReferenceError('feesItemBeingEdited.value cannot be undefined');
        const Message= isServiceFee(feesItemBeingEdited.value)?`You are about to delete the following ${feesTitle.value} item:
    {
        "Market": "${feesItemBeingEdited.value.Market}",
        "DateValidFrom": "${feesItemBeingEdited.value.DateValidFrom}",
        "OutsourcingFee": ${feesItemBeingEdited.value.OutsourcingFee},
        "RemarketingFee": ${feesItemBeingEdited.value.RemarketingFee},
        "User": "${feesItemBeingEdited.value.User}"
    }
` :
`You are about to delete the following ${feesTitle.value} item:
    {        
        "Market": "${feesItemBeingEdited.value.Market}",
        "DateValidFrom": "${feesItemBeingEdited.value.DateValidFrom}",
        "FinancingFeePercentage": ${feesItemBeingEdited.value.FinancingFeePercentage},
        "FinancingFeeFixed": ${feesItemBeingEdited.value.FinancingFeeFixed},
        "LLM Percentage'": ${feesItemBeingEdited.value.LlmPercentage},
        "Intermediation Fee Percentage": ${feesItemBeingEdited.value.IntermediationFeePercentage },
        "AMCR Percentage": ${feesItemBeingEdited.value.AmcrPercentage},
        "VAT Finance Period Months": ${feesItemBeingEdited.value.VatFinancePeriodMonths},
        "User": "${feesItemBeingEdited.value.User}"
    }
`;
        if(!confirm (Message)) return;
        if(isServiceFee(feesItemBeingEdited.value)) await serviceFees.delete(feesItemBeingEdited.value);
        else await financingFees.delete(feesItemBeingEdited.value);
        GetFees();
        useNotifierWithErrFormatter().success({
            msg: `${feesTitle.value} Deleted.`
        });
        ClosePopup();
    } catch(error) {
        const errorType = `Deleting ${feesTitle.value}`;
        useNotifierWithErrFormatter().error({
            name,
            errorType,
            error
        })
    }
}
function ShowCreateFeesPopup() {
    showFeesPopup.value = true;
}
function isServiceFee(x: ServiceFees|FinancingFees): x is ServiceFees {
    return showServiceFees.value;
}
function isEditingServiceFee(x: Partial<ServiceFees>|Partial<FinancingFees>): x is Partial<ServiceFees> {
    return showServiceFees.value;
}
function validateFees(x: Partial<ServiceFees>|Partial<FinancingFees>, ori?: ServiceFees|FinancingFees): x is ServiceFees|FinancingFees {
    if (ori && 
        !Object.entries(x)
            .filter(([k,v])=>k.toLowerCase()!=='user')
            .some(([k,v])=>JSON.stringify(v)!==JSON.stringify(ori[k]))
    ) throw new NoChangeError(`${JSON.stringify(x)}\n${JSON.stringify(ori)}`);
    x.DateValidFrom = new DateIgnoreTime(x.DateValidFrom);
    return true;
}
</script>

<style>
.fade-enter-active,
.fade-leave-active {
    transition: opacity 1s;
}

.fade-enter,
.fade-leave-to .fade-leave-active {
    opacity: 0;
    transition: opacity 2s;
}

.file-selector-fake-button {
    border-radius: var(--buttons-border-radius);
    border-top-right-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
    background-color: var(--primary-color-lighter);
    padding: 10px;
    color: blue;
    font-size: larger;
    font-weight: bold;
}

.file-selector input[type="file"] {
    display: none;
}

.file-selector button {
    border-top-left-radius: 0 !important;
    border-bottom-left-radius: 0 !important;
}

.upload-button {
    display: inline;
    padding-left: 4rem;
    padding-right: 4rem;
    border-radius: var(--buttons-border-radius);
    border-top-right-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
    background-color: var(--primary-color-lighter);
    color: blue;
    font-size: larger;
    font-weight: bold;
}
.search-with-button button:disabled:hover {
    background-color: var(--primary-color-lighter);
    cursor: not-allowed;
}
.errored-button {
    background-color: var(--red-background-color) !important;
    cursor: not-allowed;
    color: black !important;
    border-color: crimson !important;
}
.highlight-green {
    color: #4CAF50;
}

.w3-red,
.w3-hover-red:hover {
    color: #fff !important;
    background-color: #f44336 !important
}

.w3-light-grey,
.w3-hover-light-grey:hover,
.w3-light-gray,
.w3-hover-light-gray:hover {
    color: #000 !important;
    background-color: #f1f1f1 !important
}

.w3-green,
.w3-hover-green:hover {
    color: #fff !important;
    background-color: #4CAF50 !important
}
</style>

<style scoped lang="scss">
@import "@/assets/styles/centre-button.scss";
.FeesConfiguration {
    flex-direction: column;
}
:deep(.invalid-input:invalid) {
    color: red; border-color: red; outline: none;
}
.tab-button-left {
    border-radius: var(--topbar-sidebar-content-radius) 0px 0px 0px;
    padding-left: 2rem;
}
.tab-button-right {
    border-radius: 0px var(--topbar-sidebar-content-radius) 0px 0px;
    padding-right: 2rem;
}
.button-group {
    display: flex;
    flex-direction: column;
    row-gap: 0.3rem;
}
.bottom-margin-only {
    margin-top: 0px;
    margin-left: 0px;
    margin-right: 0px;
    margin-bottom: 10px;
}
.center-buttons button:disabled {
    color: blue;
    background-color: var(--light-grey);
}
.center-buttons-button-disabled:disabled {
    color: var(--primary-color-dark) !important;
    background-color: var(--light-grey);
}
.selected {
    background-color: var(--primary-color-lighter) !important;
    border-width: 0 0 .2rem 0;
    border-color: blue;
}
.unselected {
    color: #b6b6b6;
    background-color: #f0f0f0;
    font-weight: normal;
    border-color: white;
}
.confirm-button {
    border-width: 0px;
    border-radius: var(--buttons-border-radius);
    background-color: var(--primary-color-lighter);
    padding: 10px;
    color: blue;
    font-size: larger;
    font-weight: bold;
    width: 100%;
}
.confirm-button:hover {
    background-color: var(--primary-color);
    color: white;
    cursor: pointer;
}
.confirm-button:disabled {
    color: #b6b6b6;
    background-color: var(--light-grey);
}
.confirm-button:disabled:hover {
    background-color: var(--primary-color-lighter);
    cursor: not-allowed;
}
.delete-button {
    margin-top: 10px;
    border-width: 0px;
    border-radius: var(--buttons-border-radius);
    background-color: var(--red-background-color);
    padding: 10px;
    color: red;
    font-size: larger;
    font-weight: bold;
    width: 100%;
}
.delete-button:hover {
    background-color: red;
    color: white;
    cursor: pointer;
}
.overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 9999;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.popup {
    display: flex;
    flex-direction: column;
    row-gap: 1rem;
    background-color: white;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
    max-width: 75%;
    max-height: 75%;
    overflow-y: auto;
    label {
        display: flex;
        align-items: center;
        grid-column-start: 1;
    }
}
.popup-button {
    margin-top: 1rem;
    border-radius: var(--table-button-radius);
    border-width: 1px;
    border-color: blue;
    color: blue;
    padding-left: 1.6rem;
    padding-right: 1.6rem;
    padding-top: 0.4rem;
    padding-bottom: 0.4rem;
}
.popup-button:hover {
    cursor: pointer;
    background-color: var(--primary-color);
    color: white;
}
.description-title {
    font-size: 1.3rem;
    text-align: center;
}
.col2 {
    grid-column-start: 2;
}
.description-subtitle {
    display: grid;
    grid-auto-flow: dense;
    row-gap: 0.5lh;
    column-gap: 2ch;
    text-align: center;
}
.description-subtitle :deep(input) {
    font-size: .8rem;
    border-radius: .4rem;
    padding-left: .6rem;
    padding-top: .2rem;
    padding-bottom: .2rem;
}
.label {
    vertical-align: middle;
}
.label-bold {
    vertical-align: middle;
    font-weight: bold;
}
.wrap {
    white-space: pre-line;
}
.anw-info-textbox {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 1rem;
}
.details-column {
    width: 33.33%;
    float: left;
    padding: .4rem;
}
.details-column-title {
    font-size: large;
    font-weight: bold;
    text-align: center;
    color: var(--primary-color-dark);
}
@media only screen and (max-width: 104rem) {
    .details-column {
        width: 33.33%;
        float: none;
        margin-top: 30px;
    }
}
.text-link {
    color: blue;
    text-decoration: underline;
}
.text-link:hover {
    cursor: pointer;
}
.text-link-denied {
    color: #333;
    border: none;
    background-color: transparent;
}
</style>