import IDrawdownItem from "@/Interfaces/VATLoans/IDrawdownItem";
import IVatLoan from "@/Interfaces/VATLoans/IVatLoan";
import DatePeriod from "@/types/DatePeriod";

export class DrawdownTableItem {
    Market: string;
    DrawdownDate: Date;
    DrawdownAmount: number;
    DrawdownPeriod: DatePeriod;
    SumVatLoans?: number;
    VatLoans: IVatLoan[];
    UserEmail?: string;

    constructor(data: IDrawdownItem) {
        this.Market = data.Market;
        this.DrawdownDate = data.DrawdownDate;
        this.DrawdownAmount = parseFloat(data.DrawdownAmount!);
        this.DrawdownPeriod = new DatePeriod(data.DrawdownPeriod);
        this.VatLoans = data.VatLoans;
        this.UserEmail = data.UserEmail!;
    }
}