import ITransferPaymentsItem from "@/Interfaces/Ledgering/ITransferPaymentsItem";
import DateIgnoreTime from "@/types/DateIgnoreTime";
import DateTimeSv from "@/types/DateTimeSv";
import LedgerTransaction from "./LedgerTransaction";

export default class TransferPaymentTable implements ITransferPaymentsItem {
    TransactionId: number;
    Market: string;
    TransferTypeId: string;
    AccountFrom: string;
    AccountTo: string;
    ValueDate: DateIgnoreTime;
    TargetDate: DateIgnoreTime;
    Amount: number;
    Status: string;
    TransferTypeIdentifier: string;
    UserStatusUpdated: string;
    DateTimeStatusUpdated: DateTimeSv;
    Transactions?: LedgerTransaction[];

    constructor(data: ITransferPaymentsItem) {
        this.TransactionId = data.TransactionId;
        this.Market = data.Market;
        this.TransferTypeId = data.TransferTypeId;
        this.AccountFrom = data.AccountFrom;
        this.AccountTo = data.AccountTo;
        this.ValueDate = new DateIgnoreTime(data.ValueDate);
        this.TargetDate = new DateIgnoreTime(data.TargetDate);
        this.Amount = +data.Amount;
        this.Status = data.Status;
        this.TransferTypeIdentifier = data.TransferTypeIdentifier;
        this.UserStatusUpdated = data.UserStatusUpdated;
        this.DateTimeStatusUpdated = new DateTimeSv(data.DateTimeStatusUpdated);
    }
}