<template id="VATPayments">
    <div class="tabs-container">
        <div class="top-tabs tabs-buttons">
            <div class="tabs">
                <button
                    :class="`button-left ${showSubmittedVAT ? 'selected' : 'unselected'}`"
                    :disabled="showSubmittedVAT"
                    :title="
                        showSubmittedVAT
                            ? 'Currently viewing VAT submission history'
                            : 'Click to go to VAT submission form'
                    "
                    @click=toggleTabs
                >
                    Submitted VAT
                </button>
            </div>
            <div class="tabs">
                <button
                    :class="`button-right ${showSubmittedVAT ? 'unselected' : 'selected'}`"
                    :disabled="!showSubmittedVAT"
                    :title="
                        !showSubmittedVAT
                            ? 'Currently viewing VAT submission form'
                            : 'Click to view submitted VAT history'
                    "
                    @click=toggleTabs
                >
                    Confirmed
                </button>
            </div>
        </div>
    </div>
    <div class="content-container" style="justify-content: flex-start;">
        <div class="top-content">
            <form v-if=showSubmittedVAT class="form-container selection-form" @submit.prevent="submit(payment)">
                <MarketSelector @change=updateMarket />
                <PeriodSelector id="payment-period"
                    v-model=payment.period 
                    showTitle
                    @update:model-value=onUpdatePeriod />
                <div class="flex-col-centered date-picker selection">
                    <label for="vat-return-date"><h3 class="header">VAT Return Date</h3></label>
                    <DatePicker id="vat-return-date"
                        v-model=payment.VATReturnDate
                        placeholder="Select Date..."
                        :format=dateFormat
                        :minDate=minPaymentDay
                        :maxDate="new Date()"
                        :startDate="new Date()"
                        :enable-time-picker=false
                        prevent-min-max-navigation
                        textInput
                        teleportCenter
                        teleport
                        required
                    />
                </div>
                <div class="flex-col-centered selection">
                    <label for="vat-total" class="header"><h3>Total Amount</h3></label>
                    <CurrencyInput id="vat-total"
                        v-model="payment.totalAmount"
                        :currency="MarketCurrency[marketSelection]"
                        :min=0
                        title="Total amount of VAT return received"
                        required
                        />
                </div>
                <div  class="flex-col-centered selection">
                    <label for="vat-corporate"><h3 class="header">Corporate Amount</h3></label>
                    <CurrencyInput id="vat-corporate"
                        v-model=payment.corporateAmount
                        :min=0
                        :max=payment.totalAmount
                        title="The portion that goes to corporate account"
                        required
                        />
                </div>
                <button
                    type="submit"
                    class="default-btn"
                    :title=submitHint
                    :disabled=working
                >
                    Submit Form
                </button>
            </form>
            <div v-else class="flex-row-centered">
                <MarketSelector @change=updateMarketSelection />
                <button type="button"
                    class="default-btn flex-col-centered"
                    @click="downloadAll" 
                    @submit.prevent="downloadAll"
                    :title="exportHint"
                    :disabled=working
                >
                    Export to CSV
                </button>
            </div>
        </div>
        <TablePersistentChecking v-if="!showSubmittedVAT"
            :Rows="displayedTransferPayments"
            :Cols="cols"
            :Labels="labels"
            :HasError=tableError
            :DefaultSortCols="defaultSortCols"
            :DefaultSortOrders="defaultSortOrders" />
    </div>
</template>

<script setup lang="ts">
import { ref, onMounted, computed, inject } from "vue";
import { TransferPaymentsHelper } from "@/services/helpers/Ledgering/LedgerTransferPaymentsHelper";
import {
    getCols,
    getLabels,
    getDefaultSortCols,
    getDefaultSortOrders
} from "@/services/helpers/Ledgering/LedgerTransferPaymentsHelper";
import TransferPaymentTable from "@/models/Ledgering/TransferPaymentTable";
import TablePersistentChecking from "@/components/TablePersistentChecking.vue";
import {  loggerKey } from "@/types/ServiceKeys";
import { authService } from "@/auth/authSingleton";
import { GetAuthLevel } from "../config/AuthConfig";
import DbTableCsvHelper from "@/services/helpers/DbTableCsvHelper";
import LedgerTransferStatus from "@/types/LedgerTransferStatus";
import '@vuepic/vue-datepicker/dist/main.css';
import { apiLoggerKey } from '@/types/ServiceKeys';
import { dateFormat, marketSelection, MarketSelection, financialSystemNetworkService, MarketCurrency } from "@/services/helpers/Helper";
import DatePicker from '@vuepic/vue-datepicker';
import MarketSelector from '@/components/MarketSelector.vue';
import PeriodSelector from '@/components/PeriodSelector.vue';
import IPaymentVATForm from '@/Interfaces/VATReturns/IPaymentVATForm';
import CurrencyInput from '@/components/CurrencyInput.vue';
import { fromFormToReq } from '@/Interfaces/VATReturns/IPaymentVATSubmitReq';
import useNotifierWithErrFormatter from "@/services/composables/NotifierWithErrFormatter";
import useSpinnerPopup from "@/services/composables/SpinnerPopup";


const getHistory= true;
const labels = getLabels(getHistory);
const cols = getCols(getHistory);
const defaultSortCols = getDefaultSortCols(getHistory);
const defaultSortOrders = getDefaultSortOrders(getHistory);
const LedgerServices = new TransferPaymentsHelper();
const tableError = ref(false);
const originalTransferPayments = ref<TransferPaymentTable[]>();
const displayedTransferPayments = ref<TransferPaymentTable[]>();
const transferType= 'HMRC';
const showSubmittedVAT= ref(true);
const working = ref(false);

const log = inject(loggerKey);
const logger = inject(apiLoggerKey);
const minPaymentDay = computed(()=>
    payment.value.period 
        ? payment.value.period
        : undefined
);
const payment = ref<Partial<IPaymentVATForm>>({ market: marketSelection.value });
//Error Info Props
const submitHint = 'Submit the payment form';
const exportHint = 'Export VAT Payments';

onMounted(() => {
    document.title='VAT Payments - Optio';
    if (!showSubmittedVAT.value) getTransferPayments();
});

async function getTransferPayments() {
    try {
        tableError.value = false;
        displayedTransferPayments.value = undefined;
        const urlParams =
            process.env.VUE_APP_LEDGERING_TRANSFER_PAYMENTS_URL_PARAMS ?? "ledger-transfers";
        const data = await LedgerServices.getTransferPayments(
            urlParams,
            LedgerTransferStatus.COMPLETED,
            marketSelection.value,
            transferType
        );
        originalTransferPayments.value = data.map((x) => new TransferPaymentTable(x));
        displayedTransferPayments.value = originalTransferPayments.value?.filter((x) =>
            x.UserStatusUpdated?.includes("@")
        ); 
    } catch (error) {
        tableError.value = true;
        const errorType = 'Getting Transfer Payments from API';
        useNotifierWithErrFormatter().error({
            errorType,
            error
        })
    }
}
function toggleTabs() {
    if (showSubmittedVAT.value) getTransferPayments();
    showSubmittedVAT.value = !showSubmittedVAT.value;
}
function updateMarketSelection() {
    getTransferPayments();
}
async function downloadAll() {
    const userRoleLevel = GetAuthLevel(authService.getUsersRole());
    // No difference between the two???
    const formattedData = (userRoleLevel > 5 ? originalTransferPayments.value?.filter((x) =>
            x.UserStatusUpdated?.includes("@")) :
            displayedTransferPayments.value) ?? [];
    DbTableCsvHelper.generateCsvDownload(formattedData, "ConfirmedTransferPayments.csv");
}
function validatePaymentRaw(payment: Partial<IPaymentVATForm>): payment is IPaymentVATForm {
    const msgs = [] as string[];
    function assert(result: boolean, msg: string) {
        if (!result) msgs.push(msg);
        return result;
    }
    //short circuit operation
    const result = assert(MarketSelection[payment.market ?? ''],'You must choose a valid market.')
            && assert(!!payment.period, 'You must select a period')
            && assert(!!payment.VATReturnDate, 'You must select a return date')
            && assert(!minPaymentDay.value || payment.VATReturnDate! >= minPaymentDay.value, 'Return date must be within the peroid')
            && assert(payment.VATReturnDate!.zeroTime() <= new Date(), 'Return date must be within the period')
            && assert(!!payment.corporateAmount && !!payment.totalAmount, 'You must enter the VAT amounts')
            && assert(!Number.isNaN(+payment.corporateAmount!), 'Corporate amount must be a valid number')
            && assert(!Number.isNaN(+payment.totalAmount!), 'Total amount must be a valid number')
            && assert(+payment.corporateAmount! <= +payment.totalAmount!, 'Corporate amount must be less than or equal to total');
    if (!result) window.alert(msgs.join('\n'));
    return result;
}
function updateMarket() {
    payment.value.market = marketSelection.value;
}
function onUpdatePeriod() {
    payment.value.VATReturnDate = undefined;
}
//this function name is a bit silly it triggers on every button or other element with a default submit
async function submit(payment: Partial<IPaymentVATForm>) {
    log?.trace(submit.name, payment, working.value);
    if (working.value) return;
    working.value = true;

    // validate data
    // if something wrong, shows warning
    if (validatePaymentRaw(payment)) {
        const json = fromFormToReq(payment);
        const currency = '£';
        const name = submit.name;
        const Message = `submitting payment form: ${JSON.stringify(json)};`;
        const Class = __filename + "/" + name;

        const confirm = window.confirm(
            `Please confirm the VAT payment details:\n\n` +
            `Period of the VAT return: ${json.period}\n`+
            `Date of payment receive: ${json.VATReturnDate}\n`+
            `Total received: ${currency}${json.totalAmount}\n` +
            `Corporate amount: ${currency}${json.corporateAmount}`
        );
        if (confirm) {
            const close = useSpinnerPopup().show()
            try {
                logger?.LogInformation(Message, Class);
                await financialSystemNetworkService.post(json, process.env.VUE_APP_ADD_PAYMENT_URL_PARAMS ?? 'vat-payments/add');
                useNotifierWithErrFormatter().success({
                    msg: 'VAT payment successfully registered.'
                });
            } catch (error) {
                useNotifierWithErrFormatter().error({
                    errorType: 'Submitting VAT Payment',
                    error
                });
            } finally {
                close();
                working.value = false;
            }
        }
    }
    working.value = false;
}
</script>

<style scoped lang="scss">
.form-container {
    display:grid;
    grid-template-columns: auto auto auto;
    gap: 20px;
    justify-content: space-evenly;
    justify-items: center;
    align-items: center;
    >*{
        min-width: 10rem;
        width: 100%;
        >*{
            width: 100%;
        }
    }
    :deep(select) {
        width: 100%;
    }
}
.form-container-confirmed {
    display:grid;
    grid-template-columns: auto auto;
    gap: 20px;
    justify-content: space-evenly;
    justify-items: center;
    align-items: center;
    >*{
        min-width: 10rem;
        width: 100%;
        >*{
            width: 100%;
        }
    }
    :deep(select) {
        width: 100%;
    }
}
</style>