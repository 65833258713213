<template>
    <Popup :modelValue=displayingForm 
    @update:model-value=closeChild 
    title="Record Received Interest" 
    hasClose
    >
        <template #contents>
            <div class=form-container>
                <form class="two-column" @submit.prevent=confirmPayment>
                    <div class="description-subtitle">
                        <label for=inputMarket>
                            Market
                        </label>
                        <input id="inputMarket"
                            ref=inputMarket
                            class="col2"
                            type="text"
                            title="'Market' is selected from the drop down on the main table"
                            :value="interestPaymentToConfirm.market"
                            disabled
                            required
                        >
                        <label for=accountInterestReceived>
                            Account Interest Received
                        </label>
                        <select id="accountInterestReceived"
                            ref=accountInterestReceived
                            class="col2"
                            title="Select Account Interest Received"
                            v-model=interestPaymentToConfirm.receivedAccountType 
                            required
                            >
                            <option value=null disabled selected hidden>mandatory</option>
                            <option v-for="accountType of receivedAccountTypes"
                                :value=accountType
                                :key=accountType >
                            {{ accountType }}
                            </option>
                        </select>
                        <label for=accountInterestAccumulated>
                            Account Interest Accumulated
                        </label>
                        <select id="accountInterestAccumulated"
                            ref=accountInterestAccumulated
                            class="col2"
                            title="Select Account Interest Accumulated"
                            v-model=interestPaymentToConfirm.accumulatedAccountType 
                            required
                            >
                            <option value=null disabled selected hidden>mandatory</option>
                            <option v-for="accountType of accumulatedAccountTypes"
                                :value=accountType
                                :key=accountType >
                            {{ accountType }}
                            </option>
                        </select>
                        <label for=amount>
                            Amount
                        </label>
                        <CurrencyInput 
                            id="amount"
                            ref=amount
                            v-model=interestPaymentToConfirm.amount
                            class="col2 invalid-input"
                            :currency=MarketCurrency[marketSelection]
                            title="Input interest payment amount."
                            :min=0.01
                            :placeholder="'Amount'"
                            required
                        />
                        <label for="interestPeriod">
                            Interest Period
                        </label>
                        <PeriodSelector 
                            id="interestPeriod"
                            class="select"
                            type="text"
                            title="Select interest period"
                            v-model=interestPaymentToConfirm.interestPeriod
                        />
                        <label for="interestDateReceived">
                            Interest Date Received
                        </label>
                        <input id="interestDateReceived"
                            ref="interestDateReceived"
                            class="col2"
                            type="date"
                            title="Select interest date received"
                            v-model=interestPaymentToConfirm.interestDateReceived
                            required
                        />
                    </div>
                    <button :disabled=working
                        class="confirm-button" 
                        type="submit"
                    >
                        Confirm
                    </button>
                </form>
            </div>
        </template>
    </Popup>
</template>

<script setup lang="ts">
import '@vuepic/vue-datepicker/dist/main.css';
import {ref,computed} from 'vue';
import Popup from '../Popup.vue';
import PeriodSelector from '../PeriodSelector.vue';
import useSpinnerPopup from '@/services/composables/SpinnerPopup';
import IInterestPaymentRequest from '@/Interfaces/Ledgering/IInterestPaymentRequest';
import { confirmInterestPayment } from '@/services/network';
import { MarketCurrency, marketSelection } from '@/services/helpers/Helper';
import useNotifierWithErrFormatter from '@/services/composables/NotifierWithErrFormatter';
import { authService } from "@/auth/authSingleton";
import CurrencyInput from "@/components/CurrencyInput.vue";

const props = defineProps<{
    modelValue: boolean;
}>();
const emits = defineEmits<{
    (e:'update:modelValue', val: boolean): void;
    (e:'confirm')
}>();
const displayingForm = computed({
    get(){
        interestPaymentToConfirm.value.market = marketSelection.value;
        interestPaymentToConfirm.value.userStatusUpdated = authService.getEmailAddress();
        return props.modelValue;
    },
    set(val: boolean) {
        emits('update:modelValue', val);
    }
});
const working = ref(false);
const interestPaymentToConfirm = ref<Partial<IInterestPaymentRequest>>({});
enum AccountInterestReceived {
    COLLECTION = 'COLLECTION',
    CORPORATE = 'CORPORATE',
    RESERVE = 'RESERVE'
}
enum AccountInterestAccumulated {
    COLLECTION = 'COLLECTION',
    CORPORATE = 'CORPORATE',
    RESERVE = 'RESERVE'
}
const receivedAccountTypes : string[] = Object.values(AccountInterestReceived)
const accumulatedAccountTypes : string[] = Object.values(AccountInterestAccumulated)

function closeChild() {
    interestPaymentToConfirm.value = { market: marketSelection.value } as Partial<IInterestPaymentRequest>;
    displayingForm.value = false;
}
function validateRequest(obj: Partial<IInterestPaymentRequest>): obj is IInterestPaymentRequest {
    let validationResult = true;
    const date = obj.interestDateReceived;
    const period = obj.interestPeriod;
    if( !!date && !!period) {
        if (Date.parse(date.toString()) < Date.parse(period.toString())) {
            validationResult = false;
            useNotifierWithErrFormatter().warn({
                msg: "Interest Date Received must not be earlier than Interest Period"
            })
        }
    }
    else {validationResult = false}
    return validationResult;
}
async function confirmPayment() {
    if (working.value) return;
    working.value = true;
    const closeSpinner = useSpinnerPopup().show();
    try {
        if (!validateRequest(interestPaymentToConfirm.value)) return
        const interestPaymentRequest: IInterestPaymentRequest = interestPaymentToConfirm.value;
        const res = await confirmInterestPayment.post(interestPaymentRequest);
        useNotifierWithErrFormatter().success({msg: 'Transaction successfully registered.'});
        closeChild();
        emits('confirm');
    } catch (error) {
        useNotifierWithErrFormatter().error({
            errorType: 'Submitting Interest Payment',
            error
        });
    } finally {
        closeSpinner();
        working.value=false
    }
}
</script>

<style scoped lang="scss">
@import "@/assets/styles/centre-button.scss";

:deep(.invalid-input:invalid) {
    color: red; border-color: red; outline: none;
}
.tab-button-left {
    border-radius: var(--topbar-sidebar-content-radius) 0px 0px 0px;
    padding-left: 2rem;
}
.tab-button-right {
    border-radius: 0px var(--topbar-sidebar-content-radius) 0px 0px;
    padding-right: 2rem;
}
.button-group {
    display: flex;
    flex-direction: column;
    row-gap: 0.3rem;
}
.bottom-margin-only {
    margin-top: 0px;
    margin-left: 0px;
    margin-right: 0px;
    margin-bottom: 10px;
}
.center-buttons button:disabled {
    color: blue;
    background-color: var(--light-grey);
}
.center-buttons-button-disabled:disabled {
    color: var(--primary-color-dark) !important;
    background-color: var(--light-grey);
}
.selected {
    background-color: var(--primary-color-lighter) !important;
    border-width: 0 0 .2rem 0;
    border-color: blue;
}
.unselected {
    color: #b6b6b6;
    background-color: #f0f0f0;
    font-weight: normal;
    border-color: white;
}
.confirm-button {
    border-width: 0px;
    border-radius: var(--buttons-border-radius);
    background-color: var(--primary-color-lighter);
    padding: 10px;
    color: blue;
    font-size: larger;
    font-weight: bold;
    width: 100%;
}
.confirm-button:hover {
    background-color: var(--primary-color);
    color: white;
    cursor: pointer;
}
.confirm-button:disabled {
    color: #b6b6b6;
    background-color: var(--light-grey);
}
.confirm-button:disabled:hover {
    background-color: var(--primary-color-lighter);
    cursor: not-allowed;
}
.delete-button {
    margin-top: 10px;
    border-width: 0px;
    border-radius: var(--buttons-border-radius);
    background-color: var(--red-background-color);
    padding: 10px;
    color: red;
    font-size: larger;
    font-weight: bold;
    width: 100%;
}
.delete-button:hover {
    background-color: red;
    color: white;
    cursor: pointer;
}
.overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 9999;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.description-title {
    font-size: 1.3rem;
    text-align: center;
}
.col2 {
    grid-column-start: 2;
}
.description-subtitle {
    display: grid;
    grid-auto-flow: dense;
    row-gap: 0.5lh;
    column-gap: 2ch;
    text-align: center;
}
.description-subtitle :deep(input) {
    font-size: .8rem;
    border-radius: .4rem;
    padding-left: .6rem;
    padding-top: .2rem;
    padding-bottom: .2rem;
}
.description-subtitle :deep(select) {
    font-size: .8rem;
    border-radius: .4rem;
    padding-left: .6rem;
    padding-top: .2rem;
    padding-bottom: .2rem;
}
.label {
    vertical-align: middle;
}
.label-bold {
    vertical-align: middle;
    font-weight: bold;
}
.wrap {
    white-space: pre-line;
}
.anw-info-textbox {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 1rem;
}
.details-column {
    width: 33.33%;
    float: left;
    padding: .4rem;
}
.details-column-title {
    font-size: large;
    font-weight: bold;
    text-align: center;
    color: var(--primary-color-dark);
}


.form-container {
    flex: 1 1 auto;
    >.error {
        vertical-align: middle;
        text-align: center;
    }
    >form.two-column {
        display: grid;
        row-gap: 0.25rem;
        column-gap: 0.5rem;
        grid-template-columns: auto auto;
        align-items: center;

        >label {
            text-align: left;
            vertical-align: middle;
        }
        .input,
        :deep(input),
        :deep(select),
        select {
            border-color: black;
            font-size: 0.8rem;
            border-radius: 0.4rem;
            padding-top: 0.2rem;
            padding-bottom: 0.2rem;
            border-width: 2px;
            text-align: left;
        }
        select:invalid,
        select option[value=""] {
            color: grey;
        }
        select option {
            color: black;
        }
        >.confirm-button {
            margin-top: 10px;
            border-width: 0px;
            border-radius: var(--buttons-border-radius);
            background-color: var(--primary-color-lighter);
            padding: 10px;
            color: blue;
            font-size: larger;
            font-weight: bold;
            width: 100%;
            grid-column: span 2;
            &:hover {
                background-color: var(--primary-color);
                color: white;
                cursor: pointer;
            }
            &:disabled {
                color: #b6b6b6;
                background-color: var(--light-grey);
                &:hover {
                    background-color: var(--primary-color-lighter);
                    cursor: not-allowed;
                }
            }
        }
    }
}

</style>