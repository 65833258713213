<template>
    <div v-if="showWarning" class="desc-warning">
        <div class="warning">
            <div class="close-error">
                <button @click="showWarning = false" v-if="closeWarning">X</button>
            </div>
            <div class="error-header">{{ warningTitle }} {{ warningDescription }}:</div>
            <div>
                {{ warningDisplayText }}
            </div>
            <div class="warning-details">
                <button type="button" @click="ToggleWarningDetails" :title="warningTitle">
                    <span>i</span>
                </button>
            </div>
            <br />
            <div
                v-if="showWarningDetails"
                title="When contacting the help desk, please quote this error details"
                class="warning-details-text"
            >
                <div class="warning-details-header">Warning Details:</div>
                <div v-if="axiosError">
                    {{
                        `${warning}`.endsWith(".")
                            ? `${warning}`.slice(0, -1)
                            : `${warning}`
                    }}{{
                        warning?.response?.statusText
                            ? ` ${warning?.response?.statusText}`
                            : ""
                    }}
                    - message: "{{ warning?.response?.data?.Error }}".
                </div>
                <div v-else>
                    {{ warning?.message }}
                </div>
            </div>
        </div>
    </div>
</template>

<script setup lang="ts" generic="T extends any">
import { AxiosError } from "axios";
import log from "loglevel";
import { ref, watch } from "vue";
export interface IWarningInfoProps<T> {
    showWarning: boolean;
    closeWarning?: boolean;
    warningTitle?: string;
    warningDescription?: string;
    warningDisplayText?: string;
    warning?: AxiosError;
    axiosError?: boolean;
    change?: boolean;
}
const props = withDefaults(defineProps<IWarningInfoProps<any>>(), {
    showWarning: false,
    closeWarning: false,
    warningTitle: "Warning",
    warningDisplayText:
        "Your request could not be fulfilled at this moment due to an error with your request, please check your input and try again later. If this problem persists, please contact the help desk.",
    axiosError: true,
});

const showWarningDetails = ref(false);
const showWarning = ref(props.showWarning);

function ToggleWarningDetails() {
    showWarningDetails.value = !showWarningDetails.value;
}

watch(
    () => props.change,
    () => {
        log.debug("Changer changed");
        showWarning.value = props.showWarning;
    }
);
</script>

<style scoped>
.warning-details {
    padding-bottom: 0.3em;
}

.warning-details-text {
    font-size: 0.65rem;
}

.warning-details-header {
    font-size: 0.9rem;
    padding-bottom: 0.1rem;
}

.warning-details button {
    float: right;
    border-width: 0px;
    background-color: transparent;
    color: inherit;
    font-weight: 700;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 1.2rem;
    height: 1.2rem;
    border-radius: 50%;
    border: 0.1rem solid currentColor;
    font-size: 0.8rem;
}

.warning-details button:hover {
    font-weight: 900;
    cursor: pointer;
    border: 0.15rem solid currentColor;
}

.warning-details button:visited {
    color: inherit;
}
</style>
