<template>
    <Popup
        :model-value=!!contractsToDownload
        title="Download Specification"
        hasClose
        @update:model-value="close"   
    >
        <template  #contents>
            <div class="grid-item">
                Please specify Download Options
            </div>
            <div class="contract-aproval-popup-flex">
                <form class="top-content"> 
                    <div class="contract-aproval-popup-flex">
                        <span style="padding-bottom: 0.4rem">Select Owner:</span>
                        <input type="text" v-model=ownerFieldInput name="OwnerField">
                    </div>
                    <br>
                        <PeriodSelector class="periodSelector" :defaultRefDate="earliestPeriod"  v-model=selectedPeriod showTitle/>
                    <br>
                    <button class="download-btn default-btn right-btn" 
                    :title="'Approve selected contracts'"
                    type="button" 
                    :disabled="downloading"
                    @click=proceedToDownload()>
                        Download
                </button>
                </form>
            </div>
        </template>
    </Popup>
</template>

<script setup lang="ts">
import { computed, defineProps, defineEmits, ref } from "vue";
import "@vuepic/vue-datepicker/dist/main.css";
import Popup from "@/components/Popup.vue" 
import useSpinnerPopup from "@/services/composables/SpinnerPopup";
import useNotifierWithErrFormatter from '@/services/composables/NotifierWithErrFormatter';
import DbTableCsvHelper from '@/services/helpers/DbTableCsvHelper';
import { contractApprovalExport } from  '@/services/network/index'
import IContractApproval from "@/Interfaces/Contract/IContractApproval";
import PeriodSelector from "@/components/PeriodSelector.vue";
import DatePeriod from "@/types/DatePeriod";
import DateIgnoreTime from "@/types/DateIgnoreTime";

const selectedPeriod = ref();
const ownerFieldInput = ref('')
const props = defineProps<{
    modelValue?: IContractApproval[];
}>();

const emit = defineEmits<{
    (e: 'update:modelValue', val?: IContractApproval[]);
    (e: 'update:downloadToggle', val);
    (e: 'confirm');
}>();

const contractsToDownload = computed({
    get() {
        return props.modelValue;
    },
    set(val) { 
        emit('update:modelValue', val); 
        
    }
});

const earliestPeriod = computed(()=>contractsToDownload.value?.reduce
((curDate, x)=>{
    const periodToCompare = x.activationDate ? new DatePeriod(x.activationDate) : undefined;
    if(curDate) {
        return periodToCompare ? periodToCompare < curDate ? periodToCompare : curDate : curDate;
    } else {
        return periodToCompare;
    }
}, undefined as DatePeriod | undefined)
)

function close() {
    contractsToDownload?.value?.forEach(element => {
        element.toDownload = false
    });
    contractsToDownload.value=undefined;
}

const downloading = ref(false)

async function proceedToDownload() {
  if(downloading.value) return
  downloading.value = true;
  const closeSpinner = useSpinnerPopup().show();
  if (!ownerFieldInput.value) {
    useNotifierWithErrFormatter().warn({ msg: 'Owner must be specified' });
  } else {
    
    try {
        const responseData = await fetchData();
        // Change column names
        const remappedData = responseData.map(row => {
            row.issueDate = new DateIgnoreTime(row.issueDate)
            row.activationDate = new DateIgnoreTime(row.activationDate)
            const obj = Object.assign(row, { [`RV ${selectedPeriod.value}`]: row.rvValue, } )
            obj.rvValue = undefined;
            return obj;
        });
        DbTableCsvHelper.generateCsvDownload(remappedData, `EligibleContracts.csv`);
        close()      
    } catch (error) {
      useNotifierWithErrFormatter().error({
        errorType:  'Downloading Eligible Contracts',
    
        error
      });
    }
  }
  closeSpinner();
  downloading.value = false;
}

async function fetchData() {
    if (!contractsToDownload.value) throw new ReferenceError('contractsToDownload is not supposed to be undefined');
    const response = await contractApprovalExport.post({
      owner: ownerFieldInput.value.toString(),
      rvPeriod: selectedPeriod.value,
      contractIDs: contractsToDownload.value.map(x=> x.contractID)
    });
    return response.data;
}

</script>

<style scoped>
.contract-aproval-popup-flex{
    display: flex;
    flex-direction: column;
}
.grid-item {
    padding: 10px;
}

.periodSelector {
    flex-grow: 1;
    align-items: stretch;
}
:deep(.dropdown) {
    flex-grow: 1;
}
form.top-content {
    align-items: stretch;
    display: flex;
    flex-direction: column;
    row-gap: 10px;
}
</style>
