<template>
    <div class="CustomReports">
        <div v-if="isLoading" class="content-container">
            <div class="loading">
                <span class="loader"></span>
            </div>
        </div>
        <div v-else class="content-container" style="padding-left: 0; padding-right: 0;">
            <div class="top-content">
                <GetReportsInGroupErrorInfo
                    :showError="showGetReportsInGroupError"
                    errorDescription="(Getting Custom Reports From Power BI)"
                    :error="infoError"
                />
                <div class="center-dropdown-table-container">
                    <div class="center-dropdown-table">
                        <tbody>
                            <tr>
                                <td>
                                    <div>Report Selection:</div>
                                    <select
                                        v-model="customReportId"
                                        @change="updateSelection()"
                                        class="custom-report-dropdown"
                                    >
                                        <option
                                            v-for="report in reportsInGroup"
                                            :value="report.id"
                                            :key="report.name"
                                        >
                                            {{ report.name }}
                                        </option>
                                    </select>
                                </td>
                                <td colspan="2">
                                    <div class="center-buttons">
                                        <button
                                            @click="NavigateToPowerBIWorkspace()"
                                            class="incident-nav-button"
                                            title="Create a new report in PowerBI (3rd party resource opens in new tab)"
                                        >
                                            Create New Report
                                        </button>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </div>
                </div>
            </div>
            <div class="power-bi">
                <iframe
                    title="CustomReports"
                    :src="customReportUrl"
                    frameborder="0"
                    allowFullScreen="true"
                    :key="iframeKey"
                ></iframe>
            </div>
        </div>
    </div>
</template>

<script setup lang="ts">
import { Ref, inject, onMounted, onBeforeUnmount, ref } from "vue";
// Services
import { IAPILogger } from "@/interfaces/IAPILogger";
import { GetReportsInGroup, NavigateToPowerBIWorkspace, GetReportsInGroupErrorInfo } from "@/services/helpers/CustomReportsHelper"
// Data
import { IReportInGroupDetails } from "@/Interfaces/CustomReports/IGetReportsInGroupResponse";
// Misc
import log from "loglevel";
import { AxiosError } from "axios";

document.title = `Reports - Optio`;

log.trace("Reports");
const fileName = "CustomReports.vue";
const Logger: IAPILogger = inject("Logger")!;

////////////////////////////////////////////////////////////////////////////
// LOGIC
////////////////////////////////////////////////////////////////////////////
// DATA FETCHING
const isLoading = ref(false);
const reportsInGroup: Ref<IReportInGroupDetails[] | undefined> = ref(undefined);
const customReportSelection: Ref<string> = ref("");
const customReportId: Ref<string> = ref("");
const customReportUrl: Ref<string> = ref("");

// Create a ref to store the visibility state
const isPageVisible = ref(true);

// Bind iframe key to reactive property
const iframeKey = ref(0);

// Function to handle visibility change
const handleVisibilityChange = () => {
  if (document.hidden) {
    // Page is not visible
    isPageVisible.value = false;
    log.debug("isPageVisible:", isPageVisible.value);
  } else {
    // Page is visible
    isPageVisible.value = true;
    log.debug("isPageVisible:", isPageVisible.value);
    // iframeKey.value += 1;
    iframeKey.value = 1;
    log.debug("iframeKey:", iframeKey.value);
  }
};

onMounted(async () => {
    log.trace("onMounted()");

    // Add event listener for visibility change
    document.addEventListener('visibilitychange', handleVisibilityChange);

    isLoading.value = true;

    const name = GetReportsInGroup.name;
    const Class = fileName + "/" + name;
    var Message = `Getting reports in group from Power BI API`;

    await GetReportsInGroup()
        .then((reports: IReportInGroupDetails[]) => {
            log.debug("onMounted()\nreports:", reports);
            if (reports) {
                reportsInGroup.value = reports;
                reportsInGroup.value.sort((a: IReportInGroupDetails, b: IReportInGroupDetails) =>
                    a.name.localeCompare(b.name)
                );
                const report: IReportInGroupDetails = reports.at(0)!;
                // const report: IReportInGroupDetails = reports.find((r) => {
                //     r.name.includes("DatabaseTables");
                // }) || reports.at(0)!;
                log.trace("onMounted()\nreport:", report);
                customReportId.value = report.id;
                Logger.LogInformation(Message, Class);

                updateSelection();
            }
        })
        .catch(error => {
            if (error instanceof AxiosError) {
                showGetReportsInGroupError.value = true;
                infoError.value = error;
            }

            Message = `(Error) ${Message}: ${error instanceof AxiosError ? error : error.message}`;
            log.error(Message);
            Logger.LogError(Message, Class);
        });

    isLoading.value = false;
});

// Remove event listener when the component is unmounted
onBeforeUnmount(() => {
    document.removeEventListener('visibilitychange', handleVisibilityChange);
});

async function updateSelection() {
    log.trace("updateSelection()");

    const name = updateSelection.name;
    const Message = `Changed selection to: ${customReportSelection.value}.`;
    const Class = fileName + "/" + name;

    customReportUrl.value = process.env.VUE_APP_POWER_BI_CUSTOM_REPORT_URL_PREFIX! + customReportId.value + process.env.VUE_APP_POWER_BI_CUSTOM_REPORT_URL_SUFFIX!;
    log.debug("updateSelection()\ncustomReportUrl:", customReportUrl.value, "\nid:", customReportId.value);

    Logger.LogInformation(Message, Class);
}

// ERRORS
const showGetReportsInGroupError: Ref<boolean> = ref(false);
const infoError: Ref<AxiosError | undefined> = ref(undefined);
</script>

<style scoped lang="scss">
.loading {
    width: 100%;
    height: 100%;
}
.custom-report-dropdown {
    width: 20rem !important;
}
</style>
@/Interfaces/Logger@/Interfaces/IAPILogger