<script setup lang="ts" generic="T extends string|number|symbol">
import {computed,onMounted,ref} from 'vue';
enum LabelPosition {
    Bottom,
    Top,
}
defineOptions({
    inheritAttrs: false,
});
const props = withDefaults(defineProps<{
    modelValue: T;
    id: string;
    label: string;
    labelPosition?: LabelPosition;
    hideCheck?: boolean;
}>(), {
    labelPosition: LabelPosition.Bottom,
});
const emits = defineEmits<{
    (e: 'update:modelValue', val: T);
    (e: 'change', val: HTMLElement | undefined, id: string);
}>();
const assign = computed({
    get() {
        return props.modelValue;
    },
    set(val) {
        emits('update:modelValue', val);
    }
});
const checkedEl = ref<HTMLElement>();
const btnHoverSize = '1.25lh';
const btnSize = '1lh';
const selectedSize = '0.9lh';
onMounted(()=>{
    if ((checkedEl.value?.parentElement?.parentElement?.parentElement?.children.item(0) as HTMLInputElement).checked) emits('change', checkedEl.value, props.id);
})
</script>

<template>
<label :class="[$attrs.class, 'flex-col-centered', { reverse: labelPosition === LabelPosition.Bottom }, 'radio-btn-cont']" :for=id>
    <input v-bind=$attrs
        class="actual"
        type="radio"
        :id=id
        v-model=assign
        @change="$emit('change', checkedEl, id)" >
    <div class="radio-label">{{ label }}</div>
    <div class="radio-btn-wrapper">
        <div class="radio-btn"><div class="radio-on" ref=checkedEl></div></div>
    </div>
</label>
</template>

<style lang="scss" scoped>
.flex-col-centered {
    &.reverse {
        flex-direction: column-reverse;
    }
    &.radio-btn-cont {
        row-gap: 1ch;
        align-items: stretch;
        >.radio-label {
            padding-left: 1ch;
            padding-right: 1ch;
        }
    }
}
.flex-row-centered {
    &.reverse {
        flex-direction: row-reverse;
    }
    &.radio-btn-cont {
        column-gap: 1ch;
    }
}
.radio-btn-cont {
    >.radio-btn-wrapper {
        display: flex;
        justify-content: center;
        align-items: center;

        min-height: v-bind(btnHoverSize);
        min-width: v-bind(btnHoverSize);

        >.radio-btn {
            display: flex;
            justify-content: center;
            align-items: center;

            height: v-bind(btnSize);
            width: v-bind(btnSize);

            border: 0.1rem solid var(--primary-color);
            border-radius: 50%;
            transition: all 0.15s ease-in-out;

            &:hover {
                cursor: pointer;
                height: v-bind(btnHoverSize);
                width: v-bind(btnHoverSize);
            }
            >.radio-on {
                visibility: hidden;
                height: 0.9ch;
                width: 0.9ch;
                background: var(--primary-color);
                border-radius: 50%;
            }
        }
    }
    .actual {
        display: none;
        &:checked {
            ~.radio-btn-wrapper {
                >.radio-btn {
                    height: v-bind(selectedSize);
                    width: v-bind(selectedSize);
                    &:hover {
                        cursor: unset;
                    }
                    >.radio-on {
                        anchor-name: v-bind("`--${id}`");
                        height: 0.9ch;
                        width: 0.9ch;
                        background: var(--primary-color);
                        border-radius: 50%;

                        transition: all 0.15s ease-in-out;
                        visibility: v-bind("`${hideCheck?'hidden':'unset'}`");
                    }
                }
            }
        }
    }
}
</style>